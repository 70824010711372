import React, {useMemo} from "react";

import {useCreateAsset} from "providers/CreateAsset";
import {usePrice, useWeb3} from "providers";
import {errorChecker} from "utils";

import S from "./style.module.scss";

export const StepBlockchain = () => {
  const {fullPriceList, chainList, listOfNetwork} = usePrice();
  const {isCheckUserChain} = useWeb3();
  const {setStep} = useCreateAsset();

  const listOfChains = useMemo(() => {
    const names = Object.keys(chainList);

    return listOfNetwork.filter((el) => {
      return names.includes(String(el.chainId));
    });
  }, [listOfNetwork, chainList]);

  const onClick = async (chainId: number) => {
    try {
      await isCheckUserChain(Number(chainId));
      setStep("uploadFile");
    } catch (err) {
      errorChecker(err);
    }
  };

  return (
    <div className={S.StepBlockchain}>
      <ul>
        {listOfChains && listOfChains.map((chain, index) => {
          return (
            <li
              key={index}
              onClick={() => onClick(chain.chainId)}
            >
              <img
                src={fullPriceList.filter((el) => el.chainIds.includes(chain.chainId))[0].logo}
                alt={chain.name}
              />
              <div className={S.Title}>{chain.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
