import React from "react";
import ContentLoader from "react-content-loader";

export const AssetLeftSideContentSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={820}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%"}}
  >
    <rect
      x="0"
      y="0"
      rx="0"
      ry="0"
      width="40%"
      height="44"
    />
    <rect
      x="calc(100% - 50px)"
      y="12"
      rx="0"
      ry="0"
      width="50"
      height="20"
    />
    <rect
      x="calc(100% - 110px)"
      y="12"
      rx="0"
      ry="0"
      width="50"
      height="20"
    />
    <rect
      x="calc(100% - 220px)"
      y="12"
      rx="0"
      ry="0"
      width="80"
      height="20"
    />
    <rect
      x="0"
      y="68"
      rx="0"
      ry="0"
      width="100%"
      height="34"
    />
    <rect
      x="0"
      y="120"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="124"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="146"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="230"
      y="120"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="282"
      y="124"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="282"
      y="146"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="calc(100% - 70px)"
      y="124"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="calc(100% - 150px)"
      y="146"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="210"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="236"
      rx="0"
      ry="0"
      width="100%"
      height="80"
    />
    <rect
      x="0"
      y="350"
      rx="0"
      ry="0"
      width="100"
      height="24"
    />
    <rect
      x="0"
      y="388"
      rx="0"
      ry="0"
      width="210"
      height="28"
    />
    <rect
      x="0"
      y="428"
      rx="0"
      ry="0"
      width="210"
      height="10"
    />
    <rect
      x="calc(100% - 90px)"
      y="352"
      rx="0"
      ry="0"
      width="90"
      height="10"
    />
    <rect
      x="calc(100% - 220px)"
      y="352"
      rx="0"
      ry="0"
      width="120"
      height="10"
    />
    <rect
      x="calc(100% - 320px)"
      y="352"
      rx="0"
      ry="0"
      width="90"
      height="10"
    />
    <rect
      x="calc(100% - 156px)"
      y="384"
      rx="0"
      ry="0"
      width="156"
      height="48"
    />
    <rect
      x="0"
      y="474"
      rx="0"
      ry="0"
      width="100"
      height="24"
    />
    <rect
      x="0"
      y="512"
      rx="0"
      ry="0"
      width="210"
      height="28"
    />
    <rect
      x="0"
      y="552"
      rx="0"
      ry="0"
      width="210"
      height="10"
    />
    <rect
      x="calc(100% - 90px)"
      y="476"
      rx="0"
      ry="0"
      width="90"
      height="10"
    />
    <rect
      x="calc(100% - 156px)"
      y="506"
      rx="0"
      ry="0"
      width="156"
      height="48"
    />
    <rect
      x="0"
      y="620"
      rx="0"
      ry="0"
      width="100%"
      height="45"
    />
    <rect
      x="0"
      y="692"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="696"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="718"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="765"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="769"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="791"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
  </ContentLoader>
);

export const AssetLeftSideContentSkeletonMobile = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={820}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%"}}
  >
    <rect
      x="0"
      y="0"
      rx="0"
      ry="0"
      width="100%"
      height="20"
    />
    <rect
      x="calc(100% - 50px)"
      y="34"
      rx="0"
      ry="0"
      width="50"
      height="20"
    />
    <rect
      x="calc(100% - 110px)"
      y="34"
      rx="0"
      ry="0"
      width="50"
      height="20"
    />
    <rect
      x="0"
      y="34"
      rx="0"
      ry="0"
      width="80"
      height="20"
    />
    <rect
      x="0"
      y="76"
      rx="0"
      ry="0"
      width="100%"
      height="32"
    />
    <rect
      x="0"
      y="126"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="130"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="152"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="186"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="190"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="212"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="246"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="0"
      y="268"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="316"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="346"
      rx="0"
      ry="0"
      width="100%"
      height="80"
    />
    <rect
      x="0"
      y="466"
      rx="0"
      ry="0"
      width="92"
      height="20"
    />
    <rect
      x="calc(100% - 92px)"
      y="471"
      rx="0"
      ry="0"
      width="92"
      height="10"
    />
    <rect
      x="10%"
      y="505"
      rx="0"
      ry="0"
      width="80%"
      height="10"
    />
    <rect
      x="10%"
      y="525"
      rx="0"
      ry="0"
      width="80%"
      height="20"
    />
    <rect
      x="10%"
      y="555"
      rx="0"
      ry="0"
      width="80%"
      height="10"
    />
    <rect
      x="0"
      y="585"
      rx="0"
      ry="0"
      width="100%"
      height="48"
    />
    <rect
      x="0"
      y="655"
      rx="0"
      ry="0"
      width="100%"
      height="32"
    />
    <rect
      x="0"
      y="710"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="716"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="738"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="770"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="776"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="798"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
  </ContentLoader>
);


