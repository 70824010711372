import React from "react";

export const NotificationNewComment = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26346)"
      />
      <path
        d="M25.4925 12C25.4925 11.1712 24.8287 10.5 24 10.5H12C11.1712 10.5 10.5 11.1712 10.5 12V21C10.5 21.8287 11.1712 22.5 12 22.5H22.5L25.5 25.5L25.4925 12Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26346"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#0082C8"
          />
          <stop
            offset="1"
            stopColor="#667DB6"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
