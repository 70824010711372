export type TCryptoCurrency = "" | "ETH" | "MATIC" | "BTC" | "BNB";

export enum ECryptoCurrency {
  "ETH" = "ethereum",
  "Polygon" = "matic",
  "BTC" = "bitcoin",
  "BNB" = "binance"
}

export enum ECryptoCurrencyNameConvert {
  "ETH" = "ETH",
  "Polygon" = "MATIC",
  "BTC" = "BTC",
  "BNB" = "BNB"
}
