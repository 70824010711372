import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const HotIcon = ({size = 36, fill = "black"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.52571 20.5714H16.3382L12.232 36L30.24 15.4286H18.4275L22.5257 0L4.52571 20.5714Z"
        fill={fill}
      />
    </svg>
  );
};
