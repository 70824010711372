import React from "react";
import {Link} from "react-router-dom";

import WalletInstallImg from "assets/img/Faq/img-wallet-install.png";
import ConnectWalletImg from "assets/img/Faq/img-connect-wallet.png";
import InfoUserImg from "assets/img/Faq/img-info-user.png";
import CoinBaseImg from "assets/img/Faq/img-coinbase-com.png";
import {HelpPageHeader} from "pages/HelpFAQ/Layout/Header";
import {ImgComponentFAQ, TextComponentFAQ, WrapperComponentFAQ} from "pages/HelpFAQ/Layout/Components";
import {useTheme} from "hooks/useTheme";

export const WalletCoinbase = () => {
  const theme = useTheme();

  return (
    <>
      <HelpPageHeader
        title="How to connect my wallet via Coinbase"
        breadcrumb="What is a floor price?"
      />
      <WrapperComponentFAQ>
        <TextComponentFAQ>
          <p>The first thing you need before starting using <Link to="/">{theme === "nftim"
            ? "nftim.io"
            : "helpua.io"}</Link> is to get a crypto-wallet.</p>
          <p>We support two crypto-wallet providers: <a
            href="https://metamask.io"
            target="_blank"
          >Metamask</a> and <a
            href="https://www.coinbase.com/ru/wallet"
            target="_blank"
          >Coinbase</a>. You can choose any of them.</p>
          <br/>
          <p>Whether you already have your crypto-wallet installed, or not, click on the blue “Connect wallet” button and then choose your crypto wallet provider.</p>
        </TextComponentFAQ>

        <ImgComponentFAQ classRow="Left">
          <img
            src={ConnectWalletImg}
            alt=""
          />
        </ImgComponentFAQ>

        <TextComponentFAQ>
          <p>If you already have your crypto-wallet all set up, then you will see a sign request from your crypto-wallet provider. Sign it and your wallet will connect.</p>
          <p>
            You will see your profile name (by default it{"'"}s your wallet address) and balance in the upper right corner, that{"'"}s mean you have successfully connected your wallet and are ready to buy NFTs.
          </p>
        </TextComponentFAQ>

        <ImgComponentFAQ classRow="Left">
          <img
            src={InfoUserImg}
            alt=""
          />
        </ImgComponentFAQ>

        <TextComponentFAQ>
          <p>If you don{"'"}t have your wallet installed yet, then after choosing Coinbase, another popup will appear.</p>
        </TextComponentFAQ>

        <ImgComponentFAQ classRow="Left">
          <img
            src={WalletInstallImg}
            alt=""
          />
        </ImgComponentFAQ>

        <TextComponentFAQ>
          <p>Click on <a
            href="https://www.coinbase.com/ru/wallet"
            target="_blank"
          >«Install Coinbase Wallet»</a> and you will be transferred to the official Coinbase site, where you can download the Coinbase wallet and see further instructions on how to install and use it.
          </p>
        </TextComponentFAQ>

        <ImgComponentFAQ classRow="Left">
          <img
            src={CoinBaseImg}
            alt=""
          />
        </ImgComponentFAQ>
      </WrapperComponentFAQ>
    </>
  );
};
