export const ROUTES = {
  home: "/",
  asset: "/asset/:id",
  userPage: "/profile/:id",
  settings: "/settings",
  aboutUs: "/about-us",
  terms: "/terms",
  marketplace: "/marketplace",
  errorPage: "/404",
  helpCenter: "/help/*",
  aboutHelpUs: "/about",
  HelpFAQ: "/HelpFAQ/*",
  collection: "/collection/:id",
  allCollection: "/all-collection",
  supportPeople: "/support-people/:id",
  other: "/*",
  createNft: "/create",
  portfolio: "/portfolio",
  portfolioPage: "/portfolio/:id",
};
