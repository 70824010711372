/**
 * support fiat list https://docs.sendwyre.com/docs/supported-currencies#available-currencies---blockchains
 */

/**
 * support crypto list https://docs.sendwyre.com/docs/supported-currencies#available-currencies---blockchains
 * test card https://docs.sendwyre.com/docs/wyre-checkout-1#test-cards
 */
export const WyreSupportConstant = [
  "BTC",
  "ETH",
  "XLM",
  "sUSDC",
  "AVAX",
  "AVAXC",
  "AVAXC",
  "AVAXCUSDC",
  "DAI",
  "pDAI",
  "USDC",
  "mUSDC",
  "MATIC",
  "L-BTC",
  "USDT",
  "BUSD",
  "FUSD",
  "GUSD",
  "PAX",
  "USDS",
  "AAVE",
  "COMP",
  "LINK",
  "WBTC",
  "WETH",
  "BAT",
  "CRV",
  "MKR",
  "SNX",
  "UMA",
  "UNI",
  "YFI",
  "GYEN",
  "ZUSD",
  "RAI",
  "LETH",
  "LUSDC",
  "ALGO",
  "AUSDC",
  "AUSDT"
];
