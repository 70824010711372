import React from "react";

import {PlusIcon} from "assets/icons/PlusIcon";
import {Carousel} from "components/Carousel";
import {ResponsiveDesktop} from "components/Responsive";
import {
  ImagesSvgKuna,
  ImagesSvgLamborghini,
  ImagesSvgMinistry,
  ImagesSvgNftim,
  ImagesSvgNova
} from "assets/icons/PartnerList";
import {classNames} from "utils";

import S from "./style.module.scss";


export const CharityTopBlock = () => {
  const scrollToCardBlock = () => {
    const charity = document.querySelector("#charityWrapper") as HTMLDivElement;

    window.scrollTo({
      top: charity.offsetTop - 150,
      behavior: "smooth"
    });
  };

  const PartnerList = [
    {link: "#", svg: <ImagesSvgNftim/>},
    {link: "#", svg: <ImagesSvgMinistry/>},
    {link: "#", svg: <ImagesSvgNova/>},
    {link: "#", svg: <ImagesSvgLamborghini/>},
    {link: "#", svg: <ImagesSvgKuna/>},
  ];

  return (
    <div className={S.CharityTopBlockWrapper}>
      <div className={S.BGImg}/>
      <div className={S.Content}>
        <div className={classNames(S.FlexRow, "min1025:!max-w-[1240px] mx-auto")}>
          <div className={S.Left}>
            <div className={S.TitleFirst}>DISCOVERY AND BUY UNIQUE NFT’S TO</div>
            <div className={S.TitleSecond}>SUPPORT <span>UKRAINE</span></div>
            <div className={S.Subtitle}>
              <p>We create and sell unique NFT’s to support Ukrainian people.</p>
              <p>Buy NFT and follow to support Ukrainian</p>
            </div>
            <button
              onClick={scrollToCardBlock}
              className={S.BuyNowButton}
            >
              <PlusIcon
                size={20}
                fill={"#000"}
              />
              Buy NFT now
            </button>
          </div>
          <ResponsiveDesktop>
            <div className={S.Right}>
              <Carousel isFade={true}/>
            </div>
          </ResponsiveDesktop>
        </div>
        <div className={S.PartnerList}>
          <div className={S.ListText}>
            <span>A LOT OF COMPANIES HAVE</span>
            <p>SUPPORTING HELPUA.IO</p>
          </div>
          <ul>
            {PartnerList.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.link}
                    target={"_blank"}
                  >{item.svg}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
