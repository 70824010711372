import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {}

export const SelectArrow = (
  {
    size = 10,
    fill = "#9E9EB4"
  }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99985 0.150061C4.82063 0.150061 4.64143 0.218491 4.50479 0.355064L0.205019 4.65488C-0.0685025 4.9284 -0.0685025 5.37187 0.205019 5.64528C0.47843 5.91869 0.921811 5.91869 1.19535 5.64528L4.99985 1.84057L8.80437 5.64515C9.07789 5.91856 9.52123 5.91856 9.79462 5.64515C10.0683 5.37174 10.0683 4.92827 9.79462 4.65475L5.49491 0.354931C5.3582 0.218336 5.179 0.150061 4.99985 0.150061Z"
        fill={fill}
      />
    </svg>
  );
};
