import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {}


export const ArrowRight = ({onClick, className, fill = "white"}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.942688 11.1452L5.71873 6.36914L0.942688 1.5931L2.41665 0.119141L8.66665 6.36914L2.41665 12.6191L0.942688 11.1452Z"
      fill={fill}
    />
  </svg>
);
