import React from "react";

export const CharityLogoKuna = () => (
  <svg width="130"
    height="130"
    viewBox="0 0 408 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M192.943 112.569L164.339 71.3896L191.78 32.2749H170.618L145.735 67.1455V32.2749H127.13V112.569H145.735V75.4043L171.781 112.569H192.943Z"
      fill="black"/>
    <path d="M237.886 55.2161V86.5308C237.886 94.6749 233.468 98.3455 227.072 98.3455C221.491 98.3455 216.956 95.019 216.956 87.9073V55.2161H199.515V90.4308C199.515 105.916 209.514 114.175 221.258 114.175C228.933 114.175 234.747 111.422 237.886 107.178V112.569H255.328V55.2161H237.886Z"
      fill="black"/>
    <path d="M305.452 53.6102C297.778 53.6102 291.964 56.3631 288.824 60.6073V55.2161H271.383V112.569H288.824V81.2543C288.824 73.1102 293.243 69.4396 299.638 69.4396C305.219 69.4396 309.754 72.7661 309.754 79.8779V112.569H327.196V77.3543C327.196 61.869 317.196 53.6102 305.452 53.6102Z"
      fill="black"/>
    <path d="M384.646 55.2161V60.6073C380.809 56.2484 375.111 53.6102 367.32 53.6102C352.088 53.6102 339.53 66.8014 339.53 83.8926C339.53 100.984 352.088 114.175 367.32 114.175C375.111 114.175 380.809 111.537 384.646 107.178V112.569H402.087V55.2161H384.646ZM370.809 97.8867C362.786 97.8867 356.972 92.4955 356.972 83.8926C356.972 75.2896 362.786 69.8984 370.809 69.8984C378.832 69.8984 384.646 75.2896 384.646 83.8926C384.646 92.4955 378.832 97.8867 370.809 97.8867Z"
      fill="black"/>
    <path d="M79.0322 130C91.6022 130 98.9773 116.091 91.7833 105.953L64.6595 67.7269C62.7863 65.087 62.7947 61.5759 64.6804 58.9446L89.6479 24.1057C96.909 13.9737 89.5416 0 76.9385 0L15.5495 0C6.96174 0 7.38079e-10 6.84741 7.38081e-10 15.2941C7.38081e-10 15.2941 7.68427e-05 105.912 7.38106e-10 114.706C-7.68412e-05 123.5 5.99992 130 15.4999 130C24.9999 130 79.0322 130 79.0322 130Z"
      fill="#312A7D"/>
    <path fillRule="evenodd"
      clipRule="evenodd"
      d="M7.38063e-10 114.706C-7.24285e-05 122.995 5.3307 129.246 13.8986 129.937C39.1632 94.6471 64.3814 59.3618 89.6479 24.1057C96.909 13.9737 89.5416 0 76.9385 0H15.5495C6.96174 0 7.38063e-10 6.84741 7.38063e-10 15.2941V114.706Z"
      fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear"
        x1="6.92659"
        y1="4.92424"
        x2="100.32"
        y2="144.197"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#47D3FF"/>
        <stop offset="1"
          stopColor="#2195EA"/>
      </linearGradient>
    </defs>
  </svg>

);
