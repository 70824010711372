import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const NotificationSuccess = ({size = 36}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2901_23925)"
      />
      <g
        clipPath="url(#clip0_2901_23925)"
      >
        <path
          d="M25.5 17.3026V18.0001C25.4991 19.6175 24.9754 21.1911 24.007 22.4865C23.0386 23.7819 21.6775 24.7295 20.1265 25.1881C18.5756 25.6466 16.9179 25.5916 15.4009 25.0311C13.8838 24.4706 12.5885 23.4347 11.7082 22.0779C10.8279 20.7212 10.4098 19.1162 10.5163 17.5024C10.6227 15.8886 11.2479 14.3524 12.2987 13.1229C13.3495 11.8935 14.7696 11.0367 16.3471 10.6803C17.9247 10.3238 19.5752 10.4869 21.0525 11.1451"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.25 11.25L18 19.5L15.75 17.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2901_23925"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F4037"/>
          <stop
            offset="1"
            stopColor="#99F2C8"
          />
        </linearGradient>
        <clipPath id="clip0_2901_23925">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>

  );
};
