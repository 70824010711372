import React from "react";

import {Container} from "components/Container";
import {Title} from "components/HELPUA/Title";
import {NFTIMCollectionContainer} from "components/NFTIM/Collection/Container";

export const HomeNFTIMTrendingCollection = () => {
  return (
    <Container>
      <div className={"grid"}>
        <Title
          title={"COLLECTIONS"}
          underTitle={"COLLECTION"}
          description={"Popular & new collections"}
        />
      </div>
      <NFTIMCollectionContainer/>
    </Container>
  );
};
