import React from "react";

import {Container} from "components/Container";
import {AboutNftim} from "components/NFTIM/Home/About/AboutNftim";

export const HomeNFTIMAbout = () => {
  return (
    <Container className={"mb-16"}>
      <AboutNftim/>
    </Container>
  );
};
