import React from "react";
import {Link} from "react-router-dom";

import imgVal1 from "assets/img/charity/val/ico-val-1.png";
import imgVal2 from "assets/img/charity/val/ico-val-2.png";
import imgVal3 from "assets/img/charity/val/ico-val-3.png";
import imgVal4 from "assets/img/charity/val/ico-val-4.png";
import imgVal5 from "assets/img/charity/val/ico-val-5.png";
import imgVal6 from "assets/img/charity/val/ico-val-6.png";

import {classNames} from "../../../utils";

import S from "./style.module.scss";


const ListFirst = [
  {img: imgVal1, title: "ETHEREUM", text: "Blockchain technology"},
  {img: imgVal2, title: "POLYGON", text: "Blockchain technology"},
  {img: imgVal3, title: "BINANCE SMART CHAIN", text: "Blockchain technology"},
  {img: imgVal4, title: "METAMASK", text: "Crypto Wallet"},
  {img: imgVal5, title: "COINBASE", text: "Crypto Wallet"},
  {img: imgVal6, title: "SENDWYRE", text: "Change fiat to crypto"},
];

const ListSecond = [
  {
    img: imgVal4,
    title: "How to install",
    text: "MetaMask wallet?",
    linkPC: "https://metamask.io/",
    linkAndriod: "https://play.google.com/store/apps/details?id=io.metamask",
    linkIOS: "https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202",
    linkHow: "#"
  },
  {
    img: imgVal5,
    title: "How to install",
    text: "Coinbase wallet?",
    linkPC: "https://www.coinbase.com/wallet",
    linkAndriod: "https://play.google.com/store/apps/details?id=org.toshi",
    linkIOS: "https://apps.apple.com/us/app/coinbase-buy-bitcoin-ether/id886427730",
    linkHow: "#"
  },
  {
    img: imgVal6,
    title: "How to change fiat to crypto",
    text: "Sendwyre service",
    linkPC: "#",
    linkAndriod: "#",
    linkIOS: "#",
    linkHow: "/"
  },
];

const ListFaq = [
  {to: "helpFAQ/wallet-coinbase", text: "How to connect my wallet via Coinbase?"},
  {to: "HelpFAQ/wallet-metamask", text: "How to connect my wallet via Metamask"},
  {to: "HelpFAQ/blockchain", text: "What is blockchain and which of them does HelpUA support?"},
  {to: "HelpFAQ/crypto", text: "What is a crypto wallet and how to get one?"},
];

export const CharityTechnology = () => {
  return (
    <div className={classNames(S.CharityTechnology, "CharityWrapper")}>
      <div className={"CharityInnerWrapper"}>
        <div className={S.Title}>
          <span>Stack of technologies used on the project</span>
          <p>HELPUA - HELP TO UKRAINIAN PEOPLE</p>
        </div>
        <ul className={S.ListFirst}>
          {ListFirst.map((item, index) => (
            <li key={index}>
              <div className={S.Ico}><img src={item.img} alt=""/></div>
              <div className={S.Desc}>
                <span>{item.title}</span>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
        <ul className={S.ListSecond}>
          {ListSecond.map((item, index) => (
            <li key={index}>
              <div className={S.Ico}><img src={item.img} alt=""/></div>
              <div className={S.Desc}>
                <span>{item.title}</span>
                <p>{item.text}</p>
              </div>
              <div className={S.Links}>

                {item.linkPC == "#" ? "" : (<a href={item.linkPC}>PC / Mac</a>)}
                {item.linkAndriod == "#" ? "" : (<a href={item.linkAndriod}>Android</a>)}
                {item.linkIOS == "#" ? "" : (<a href={item.linkIOS}>IOS</a>)}
                {item.linkHow == "#" ? "" : (<a href={item.linkHow}>How to change instruction</a>)}

              </div>
            </li>
          ))}
        </ul>
        <div className={S.ListThird}>
          <div className={S.Desc}>Answers to frequently asked questions:</div>
          <ul>
            {ListFaq.map((item, index) => (
              <li key={index}>
                <Link to={item.to}>{item.text}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
