import React from "react";
import {Link} from "react-router-dom";

import {useWeb3} from "providers";
import {BurgerCrossIconNoPadding} from "assets/icons/BurgerCrossIcon";
import {IUser} from "types";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {Status} from "components/Header/MenuContent/MenuProfileDesktop/status";
import {StatusButton} from "components/Header/MenuContent/MenuProfileDesktop/button";

import S from "./style.module.scss";

interface IProps {
  onWalletClick: () => void;
  auth: boolean;
  onClose: () => void;
  onLogout: () => void;
  user?: IUser;
  navigationList?: any[];
  classStatus?: boolean;
  showPopup?: () => void;
  showSetting?: () => void;
}

export const BurgerMenuProfile = ({
  auth,
  onClose,
  onLogout,
  user,
  classStatus,
  showPopup,
  showSetting
}: IProps) => {
  const {web3State} = useWeb3();
  const theme = useTheme();

  const ProfileList = [
    {to: `/profile/${user?.id}#profile`, name: "Profile", hash: "#profile", theme: "nftim"},
    {to: `/profile/${user?.id}#nft`, name: "My NFTs", hash: "#nft", theme: "all"},
    {to: `/profile/${user?.id}#project`, name: "Projects", hash: "#project", theme: "all"},
  ];

  return (
    <div className={classNames(S.DropWrapper, S[theme], classStatus ? S.OpenAnimate : S.CloseAnimate)}>
      <div className={S.DropHead}>
        <BurgerCrossIconNoPadding onClick={onClose}/>
      </div>
      <div className={S.DropBody}>
        <div className={S.DropBodyScrolled}>
          <div className={S.List}>
            {theme === "nftim" && <Status/>}
            <div className={S.UserBalance}>
              <span>Balance:</span>
              <p>{web3State?.balance} {web3State?.symbol}</p>
            </div>
            {theme === "nftim" && (
              <StatusButton
                showPopup={showPopup}
                className={S.Buttons}
                onClose={onClose}
              />
            )}
            <ul className={S.NaviList}>
              {ProfileList.filter(item => item.theme === theme).map((item, index) => {
                return (
                  <li
                    className={location.pathname == `/profile/${user?.id}` && location.hash === item.hash ? S.ActiveLi : ""}
                    key={index}
                  >
                    <Link
                      to={item.to}
                      onClick={onClose}
                    ><span>•</span> {item.name}</Link>
                  </li>
                );
              })}
              {ProfileList.filter(item => item.theme === "all").map((item, index) => {
                return (
                  <li
                    className={location.pathname == `/profile/${user?.id}` && location.hash === item.hash ? S.ActiveLi : ""}
                    key={index}
                  >
                    <Link
                      to={item.to}
                      onClick={onClose}
                    ><span>•</span> {item.name}</Link>
                  </li>
                );
              })}
              <li>
                <a
                  onClick={showSetting}
                  className={"cursor-pointer"}
                >
                  <span>•</span> Setting
                </a>
              </li>
            </ul>
            {!auth ? (
              ""
            ) : (
              <ul className={S.OutRow}>
                <li
                  onClick={() => {
                    onLogout();
                    onClose();
                  }}
                >Log out
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
