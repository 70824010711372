import React from "react";

import ImgHeart0 from "assets/img/charity/img-heart-0.png";
import {classNames} from "utils";
import {NFTIMLogo} from "assets/icons/CharityLogos/NFTIMLogo";
import {CharityLogoMinistry} from "assets/icons/CharityLogoMinistry";
import {WorldCelebrityIcon} from "assets/icons/WorldCelebrity";

import S from "./style.module.scss";

export const CharityInfo = () => {
  return (
    <div className={classNames(S.CharityInfo, "CharityWrapper")}>
      <div className={"CharityInnerWrapper"}>
        <div className={S.FlexBox}>
          <div className={S.LeftRow}>
            <div className={S.Title}>
              <div className={S.TextFirst}>NFT’S DROP TO SUPPORT</div>
              <div className={S.TextSecond}>UKRAINE</div>
            </div>
            <div className={S.Description}>
              <p>Ukraine is now on the verge of a humanitarian catastrophe. Hundreds of houses have been destroyed, and
                many people keep fleeing their homes. To help Ukraine, Ukrainian artists, musicians, entrepreneurs, and
                IT specialists came together.</p>
              <p>Everyone can donate to Ukraine and receive a blue-yellow heart as a piece of an NFT art. All raised
                funds will be sent for the vital needs of the refugees.</p>
            </div>
            {/*<a href="#" className={S.ButtonDonate}>*/}
            {/*  <span className={S.Desc}>Donate with PayPal</span>*/}
            {/*  <span className={S.Arrow}>*/}
            {/*    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <path d="M0.942688 11.1459L5.71873 6.36987L0.942688 1.59383L2.41665 0.119873L8.66665 6.36987L2.41665 12.6199L0.942688 11.1459Z" fill="white"/>*/}
            {/*    </svg>*/}
            {/*  </span>*/}
            {/*</a>*/}
          </div>
          <div className={S.RightRow}>
            <div className={S.HeartBox}>
              <div className={S.CircleFirst}>
                <span className={S.ChildFirst}>
                  <p>
                    <WorldCelebrityIcon/>
                  </p>
                </span>
              </div>
              <div className={S.CircleSecond}>
                <span className={S.ChildFirst}>
                  <p>
                    <CharityLogoMinistry/>
                  </p>
                </span>
                {/*<span className={S.ChildSecond}><p><img src={ImgHeart3} alt=""/></p></span>*/}
              </div>
              <div className={S.CircleThird}>
                <span className={S.ChildFirst}>
                  <p>
                    <NFTIMLogo size={80}/>
                  </p>
                </span>
              </div>
              <div className={S.Heart}>
                <img
                  src={ImgHeart0}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
