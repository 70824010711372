import React, {ReactNode} from "react";

interface IProps {
  status?: boolean;
  children?: ReactNode;
}

export const DevMode = (
  {
    status = true,
    children
  }:IProps) => {
  return <>{!status && children}</>;
};
