import {httpWyre} from "api/src/api/wyre/api.init";
import {IOrdersReserveRequest, IOrdersReserveResponse} from "types";

export const ApiWYRE = {
  wyre: {
    ordersReserve: async (body: any) => httpWyre.post<IOrdersReserveRequest, IOrdersReserveResponse>(
      "v3/orders/reserve",
      {
        body
      }
    )
  }
};
