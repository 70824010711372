import React from "react";
import {Link} from "react-router-dom";

import {UserInfo} from "pages/Asset/UserInfo";
import {Time} from "components/Time";
import {Price} from "components/Price";
import {LightningIcon} from "assets/icons/LightningIconFilters";
import {AssetsTables} from "components/Asset/AssetTabs/Tables";

import S from "./style.module.scss";

export const OtherOffersPopupDesktop = () => {
  return (
    <AssetsTables className={S.AssetsTables}>
      <tr>
        <td>
          <div>
            <UserInfo
              avatar={"https://helpuadevmedia.blob.core.windows.net/public/CACHE/images/user/photos/2022/03/11/5730f72c-8682-4632-bab4-f37374d6da3c/c86cedab8760f71a9b6d3cf526dd34df.jpg"}
              label={"Owner"}
              title={"0x87A9f6be3d40214964f0D383FD86A23121Ddcc9b"}
              text={
                <>
                  <Link to={"/profile/1"}>0x87A9f6be3d40214964f0D383FD86A23121Ddcc9b</Link>
                </>
              }
            />
          </div>
        </td>
        <td>
          <div>
            <UserInfo
              classNameDesc={S.TimerDesc}
              label={"auction will be ended:"}
              text={
                <Time date={2022232323201}/>
              }
            />
          </div>
        </td>
        <td>
          <div>
            <Price
              classNamePriceCrypto={S.PriceSize}
              isShowFiat={false}
              price={"9999999999999"}
            />
          </div>
        </td>
        <td>
          <div>
            <UserInfo
              label={"in stock"}
              text={"10"}
              classNameText={S.TextSize}
            />
          </div>
        </td>
        <td>
          <div>
            <ul className={S.ListIcons}>
              <li title={"buy now price available"}><LightningIcon size={22}/></li>
            </ul>
          </div>
        </td>
        <td>
          <div>
            <a
              href="#"
              className={S.View}
            >View offer {">"}</a>
          </div>
        </td>
      </tr>
    </AssetsTables>
  );
};
