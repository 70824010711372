import React from "react";
import {Route, Routes} from "react-router-dom";

import {ROUTES} from "routingConstants";
import {Home} from "pages/Home";
import {Asset} from "pages/Asset";
import {Profile} from "pages/Profile";
import {Settings} from "pages/Settings";
import {AboutUs} from "pages/AboutUs";
import {HelpCenterPage} from "pages/HelpCenter";
import {MarketplacePage} from "pages/MarketplacePage";
import {Page404} from "pages/404Page";
import {AboutHelpUs} from "pages/AboutHelpUs";
import {TermsHelp} from "pages/TermsHelp";
import {HelpFAQ} from "pages/HelpFAQ";
import {CollectionPage} from "pages/Collection";
import {AllCollectionPage} from "pages/AllCollection";
import {SupportPeoplePage} from "pages/SupportPeoplePage";
import {CreateNftPage} from "pages/CreateNft";
import {useTheme} from "hooks/useTheme";
import {useMyUser} from "providers";
import {Terms} from "pages/Terms";
import {Portfolio} from "pages/Portfolio";
import {PortfolioPage} from "pages/Portfolio/page";

export const AppRoutes = () => {
  const theme = useTheme();
  const {user} = useMyUser();

  return (
    <Routes>
      <Route
        path={ROUTES.home}
        element={<Home/>}
      />
      <Route
        path={ROUTES.asset}
        element={<Asset/>}
      />
      <Route
        path={ROUTES.userPage}
        element={<Profile/>}
      />
      <Route
        path={ROUTES.settings}
        element={<Settings/>}
      />
      <Route
        path={ROUTES.aboutUs}
        element={<AboutUs/>}
      />
      {/*<Route*/}
      {/*  path={ROUTES.terms}*/}
      {/*  element={<Terms/>}*/}
      {/*/>*/}
      <Route
        path={ROUTES.marketplace}
        element={<MarketplacePage/>}
      />
      <Route
        path={ROUTES.helpCenter}
        element={<HelpCenterPage/>}
      />
      <Route
        path={ROUTES.errorPage}
        element={<Page404/>}
      />
      <Route
        path={ROUTES.aboutHelpUs}
        element={<AboutHelpUs/>}
      />
      <Route
        path={ROUTES.terms}
        element={theme === "nftim" ? <Terms/> : <TermsHelp/>}
      />
      <Route
        path={ROUTES.HelpFAQ}
        element={<HelpFAQ/>}
      />
      <Route
        path={ROUTES.collection}
        element={<CollectionPage/>}
      />
      <Route
        path={ROUTES.other}
        element={<Page404/>}
      />
      <Route
        path={ROUTES.allCollection}
        element={<AllCollectionPage/>}
      />
      <Route
        path={ROUTES.supportPeople}
        element={<SupportPeoplePage/>}
      />
      {theme === "nftim" && user.verificationStatus === "verified" && (
        <Route
          path={ROUTES.createNft}
          element={<CreateNftPage/>}
        />
      )}
      {theme === "help_ua" && (
        <>
          <Route
            path={ROUTES.portfolio}
            element={<Portfolio/>}
          />
          <Route
            path={ROUTES.portfolioPage}
            element={<PortfolioPage/>}
          />
        </>
      )}
    </Routes>
  );
};
