import React, {
  useMemo,
  useState
} from "react";
import {Link} from "react-router-dom";

import {
  useAsset
} from "providers";
import {
  classNames,
} from "utils";
import {useTheme} from "hooks/useTheme";
import {TimerWrapper} from "components/Timer";
import {AssetSellingActionBlock} from "pages/Asset/SellingAction/Block";
import {AuctionButton} from "components/layout/AuctionButton";
import {AuctionOffersPopup} from "components/Popups/AuctionOffersPopup";
import {ClockIconLight} from "assets/icons/ClockIcon";

import S from "../style.module.scss";

export const AssetSellingActionAuction = () => {
  const {asset, bidsList} = useAsset();

  const [isAuctionOffersPopupValue, setIsAuctionOffersPopupValue] = useState<boolean>(false);

  const price = useMemo(() => {
    if (asset?.currentAuction?.highestBid === "0") {
      return String(asset?.currentAuction?.minimumBid);
    }

    return String(asset?.currentAuction?.highestBid);
  }, [asset?.currentAuction?.highestBid, asset?.currentAuction?.minimumBid]);

  const theme = useTheme();

  const onClickMoreOffers = () => setIsAuctionOffersPopupValue(!isAuctionOffersPopupValue);

  const aucId = asset?.currentAuction?.id;
  const resultObject = aucId && bidsList[aucId] && bidsList[aucId].map((item, index) => {
    if (item.bidder === asset?.currentAuction?.highestBidder) {
      return (
        <Link
          to={`/profile/${item.user?.id}`}
          key={index}
          className={S.BidName}
        >
          <span>{index === 0 && item.user?.displayName}</span>
        </Link>
      );
    } else {
      return false;
    }
  });

  return (
    <div className={classNames(S.AuctionWrapper, S[theme])}>
      <div className={S.HeadBlock}>
        <div className={S.TitleRow}>
          <div className={S.Icon}><ClockIconLight size={22}/></div>
          <div className={S.Title}>Auction</div>
        </div>
        <div className={S.AmountRow}>{asset?.currentAuction?.number} in stock</div>
        {!asset?.currentAuction?.isEndedTimer && (
          <div className={S.TimerRow}>
            <div className={S.Text}>auction will be ended:</div>
            <TimerWrapper
              expiryTimestamp={asset?.currentAuction?.auctionEnd}
              className={S.AuctionTimer}
              isEnded={asset?.currentAuction?.isEndedTimer}
              isSmall={true}
            />
          </div>
        )}
      </div>
      <div className={classNames(S.ActionBlock, S[theme])}>
        <AssetSellingActionBlock
          price={price}
          userBid={resultObject}
          currency={asset?.currentAuction?.currency}
        >
          <AuctionButton
            className={S.Button}
            auction={asset?.currentAuction}
          />
        </AssetSellingActionBlock>
      </div>
      {isAuctionOffersPopupValue &&
        <AuctionOffersPopup
          onClickClose={onClickMoreOffers}
          bidsList={bidsList}
          auctionId={asset?.currentAuction?.id}
        />
      }
    </div>
  );
};
