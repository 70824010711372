import React, {useState} from "react";

import {classNames} from "utils";
import {ArrowIcon} from "assets/icons/Arrow";

import S from "./style.module.scss";

interface ISpoilerView {
  title: string;
  description?: string;
  spoiler: string;
}

interface ISpoilerViewBlockProps {
  block: ISpoilerView;
}

const SpoilerViewBlock = ({block}: ISpoilerViewBlockProps) => {
  const [isShowSpoiler, setIsShowSpoiler] = useState<boolean>(false);

  const {title, description, spoiler} = block;

  return (
    <div className={S.Item}>
      <div
        className={S.Question}
        onClick={() => setIsShowSpoiler(!isShowSpoiler)}
      >
        <p><span>{title}</span>{description && `– ${description}`}</p>
        <ArrowIcon
          className={classNames(S.Arrow, {[S.Rotate]: !isShowSpoiler})}
          size={24}
        />
      </div>
      <div className={classNames(S.Answer, {[S.Active]: !isShowSpoiler})}>
        <div className={S.Text}>
          {spoiler}
        </div>
      </div>
    </div>
  );
};

interface IProps {
  title?: string;
  body: ISpoilerView[];
}

export const SpoilerView = ({title, body}: IProps) => {
  return (
    <div className={S.SpoilerView}>
      {title && <h6>{title}</h6>}
      <div className={S.FAQBox}>
        {body.map((el, key) => {
          return (
            <SpoilerViewBlock
              key={key}
              block={el}
            />
          );
        })}
      </div>
    </div>
  );
};
