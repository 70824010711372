import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {MarketplaceButtonIcon} from "assets/icons/MarketplaceButtonIcon";
import {ROUTES} from "routingConstants";
import {Cards} from "components/Cards";
import {useApi} from "api";
import {Container} from "components/Container";

import S from "./style.module.scss";

export const CharityCardBlock = () => {
  const {api} = useApi();
  const queryMemo = useMemo(() => {
    return {
      ordering: "-createdAt"
    };
  }, []);

  return (
    <Container>
      <div className={S.MarketplaceBlock}>
        <h1>Last added</h1>
        <Link to={ROUTES.marketplace}>
          <div className={S.MarketplaceButton}>
            <div className={S.MarketplaceButtonContent}>
              Discover more NFT
              <MarketplaceButtonIcon/>
            </div>
          </div>
          <div className={S.MarketplaceButtonMobile}>
            <MarketplaceButtonIcon/>
          </div>
        </Link>
      </div>
      <Cards
        request={api.news.getNewsFromES}
        removeInfinityScroll={true}
        query={queryMemo}
        limit={12}
        stateType={"object"}
        theme={"nftim"}
      />
    </Container>
  );
};
