import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const TwitterIcon = ({ fill = "#000", size = 24 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.953 4.19558C23.0545 4.59118 22.1026 4.85231 21.128 4.97057C22.1541 4.35383 22.9224 3.38662 23.291 2.24758C22.34 2.80258 21.286 3.20658 20.164 3.43158C19.4238 2.64 18.4429 2.115 17.3736 1.93808C16.3044 1.76116 15.2067 1.94223 14.2509 2.45317C13.2952 2.96411 12.5349 3.77633 12.0881 4.7637C11.6412 5.75107 11.533 6.85834 11.78 7.91357C7.69 7.72057 4.067 5.75557 1.64 2.78758C1.19879 3.53739 0.968664 4.3926 0.974 5.26258C0.974 6.97258 1.844 8.47557 3.162 9.35857C2.38086 9.33371 1.61697 9.12251 0.934 8.74257V8.80257C0.933557 9.93896 1.32627 11.0405 2.04551 11.9203C2.76474 12.8001 3.76621 13.404 4.88 13.6296C4.1583 13.823 3.40241 13.852 2.668 13.7146C2.98412 14.6926 3.59766 15.5475 4.42301 16.1601C5.24837 16.7726 6.24435 17.1123 7.272 17.1316C5.53153 18.4975 3.38248 19.2389 1.17 19.2366C0.78 19.2366 0.391 19.2136 0 19.1696C2.25571 20.6139 4.87851 21.3806 7.557 21.3786C16.61 21.3786 21.555 13.8826 21.555 7.39358C21.555 7.18358 21.555 6.97358 21.54 6.76358C22.506 6.06844 23.3392 5.20539 24 4.21558L23.953 4.19558Z"
        fill={fill}
      />
    </svg>
  );
};

// export default React.memo(TwitterIcon);
