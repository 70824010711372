import React from "react";

import gifAnimation from "assets/img/animation.gif";

import {classNames} from "../../utils";

import S from "./style.module.scss";

interface IProps {
  className: any;
}

export const Loader = (props: IProps) => {
  return (
    <div className={classNames(S.Loader, props.className)}>
      <img
        src={gifAnimation}
        alt="loader"
      />
      <p>Blockchain updating</p>
    </div>
  );
};
