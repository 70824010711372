import React from "react";

import {Title} from "components/HELPUA/Title";
import {NFTIMLaunchpadContainer} from "components/NFTIM/Launchpad/Container";
import {RockerIcon} from "assets/icons/Rocket";
import {Container} from "components/Container";

interface IProps {

}

export const HomeNFTIMLaunchpad = (props: IProps) => {
  return (
    <Container>
      <div className={"grid"}>
        <Title
          title={"Launchpad"}
          icon={<RockerIcon/>}
          underTitle={"LAUNCHPAD"}
        />
      </div>
      <NFTIMLaunchpadContainer/>
    </Container>
  );
};
