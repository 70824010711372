import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {useProfile} from "providers";
import {MarketplaceButtonIcon} from "assets/icons/MarketplaceButtonIcon";
import {useApi} from "api";
import {ROUTES} from "routingConstants";
import {Cards} from "components/Cards";
import {TGetAssetKeys} from "types";
import {classNames} from "utils";
import {Container} from "components/Container";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  type?: TGetAssetKeys;
  limit?: number;
  listOnly?: boolean;
  children?: React.ReactNode;
}

export const CardBlockNew = ({
  className,
  type,
  limit = 20,
  listOnly = false,
  children
}: IProps) => {
  const {profile} = useProfile();
  const {api} = useApi();

  const renderCardsList = useMemo(() => {
    switch (type) {
      case "creator": {
        return (
          <Cards
            request={api.asset.getAssets}
            limit={limit}
            query={{
              creator: String(profile.cryptoWallet)
            }}
            offsetBottom={500}
            theme={"nftim"}
          />
        );
      }
      case "onSaleBy": {
        return (
          <Cards
            request={api.asset.getAssets}
            limit={limit}
            query={{
              onSaleBy: String(profile.cryptoWallet)
            }}
            offsetBottom={500}
            theme={"nftim"}
          />
        );
      }
      case "owner": {
        return (
          <Cards
            request={api.asset.getAssets}
            limit={limit}
            query={{
              owner: String(profile.cryptoWallet)
            }}
            offsetBottom={500}
            theme={"nftim"}
          />
        );
      }
      default : {
        return (
          <Cards
            request={api.news.getNewsFromES}
            limit={limit}
            removeInfinityScroll={true}
            stateType={"object"}
            theme={"nftim"}
          />
        );
      }
    }
  }, [type, api.asset.getAssets, api.news.getNewsFromES, limit, profile.cryptoWallet]);

  return (
    <Container className={classNames(className, "mt-8")}>
      {children}
      {!listOnly && (
        <div className={S.BlockTitleWrapper}>
          <div>
            <div className={S.BlockTitle}>
              <span>Most </span> desired NFT&apos;s
            </div>
            <div className={S.BlockSubtitle}>Find and buy your favorite NFT</div>
          </div>
          <Link to={ROUTES.marketplace}>
            <div className={S.MarketplaceButton}>
              <div className={S.MarketplaceButtonContent}>
                Discover more NFT
                <MarketplaceButtonIcon/>
              </div>
            </div>
            <div className={S.MarketplaceButtonMobile}>
              <MarketplaceButtonIcon/>
            </div>
          </Link>
        </div>
      )}
      {renderCardsList}
    </Container>
  );
};
