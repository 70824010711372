import React from "react";

export const CharityMainIcon = () => (
  <svg
    width="150"
    height="55"
    viewBox="0 0 150 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3522_30691)">
      <path
        d="M13.6111 41.3417C8.19027 44.3451 8.3612 49.4913 9.12426 51.6888C7.49623 49.2206 7.07301 46.0595 7.9928 43.1732C8.91934 41.0976 12.9152 36.855 21.4859 36.4887C23.6509 36.3961 25.5316 36.1839 27.163 35.8945C27.8223 39.5945 31.9286 44.6388 33.8474 46.6527C29.7077 50.6797 24.8242 53.1704 21.9437 54.2528C16.7244 51.8719 13.1532 49.3996 10.4062 46.5612C11.2303 43.1098 12.5123 42.4711 13.6111 41.3417Z"
        fill="url(#paint0_linear_3522_30691)"
      />
      <path
        d="M0.608314 9.43144C5.00356 7.14227 14.0687 4.48681 19.9291 4.30367C26.3023 3.71763 32.9623 5.46353 35.4956 6.40972C35.2026 7.28877 31.8329 9.52302 30.1846 10.5303C37.5832 7.89312 39.9213 2.41128 40.1654 0C40.5927 3.05226 39.909 9.59627 33.7558 11.3544C30.1394 12.3876 26.8732 14.4834 24.429 16.4821C22.7142 17.8844 21.4041 19.2388 20.6616 20.1449C26.6683 21.4634 27.0714 24.54 26.522 25.9137C27.9138 27.5985 27.346 29.79 26.8882 30.675C26.1557 29.3015 22.8592 24.3569 15.2591 28.4775C14.693 28.7843 14.1633 29.1281 13.6679 29.5019C8.84254 33.1415 7.2757 39.6256 7.10961 42.6704C2.62279 36.3523 1.15771 28.6606 0.699875 24.8148C0.544521 23.853 0.443595 22.556 0.380762 21.1533C0.217583 18.8086 0.112178 15.8981 0.333597 13.1857C0.384171 11.7412 0.51673 10.8965 0.608314 9.43144Z"
        fill="url(#paint1_linear_3522_30691)"
      />
      <path
        d="M36.9605 28.4776C35.056 29.5032 32.8096 29.8816 31.9243 29.9428C32.6218 29.7606 33.5913 28.9506 34.6369 27.9282C36.578 27.8655 41.1416 28.0748 42.6948 29.027C42.1149 32.6592 39.5267 41.352 33.8127 46.6995C31.8939 44.6856 27.8221 39.5498 27.1628 35.8496C27.0902 35.2922 26.9453 34.0924 26.9453 33.754C28.6807 33.392 31.3689 32.7066 32.1992 32.2791C34.4272 31.1314 36.1766 29.4473 36.9605 28.4776Z"
        fill="url(#paint2_linear_3522_30691)"
      />
      <path
        d="M30.1846 19.5951C37.0705 16.2254 39.8296 10.4994 40.3485 8.05762L43.4617 9.33956C43.5534 12.2697 44.2514 20.1445 42.6948 29.0266C41.1416 28.0743 36.578 27.8651 34.6369 27.9277C36.1168 26.4805 37.7143 24.5121 38.6088 23.3494C36.7773 24.3749 33.0842 25.3027 31.4666 25.6385C39.3779 20.8038 41.0506 14.8336 40.898 12.4528C37.5283 18.0202 32.3516 19.5341 30.1846 19.5951Z"
        fill="url(#paint3_linear_3522_30691)"
      />
      <path
        d="M12.2355 37.634C17.4548 34.246 20.4439 34.6122 25.2053 33.9714C25.4802 33.9343 26.5398 33.8003 27.0368 33.6965C28.7722 33.3346 31.2428 32.7508 32.0729 32.3231C34.124 32.3608 36.1345 31.8051 36.8671 31.499C36.0634 32.4489 33.5107 34.7978 27.0694 35.941C25.4381 36.2306 23.5572 36.4427 21.3922 36.5352C12.8215 36.9014 8.91729 41.144 7.99072 43.2196C8.67467 41.0734 10.0096 39.0789 12.2355 37.634Z"
        fill="#DC3C10"
      />
      <path
        d="M7.65902 31.0355C9.12411 29.7169 11.4438 28.2885 12.4206 27.7391C22.2366 22.8311 26.1557 27.6474 26.8882 30.6692C27.346 29.7842 27.9138 27.5925 26.522 25.9077C27.0714 24.5342 26.6683 21.4575 20.6616 20.139C21.4041 19.2329 22.7142 17.8785 24.429 16.4763C21.3167 12.8746 12.0241 7.31953 0.333596 13.1798C-0.0326778 17.6667 0.495383 22.6958 0.699874 24.809C0.919636 27.0798 3.50796 32.4394 4.72887 34.6981C5.09513 34.0268 6.19393 32.354 7.65902 31.0355Z"
        fill="url(#paint4_linear_3522_30691)"
      />
      <path
        d="M7.65902 30.9499C9.12411 29.6313 11.4438 28.2027 12.4206 27.6535C5.82769 15.127 14.7362 6.89813 19.9861 4.30371C14.1258 4.48686 4.96904 7.14232 0.573789 9.43149C0.482205 10.8966 0.38417 11.6497 0.333596 13.0942C-0.0326778 17.581 0.495383 22.6102 0.699874 24.7232C0.919636 26.9942 3.50796 32.3538 4.72887 34.6125C5.09513 33.9412 6.19393 32.2684 7.65902 30.9499Z"
        fill="url(#paint5_linear_3522_30691)"
        fillOpacity="0.35"
      />
      <path
        d="M15.8709 14.3769C16.625 16.9501 14.5325 20.64 10.9437 24.4331C6.42868 21.8097 3.46622 18.9223 3.46192 16.2409C3.45764 13.5596 7.20151 13.088 9.62235 15.2788C11.3248 12.4929 15.1168 11.8038 15.8709 14.3769Z"
        fill="url(#paint6_radial_3522_30691)"
      />
      <path
        d="M65.4851 39.6632C65.2899 39.7162 64.979 39.7692 64.5529 39.8221C64.1445 39.8751 63.7362 39.9017 63.3275 39.9017C62.9191 39.9017 62.5553 39.8664 62.2357 39.7958C61.9337 39.7428 61.6763 39.6368 61.4632 39.4776C61.25 39.3187 61.0901 39.0978 60.9837 38.8152C60.8772 38.5324 60.8237 38.1613 60.8237 37.7021V33.5674H55.7097V39.6632C55.5145 39.7162 55.2036 39.7692 54.7775 39.8221C54.3689 39.8751 53.9605 39.9017 53.5521 39.9017C53.1437 39.9017 52.7796 39.8664 52.46 39.7958C52.1581 39.7428 51.9007 39.6368 51.6875 39.4776C51.4746 39.3187 51.3147 39.0978 51.2083 38.8152C51.1016 38.5324 51.0483 38.1613 51.0483 37.7021V23.7083C51.2436 23.673 51.5545 23.6289 51.9805 23.5759C52.4068 23.505 52.8152 23.4697 53.2059 23.4697C53.6143 23.4697 53.9695 23.505 54.2714 23.5759C54.591 23.6289 54.8574 23.7349 55.0705 23.8938C55.2834 24.0528 55.4433 24.2737 55.5498 24.5565C55.6565 24.8391 55.7097 25.2102 55.7097 25.6696V29.91H60.8237V23.7083C61.0192 23.673 61.3299 23.6289 61.7562 23.5759C62.1822 23.505 62.5906 23.4697 62.9813 23.4697C63.3897 23.4697 63.7449 23.505 64.0468 23.5759C64.3664 23.6289 64.6328 23.7349 64.8459 23.8938C65.0591 24.0528 65.2187 24.2737 65.3254 24.5565C65.4319 24.8391 65.4851 25.2102 65.4851 25.6696V39.6632Z"
        fill="white"
      />
      <path
        d="M68.9448 26.1738C68.9448 25.4141 69.1669 24.8134 69.6109 24.3716C70.0546 23.9299 70.6584 23.709 71.422 23.709H80.212C80.3363 23.9035 80.4428 24.1597 80.5316 24.4776C80.638 24.7958 80.6915 25.1313 80.6915 25.4848C80.6915 26.1562 80.5405 26.6333 80.2386 26.9158C79.9546 27.1987 79.5728 27.34 79.0933 27.34H73.4731V29.8048H79.4661C79.5905 29.9991 79.6969 30.2553 79.7857 30.5734C79.8924 30.8737 79.9456 31.2005 79.9456 31.554C79.9456 32.2254 79.8036 32.7025 79.5193 32.985C79.2353 33.2679 78.8535 33.4091 78.374 33.4091H73.4731V36.1921H80.3184C80.4428 36.3864 80.5493 36.6426 80.638 36.9605C80.7447 37.2787 80.798 37.6145 80.798 37.9677C80.798 38.6391 80.647 39.1251 80.3451 39.4254C80.061 39.7082 79.6793 39.8495 79.1997 39.8495H71.422C70.6584 39.8495 70.0546 39.6286 69.6109 39.1868C69.1669 38.745 68.9448 38.1443 68.9448 37.3846V26.1738Z"
        fill="white"
      />
      <path
        d="M86.0148 39.8488C85.2512 39.8488 84.6474 39.6279 84.2034 39.1861C83.7595 38.7443 83.5376 38.1436 83.5376 37.3839V23.6819C83.7328 23.6466 84.0435 23.6023 84.4698 23.5493C84.8961 23.4787 85.3045 23.4434 85.6949 23.4434C86.1036 23.4434 86.4587 23.4787 86.7604 23.5493C87.08 23.6023 87.3464 23.7083 87.5595 23.8675C87.7727 24.0264 87.9326 24.2473 88.0391 24.5299C88.1455 24.8127 88.199 25.1836 88.199 25.643V36.1118H94.2987C94.4228 36.3061 94.5384 36.5713 94.6449 36.9068C94.7513 37.225 94.8045 37.5605 94.8045 37.914C94.8045 38.6207 94.6538 39.1244 94.3519 39.4247C94.0499 39.7075 93.6505 39.8488 93.1533 39.8488H86.0148Z"
        fill="white"
      />
      <path
        d="M103.24 31.1558C103.986 31.1558 104.554 30.9879 104.945 30.6521C105.353 30.2989 105.558 29.7688 105.558 29.0621C105.558 28.3905 105.344 27.8783 104.918 27.5248C104.51 27.1537 103.906 26.9681 103.107 26.9681C102.823 26.9681 102.583 26.9771 102.388 26.9947C102.21 26.9947 102.024 27.0124 101.828 27.0477V31.1558H103.24ZM101.855 39.6632C101.66 39.7162 101.349 39.7692 100.923 39.8222C100.514 39.8752 100.106 39.9018 99.6976 39.9018C99.2892 39.9018 98.9251 39.8664 98.6055 39.7958C98.3036 39.7428 98.0462 39.6368 97.833 39.4777C97.6199 39.3187 97.4602 39.0978 97.3535 38.8152C97.2471 38.5324 97.1938 38.1613 97.1938 37.7021V25.2189C97.1938 24.8481 97.2913 24.5652 97.4868 24.371C97.6997 24.159 97.984 23.9911 98.3392 23.8675C98.9428 23.6553 99.6265 23.5051 100.39 23.4168C101.172 23.3108 101.953 23.2578 102.734 23.2578C105.185 23.2578 107.058 23.7789 108.354 24.8214C109.651 25.8639 110.299 27.2776 110.299 29.0621C110.299 29.9277 110.157 30.714 109.873 31.4207C109.606 32.1097 109.198 32.7105 108.647 33.2229C108.115 33.7177 107.431 34.1064 106.596 34.389C105.779 34.6542 104.83 34.7868 103.746 34.7868H101.855V39.6632Z"
        fill="white"
      />
      <path
        d="M132.206 33.8325C132.206 34.7867 132.038 35.6613 131.7 36.4563C131.381 37.2337 130.91 37.9053 130.289 38.4707C129.667 39.0182 128.912 39.4423 128.024 39.7428C127.137 40.043 126.133 40.1933 125.015 40.1933C123.896 40.1933 122.893 40.043 122.005 39.7428C121.117 39.4423 120.362 39.0182 119.741 38.4707C119.119 37.9053 118.64 37.2337 118.302 36.4563C117.983 35.6613 117.823 34.7867 117.823 33.8325V23.7083C118.018 23.673 118.329 23.6289 118.755 23.5759C119.181 23.505 119.59 23.4697 119.98 23.4697C120.389 23.4697 120.744 23.505 121.046 23.5759C121.365 23.6289 121.632 23.7349 121.845 23.8938C122.058 24.0528 122.218 24.2737 122.324 24.5565C122.431 24.8391 122.484 25.2102 122.484 25.6696V33.753C122.484 34.6012 122.715 35.2636 123.177 35.7407C123.656 36.2178 124.269 36.4563 125.015 36.4563C125.778 36.4563 126.391 36.2178 126.853 35.7407C127.314 35.2636 127.545 34.6012 127.545 33.753V23.7083C127.74 23.673 128.051 23.6289 128.477 23.5759C128.903 23.505 129.312 23.4697 129.703 23.4697C130.111 23.4697 130.466 23.505 130.768 23.5759C131.088 23.6289 131.354 23.7349 131.567 23.8938C131.78 24.0528 131.94 24.2737 132.047 24.5565C132.153 24.8391 132.206 25.2102 132.206 25.6696V33.8325Z"
        fill="#FF2D55"
      />
      <path
        d="M138.819 24.1854C139.12 24.0088 139.573 23.8409 140.177 23.6819C140.798 23.5227 141.42 23.4434 142.041 23.4434C142.929 23.4434 143.711 23.5759 144.386 23.8409C145.078 24.0881 145.522 24.4859 145.717 25.0336C146.037 25.9522 146.383 27.0213 146.756 28.2405C147.129 29.4596 147.502 30.7227 147.875 32.0304C148.248 33.3201 148.612 34.6012 148.967 35.8733C149.322 37.1277 149.633 38.2409 149.899 39.2127C149.686 39.46 149.367 39.6545 148.94 39.7958C148.514 39.9547 148.008 40.0343 147.422 40.0343C146.996 40.0343 146.632 39.999 146.33 39.9284C146.046 39.8575 145.806 39.7515 145.611 39.6102C145.433 39.4689 145.282 39.2834 145.158 39.0538C145.051 38.8239 144.954 38.559 144.865 38.2585L144.386 36.4297H139.564C139.422 36.9598 139.28 37.5076 139.138 38.073C138.996 38.6384 138.854 39.1861 138.712 39.7162C138.463 39.8045 138.197 39.8751 137.913 39.9284C137.646 39.999 137.318 40.0343 136.927 40.0343C136.022 40.0343 135.347 39.8841 134.903 39.5836C134.477 39.2657 134.264 38.8329 134.264 38.2851C134.264 38.0376 134.299 37.7904 134.37 37.5429C134.441 37.2956 134.521 37.0041 134.61 36.6683C134.734 36.1914 134.903 35.5994 135.116 34.8927C135.347 34.186 135.596 33.4261 135.862 32.6134C136.128 31.7829 136.412 30.9349 136.714 30.069C137.016 29.2034 137.3 28.3905 137.567 27.6308C137.833 26.8534 138.073 26.1644 138.286 25.5637C138.517 24.9627 138.694 24.5035 138.819 24.1854ZM142.015 27.6044C141.766 28.3464 141.482 29.1767 141.162 30.0956C140.861 30.9966 140.585 31.9155 140.337 32.852H143.64C143.391 31.8978 143.134 30.9702 142.867 30.069C142.619 29.1504 142.388 28.3288 142.175 27.6044H142.015Z"
        fill="#FF2D55"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3522_30691"
        x1="3.68725"
        y1="55.26"
        x2="25.3886"
        y2="54.2528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="1"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3522_30691"
        x1="-1.14144"
        y1="25.4681"
        x2="48.0956"
        y2="25.4681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3522_30691"
        x1="24.6561"
        y1="47.7301"
        x2="37.6541"
        y2="47.3774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="1"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3522_30691"
        x1="30.2417"
        y1="28.9349"
        x2="50.1145"
        y2="23.3364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="0.914792"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3522_30691"
        x1="-0.708154"
        y1="24.9922"
        x2="32.7141"
        y2="24.9922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3522_30691"
        x1="-0.458484"
        y1="22.3937"
        x2="23.8507"
        y2="22.3937"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <radialGradient
        id="paint6_radial_3522_30691"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.072 18.1221) rotate(81.7868) scale(6.37079 7.18756)"
      >
        <stop stopColor="#FF3A3A"/>
        <stop
          offset="1"
          stopColor="#D81C3E"
        />
      </radialGradient>
      <clipPath id="clip0_3522_30691">
        <rect
          width="150"
          height="54.4355"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const CharityMainIconMini = () => (
  <svg
    width="38"
    height="48"
    viewBox="0 0 38 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5617_39247)">
      <path
        d="M11.9587 36.2275C7.19613 38.8594 7.3463 43.369 8.01672 45.2947C6.58636 43.1318 6.21453 40.3617 7.02264 37.8324C7.83668 36.0136 11.3473 32.2958 18.8774 31.9748C20.7795 31.8936 22.4319 31.7077 23.8652 31.4541C24.4444 34.6964 28.0522 39.1168 29.738 40.8815C26.1009 44.4104 21.8104 46.5931 19.2796 47.5416C14.694 45.4552 11.5565 43.2887 9.14301 40.8014C9.86705 37.7769 10.9933 37.2172 11.9587 36.2275Z"
        fill="url(#paint0_linear_5617_39247)"
      />
      <path
        d="M0.534748 8.26483C4.39633 6.25881 12.3608 3.93182 17.5096 3.77133C23.109 3.25778 28.9603 4.78772 31.1861 5.61687C30.9287 6.38719 27.9681 8.34507 26.5199 9.22773C33.0202 6.91679 35.0744 2.11301 35.2889 0C35.6643 2.67471 35.0636 8.40927 29.6575 9.9499C26.4802 10.8553 23.6106 12.6919 21.4631 14.4434C19.9566 15.6722 18.8055 16.859 18.1532 17.653C23.4306 18.8085 23.7847 21.5045 23.302 22.7083C24.5249 24.1848 24.026 26.1051 23.6238 26.8807C22.9802 25.6771 20.084 21.344 13.4067 24.955C12.9093 25.2238 12.4439 25.5251 12.0087 25.8526C7.76919 29.0421 6.39259 34.7242 6.24667 37.3923C2.30463 31.8557 1.01744 25.1155 0.615192 21.7454C0.4787 20.9026 0.390029 19.7659 0.334825 18.5368C0.191459 16.4821 0.0988519 13.9316 0.293386 11.5547C0.33782 10.2889 0.454284 9.54869 0.534748 8.26483Z"
        fill="url(#paint1_linear_5617_39247)"
      />
      <path
        d="M32.473 24.9553C30.7997 25.854 28.8261 26.1855 28.0483 26.2392C28.6611 26.0795 29.5129 25.3697 30.4315 24.4738C32.1369 24.4189 36.1464 24.6023 37.5111 25.4367C37.0016 28.6196 34.7276 36.2371 29.7073 40.9232C28.0215 39.1584 24.4441 34.6579 23.8649 31.4154C23.8012 30.9269 23.6738 29.8756 23.6738 29.579C25.1985 29.2618 27.5603 28.6612 28.2898 28.2866C30.2472 27.2808 31.7843 25.805 32.473 24.9553Z"
        fill="url(#paint2_linear_5617_39247)"
      />
      <path
        d="M26.5195 17.1719C32.5694 14.219 34.9935 9.20127 35.4494 7.06152L38.1846 8.1849C38.2652 10.7526 38.8784 17.6533 37.5108 25.4368C36.1462 24.6023 32.1366 24.4189 30.4313 24.4738C31.7315 23.2056 33.135 21.4807 33.9209 20.4618C32.3118 21.3605 29.0671 22.1735 27.6459 22.4678C34.5966 18.2311 36.0662 12.9994 35.9321 10.9131C32.9716 15.7918 28.4234 17.1184 26.5195 17.1719Z"
        fill="url(#paint3_linear_5617_39247)"
      />
      <path
        d="M10.7499 32.9787C15.3355 30.0097 17.9616 30.3307 22.1449 29.7691C22.3864 29.7366 23.3174 29.6192 23.754 29.5282C25.2787 29.2111 27.4494 28.6995 28.1787 28.3246C29.9807 28.3577 31.7471 27.8707 32.3908 27.6025C31.6846 28.4349 29.4419 30.4933 23.7827 31.495C22.3494 31.7488 20.697 31.9347 18.7948 32.0157C11.2647 32.3367 7.83457 36.0545 7.02051 37.8733C7.62141 35.9926 8.79426 34.2448 10.7499 32.9787Z"
        fill="#DC3C10"
      />
      <path
        d="M6.72937 27.197C8.01657 26.0415 10.0546 24.7898 10.9128 24.3084C19.537 20.0074 22.9802 24.228 23.6238 26.876C24.026 26.1005 24.5249 24.1799 23.302 22.7034C23.7847 21.4999 23.4306 18.8038 18.1532 17.6483C18.8055 16.8543 19.9566 15.6674 21.4631 14.4387C18.7287 11.2825 10.5644 6.41456 0.293385 11.55C-0.0284157 15.4818 0.435528 19.8889 0.615191 21.7407C0.80827 23.7306 3.08233 28.4273 4.15499 30.4066C4.47678 29.8183 5.44217 28.3524 6.72937 27.197Z"
        fill="url(#paint4_linear_5617_39247)"
      />
      <path
        d="M6.72937 27.1207C8.01657 25.9652 10.0546 24.7133 10.9128 24.2321C5.12039 13.255 12.9473 6.04401 17.5597 3.77051C12.4109 3.931 4.366 6.25799 0.504415 8.26401C0.42395 9.54787 0.337819 10.2078 0.293385 11.4737C-0.0284157 15.4055 0.435528 19.8126 0.615191 21.6642C0.808269 23.6543 3.08233 28.351 4.15499 30.3303C4.47678 29.742 5.44217 28.2761 6.72937 27.1207Z"
        fill="url(#paint5_linear_5617_39247)"
        fillOpacity="0.35"
      />
      <path
        d="M13.9433 12.5986C14.6059 14.8535 12.7674 18.087 9.61433 21.4109C5.64756 19.1121 3.0448 16.5818 3.04102 14.2321C3.03726 11.8824 6.32656 11.4692 8.45346 13.389C9.94924 10.9476 13.2808 10.3438 13.9433 12.5986Z"
        fill="url(#paint6_radial_5617_39247)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5617_39247"
        x1="3.23986"
        y1="48.4242"
        x2="22.3061"
        y2="47.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="1"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5617_39247"
        x1="-1.00255"
        y1="22.3178"
        x2="42.2562"
        y2="22.3178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5617_39247"
        x1="21.6626"
        y1="41.8263"
        x2="33.0823"
        y2="41.5156"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="1"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5617_39247"
        x1="26.5697"
        y1="25.3564"
        x2="44.0229"
        y2="20.4268"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB7614"/>
        <stop
          offset="0.914792"
          stopColor="#F5B20A"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5617_39247"
        x1="-0.621877"
        y1="21.9012"
        x2="28.7423"
        y2="21.9012"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5617_39247"
        x1="-0.402521"
        y1="19.6229"
        x2="20.955"
        y2="19.6229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3245C7"/>
        <stop
          offset="1"
          stopColor="#5CC6E7"
        />
      </linearGradient>
      <radialGradient
        id="paint6_radial_5617_39247"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.8485 15.8806) rotate(81.7658) scale(5.58306 6.31453)"
      >
        <stop stopColor="#FF3A3A"/>
        <stop
          offset="1"
          stopColor="#D81C3E"
        />
      </radialGradient>
      <clipPath id="clip0_5617_39247">
        <rect
          width="38"
          height="47.7021"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

);
