import React, {useCallback, useMemo} from "react";
import {ifElse} from "ramda";

import {Tooltip} from "components/Tooltip";
import {classNames} from "utils";
import {useCurrentState} from "hooks/useCurrentState";
import {useTheme} from "hooks/useTheme";
import {Input} from "components/Input";
import {priceValidator} from "common/regEx";

import S from "./style.module.scss";

interface IProps {
  tooltipText?: string;
  maxQuantity?: number;
  setAmount?: (value: number) => void;
}

// TODO maybe update h5.CounterAmount to Input
export const Counter = ({tooltipText, maxQuantity = 1, setAmount}: IProps) => {
  const memoMaxQuantity = useMemo(() => maxQuantity, [maxQuantity]);
  const [value, setValue] = useCurrentState<number>(1);
  const theme = useTheme();

  const updateStateAmount = useCallback((action: "increment0" | "increment1" | "increment10" | "increment100" | "clear") => {
    if (action === "increment0") {
      if (value > 1) {
        let newValue = value - 1;
        setValue(newValue);
        setAmount && setAmount(newValue);
      }
    }

    if (action === "increment1") {
      if (value < memoMaxQuantity) {
        let newValue = value + 1;
        setValue(newValue);
        setAmount && setAmount(newValue);
      }
    }

    if (action === "increment10") {
      if (value + 10 < memoMaxQuantity) {
        let newValue = value + 10;
        setValue(newValue);
        setAmount && setAmount(newValue);
      }
    }

    if (action === "increment100") {
      if (value + 100 < memoMaxQuantity) {
        let newValue = value + 100;
        setValue(newValue);
        setAmount && setAmount(newValue);
      }
    }

    if (action === "clear") {
      setValue(1);
      setAmount && setAmount(1);
    }
  }, [value, memoMaxQuantity, setValue, setAmount]);

  return (
    <div className={classNames(S.CounterWrapper, S[theme])}>
      <div className={"relative block flex-none max1024:flex-1 max1024:order-1"}>
        <p className={"relative block text-black font-medium text-base leading-5 mb-1"}>Quantity</p>
        <span className={"relative block text-text_description text-xs leading-4"}>{` (${maxQuantity} asset available) `}</span>
      </div>
      <div className={"relative block w-full max1024:order-3"}>
        <div className={"relative w-full flex items-center h-[52px] px-2 gap-1 justify-between border border-solid border-grey_border bg-white rounded-lg"}>
          {theme === "help_ua" && (
            <>
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("clear")}
              >
                C
              </button>
              <h5 className={classNames(S.CounterAmount, "text-base")}>{value}</h5>
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("increment1")}
              >
                +1
              </button>
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("increment10")}
              >
                +10
              </button>
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("increment100")}
              >
                +100
              </button>
            </>
          )}
          {theme === "nftim" && (
            <>
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("increment0")}
              >
                <span className={"absolute block w-[10px] h-[2px] bg-dark"}/>
              </button>
              <input
                type={"number"}
                className={"relative w-full h-10 border-none bg-transparent text-center shadow-none text-base font-medium text-dark"}
                value={`${value}`}
                min={0}
                max={maxQuantity}
                step={1}
                onChange={(event: any) => {
                  let count = Number(event.target.value);
                  if (count > 0 && event.target.value < maxQuantity) {
                    setValue(count);
                    setAmount && setAmount(count);
                  }
                  count < 1 && setValue(1);
                  count > maxQuantity && setValue(maxQuantity);
                }}
              />
              <button
                className={classNames(S.CounterButton, "text-base")}
                onClick={() => updateStateAmount("increment1")}
              >
                <span className={"absolute block w-[10px] h-[2px] bg-dark"}/>
                <span className={"absolute block w-[10px] h-[2px] bg-dark rotate-90"}/>
              </button>
            </>
          )}
        </div>
      </div>
      <Tooltip text={tooltipText} place={"left"} className={"relative block flex-none div:max-w-[70vw] max1024:order-2"}/>
    </div>
  );
};
