import React from "react";
import {Link} from "react-router-dom";

import {AssetBlueArrow} from "assets/icons/AssetBlueArrow";
import {OwnersPopup} from "components/Asset/OwnersPopup";
import {useToggle} from "hooks/useToggle";
import {useAsset} from "providers";
import {classNames} from "utils";

import {UserInfo} from "../UserInfo";

import S from "./style.module.scss";

export const AssetOwners = () => {
  const {asset, owners} = useAsset();
  const [isUsersPopup, toggleUsersPopup] = useToggle(false);

  return (
    <div className={S.OwnersWrapper}>
      <div className={S.OwnerItem}>
        <UserInfo
          avatar={asset && (asset.author?.thumbnailPhoto)}
          title={asset && (asset.author?.displayName || asset.author?.firstName)}
          label={"Creator"}
          text={
            <>
              {asset && (
                <Link to={`/profile/${asset.author?.id}`}>
                  {asset.author?.displayName || asset.author?.firstName}
                </Link>
              )}
            </>
          }
        />
      </div>
      <div className={S.OwnerItem}>
        <UserInfo
          avatar={owners && (owners[0]?.user?.thumbnailPhoto)}
          label={"Owner"}
          text={
            <>
              {owners && owners.length > 1 ? (
                <span
                  onClick={toggleUsersPopup}
                  className={"flex items-center"}
                >
                  <p className={"truncate"}>Multiple owners</p>
                  <AssetBlueArrow
                    size={18}
                  />
                </span>
              ) : owners ? (
                <Link to={`/profile/${owners[0]?.user?.id}`}>
                  {owners[0]?.user?.displayName}</Link>
              ) : ("")}
            </>
          }
          textPopup={
            <>
              {isUsersPopup && owners && owners.length > 1 && (
                <OwnersPopup
                  onClose={toggleUsersPopup}
                  ownersList={owners}
                />
              )}
            </>
          }
        />
      </div>
      <div className={classNames(S.OwnerItem, S.LastItem)}>
        <UserInfo
          label={"Available"}
          textAlign={"right"}
          text={
            <>
              {asset?.available} / {asset?.initialCount}
            </>
          }
        />
      </div>
    </div>
  );
};
