import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {TextComponentFAQ, WrapperComponentFAQ} from "pages/HelpFAQ/Layout/Components";
import {HelpPageHeader} from "pages/HelpFAQ/Layout/Header";
import {useTheme} from "hooks/useTheme";

export const CryptoFAQ = () => {
  const theme = useTheme();

  const {siteName} = useMemo(() => {
    return {siteName: theme === "nftim" ? "NFTIM" : "HelpUA"};
  }, [theme]);

  return (
    <>
      <HelpPageHeader
        title="What is a crypto wallet and how to get one?"
        breadcrumb="crypto"
      />
      <WrapperComponentFAQ>
        <TextComponentFAQ>
          <p>
            If you want to buy or sell NFTs, the first thing you will need is a <b>crypto wallet.</b> Like your physical wallet contains your money, cards, or an ID, the crypto wallet does it on a blockchain. It holds proof of digital tokens like <b>cryptocurrencies and Non-Fungible Tokens (NFTs).</b> A crypto wallet also allows you to send and receive your NFTs, as well as interact with blockchain-integrated marketplaces - like siteName. Without a crypto wallet, you cannot use {siteName}.
          </p>
          <p>
            A crypto wallet has its own ID(or address, which consists of a string with lots of letters and numbers in it), your cryptocurrencies, and any NFTs you bought with those cryptocurrencies. Right now <b>{siteName} supports such wallet providers as Metamask and Coinbase.</b> Both of them are easy to start and easy to use.
          </p>
          <br/>
          <p>
            <b>Metamask</b> is one of the most popular choices because of its versatility. You just need to install a browser extension for your PC or an app for your phone. Visit the official metamask site for the details: <a
              href="https://metamask.io"
              target="_blank"
            >https://metamask.io</a>
          </p>
          <br/>
          <p>
            <b>Coinbase</b> also is a popular wallet that is easy to use. It allows you to create a business account. The Coinbase Wallet app allows users to explore the decentralized web with a dapp browser. You do not need a Coinbase account to use the Coinbase Wallet app. Visit the official Coinbase site for the details: <a
              href="https://www.coinbase.com"
              target="_blank"
            >https://www.coinbase.com</a>
          </p>
        </TextComponentFAQ>
      </WrapperComponentFAQ>
    </>
  );
};
