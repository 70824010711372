import React from "react";

import S from "./style.module.scss";

export const MarketplaceHeader = () => {
  return (
    <div className={S.MarketPlaceHeader}>
      <h1 className={S.Title}>
        Marketplace NFTs
      </h1>
    </div>
  );
};
