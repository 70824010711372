import React from "react";

import {useAsset} from "providers";

import S from "./style.module.scss";

export const DescriptionAbout = () => {
  const {asset} = useAsset();

  return (
    <div className={S.TextRow}>
      <div className={S.Title}>Description</div>
      <div className={S.Text}>{asset?.description || "Empty description"}</div>
    </div>
  );
};
