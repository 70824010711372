import React, {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {useApi} from "api";
import {useCurrentState} from "hooks/useCurrentState";
import {errorChecker} from "utils";
import {IUserAssetCount, IUserGetById, IUserStatistics} from "types";

interface IUseProfile {
  profile: Partial<IUserGetById>;
  profileStatistic: Partial<IUserStatistics>;
  profileAssetCount: Partial<IUserAssetCount>;
}

const HOCProfileContext = React.createContext<IUseProfile>({
  profile: {},
  profileStatistic: {},
  profileAssetCount: {},
});
HOCProfileContext.displayName = "HOCProfileContext";
const HOCProfileProvider = HOCProfileContext.Provider;

export function useProfile (): IUseProfile {
  const hocProfileData = useContext(HOCProfileContext);
  if (hocProfileData === null) {
    throw new Error("Hook available only in <Profile> components");
  }
  return hocProfileData;
}

interface IProps {
  children?: React.ReactNode;
}

export const ProfileProvider = ({children}: IProps) => {
  const {id} = useParams<{ id: string }>();
  const {api} = useApi();

  const [profile, setProfile] = useCurrentState<Partial<IUserGetById>>({});
  const [profileStatistic, setProfileStatistic] = useState<Partial<IUserStatistics>>({});
  const [profileAssetCount, setProfileAssetCount] = useState<Partial<IUserAssetCount>>({});

  const getProfile = useCallback(async () => {
    try {
      let res = await api.user.getUserById(Number(id));
      setProfile(res);
    } catch (err) {
      // @ts-ignore
      if (err.status === 404) {
        window.location.href = "/404";
      }
    }
  }, [api.user, id, setProfile]);

  const getProfileStatistics = useCallback(async () => {
    try {
      let res = await api.user.getUserStatistics(Number(id));
      setProfileStatistic(res);
    } catch (err) {
      errorChecker(err);
    }
  }, [api, id]);

  const getProfileAssetCount = useCallback(async () => {
    try {
      let res = await api.user.getUserAssetsCount(Number(id));
      setProfileAssetCount(res);
    } catch (err) {
      errorChecker(err);
    }
  }, [api, id]);

  useEffect(() => {
    setProfile({});
    setProfileStatistic({});
    setProfileAssetCount({});

    (async () => {
      await getProfile();
      await getProfileStatistics();
      await getProfileAssetCount();
    })();
  }, [id, getProfile, getProfileStatistics, getProfileAssetCount, setProfile]);

  // TODO request to update user info
  // TODO request to update user counter
  // TODO request to update user statistic
  return (
    <HOCProfileProvider
      value={{
        profile,
        profileStatistic,
        profileAssetCount
      }}
    >
      {children}
    </HOCProfileProvider>
  );
};
