import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const NotificationProgressIcon = ({size = 42}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21"
        cy="21"
        r="21"
        fill="url(#paint0_linear_4284_36199)"
      />
      <g clipPath="url(#clip0_4284_36199)">
        <path
          d="M20.9998 32.9996C14.3973 32.9996 9 27.6023 9 20.9998C9 14.3973 14.3973 9 20.9998 9C27.6023 9 32.9996 14.3973 32.9996 20.9998C33.052 27.6023 27.6547 32.9996 20.9998 32.9996ZM20.9998 10.2576C15.0785 10.2576 10.31 15.0785 10.31 20.9474C10.31 26.8163 15.1309 31.6372 20.9998 31.6372C26.8687 31.6372 31.6896 26.8163 31.6896 20.9474C31.6896 15.0785 26.9211 10.2576 20.9998 10.2576Z"
          fill="white"
        />
        <path
          d="M24.7727 27.1307L20.3186 22.6766V14.5545H21.7334V22.1002L25.7683 26.1351L24.7727 27.1307Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4284_36199"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC4A1A"/>
          <stop
            offset="1"
            stopColor="#F7B733"
          />
        </linearGradient>
        <clipPath id="clip0_4284_36199">
          <rect
            width="24"
            height="23.9996"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
