import React from "react";

import {List} from "components/List";

import S from "./style.module.scss";

export interface ITableBlock {
  title: string;
  body: string[];
  align?: "left" | "center" | "right" | "justify" | "char" | undefined;
}

interface IProps {
  title?: string;
  tableBlock?: ITableBlock[];
}

export const Table = ({title, tableBlock}: IProps) => {
  if (tableBlock?.length === 0) {
    return (<></>);
  }

  return (
    <div className={S.Table}>
      {title && <h6>{title}</h6>}
      <div className={S.TableScroll}>
        <table>
          <thead>
            <tr>
              {tableBlock?.map((el, key) => {
                let align = el.align || "center";
                return (
                  <th
                    key={key}
                    align={align}
                  >{el.title}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tableBlock?.map((el, key) => {
                let align = el.align || "center";
                return (
                  <td
                    key={key}
                    align={align}
                  >
                    <List list={el.body}/>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
