import React, {useMemo} from "react";

import {useAsset} from "providers";
import {AssetTabsItemSkeleton} from "pages/Asset/AssetSkeleton/AssetTabsItemSkeleton";
import {Bids} from "components/Asset/AssetTabs/Tables/Bids";

import {AssetsTables} from "../Tables";

export const BidsContent = () => {
  const {available} = useAsset();

  const isShowNoRates = useMemo(() => {
    return available?.auctions?.filter((el) => el.highestBidder !== null).length === 0;
  }, [available?.auctions]);

  return (
    <>
      <AssetsTables>
        {available?.auctions &&
          available.auctions?.length > 0 &&
          available?.auctions?.map((auction, key) =>
            <Bids
              key={key}
              auctionId={auction.id}
            />
          )
        }
        {isShowNoRates &&
          <tr>
            <td
              colSpan={3}
              align={"center"}
            >
              No Bids
            </td>
          </tr>
        }
      </AssetsTables>
      {!available?.auctions && <AssetTabsItemSkeleton/>}
    </>
  );
};
