import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const PlusIcon = ({
  fill = "white",
  className,
  size = 24,
}: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12H12M17 12H12M12 12V7M12 12V17"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlusIconNoPadding = ({
  fill = "white",
  className,
  size = 14,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 7.5H7M14 7.5H7M7 7.5V0.5M7 7.5V14.5" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
