import React, {useCallback, useContext, useState} from "react";
import Torus, {TORUS_BUILD_ENV_TYPE} from "@toruslabs/torus-embed";
import Web3 from "web3";
import {AbstractProvider} from "web3-core";
import {Unit} from "web3-utils";
import {useNavigate} from "react-router-dom";

import {errorChecker} from "utils";
import {showNotification} from "components/Notification";
import {useApi} from "api";
import {useQuery} from "hooks/useQuery";
import {useDebug, usePrice} from "providers";
import {TCrypto} from "types";
import {TWeb3PopupCustomEventPartial} from "providers/Web3/web3Popup";
import {useStore} from "providers";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    ethereum: any;
    web3: any;
  }
}

export enum EWeb3Provider {
  empty = "",
  torus = "torus",
  isMetaMask = "MetaMask",
  isCoinbaseWallet = "Coinbase"
}

export type TWeb3ProvideFlag = Exclude<keyof typeof EWeb3Provider, EWeb3Provider.torus>;
export type TWeb3Wallet = "torus" | "MetaMask" | "Coinbase";

export interface IWeb3UserData {
  email: string;
  name: string;
  profileImage: string;
  typeOfLogin: string;
  verifier: string;
  verifierId: string;
  balance?: string;
}

export interface IWeb3State {
  accounts: string[];
  chainId?: number;
  networkId?: number;
  chainIdNetworkMap: Record<string, string>;
  buildEnv: TORUS_BUILD_ENV_TYPE;
  userData?: Partial<IWeb3UserData>;
  wallet?: string;
  balance: string;
  currency: TCrypto;
  symbol: TCrypto;
}

interface IWeb3FromWei {
  price?: string | number;
  toFixed?: number;
  decimals?: number;
}

interface IWeb3ToWei {
  price?: number | string;
  type?: Unit;
  decimals?: number;
}

export interface IWeb3 {
  web3State?: IWeb3State;
  web3: Web3;
  torus: Torus;
  isCheckUserChain: (chainId: number) => Promise<void>;
  isUserAuth: () => void;
  checkAuth: (action?: Function) => Promise<void>;
  auth: () => void;
  login: (walletName: TWeb3Wallet) => void;
  checkAutoSign: () => void;
  logout: () => Promise<void>;
  fromWei: ({price, toFixed, decimals}: IWeb3FromWei) => string;
  toWei: ({price, type, decimals}: IWeb3ToWei) => string;
  getPendingTransactions: () => void;
  getBalance: (wallet: string) => void;
  clear: () => void;
  walletTypePopup: TWeb3ProvideFlag;
}

const HocWeb3Context = React.createContext<IWeb3>({
  web3State: undefined,
  web3: new Web3(),
  torus: new Torus(),
  isCheckUserChain () {
    return Promise.resolve(undefined);
  },
  isUserAuth () {
  },
  checkAuth () {
    return Promise.resolve(undefined);
  },
  auth () {
  },
  login () {
  },
  checkAutoSign () {
  },
  logout () {
    return Promise.resolve(undefined);
  },
  fromWei () {
    return "0";
  },
  toWei () {
    return "0";
  },
  getPendingTransactions () {

  },
  getBalance () {

  },
  clear () {

  },
  walletTypePopup: "empty"
});
HocWeb3Context.displayName = "HocWeb3Context";
const HocWeb3ContextProvider = HocWeb3Context.Provider;


/**
 * @example https://github.com/torusresearch/torus-embed/blob/8a1e3ab0e9f1c4d9eaac6ef09059ee2656f5ff3e/examples/react-basic-app/src/App.tsx
 * @documentation https://www.npmjs.com/package/@toruslabs/torus-embed
 */
export function useWeb3 (): IWeb3 {
  const hocWeb3Data = useContext(HocWeb3Context);
  if (hocWeb3Data === null) {
    throw new Error("Hook Web3 available only is nested components");
  }
  return hocWeb3Data;
}

interface IProps {
  children?: React.ReactNode;
}

export const Web3Provider = ({children}: IProps) => {
  const {api} = useApi();
  const queryParams = useQuery();
  const {debug} = useDebug();
  const navigate = useNavigate();
  const {listOfNetwork} = usePrice();
  const {token, setIsLoader} = useStore();

  const [walletTypePopup, setWalletTypePopup] = useState<TWeb3ProvideFlag>("torus");
  const [web3State, setWeb3State] = useState<IWeb3State>({
    accounts: [],
    chainId: 3,
    networkId: 0,
    chainIdNetworkMap: {
      1: "mainnet",
      3: "ropsten",
      4: "rinkeby",
      5: "goerli",
      42: "kovan",
      56: "bsc_mainnet",
      97: "bsc_testnet",
    },
    userData: undefined,
    buildEnv: "testing",
    wallet: "",
    balance: "0.00000",
    currency: "",
    symbol: ""
  });
  const refWeb3State = React.useRef<IWeb3State>(web3State);
  refWeb3State.current = web3State;

  const updateWeb3State = useCallback((state: Partial<IWeb3State>) => {
    setWeb3State(prevState => ({
      ...prevState,
      ...state
    }));
  }, []);

  const [web3] = useState<Web3>(new Web3());
  const [torus] = useState<Torus>(new Torus({}));

  const clear = useCallback((isReload = true) => {
    debug({title: "useWeb3-clear", desc: "Clear all user information and reload page"});

    (async () => {
      try {
        await torus.cleanUp();
      } catch (err) {
        errorChecker(err);
      }
    })();

    const debugValue = localStorage.getItem("debugValue");

    localStorage.clear();
    if (debugValue === "1") {
      localStorage.setItem("debugValue", "1");
    }

    sessionStorage.clear();

    if (isReload) {
      window.location.assign("/");
    }
  }, [debug, torus]);


  const triggerCustomEvent = useCallback((event: TWeb3PopupCustomEventPartial) => {
    const customEvent = new CustomEvent("web3PopupCustomEvent", {detail: event});
    window.dispatchEvent(customEvent);
  }, []);

  const isCheckUserChain = useCallback(async (chainId: number) => {
    if (!refWeb3State.current.wallet) {
      triggerCustomEvent({connect: true});
      throw new Error("");
    }
    let userChain = await web3.eth.getChainId();

    if (chainId !== userChain) {
      triggerCustomEvent({changeChainId: true, setChainId: chainId});
      throw new Error("");
    }
  }, [triggerCustomEvent, web3.eth]);

  const isUserAuth = useCallback(() => {
    if (!refWeb3State.current.wallet) {
      triggerCustomEvent({connect: true});
      throw new Error("");
    }
  }, [triggerCustomEvent]);

  const checkAuth = useCallback(async (action?: Function) => {
    if (refWeb3State.current.wallet) {
      action && await action();
      return;
    }

    triggerCustomEvent({connect: true});
  }, [triggerCustomEvent]);

  // from 500000000 => 0.00005
  const fromWei = useCallback(({price, toFixed = 5, decimals = 18}: IWeb3FromWei) => {
    return String(Number(price) / (10 ** decimals)).substring(0, toFixed);
  }, []);

  // from 0.00005 => 500000000
  const toWei = useCallback(({price, type = "ether", decimals = 18}: IWeb3ToWei) => {
    return String(Number(price) * (10 ** decimals));
  }, []);

  // TODO find solution to get pending transactions list
  const getPendingTransactions = useCallback(async () => {
    const transactions = await web3.eth.getPendingTransactions();
  }, [web3.eth]);

  const getWallet = useCallback(async (type: TWeb3ProvideFlag): Promise<string> => {
    const accounts = await web3.eth.getAccounts();

    debug({title: "useWeb3-loginByProvider-accounts", desc: accounts});

    sessionStorage.setItem("wallet", accounts[0]);

    updateWeb3State({
      wallet: accounts[0],
      accounts: accounts
    });

    sessionStorage.setItem("walletService", EWeb3Provider[type]);

    return accounts[0];
  }, [debug, updateWeb3State, web3.eth]);

  const getBalance = useCallback(async (wallet: string) => {
    if (wallet) {
      await web3.eth.getBalance(wallet || "", (err, balance) => {
        debug({title: "useWeb3-loginByProvider-getBalance", desc: balance});

        if (balance) {
          updateWeb3State({
            balance: fromWei({price: balance})
          });
        }
      });
    }

  }, [web3.eth, debug, updateWeb3State, fromWei]);

  const getChainId = useCallback(async (wallet: string) => {
    if (wallet) {
      let chainId = await web3.eth.getChainId();
      debug({title: "useWeb3-loginByProvider-getChainId", desc: chainId});

      updateWeb3State({
        chainId
      });
    }
  }, [debug, updateWeb3State, web3.eth]);

  const getNetworkId = useCallback(async (wallet: string) => {
    if (wallet) {
      let networkId = await web3.eth.net.getId();
      debug({title: "useWeb3-loginByProvider-getNetworkId", desc: networkId});

      updateWeb3State({
        networkId
      });
    }
  }, [debug, updateWeb3State, web3.eth]);

  const getCurrency = useCallback(async (wallet: string) => {
    if (wallet) {
      let network = listOfNetwork.find(el => el.chainId === refWeb3State.current.chainId);
      debug({title: "useWeb3-loginByProvider-getCurrency", desc: network});

      updateWeb3State({
        currency: network?.chain || "ETH",
        symbol: network?.nativeCurrency.symbol
      });
    }
  }, [debug, listOfNetwork, updateWeb3State]);

  let loginToBE: (wallet: string) => Promise<void>;
  loginToBE = useCallback(async (wallet: string) => {
    const walletService = sessionStorage.getItem("walletService") || "";
    clear(false);

    if (wallet) {
      triggerCustomEvent({signature: true});

      const {token: t} = await api.auth.getToken();

      const dataToSign = web3.utils.toHex(t) || "";

      debug({title: "Web3-loginToBE-dataToSign", desc: dataToSign});
      debug({title: "Web3-loginToBE-wallet", desc: wallet});

      const signature = await web3.eth.personal.sign(dataToSign, wallet, "") || "";

      const login = await api.auth.login({
        address: wallet || "",
        signature,
        token: t
      });

      sessionStorage.setItem("wallet", wallet);
      sessionStorage.setItem("walletService", walletService);
      sessionStorage.setItem("token", login?.key);
      sessionStorage.setItem("refreshToken", login?.refreshToken);
      sessionStorage.setItem("expirationDate", login?.expirationDate);
      window.location.reload();
    }
  }, [clear, triggerCustomEvent, api.auth, web3.utils, web3.eth.personal, debug]);

  const getBaseUserInfo = useCallback(async (wallet: string) => {
    /**
     * Show balance modal to login in wallet
     */
    await getBalance(wallet);
    await getChainId(wallet);
    await getNetworkId(wallet);
    await getCurrency(wallet);
  }, [getBalance, getChainId, getCurrency, getNetworkId]);

  const getUserInfo = useCallback(async (type: TWeb3ProvideFlag, enterType: "auth" | "login" = "auth") => {
    let wallet = await getWallet(type);

    if (enterType === "login") {
      /**
       * Show login modal with information to login user for our side
       */
      await loginToBE(wallet);
    } else {
      await getBaseUserInfo(wallet);
    }
  }, [getWallet, loginToBE, getBaseUserInfo]);

  const showModalToDesktop = useCallback((type: TWeb3ProvideFlag) => {
    triggerCustomEvent({connect: false, install: true});
    setWalletTypePopup(type);
    throw {code: 1000};
  }, [triggerCustomEvent]);

  const handleChainChanged = useCallback(async (chainId: number) => {
    const oldChainId = Number(refWeb3State.current.chainId);
    const newChainId = Number(chainId);

    debug({title: "Web3-handleChainChanged-chainId", desc: newChainId});

    if (oldChainId && (oldChainId !== newChainId)) {
      const wallet = refWeb3State.current.wallet || "";
      showNotification({
        subtitle: "Change chainId success"
      });
      if (wallet) {
        await getBaseUserInfo(wallet);
      }
    }
  }, [debug, getBaseUserInfo]);

  const handleAccountChange = useCallback(async () => {
    try {
      const accounts = await web3.eth.getAccounts();
      const accountAddress = accounts[0] || "";
      const oldWallet = String(refWeb3State.current.wallet).toUpperCase();
      const newWallet = String(accountAddress).toUpperCase();

      debug({title: "Web3-handleAccountChange-_accountAddress", desc: accountAddress});

      if (oldWallet && (oldWallet !== newWallet)) {
        showNotification({
          subtitle: "Change wallet success"
        });
        /**
         * don't send UpperCase value to BE or State
         */
        await loginToBE(accountAddress);
      }
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoader(false);
    }
  }, [debug, loginToBE]);

  const connectListener = useCallback(async (provider?: AbstractProvider) => {
    if (provider) {
      // @ts-ignore
      provider.removeListener("chainChanged", handleChainChanged);
      // @ts-ignore
      provider.on("chainChanged", handleChainChanged);

      // @ts-ignore
      provider.removeListener("accountsChanged", handleAccountChange);
      // @ts-ignore
      provider.on("accountsChanged", handleAccountChange);

      // @ts-ignore
      provider.on("logs", (logs) => {
        debug({title: "Web3-connectListener-logs", desc: logs});
      });
    }
  }, [debug, handleAccountChange, handleChainChanged, setIsLoader, web3.eth]);

  const loginTorus = useCallback(async () => {
    try {
      const {buildEnv, chainId, chainIdNetworkMap} = refWeb3State.current;
      const isTorus = torus.provider;

      if (!isTorus) {
        await torus.init({
          showTorusButton: true,
          network: {host: chainIdNetworkMap?.[String(chainId)] || ""},
          useLocalStorage: true,
        });

        const accounts = await torus.login();
        sessionStorage.setItem("pageUsingTorus", buildEnv || "test");

        sessionStorage.setItem("wallet", accounts[0]);
        updateWeb3State({
          wallet: accounts[0],
          accounts
        });

        web3.setProvider(torus.provider as AbstractProvider);

        sessionStorage.setItem("walletService", "torus");

        if (accounts?.length > 0) {
          let userData = await torus.getUserInfo(accounts[0]);
          updateWeb3State({
            userData
          });
        }
      }
    } catch (err) {
      clear();
    }
  }, [clear, torus, updateWeb3State, web3]);

  const loginByProvider = useCallback(async (type: TWeb3ProvideFlag) => {
    debug({title: "useWeb3-loginByProvider-window.ethereum", desc: window.ethereum});
    debug({title: "useWeb3-loginByProvider-type", desc: type});

    if (typeof window.ethereum === "undefined") {
      showModalToDesktop(type);
    } else {
      if ("providers" in window.ethereum) {
        const provide = window.ethereum.providers?.find((provider: any) => {
          return provider[type];
        });

        if (provide) {
          await provide.request({method: "eth_requestAccounts"});
          web3.setProvider(provide);

          await connectListener(provide);
        } else {
          showModalToDesktop(type);
          debug({title: "useWeb3-loginByProvider-provide", desc: "cant find needed provider"});
        }

      } else {
        if (window.ethereum[type]) {
          debug({title: "useWeb3-loginByProvider-window.ethereum", desc: window.ethereum});

          await window.ethereum.request({method: "eth_requestAccounts"});
          web3.setProvider(window.ethereum);

          await connectListener(window.ethereum);
        } else {
          showModalToDesktop(type);
        }
      }
    }
  }, [connectListener, debug, showModalToDesktop, web3]);

  const login = useCallback(async (walletName: TWeb3Wallet) => {
    debug({title: "useWeb3-LOGIN-START", desc: "+++++++++++++++++++++"});

    debug({title: "useWeb3-LOGIN-walletName", desc: walletName});

    try {
      if (walletName === "torus") {
        await loginTorus();
      }

      if (walletName === "MetaMask") {
        await loginByProvider("isMetaMask");
        await getUserInfo("isMetaMask", "login");
      }

      if (walletName === "Coinbase") {
        await loginByProvider("isCoinbaseWallet");
        await getUserInfo("isCoinbaseWallet", "login");
      }

      debug({title: "useWeb3-LOGIN-END", desc: "+++++++++++++++++++++"});
    } catch (err: any) {
      if (err && err.code !== 1000) {
        clear();
      }
      errorChecker(err);
      debug({title: "useWeb3-LOGIN-END-ERROR_MSG", desc: err});
      debug({title: "useWeb3-LOGIN-END-ERROR", desc: "+++++++++++++++++++++"});
    }
  }, [clear, debug, getUserInfo, loginByProvider, loginTorus]);

  const logout = useCallback(async () => {
    clear();
  }, [clear]);

  const checkAutoSign = useCallback(async () => {
    const [sign] = [queryParams.get("sign")];

    if (queryParams.has("sign")) {
      queryParams.delete("sign");
      navigate({
        search: queryParams.toString()
      });

      if (token === "") {
        await login(sign as TWeb3Wallet);
      }
    }
  }, [login, navigate, queryParams, token]);

  const auth = useCallback(async () => {
    debug({title: "useWeb3-AUTH-START", desc: "+++++++++++++++++++++"});

    const t = sessionStorage.getItem("token");
    const walletService = sessionStorage.getItem("walletService") as TWeb3Wallet;

    try {
      if (t && walletService === "torus") {
        await loginTorus();
      }

      if (t && walletService === "MetaMask") {
        await loginByProvider("isMetaMask");
        await getUserInfo("isMetaMask");
      }

      if (t && walletService === "Coinbase") {
        await loginByProvider("isCoinbaseWallet");
        await getUserInfo("isCoinbaseWallet");
      }

      debug({title: "useWeb3-AUTH-END", desc: "+++++++++++++++++++++"});

      await checkAutoSign();
    } catch (err) {
      debug({title: "useWeb3-AUTH-END-ERROR", desc: "+++++++++++++++++++++"});
      errorChecker(err);
      clear();
    }
  }, [debug, checkAutoSign, loginTorus, loginByProvider, getUserInfo, clear]);

  return (
    <HocWeb3ContextProvider
      value={{
        web3State,
        web3,
        torus,
        isCheckUserChain,
        isUserAuth,
        checkAuth,
        auth,
        login,
        checkAutoSign,
        logout,
        fromWei,
        getPendingTransactions,
        toWei,
        getBalance,
        clear,
        walletTypePopup
      }}
    >
      {children}
    </HocWeb3ContextProvider>
  );
};

// onSocketEvent((data) => {
//   const msg = "purchased";
//   if (data.data.notifications[0].type === msg) {
//     setBuyPopup(true);
//     setBuyPopupData({
//       blockHash: data.data.notifications[0].data.event.blockHash,
//       title: data.data.notifications[0].data.title,
//       buyer: data.data.notifications[0].initiator.displayName,
//     });
//   }
//   dispatch(hideLoader());
// });
