import React, {createRef, FormEvent, forwardRef, useEffect, useImperativeHandle, useState} from "react";

import {Delete} from "assets/icons";
import {classNames, errorChecker} from "utils";
import {bioValidator, emailValidator, nameValidator} from "common/regEx";
import {useApi} from "api";
import {useStore, useWeb3} from "providers";
import {useMyUser} from "providers";
import {showNotification} from "components/Notification";
import {Input} from "components/Input";
import {CheckMarkAvatar} from "assets/icons/CheckMarkAvatar";
import {AddImages} from "components/AddImages";

import S from "./style.module.scss";

interface IFormProp {
  name: string;
  email: string;
  description: string;
  instagramUrl: string;
  twitterUrl: string;
}

export const EditProfile = forwardRef((props?, popupRef?) => {

  const {api} = useApi();
  const {web3, web3State} = useWeb3();

  const {user, requestUser} = useMyUser();

  const [photo, setPhoto] = useState<string>();
  const [photoFile, setPhotoFile] = useState<string>();
  const [coverPhoto, setCoverPhoto] = useState<string>();
  const [coverFile, setCoverFile] = useState<string>();
  const [isEmailError, setIsEmailError] = useState(false);
  const [isDescriptionsError, setIsDescriptionsError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const formRef = createRef<HTMLFormElement>();

  const {setIsAlerts} = useStore();

  useImperativeHandle(popupRef, () => ({
    submitForm () {
      formRef?.current?.dispatchEvent(
        new Event("submit", {cancelable: true, bubbles: true})
      );
    }
  }));

  const wallet = web3State?.wallet;

  useEffect(() => {
    setPhotoFile(user?.photo);
    setCoverFile(user?.cover);
  }, [user?.photo, user?.cover]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const form: IFormProp = {
      name: "",
      email: "",
      description: "",
      twitterUrl: "",
      instagramUrl: "",
    };
    const formData = new FormData(e.target as HTMLFormElement);

    // @ts-ignore
    for (const [key, value] of formData.entries()) {
      form[key] = value;
    }

    const nameChecked = form.name.length === 0 ? (user?.cryptoWallet || "1") : form.name;

    if (!nameValidator(form.name) && form.name.length != 0) {
      setIsNameError(true);
      return;
    } else if (!bioValidator(form.description)) {
      setIsDescriptionsError(true);
      return;
    } else if (form.email.length > 0 ? !emailValidator(form.email) : false) {
      setIsEmailError(true);
      return;
    }

    const emptyTokenRes = await api.auth.getToken();
    const msg = web3?.utils.toHex(emptyTokenRes.token) || "";
    await web3?.eth.personal.sign(msg, wallet || "", "", async (error, res) => {
      if (error) {
        console.info(error);
        return;
      } else {
        const formDataWeb3 = new FormData();
        formDataWeb3.append("token", emptyTokenRes.token);
        formDataWeb3.append("signature", res);
        formDataWeb3.append("description", form.description);
        formDataWeb3.append("nickname", nameChecked);
        formDataWeb3.append("email", form.email);

        if (wallet) {
          formDataWeb3.append("address", wallet);
        }

        if (form.instagramUrl) {
          formDataWeb3.append("instagramUrl", form.instagramUrl);
        }
        if (form.twitterUrl) {
          formDataWeb3.append("twitterUrl", form.twitterUrl);
        }

        if (coverPhoto) {
          formDataWeb3.append("cover", coverPhoto);
        } else {
          if (!coverFile) {
            formDataWeb3.append("cover", "");
          }
        }

        if (photo) {
          formDataWeb3.append("photo", photo);
        }
        // else {
        //   if (!photoFile) {
        //     formDataWeb3.append("photo", "");
        //   }
        // }

        setIsAlerts(true);
        try {
          await api.user.updateUser(formDataWeb3);
          await requestUser();

          setIsAlerts(false);

          showNotification({
            title: "Success",
            subtitle: "Profile updated"
          });
        } catch (err) {
          errorChecker(err);
          setIsAlerts(false);
        }
      }
    });
  };

  const deleteCoverPhoto = () => {
    setCoverFile("");
    setCoverPhoto("");
  };

  const deletePhoto = () => {
    setPhotoFile("");
    setPhoto("");
  };

  const format = ["image/jpeg", "image/png", "image/gif"];

  return (
    <>
      <div className={classNames(S.BodyWrapper, popupRef ? S.PopupMode : "")}>
        <form
          ref={formRef}
          className={S.Body}
          onSubmit={onSubmit}
        >
          <article className={S.Row}>
            <div className={S.TitleRow}>Images</div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Cover</div>
              {coverFile ? (
                <figure className={S.Cover}>
                  <img
                    decoding="async"
                    src={coverFile || user?.cover}
                    alt=""
                  />
                  <div
                    className={S.DeleteIcon}
                    onClick={deleteCoverPhoto}
                  >
                    <Delete/>
                  </div>
                </figure>
              ) : (
                <AddImages
                  description={"We recommend an image of at least 1920x320 px"}
                  format={format}
                  image={setCoverFile}
                  imageSet={setCoverPhoto}
                />
              )}
            </div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Avatar</div>
              {photoFile ? (
                <figure className={S.Photo}>
                  <span>
                    <img
                      decoding="async"
                      src={photoFile || user?.photo}
                      alt=""
                    />
                  </span>
                  {user && user.verificationStatus !== "none" && <CheckMarkAvatar className={S.CheckMark}/>}
                  <div
                    className={S.DeleteIcon}
                    onClick={deletePhoto}
                  >
                    <Delete/>
                  </div>
                </figure>
              ) : (
                <AddImages
                  description={"We recommend an image of at least 360x360 px"}
                  format={format}
                  image={setPhotoFile}
                  imageSet={setPhoto}
                />
              )}
            </div>
          </article>
          <article className={S.Row}>
            <div className={S.TitleRow}>About</div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Your name</div>
              <Input
                afterLabel={
                  isNameError
                    ? "Set valid name"
                    : "Pick the name by which user identify you"
                }
                name={"name"}
                isError={isNameError}
                placeholder={"Your name"}
                value={user?.displayName || ""}
                onFocus={() => setIsNameError(false)}
                onBlur={(e) => {
                  e.currentTarget.value.length > 0 && !nameValidator(e.currentTarget.value) && setIsNameError(true);
                }}
                minLength={0}
                maxLength={30}
              />
            </div>
            <div className={S.InfoRow}>
              <div className={S.Title}>About you</div>
              <Input
                textarea={true}
                rows={3}
                name={"description"}
                afterLabel={
                  isDescriptionsError
                    ? "Max About you length 400 symbols"
                    : "Add a short descriptions"
                }
                placeholder={
                  isDescriptionsError
                    ? "Max About you length 400 symbols"
                    : "Add a short descriptions"
                }
                value={user?.description || ""}
                onFocus={() => setIsDescriptionsError(false)}
                isError={isDescriptionsError}
                onBlur={(e) => {
                  !bioValidator(e.currentTarget.value) && setIsDescriptionsError(true);
                }}
                className={S.Textarea}
              />
            </div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Email</div>
              <Input
                afterLabel={
                  isEmailError
                    ? "Please set valid email"
                    : "Receive email notification"
                }
                name={"email"}
                placeholder={"Add your email"}
                value={user?.email || ""}
                isError={isEmailError}
                onFocus={() => setIsEmailError(false)}
                onBlur={(e) => {
                  !emailValidator(e.currentTarget.value) &&
                  setIsEmailError(true);
                }}
              />
            </div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Instagram</div>
              <Input
                name={"instagramUrl"}
                placeholder={"Enter link"}
                value={user?.instagramUrl || ""}
              />
            </div>
            <div className={S.InfoRow}>
              <div className={S.Title}>Twitter</div>
              <Input
                name={"twitterUrl"}
                placeholder={"Enter link"}
                value={user?.twitterUrl || ""}
              />
            </div>
          </article>
        </form>
      </div>
    </>
  );
});
