import React, {ReactNode} from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  avatar?: string;
  title?: string;
  label?: ReactNode;
  text?: ReactNode;
  textPopup?: ReactNode;
  className?: string;
  classNameLabel?: string;
  classNameDesc?: string;
  classNameText?: string;
  classNameAvatar?: string;
  descReverse?: boolean;
  textAlign?: "left" | "right";
}

export const UserInfo = ({
  avatar,
  title,
  label,
  text,
  textPopup,
  className,
  classNameLabel,
  classNameDesc,
  classNameText,
  classNameAvatar,
  textAlign = "left",
}: IProps) => {
  return (
    <div className={classNames(S.UserInfo, className)}>
      {avatar && (
        <div className={classNames(S.Avatar, classNameAvatar)}>
          <img
            src={avatar}
            alt=""
          />
        </div>
      )}

      <div
        className={classNames(
          S.Desc,
          S.Small,
          S[textAlign],
          classNameDesc
        )}
      >
        <div className={classNames(S.Label, classNameLabel)}>{label}</div>
        <div
          className={S.TextRow}
          title={title && title}
        >
          <div className={classNames(S.Text, classNameText)}>{text}</div>
          {textPopup && (
            <div className={S.textPopup}>{textPopup}</div>
          )}
        </div>
      </div>
    </div>
  );
};
