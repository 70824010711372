import React, {useEffect, useState} from "react";

import {classNames} from "utils";
import {ProfileHeader} from "components/Profile/ProfileHeader";
import {TGetAssetKeys} from "types";
import {useMyUser, useProfile} from "providers";
import {CardBlockNew} from "components/Home/CardBlockNew";
import {ProfileBids} from "components/Profile/ProfileMyBids";
import {ProfileProjects} from "components/Profile/ProfileContent/Projects";
import {useTheme} from "hooks/useTheme";
import {ProfilePosts} from "components/Profile/ProfileContent/Profile";

interface IProps {
  className?: string;
}

export type TGetActiveContent = "profile" | "nft" | "project";

const tabs = ["profile", "nft", "project"];

export const ProfileContent = (
  {
    className
  }: IProps) => {
  const {user} = useMyUser();
  const {profile} = useProfile();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<TGetAssetKeys>("owner");
  const [isBidsTab, setIsBidsTab] = useState<boolean>(false);
  const onClickBidTab = () => setIsBidsTab(true);

  const [isActiveContent, setActiveContent] = useState<TGetActiveContent>();

  let click: () => void;
  click = () => {
    const hash = window.location.hash.slice(1);
    const result = tabs.filter(item => item === hash);
    // @ts-ignore
    result.length > 0 && setActiveContent(hash);
    result.length === 0 && setActiveContent(theme === "nftim" ? "profile" : "nft");
  };

  useEffect(() => {
    click();
  }, [click]);

  return (
    <div className={classNames("relative block flex-1 min-w-0 max1024:flex-none max1024:w-full", className)}>
      <ProfileHeader
        currentTab={currentTab}
        onTabClick={setCurrentTab}
        onClickBidTab={onClickBidTab}
        userWallet={profile.cryptoWallet}
        isActiveContent={isActiveContent}
        setActiveContent={setActiveContent}
      />
      <div className={"relative block w-full"}>
        {isActiveContent === "profile" && theme === "nftim" && (
          <ProfilePosts/>
        )}
        {isActiveContent === "nft" && (
          <>
            {profile.cryptoWallet && currentTab != "myBids" && (
              <CardBlockNew
                className={"!px-0"}
                type={currentTab}
                listOnly
              />
            )}
            {profile.cryptoWallet === user.cryptoWallet && currentTab === "myBids" && (
              <ProfileBids
                wallet={profile.cryptoWallet}
                bidsTab={isBidsTab}
                currentTab={currentTab}
              />
            )}
          </>
        )}
        {isActiveContent === "project" && (
          <ProfileProjects
            profileId={profile.id}
          />
        )}
      </div>
    </div>
  );
};
