import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const LightningIcon = ({
  size = 19,
  fill = "#4F4F4F",
}: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75391 10.125H8.92188L7.12539 16.875L15.0039 7.875H9.83594L11.6289 1.125L3.75391 10.125Z"
      fill={fill}
    />
  </svg>
);
