import React, {useEffect, useState} from "react";

import {IStructureFilter} from "api";
import S from "components/Filters/Popup/style.module.scss";
import {CustomSelect} from "components/Select";
import {TSetFilterValue, useFilter} from "components/Filters/provider";

interface IProps {
  inputData: IStructureFilter[];
}

export const FilterInput = ({
  inputData,
}: IProps) => {
  const [filters, setFilters] = useState<TSetFilterValue[]>([]);
  const {baseFilterState, setFilter} = useFilter();

  useEffect(() => {
    setFilters(baseFilterState);
  }, [baseFilterState]);

  return (
    <div className={S.FilterInputWrapper}>
      {inputData && inputData.map((filterItem, key) => {
        const isCheckExists = Boolean(filters?.filter(el => filterItem?.filters?.filter((filter) => filter?.key === el?.key)[0]).length > 0);

        const checkValue = filters?.filter(el => filterItem?.filters?.filter((filter) => filter?.key === el?.key)[0])[0];

        return (
          <div key={key}>
            <p className={S.FilterInputTitle}>
              {filterItem.title.toUpperCase()}
            </p>
            <CustomSelect
              options={filterItem.filters}
              value={checkValue || null}
              onChange={(option) => setFilter(
                option || {...filterItem.filters[0], value: undefined},
                isCheckExists,
                filterItem.type
              )}
            />
          </div>
        );
      })}
    </div>
  );
};
