import {ApiConfig} from "api/src/api/api.config";
import {TCryptoCurrency, TFiatCurrency} from "types";

export const ApiCoinbase = {
  coinbase: {
    getCryptoPrice: async (
      crypto: TCryptoCurrency,
      fiat: TFiatCurrency = "USD"
    ) => new ApiConfig({
      baseUrl: "https://api.coinbase.com/"
    }).get<{ data: { base: string, currency: string, amount: number } }>(
      `v2/prices/${crypto}-${fiat}/spot`)
  },
};
