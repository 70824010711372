import React from "react";

import {NotificationSuccess} from "assets/icons/NotificationIcons/NotificationSuccess";
import {NotificationImportant} from "assets/icons/NotificationIcons/NotificationImportant";
import {NotificationCanceled} from "assets/icons/NotificationIcons/NotificationCanceled";
import {NotificationNewSubs} from "assets/icons/NotificationIcons/NotificationNewSubs";
import {NotificationNewComment} from "assets/icons/NotificationIcons/NotificationNewComment";
import {NotificationNewLike} from "assets/icons/NotificationIcons/NotificationNewLike";
import {NotificationMarket} from "assets/icons/NotificationIcons/NotificationMarket";
import {NotificationAuction} from "assets/icons/NotificationIcons/NotificationAuction";
import {NotificationBidIcon} from "assets/icons/NotificationIcons/NotificationBidIcon";
import {NotificationTransferred} from "assets/icons/NotificationIcons/NotificationTransferred";
import {NotificationProgressIcon} from "assets/icons/NotificationIcons/NotificationProgressing";

interface INotificationIcon {
  className?: string;
  size?: number;
  notificationType: string;
}

const iconsTypes = {
  "new_asset": <NotificationSuccess/>,
  "new_your_asset": <NotificationSuccess/>,
  "asset_bought": <NotificationSuccess/>,
  "purchased": <NotificationSuccess/>,
  "auction_won": <NotificationSuccess/>,
  "auction_will_be_soon": <NotificationImportant/>,
  "auction_ended": <NotificationImportant/>,
  "bid_canceled": <NotificationCanceled/>,
  "marketplace_you_canceled": <NotificationCanceled/>,
  "marketplace_canceled": <NotificationCanceled/>,
  "auction_you_canceled": <NotificationCanceled/>,
  "auction_canceled": <NotificationCanceled/>,
  "new_subscribe": <NotificationNewSubs/>,
  "new_comment_to_asset": <NotificationNewComment/>,
  "new_comment_to_comment": <NotificationNewComment/>,
  "new_like_to_asset": <NotificationNewLike/>,
  "new_like_to_comment": <NotificationNewLike/>,
  "new_your_marketplace": <NotificationMarket/>,
  "new_marketplace": <NotificationMarket/>,
  "new_your_auction": <NotificationAuction/>,
  "new_auction": <NotificationAuction/>,
  "bid_placed": <NotificationBidIcon/>,
  "bid_received": <NotificationBidIcon/>,
  "token_transferred": <NotificationTransferred/>,
  "token_received": <NotificationTransferred/>,
  "success": <NotificationSuccess/>,
  "error": <NotificationCanceled/>,
  "progress": <NotificationProgressIcon/>
};

export const NotificationIcon = ({notificationType, className, size}: INotificationIcon) => {

  if (iconsTypes?.[notificationType]) {
    return React.cloneElement(
      iconsTypes?.[notificationType],
      {className, size}
    );
  }

  return React.cloneElement(
    <NotificationSuccess/>,
    {className, size}
  );
};
