import React, {useCallback, useContext, useEffect, useReducer} from "react";

import {useApi} from "api";
import {errorChecker} from "utils";
import {IGetChainIdList, IGetPriceList} from "types";

type TPriceList = Record<string, number>;
type TChainList = Record<number, string>;

interface IUsePrice {
  fullPriceList: IGetPriceList[];
  cryptoList: TPriceList;
  chainList: TChainList;
  listOfNetwork: IGetChainIdList[];
  updatePrice: () => void;
}

const HocPriceContext = React.createContext<IUsePrice>({
  fullPriceList: [],
  cryptoList: {},
  chainList: {},
  listOfNetwork: [],
  updatePrice (): void {
  }
});
HocPriceContext.displayName = "HocPriceContext";
const HocPriceContextProvide = HocPriceContext.Provider;

export function usePrice (): IUsePrice {
  const hocPriceData = useContext(HocPriceContext);
  if (hocPriceData === null) {
    throw new Error("Hook available only in nested components");
  }
  return hocPriceData;
}

interface IStats {
  fullPriceList: IGetPriceList[];
  cryptoList: TPriceList;
  chainList: TChainList;
  listOfNetwork: IGetChainIdList[];
}

const reducer = (state: IStats, action: { type: "set", value: IStats }) => {
  switch (action.type) {
    case "set":
      return action.value;
  }
};

interface IProps {
  setPreloader: (value: boolean) => void;
  children?: React.ReactNode;
}

export const PriceProvide = ({setPreloader, children}: IProps) => {
  const {api} = useApi();

  const [items, setItems] = useReducer(
    reducer,
    {fullPriceList: [], cryptoList: {}, chainList: {}, listOfNetwork: []}
  );

  const getPrices = useCallback(async () => {
    try {
      const [fullPriceList, listOfNetwork] = await Promise.all([
        api.other.getPriceList("USD"),
        api.other.getChainIdList()
      ]);

      let cryptoList = {};
      let chainList = {};

      fullPriceList.forEach((price) => {
        cryptoList[price.base] = price.amountFormatted;
        price.chainIds.map((chain) => {
          chainList[chain] = price.chain;
        });
      });

      setItems({
        type: "set",
        value: {
          fullPriceList,
          cryptoList,
          chainList,
          listOfNetwork
        }
      });
    } catch (err) {
      errorChecker(err);
    } finally {
      setPreloader(false);
    }
  }, [api.other, setPreloader]);

  useEffect(() => {
    getPrices();
  }, [getPrices]);

  const updatePrice = () => {
    getPrices();
  };

  return (
    <HocPriceContextProvide
      value={{
        ...items,
        updatePrice
      }}
    >
      {children}
    </HocPriceContextProvide>
  );
};
