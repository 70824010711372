import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";

import IcoVal1 from "assets/img/charity/val/ico-val-3.png";
import IcoVal2 from "assets/img/charity/val/ico-val-7.png";
import IcoVal3 from "assets/img/charity/val/ico-val-1.png";
import {classNames} from "utils";

import {showNotification} from "../../Notification";

import S from "./style.module.scss";


const donate = [
  {
    ico: IcoVal1,
    title: "Binance smart chain",
    desc: "BNB, BUSD",
    wallet: "0x4fb90C5DbC81A06283C21FCCC41388Cebcd8eA37",
  },
  {
    ico: IcoVal2,
    title: "BITCOIN",
    desc: "BTC",
    wallet: "bc1qyl4nryt357gq9ed0ej44kt0g468l8x49y7ktw4",
  },
  {
    ico: IcoVal3,
    title: "ETH, USDT, USDC",
    desc: "ETH (ERC20)",
    wallet: "0x4fb90C5DbC81A06283C21FCCC41388Cebcd8eA37",
  }
];

export const CharityDonate = () => {
  return (
    <div className={classNames(S.CharityDonate, "CharityWrapper")}>
      <div className={"CharityInnerWrapper"}>
        <div className={S.Title}>
          <div className={S.First}>You can also donate by transferring cryptocurrency</div>
          <div className={S.Second}>BY TRANSFERRING CRYPTOCURRENCY</div>
        </div>
        <div className={S.DonateBlock}>
          {donate.map((item, index) => (
            <div
              className={S.Item}
              key={index}
            >
              <div className={S.Ico}><img
                src={item.ico}
                alt=""
              /></div>
              <div className={S.Box}>
                <div className={S.Desc}>{item.desc}</div>
                <div className={S.Name}>{item.title}</div>
                <div className={S.Wallet}>{item.wallet}</div>
                {/*// @ts-ignore*/}
                <CopyToClipboard
                  onCopy={() =>
                    showNotification({
                      title: "Success",
                      subtitle: "Copied successfully"
                    })
                  }
                  text={item.wallet}
                >
                  <button className={S.CopyButton}>copy</button>
                </CopyToClipboard>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
