import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const ContactUsIcon = ({className, fill = "var(--blue_static)", size = 18}: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9L5.61 11.992L14 5L6 14.455V18L9.164 13.887L15 17L18 0L0 9Z"
        fill={fill}
      />
    </svg>
  );
};
