import React from "react";
import {Link} from "react-router-dom";

import {classNames} from "utils";
import {Button} from "components/Button";
import {useMyUser} from "providers";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  showPopup?: () => void;
  onClose?: () => void;
}

export const StatusButton = ({className, showPopup, onClose}: IProps) => {
  const {user} = useMyUser();

  return (
    <>
      {user.verificationStatus === "none" && (
        <div className={classNames(S.Buttons, className)}>
          <Button
            type={"button"}
            color={"static"}
            onClick={showPopup}
            className={S.ButtonPopup}
          >
            <div className={S.ButtonText}>Verify account</div>
          </Button>
        </div>
      )}
      {user.verificationStatus === "rejected" && (
        <div className={classNames(S.Buttons, className)}>
          <Button
            type={"button"}
            color={"static"}
            onClick={showPopup}
            className={S.ButtonPopup}
          >
            <div className={S.ButtonText}>Verify account</div>
          </Button>
        </div>
      )}
      {user.verificationStatus === "verified" && (
        <div className={classNames(S.Buttons, className)}>
          <Link
            to={"/create"}
            className={S.LinkCreate}
            onClick={onClose}
          >Create NFT</Link>
        </div>
      )}
    </>
  );
};
