import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const RockerIcon = ({size = 36, fill = "black"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2 26.4L9.60001 22.8V16.8L19.2 26.4H13.2Z"
        fill={fill}
      />
      <path
        d="M32.3748 3.6252C32.3748 3.6252 21.6 3.6 13.2 12C9.612 15.588 9.6 21.6 12 24C14.4 26.4 20.412 26.388 24 22.8C32.4 14.4 32.3748 3.6252 32.3748 3.6252ZM20.4 13.2C20.4 11.874 21.474 10.8 22.8 10.8C24.126 10.8 25.2 11.874 25.2 13.2C25.2 14.526 24.126 15.6 22.8 15.6C21.474 15.6 20.4 14.526 20.4 13.2Z"
        fill={fill}
      />
      <path
        d="M11.4504 26.808C11.3964 26.8848 11.364 26.9736 11.2944 27.042C10.1244 28.212 8.3688 27.6276 8.3688 27.6276C8.3688 27.6276 7.7832 25.872 8.9544 24.702C9.024 24.6324 9.1116 24.6012 9.1884 24.546L8.64 23.9964C8.184 24.1164 7.7508 24.3444 7.3944 24.7008C5.7552 26.34 6.0156 29.9808 6.0156 29.9808C6.0156 29.9808 9.6348 30.2628 11.2956 28.602C11.652 28.2456 11.88 27.8124 12 27.3564L11.4504 26.808Z"
        fill={fill}
      />
      <path
        d="M22.5732 22.4112C22.5732 22.4112 23.4816 26.952 21.6648 28.7676C18.9408 31.4916 14.4 32.4 14.4 32.4L15.6 24L22.5732 22.4112Z"
        fill={fill}
      />
      <path
        d="M13.5888 13.4268C13.5888 13.4268 9.04801 12.5184 7.23241 14.3352C4.50841 17.0592 3.60001 21.6 3.60001 21.6L12 20.4L13.5888 13.4268Z"
        fill={fill}
      />
    </svg>
  );
};
