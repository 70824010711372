import React from "react";
import Lightbox from "react-image-lightbox";

import {useTheme} from "hooks/useTheme";
import {useAsset} from "providers";
import {ZoomIcon} from "assets/icons/ZoomIcon";

import S from "./style.module.scss";

export const AssetZoom = () => {
  const {asset, isLightbox, toggleLightbox} = useAsset();
  // const theme = useTheme();

  // if (theme === "nftim") {
  //   return null;
  // }

  return (
    <div className={S.ZoomWrapper}>
      {(asset?.fileExtension === "mp3" || asset?.fileExtension === "mp4")
        ? ""
        : <div
          className={S.LightBoxButton}
          onClick={() => toggleLightbox(true)}
        >
          <ZoomIcon/>
        </div>
      }
      {isLightbox && (
        // @ts-ignore
        <Lightbox
          mainSrc={asset?.ipfsFull || asset?.thumbnail || ""}
          onCloseRequest={() => toggleLightbox(false)}
        />
      )}
    </div>
  );
};
