import React from "react";



export const NotificationActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9 18.7534L21.6733 18.5534C21.0303 17.9804 20.4674 17.3234 20 16.6C19.4895 15.6017 19.1835 14.5115 19.1 13.3934V10.1C19.1044 8.34376 18.4673 6.64633 17.3085 5.32667C16.1496 4.007 14.5488 3.15592 12.8067 2.93335V2.07335C12.8067 1.83731 12.7129 1.61093 12.546 1.44402C12.3791 1.27712 12.1527 1.18335 11.9167 1.18335C11.6806 1.18335 11.4542 1.27712 11.2873 1.44402C11.1204 1.61093 11.0267 1.83731 11.0267 2.07335V2.94668C9.30016 3.1853 7.71864 4.04152 6.57501 5.35675C5.43138 6.67199 4.80315 8.35711 4.80666 10.1V13.3934C4.72316 14.5115 4.41718 15.6017 3.90666 16.6C3.44725 17.3216 2.89346 17.9785 2.25999 18.5534L2.03333 18.7534V20.6334H21.9V18.7534Z" fill="white"/>
      <path
        d="M10.2134 21.3333C10.2718 21.7558 10.4812 22.143 10.8029 22.4232C11.1246 22.7034 11.5368 22.8578 11.9634 22.8578C12.39 22.8578 12.8022 22.7034 13.1239 22.4232C13.4455 22.143 13.6549 21.7558 13.7134 21.3333H10.2134Z" fill="white"/>
      <circle
        cx="18"
        cy="6"
        r="4"
        fill="#FF2D55"
      />
    </svg>
  );
};
