import React, {RefObject} from "react";
import {Link} from "react-router-dom";

import {CharityMainIconBlack} from "assets/icons/CharityMainIconBlack";

import S from "./style.module.scss";

const NaviList = [
  {to: "terms", text: "Terms of use"},
];

interface IProps {
  refAttr?: RefObject<HTMLElement>;
}

export const CharityFooter = ({refAttr}: IProps) => {

  return (
    <footer
      className={S.CharityFooterWrapper}
      ref={refAttr}
    >
      <div className={S.CharityFooter}>
        <div className={S.Left}>
          <CharityMainIconBlack/>
        </div>
        <div className={S.Right}>
          <ul className={S.NaviList}>
            {
              NaviList.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.to}>{item.text}</Link>
                  </li>
                );
              })
            }
          </ul>
          <ul className={S.Social}>
            <li>
              <a
                href="https://www.instagram.com/helpua.io/"
                target="_blank"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9375 5.06885C7.97456 5.06885 5.51953 7.48155 5.51953 10.4868C5.51953 13.4921 7.93223 15.9048 10.9375 15.9048C13.9428 15.9048 16.3555 13.4498 16.3555 10.4868C16.3555 7.52387 13.9005 5.06885 10.9375 5.06885ZM10.9375 13.9577C9.03276 13.9577 7.46662 12.3916 7.46662 10.4868C7.46662 8.58207 9.03276 7.01594 10.9375 7.01594C12.8423 7.01594 14.4084 8.58207 14.4084 10.4868C14.4084 12.3916 12.8423 13.9577 10.9375 13.9577Z"
                    fill="black"
                  />
                  <path
                    d="M16.5674 6.16938C17.2453 6.16938 17.7949 5.61981 17.7949 4.94187C17.7949 4.26393 17.2453 3.71436 16.5674 3.71436C15.8894 3.71436 15.3398 4.26393 15.3398 4.94187C15.3398 5.61981 15.8894 6.16938 16.5674 6.16938Z"
                    fill="black"
                  />
                  <path
                    d="M19.741 1.76719C18.6405 0.624331 17.0744 0.0317383 15.2966 0.0317383H6.57702C2.89448 0.0317383 0.439453 2.48676 0.439453 6.1693V14.8466C0.439453 16.6667 1.03205 18.2328 2.21723 19.3757C3.36009 20.4762 4.8839 21.0264 6.61935 21.0264H15.2543C17.0744 21.0264 18.5982 20.4339 19.6987 19.3757C20.8416 18.2751 21.4342 16.709 21.4342 14.8889V6.1693C21.4342 4.39153 20.8416 2.86772 19.741 1.76719ZM19.5717 14.8889C19.5717 16.2011 19.1061 17.2593 18.3442 17.9788C17.5823 18.6984 16.5241 19.0794 15.2543 19.0794H6.61935C5.34951 19.0794 4.2913 18.6984 3.5294 17.9788C2.76749 17.2169 2.38654 16.1587 2.38654 14.8466V6.1693C2.38654 4.89946 2.76749 3.84126 3.5294 3.07936C4.24898 2.35978 5.34951 1.97883 6.61935 1.97883H15.3389C16.6088 1.97883 17.667 2.35978 18.4289 3.12169C19.1484 3.88359 19.5717 4.94179 19.5717 6.1693V14.8889Z"
                    fill="black"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/helpua_"
                target="_blank"
              >
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3683 2.37727C20.6017 2.71805 19.7766 2.94792 18.9109 3.05079C19.7948 2.52162 20.4725 1.68342 20.7922 0.683513C19.9654 1.17416 19.0501 1.53018 18.0752 1.72237C17.295 0.890946 16.1829 0.371094 14.9523 0.371094C12.5897 0.371094 10.6741 2.28667 10.6741 4.64972C10.6741 4.98457 10.7118 5.31096 10.785 5.62465C7.229 5.44601 4.07602 3.74294 1.9657 1.15426C1.5974 1.78587 1.38658 2.52078 1.38658 3.30564C1.38658 4.78984 2.14181 6.09963 3.28989 6.86671C2.58885 6.84427 1.92887 6.65166 1.35187 6.33119C1.35145 6.34897 1.35145 6.36718 1.35145 6.38538C1.35145 8.45802 2.82634 10.1869 4.78383 10.5806C4.42484 10.678 4.04681 10.7305 3.65649 10.7305C3.38048 10.7305 3.11251 10.7038 2.85131 10.6534C3.39614 12.3531 4.97602 13.5905 6.848 13.6248C5.38369 14.7724 3.53923 15.4566 1.53433 15.4566C1.18931 15.4566 0.848528 15.4362 0.513672 15.3964C2.40766 16.611 4.65641 17.3188 7.07236 17.3188C14.9425 17.3188 19.2457 10.7995 19.2457 5.14544C19.2457 4.96002 19.2419 4.77502 19.2339 4.5913C20.0695 3.98932 20.7951 3.23536 21.3683 2.37727Z"
                    fill="black"
                  />
                </svg>
              </a>
            </li>
          </ul>
          <div>Contact us - <a href="mailto:donate@helpua.io">donate@helpua.io</a></div>
          <div>NFTIM - Support for UKRAINE © {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  );
};
