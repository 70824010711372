import React from "react";

import {CharityNovaLogo} from "assets/icons/CharityNovaLogo";
import {CharityNFTIMLogo} from "assets/icons/CharityNFTIMLogo";
import {CharityLogoMinistry} from "assets/icons/CharityLogoMinistry";
import {CharityLogoKuna} from "assets/icons/CharityKunaLogo";
import {classNames} from "utils";

import S from "./style.module.scss";

export const CharityMinistry = () => {
  return (
    <div className={classNames(S.CharityMinistry, "CharityWrapper")}>
      <div className={"CharityInnerWrapper"}>
        <div className={S.Title}>
          <div className={S.First}>Charitable NFT marketplace initiative</div>
          <div className={S.Second}>NFTIM supported by Ministry of Digital Transformation of Ukraine</div>
        </div>
        <div className={S.Description}>
          <p>Supported by Ministry of Digital Transformation of Ukraine NFT fundraising initiative was created by NFTim marketplace as an immediate response to war in Ukraine in order to support people in urgent need.</p>
          <br/>
          <p>NFTim has turned their marketplace into the charitable project HELPUA to aid Ukrainian people. After severe war damages many people have lost their homes and had no access to food and other basics. HELPUA is a way to provide support to Ukrainians who require urgent help.</p>
        </div>
        <div className={S.List}>
          <CharityNFTIMLogo />
          <CharityLogoMinistry />
          <CharityLogoKuna />
          <CharityNovaLogo />
        </div>
      </div>
    </div>
  );
};
