import React from "react";
import {Link} from "react-router-dom";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  classNameTitle?: string;
  classNameWrapper?: string;
  classNameUserName?: string;
  id?: number;
  title?: string;
  thumbnailPhoto?: string;
  displayName?: string;
  children?: React.ReactNode;
}

export const UserBlock = ({
  className,
  classNameTitle,
  classNameWrapper,
  classNameUserName,
  id,
  title,
  thumbnailPhoto,
  displayName,
  children
}: IProps) => {
  return (
    <div className={classNames(S.UserWrapper, className)}>
      {title && (
        <div className={classNames(S.UserTitle, classNameTitle)}>
          {title}
        </div>
      )}
      <div className={classNames(S.UserBlockWrapper, classNameWrapper)}>
        <Link
          className={S.UserPhoto}
          to={`/profile/${id}`}
          style={{backgroundImage: `url(${thumbnailPhoto})`}}
        />
        <Link
          className={classNames(S.UserName, classNameUserName)}
          to={`/profile/${id}`}
          title={displayName}
        >
          {displayName}
        </Link>
      </div>
      {children}
    </div>
  );
};
