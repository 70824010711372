import React from "react";
import {Link} from "react-router-dom";

import {ICollection} from "types";
import {UserInfo} from "pages/Asset/UserInfo";

import S from "./style.module.scss";

interface IProps {
  collection: ICollection;
}

// TODO change to base img wrapper
export const NFTIMCollectionCard = ({collection}: IProps) => {
  return (
    <div className={S.NFTIMCollectionCard}>
      <Link
        to={`/collection/${collection.id}`}
        className={S.Box}
      >
        <div
          className={S.ImageBg}
          style={{backgroundImage: `url(${collection.thumbnailCover})`}}
        />
        <div className={S.RowBox}>
          <div className={S.CountNFT}>{collection.nftCount} NFT</div>
          <div className={S.Title}>{collection.name}</div>
          <UserInfo
            avatar={collection?.owner?.thumbnailPhoto || collection.thumbnailPhoto}
            label={"Created by"}
            text={collection.owner ?
              <>
                <div className={S.Name}><span>{collection?.owner?.displayName}</span></div>
              </>
              : "Creator"
            }
            className={S.UserInfo}
            classNameAvatar={S.Avatar}
            classNameDesc={S.Desc}
            classNameLabel={S.Label}
            classNameText={S.Text}
          />
        </div>
      </Link>
    </div>
  );
};
