import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const MasterCardIcon = ({size = 36}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3878_33501)">
        <path
          d="M35.9999 18.1526C35.9999 24.3141 31.0165 29.3052 24.8706 29.3052C18.7246 29.3052 13.7412 24.3141 13.7412 18.1526C13.7412 11.9912 18.7169 7 24.8628 7C31.0088 7 35.9999 11.9912 35.9999 18.1526Z"
          fill="#F79F1A"
        />
        <path
          d="M22.2587 18.1526C22.2587 24.3141 17.2753 29.3052 11.1294 29.3052C4.98341 29.3052 0 24.3141 0 18.1526C0 11.9912 4.98341 7 11.1294 7C17.2753 7 22.2587 11.9912 22.2587 18.1526Z"
          fill="#EA001B"
        />
        <path
          d="M17.996 9.37158C15.3997 11.4099 13.7334 14.5875 13.7334 18.1526C13.7334 21.7177 15.3997 24.8953 17.996 26.9336C20.5924 24.8953 22.2587 21.7177 22.2587 18.1526C22.2587 14.5875 20.5924 11.4176 17.996 9.37158Z"
          fill="#FF5F01"
        />
        <path
          d="M35.9725 24.0751V23.6812H35.874L35.7558 23.9569L35.6377 23.6714H35.5294V24.0652H35.5983V23.7698L35.7066 24.0258H35.7854L35.9035 23.7698V24.0751H35.9725ZM35.3128 24.0751V23.7403H35.4506V23.6714H35.1158V23.7403H35.2537V24.0751H35.3128Z"
          fill="#F79F1A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3878_33501">
          <rect
            width="35.9999"
            height="36.3052"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
