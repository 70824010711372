import React, {useContext, useState} from "react";

import {useToggle} from "hooks/useToggle";
import {NewBidPopup} from "components/Popups/NewBidPopup";
import {useWeb3Contract} from "providers/Web3Contract/index";
import {BuyNowPopup, TBuyPopup} from "components/Popups/BuyNowPopup/BuyNowPopup";
import {SalePopup} from "components/Popups/SalePopup";
import {SharePopup} from "components/Popups/SharePopup";

interface IUseWeb3ContractPopup {
  togglePlaceBidPopup: () => void;
  setSalePopup: (value: boolean) => void;
  toggleSalePopup: () => void;
  toggleSharePopup: () => void;
  setBuyNowPopup: (value: boolean) => void;
  openBuyNowPopup: (payload: TBuyPopup) => void;
}

export const Web3ContractPopupContext = React.createContext<IUseWeb3ContractPopup>({
  togglePlaceBidPopup (): void {
  },
  setSalePopup (): void {
  },
  toggleSalePopup (): void {
  },
  toggleSharePopup (): void {
  },
  setBuyNowPopup (): void {
  },
  openBuyNowPopup (): void {
  }
});
Web3ContractPopupContext.displayName = "Web3ContractPopupContext";

export function useWeb3ContractPopup (): IUseWeb3ContractPopup {
  const Web3ContractPopupData = useContext(Web3ContractPopupContext);
  if (Web3ContractPopupData === null) {
    throw new Error("Web3ContractPopupData");
  }
  return Web3ContractPopupData;
}

interface IProps {
  children: React.ReactNode;
}

export const Web3ContractPopupProvider = ({children}: IProps) => {
  const [isBuyNowPopup, toggleBuyNowPopup, setBuyNowPopup] = useToggle(false);
  const [isPlaceBidPopup, togglePlaceBidPopup] = useToggle(false);
  const [isSalePopup, toggleSalePopup, setSalePopup] = useToggle(false);
  const [isSharePopup, toggleSharePopup] = useToggle(false);

  const [stateBuyNowPopup, setStateBuyNowPopup] = useState<TBuyPopup>({
    address: "",
    id: 0,
    number: 0,
    price: "0",
    type: "buy",
    currencyAddress: null
  });
  const openBuyNowPopup = (payload: TBuyPopup) => {
    setStateBuyNowPopup(payload);
    setBuyNowPopup(true);
  };

  return (
    <Web3ContractPopupContext.Provider
      value={{
        toggleSalePopup,
        setSalePopup,
        toggleSharePopup,
        togglePlaceBidPopup,
        setBuyNowPopup,
        openBuyNowPopup
      }}
    >
      {children}
      {isSalePopup && (
        <SalePopup onClose={toggleSalePopup}/>
      )}
      {isSharePopup && (
        <SharePopup onClose={toggleSharePopup}/>
      )}
      {isPlaceBidPopup && (
        <NewBidPopup onClose={togglePlaceBidPopup}/>
      )}
      {isBuyNowPopup && (
        <BuyNowPopup
          {...stateBuyNowPopup}
          onClose={toggleBuyNowPopup}
        />
      )}
    </Web3ContractPopupContext.Provider>
  );
};
