import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resourcesEn from "./locales/en/translation.json";
import resourcesRU from "./locales/ru/translation.json";

i18n.use(initReactI18next);
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init({
    resources: {
      en: {
        translations: resourcesEn,
      },
      ru: {
        translations: resourcesRU,
      },
    },
    lng: "en",
    fallbackLng: "en",
    load: "languageOnly", // we only provide en, de -> no region specific locals like en-US, de-DE
    // have a common namespace used around the full app
    supportedLngs: ["en", "ru"],

    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
}

export default i18n;
