import React, {useCallback, useEffect, useRef, useState} from "react";
import Slider from "react-slick";

import {classNames, errorChecker} from "utils";
import {Container} from "components/Container";
import {HelpPeopleItemCard} from "components/CharityHome/CharityHelpPeople/Item/item";
import {ArrowRight} from "assets/icons";
import {IGoalDocument, useApi} from "api";
import {ResponsiveCustom} from "components/Responsive";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  onClick?: () => void;
}

export const CharityHelpPeople = (
  {
    className
  }: IProps) => {
  const sliderRef = useRef<HTMLDivElement | any>(null);

  const PreviousArrow = ({onClick}: IProps) => {
    return (
      <div
        className={S.Prev}
        onClick={onClick}
      >
        <ArrowRight/>
      </div>
    );
  };

  const NextArrow = ({onClick}: IProps) => {
    return (
      <div
        className={S.Next}
        onClick={onClick}
      >
        <ArrowRight/>
      </div>
    );
  };

  const gotoPrev = () => {
    sliderRef.current?.slickPrev();
  };
  const gotoNext = () => {
    sliderRef.current?.slickNext();
  };

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    focusOnSelect: true,
    cssEase: "ease",
    easing: "linear",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const {api} = useApi();
  const [isGoals, setGoals] = useState<IGoalDocument[]>([]);

  const requestData = useCallback(async () => {
    try {
      let result = await api.goals.getGoals();
      setGoals(result?.results);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.goals]);

  useEffect(() => {
    requestData();
  }, [requestData]);


  return (
    <Container>
      <div className={classNames(S.CharityHelpPeople, className)}>
        <div className={S.TitleBlock}>
          <div className={S.Title}>
            <span>Current Stories</span>
            <p>Choose the story you want to help</p>
          </div>
          <ResponsiveCustom min={1024}>
            <>
              {isGoals && isGoals.length > 3 && (
                <div className={S.Arrows}>
                  <PreviousArrow onClick={gotoPrev}/>
                  <NextArrow onClick={gotoNext}/>
                </div>
              )}
            </>
          </ResponsiveCustom>
          <ResponsiveCustom
            min={768}
            max={1023}
          >
            <>
              {isGoals && isGoals.length > 2 && (
                <div className={S.Arrows}>
                  <PreviousArrow onClick={gotoPrev}/>
                  <NextArrow onClick={gotoNext}/>
                </div>
              )}
            </>
          </ResponsiveCustom>
          <ResponsiveCustom max={767}>
            <>
              {isGoals && isGoals.length > 1 && (
                <div className={S.Arrows}>
                  <PreviousArrow onClick={gotoPrev}/>
                  <NextArrow onClick={gotoNext}/>
                </div>
              )}
            </>
          </ResponsiveCustom>
        </div>
        <div className={S.SliderBlock}>
          {isGoals && isGoals.length > 0 && (
            //@ts-ignore
            <Slider {...settingsSlider} className={S.Slider}
              ref={sliderRef}
            >
              {isGoals && isGoals.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={S.Slide}
                  >
                    <HelpPeopleItemCard
                      image={item.photo || item.thumbnailPhoto}
                      title={item.name}
                      text={item.description}
                      goal={item.goalPrice}
                      progress={item.progressPrice}
                      id={item.id}
                    />
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </Container>
  );
};
