import React from "react";
import ContentLoader from "react-content-loader";

export const AssetMainItemSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"100%"}
    viewBox="0 0 632 632"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", display: "block", left: "0px", maxWidth: "100%", margin: "0 auto"}}
  >
    <rect
      x="0"
      y="0"
      rx="0"
      ry="0"
      width="632"
      height="632"
    />
  </ContentLoader>
);
