import React, {useMemo} from "react";

import {MetamaskIcon} from "assets/icons/MetamaskIcon";
import {TWeb3ProvideFlag} from "providers";
import {CoinbaseIcon} from "assets/icons/Coinbase";
import {Button} from "components/Button";

import {PopupSubContentText, PopupSubContentTitle} from "../index";

import S from "./style.module.scss";

export const WalletsExtensionPopup = ({type}: { type: TWeb3ProvideFlag }) => {

  const walletInfo = useMemo(() => {
    switch (type) {
      case "isMetaMask": {
        return {
          name: "Metamask",
          link: "https://metamask.io/download/",
          icon: <MetamaskIcon/>
        };
      }
      case "isCoinbaseWallet": {
        return {
          name: "Coinbase",
          link: "https://www.coinbase.com/wallet/",
          icon: <CoinbaseIcon/>
        };
      }
      default: {
        return {
          name: "Metamask",
          link: "https://metamask.io/download/",
          icon: <MetamaskIcon/>
        };
      }
    }
  }, [type]);

  return (
    <>
      <PopupSubContentTitle
        setTop={"32px"}
        position={"center"}
      >
        <>
          <div className={S.Icon}>{walletInfo.icon}</div>
          <p>{walletInfo.name} wallet not detected</p>
        </>
      </PopupSubContentTitle>
      <PopupSubContentText position={"center"}>
        <>
          Please make sure your wallet is unlocked and available. If you do not currently have a Web3 wallet, we suggest <a href={walletInfo.link}>{walletInfo.name}.</a>
        </>
      </PopupSubContentText>
      <div className={S.subText}>
      </div>
      <a href={walletInfo.link}>
        <Button
          title={`Install ${walletInfo.name} Wallet`}
          color={"primary"}
          className={S.customButton}
        />
      </a>
    </>
  );
};
