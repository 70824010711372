import React from "react";

import {ViewBlock} from "components/ViewBlock";
import {CharityTopBlock} from "components/CharityHome/CharityTopBlock";
import {CharityCardBlock} from "components/CharityHome/CharityCardBlock";
import {CharityInfo} from "components/CharityHome/CharityInfo";
import {CharityTechnology} from "components/CharityHome/CharityTechnology";
import {CharityWorldStars} from "components/CharityHome/CharityWorldStars";
import {CharityCommand} from "components/CharityHome/CharityCommand";
import {CharityDonate} from "components/CharityHome/CharityDonate";
import {CharityMinistry} from "components/CharityHome/CharityMinistry";
import {CharityCollection} from "components/CharityHome/CharityCollection";
import {CharityCardBlockPopular} from "components/CharityHome/CharityCardBlock/popular";
import {HomeNFTIM} from "components/NFTIM/Home";
import {CharityHelpPeople} from "components/CharityHome/CharityHelpPeople";

export const Home = () => {
  return (
    <section>
      <ViewBlock
        value={["nftim"]}
      >
        <HomeNFTIM/>
      </ViewBlock>
      <ViewBlock
        value={["help_ua", "help_ua_dev"]}
      >
        <CharityTopBlock/>
        <CharityInfo/>
        <CharityHelpPeople/>
        <CharityCardBlockPopular/>
        <CharityCollection/>
        <CharityCardBlock/>
        <CharityTechnology/>
        <CharityWorldStars/>
        <CharityCommand/>
        <CharityDonate/>
        <CharityMinistry/>
      </ViewBlock>
    </section>
  );
};
