import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const CoinbaseIcon = ({size = 40}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 0C38.8086 0 50 11.1914 50 25C50 38.8086 38.8086 50 25 50C11.1914 50 0 38.8086 0 25C0 11.1914 11.1914 0 25 0Z"
        fill="#0052FF"
      />
      <path
        d="M25.0049 33.7891C20.1514 33.7891 16.2158 29.8584 16.2158 25C16.2158 20.1416 20.1514 16.2109 25.0049 16.2109C29.3555 16.2109 32.9688 19.3848 33.6621 23.5352H42.5146C41.7676 14.5117 34.2139 7.42188 25 7.42188C15.293 7.42188 7.42188 15.293 7.42188 25C7.42188 34.707 15.293 42.5781 25 42.5781C34.2139 42.5781 41.7676 35.4883 42.5146 26.4648H33.6572C32.959 30.6152 29.3555 33.7891 25.0049 33.7891Z"
        fill="white"
      />
    </svg>
  );
};
