import React from "react";

interface IProps {
  color?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  className?: string;
  borderColor?: string;
}

export const MusicIcon = ({
  onClick,
  className,
  width = "50",
  height = "50",
}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43 3V38.004C43 38.004 42.875 44 36.016 44C28.506 44 28 39.127 28 38C28 35.59 28.942 32.963 36 32.963C39.249 32.963 40 32.128 40 30C40 30 40 14.537 40 13.22C40 11.903 39.92 11.427 38.457 11.758C35.94 12.327 19.5 15.891 18.844 16.048C18.188 16.205 18 16.594 18 17.75C18 19.877 18 40.747 18 43C18 45.253 16.258 49 10 49C3.742 49 3 45.002 3 43C3 40.027 5.25 38.109 10.007 38.109C14.438 38.109 15 36.86 15 35C15 28.367 15 8.96 15 8C15 7.04 15.391 6.391 16.366 6.176C17.997 5.818 41.146 1.045 41.146 1.045C41.146 1.045 43 0.621996 43 3Z"
      fill="white"
    />
  </svg>
);
