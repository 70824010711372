import React from "react";

import {CardNew} from "components/Home/CardBlockNew/CardNew";
import {CardSkeletonResponsive} from "components/Home/CardBlockNew/CardSkeleton";
import {
  useWeb3,
  IInfinityScrollProviderProps,
  InfinityScrollProvider,
  useInfinityScroll
} from "providers";
import {TReactAppTheme, useTheme} from "hooks/useTheme";
import {CardNFTIM} from "components/NFTIM/Card";
import {Button} from "components/Button";
import {INFTNews} from "components/Home/types";
import {IAsset} from "types";
import {classNames} from "utils";
import {EmptyItems} from "components/EmptyItems";

import S from "./style.module.scss";

interface ICardsBodyProps {
  theme: TReactAppTheme;
}

const LoadingButton = () => {
  const {isLoading, requestNextPage} = useInfinityScroll();

  const theme = useTheme();

  if (isLoading === "loaded_last" || isLoading === "empty") {
    return null;
  }

  return (
    <Button
      className={`mt-8 ${theme === "nftim" && "h-[60px] !border-grey_border hover:!border-black"}`}
      title={"Load more NFT"}
      color={"primary_o"}
      onClick={requestNextPage}
      disabled={isLoading === "loading"}
      doubleHover={"colorBlack"}
    />
  );
};

const CardsBody = ({theme}: ICardsBodyProps) => {
  const {checkAuth} = useWeb3();
  const {state, onClickLike, isLoading} = useInfinityScroll();

  if (isLoading === "first") {
    return (
      <div className={S.CardListGrid}>
        {Array.from([1, 2, 3, 4].map((el, key) => {
          return (
            <div key={key}>
              <CardSkeletonResponsive key={key}/>
            </div>
          );
        }))}
      </div>
    );
  }

  if (isLoading === "empty") {
    return (
      <EmptyItems/>
    );
  }

  const renderCardBlock = (asset: INFTNews | IAsset) => {
    if (theme === "help_ua") {
      return (
        <CardNew
          key={asset.id}
          asset={asset}
          onLike={(isLike, id) => checkAuth(() => onClickLike(isLike, id))}
        />
      );
    }

    return (
      <CardNFTIM
        key={asset.id}
        asset={asset}
        onLike={(isLike, id) => checkAuth(() => onClickLike(isLike, id))}
      />
    );
  };

  return (
    <>
      <div className={classNames(S.CardList, S[theme])}>
        {state.map((asset: INFTNews | IAsset) => renderCardBlock(asset))}
      </div>
      {isLoading === "loading" && (
        <div className={classNames(S.CardListGrid, "mt-6")}>
          {Array.from([1, 2, 3, 4].map((el, key) => {
            return (
              <div key={key}>
                <CardSkeletonResponsive key={key}/>
              </div>
            );
          }))}
        </div>
      )}
    </>
  );
};

interface IProps extends Omit<IInfinityScrollProviderProps, "children"> {
  theme?: TReactAppTheme;
  isShowLoadMoreButton?: boolean;
}

export const Cards = (props: IProps) => {
  return (
    <>
      <InfinityScrollProvider {...props}>
        <CardsBody theme={props.theme || "help_ua"}/>
        {props.isShowLoadMoreButton &&
          <LoadingButton/>
        }
      </InfinityScrollProvider>
    </>
  );
};
