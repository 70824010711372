import React, {useState} from "react";

import {Cards} from "components/Cards";
import {TQuery, useApi} from "api";
import {MarketplaceHeader} from "components/MarketplacePage/MarketplaceHeader/MarketplaceHeader";
import {Container} from "components/Container";
import {Filters} from "components/Filters";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";

import S from "./style.module.scss";

export const MarketplacePage = () => {
  const {api} = useApi();

  const [query, setQuery] = useState<TQuery>({});

  const theme = useTheme();

  return (
    <Container
      className={classNames(S.MarketplaceContainer, S[theme])}
    >
      <MarketplaceHeader/>
      <Filters setQuery={setQuery}/>
      <Cards
        request={api.news.getNewsFromES}
        query={query}
        stateType={"object"}
        theme={"nftim"}
      />
    </Container>
  );
};
