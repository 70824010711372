import React, {
  FC,
  useRef,
  ReactNode,
  Dispatch,
  SetStateAction
} from "react";

import {useClickOutside} from "hooks/useClickOutside";
import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  title: string | ReactNode;
  hideArrow?: boolean;
  className?: string;
  children: ReactNode;
  onClick?: boolean;
}

export const Dropdown: FC<IProps> = (
  {
    opened,
    setOpened,
    title,
    className,
    children,
    onClick = true
  }) => {

  const wrapperRef = useRef(null);


  useClickOutside(wrapperRef, () => setOpened(!onClick));

  return (
    <div
      className={classNames(S.Dropdown, S.DropdownDiv, className, {[S.Hover]: !onClick})}
      ref={wrapperRef}
    >
      <div
        onClick={() => setOpened(onClick ? (value) => !value : true)}
        className={S.ClickedItem}
      >
        {title}
      </div>
      {opened && (
        <div className={S.DropdownListBox}>
          {children}
        </div>
      )}
    </div>
  );
};

