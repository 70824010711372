import React, {Component} from "react";
import Lightbox from "react-image-lightbox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";

import Img1 from "assets/img/About/1.jpg";
import Img2 from "assets/img/About/2.jpg";
import Img4 from "assets/img/About/4.jpg";
import Img5 from "assets/img/About/5.jpg";
import Img6 from "assets/img/About/6.jpg";
import Img7 from "assets/img/About/7.jpg";
import Img8 from "assets/img/About/8.jpg";
import Img9 from "assets/img/About/9.jpg";
import textImg from "assets/img/About/textImg.png";
import {PlusIcon} from "assets/icons/PlusIcon";
import {CharitySupportBlock} from "components/CharityHome/CharitySupportBlock";
import ImgCommand0 from "assets/img/Command/0.png";
import ImgCommand1 from "assets/img/Command/1.png";
import ImgCommand2 from "assets/img/Command/2.png";
import ImgCommand4 from "assets/img/Command/4.png";
import ImgCommand5 from "assets/img/Command/5.png";
import ImgCommand6 from "assets/img/Command/6.png";
import ImgCommand7 from "assets/img/Command/7.png";
import ImgCommand8 from "assets/img/Command/8.png";
import ImgCommand9 from "assets/img/Command/9.png";
import ImgHeart from "assets/img/About/heart.png";

import S from "./style.module.scss";


const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
    paritialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
    paritialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
    paritialVisibilityGutter: 100
  }
};

const images = [
  Img1, Img2, Img4, Img5, Img6, Img7, Img8, Img9,
];

const CustomLeftArrow = ({onClick}: any) => <div
  onClick={() => onClick()}
  className={S.CustomLeftArrow}
/>;
const CustomRightArrow = ({onClick}: any) => <div
  onClick={() => onClick()}
  className={S.CustomRightArrow}
/>;

export default class LightboxExample extends Component<any, any> {
  constructor (props: any) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render () {
    const {photoIndex, isOpen} = this.state;

    return (
      <div className={S.PhotoGallery}>
        {/*// @ts-ignore*/}
        <Carousel
          responsive={responsive}
          partialVisible
          className={S.List}
          draggable={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={8000}
          customLeftArrow={<CustomLeftArrow/>}
          customRightArrow={<CustomRightArrow/>}
        >
          {images.map((img, index) => (
            <div
              key={index}
              onClick={() => this.setState({photoIndex: index, isOpen: true})}
              className={S.DivG}
              draggable={false}
            >
              <div
                className={S.Frame}
                draggable={false}
              >
                <div
                  className={S.Img}
                  draggable={false}
                >
                  <img
                    src={img}
                    alt=""
                    draggable={false}
                  />
                </div>
              </div>
            </div>
          ))}
        </Carousel>

        {isOpen && (
          // @ts-ignore
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({isOpen: false})}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export const TextAboutBlock = () => {
  return (
    <div className={S.TextAboutBlock}>
      <div className={S.TextAboutFlex}>
        <div className={S.TextRow}>
          <div className={S.Title}>
            <span>Stand with Ukraine</span>
            <p>AND BUY OUR NFTS</p>
          </div>
          <div className={S.Text}>
            The funds will be used for the support of humanitarian aid programs and the Humanitarian Forces of Ukraine.
          </div>
          <Link
            to="/"
            className={S.BuyNowButton}
          >
            <PlusIcon
              size={20}
            />
            Buy NFT now
          </Link>
        </div>
        <div className={S.textImg}><img
          src={textImg}
          alt=""
        /></div>
      </div>
    </div>
  );
};

const command = [
  {id: 0, img: ImgCommand0, names: "Tim Suleymanov", desc: "Founder\n" + "CEO at NFTIM.IO"},
  {id: 1, img: ImgCommand1, names: "Oleksii Potapenko", desc: "Co-Founder\n" + "CCO at NFTIM.IO"},
  {id: 2, img: ImgCommand2, names: "Sergii Shoot", desc: "Founder & CEO at \n" + "Shooter. UA"},
  {id: 4, img: ImgCommand4, names: "Gary Baiton", desc: "Co-Founder\n" + "CSO at NFTIM.IO"},
  {id: 5, img: ImgCommand5, names: "Mike Kostinsky", desc: "CTO"},
  {id: 6, img: ImgCommand6, names: "Ihor Mishchenko", desc: "Head of Design"},
  {id: 7, img: ImgCommand7, names: "Aleksey Bondar", desc: "Content Manager"},
  {id: 8, img: ImgCommand8, names: "Constantine Yevtushenko", desc: "Managing Partner at UNIT.City"},
  {id: 9, img: ImgCommand9, names: "Olena Tarieieva", desc: "CMO at AWT Bavaria\n" + "BMW Ukraine"},
];

export const CommandAboutBlock = () => {
  return (
    <div className={S.CommandAboutBlock}>
      <div className={S.InnerAboutHelpUs}>
        <div className={S.CMtxt1}>We are a community of:</div>
        <div className={S.CMtxt2}>
          <p>International artists, photographers and business people, supporters of Ukraine and its independence.</p>
          <p><span>We help the Ukrainian people</span> fight for their freedom, for the right to live and exist.</p>
        </div>
        <ul className={S.ListCommand}>
          {command.map((item) =>
            <li key={item.id}>
              <div className={S.Avatar}><span><p><img
                src={item.img}
                alt=""
              /></p></span></div>
              <div className={S.Title}>
                <span>{item.names}</span>
                <p>{item.desc}</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export const AboutHelpUs = () => {
  return (
    <section className={S.WrapperAboutHelpUs}>
      <div className={S.InnerAboutHelpUs}>
        <div className={S.TextOne}>POWERED BY NFTIM</div>
        <div className={S.TextTwo}><span>HELPUA.IO</span> A HUMANITARIAN ART</div>
        <div className={S.TextThree}>COLLECT HUMANITARIAN NFTS TO SUPPORT UKRAINIAN PEOPLE IN THEIR FIGHT FOR FREEDOM</div>
        <div className={S.TextFour}>UKRAINE NEEDS YOUR HELP</div>
        <div className={S.TextFive}>Ukraine is in the middle of a humanitarian disaster. Hundreds of <span>children have been killed</span>. Thousands of <span>adults have lost their lives</span>. The people will continue their fight for freedom, but they need more food, humanitarian supplies, medicine, relocation services and other necessities.
        </div>
        <LightboxExample/>
        <TextAboutBlock/>
      </div>
      <CharitySupportBlock/>
      {/*<CommandAboutBlock/>*/}
      <div className={S.DescAboutHelpUs}>
        <div className={S.InnerAboutHelpUs}>
          <div className={S.DescRow}>
            <div className={S.Txt1}>We want Ukraine to remain sovereign, which is why we create and sell our digital art to help Ukrainians in these terrible times.</div>
            <div className={S.Txt2}>WHILE WE CANNOT FIGHT BATTLES, <span>WE CAN FIGHT WITH OUR CREATIVITY.</span></div>
            <div className={S.Txt3}>
              <p>This is why our most significant NFT digital art has been uploaded to the blockchain as an immutable and eternal timestamp.</p>
              <p>We have selected trustworthy, well-known and respected cryptocurrency donation organizations across many continents to remind the world that the Ukrainian spirit cannot be broken and together we will help Ukraine regain its freedom and rebuild its beautiful country.</p>
            </div>
          </div>
        </div>
      </div>
      <div className={S.Stand}>
        <div className={S.InnerAboutHelpUs}>
          <img
            src={ImgHeart}
            alt=""
          />
          <span>STAND WITH UKRAINE</span>
          <p>Donations from NFT sales are handled by Nova Ukraina <a
            href="https://novaukraine.org"
            target="blank_"
          >novaukraine.org</a>
          </p>
        </div>
      </div>
    </section>
  );
};
