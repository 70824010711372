import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const ReadAll = (
  {
    size = 18,
  }: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2978_25371)">
      <path
        d="M16.5 8.30264V9.00014C16.4991 10.6175 15.9754 12.1911 15.007 13.4865C14.0386 14.7819 12.6775 15.7295 11.1265 16.1881C9.57557 16.6466 7.91795 16.5916 6.40085 16.0311C4.88376 15.4706 3.58849 14.4347 2.70822 13.0779C1.82795 11.7212 1.40984 10.1162 1.51626 8.50238C1.62267 6.88857 2.24791 5.35239 3.29871 4.12295C4.34951 2.89351 5.76959 2.03668 7.34714 1.68025C8.9247 1.32382 10.5752 1.4869 12.0525 2.14514"
        stroke="#939CB4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 2.25L9 10.5L6.75 8.25"
        stroke="#939CB4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2978_25371">
        <rect
          width="18"
          height="18"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
