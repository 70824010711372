import React, {useState} from "react";
import Slider from "react-slick";

import previewPotap from "assets/img/charity/potap.jpg";
import previewDorn from "assets/img/charity/dorn.jpg";
import previewKamenski from "assets/img/charity/kamenski.jpg";
import previewDorofeeva from "assets/img/charity/dorofeeva.jpg";
import previewDantes from "assets/img/charity/dantes.jpg";
import {useToggle} from "hooks/useToggle";
import {Popup} from "components/Popups";

import S from "./style.module.scss";


export const SliderStars = () => {

  const SampleNextArrow = (props: any) => {
    const {className, onClick} = props;
    return (
      <div
        className={className + " " + S.Next}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const {className, onClick} = props;
    return (
      <div
        className={className + " " + S.Prev}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: "ease",
    easing: "linear",
    waitForAnimate: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      },
    ]
  };

  const sliders = [
    {id: 0, src: previewPotap, link: "https://youtube.com/embed/vcTShPuSENQ"},
    {id: 1, src: previewDorn, link: "https://youtube.com/embed/SSfRYlSIy4w"},
    {id: 2, src: previewKamenski, link: "https://youtube.com/embed/dMrqh0wp2WA"},
    {id: 3, src: previewDorofeeva, link: "https://youtube.com/embed/6nlo04DhYqk"},
    {id: 4, src: previewDantes, link: "https://youtube.com/embed/QYLN9Q1F5Rw"},
    // {id: 5, src: imgPrev, link: "https://www.youtube.com/embed/16yIdoa48UI"},
    // {id: 6, src: imgPrev, link: "https://www.youtube.com/embed/16yIdoa48UI"},
    // {id: 7, src: imgPrev, link: "https://www.youtube.com/embed/16yIdoa48UI"},
    // {id: 8, src: imgPrev, link: "https://www.youtube.com/embed/16yIdoa48UI"},
    // {id: 9, src: imgPrev, link: "https://www.youtube.com/embed/16yIdoa48UI"},
  ];

  const [isVideoPopup, , setVideoPopup] = useToggle(false);
  const [videoValue, setVideoValue] = useState<string>("");

  const onPreviewClick = (value: string) => {
    setVideoPopup(true);
    setVideoValue(value);
  };

  return (
    <>
      <div className={S.Slider}>
        {/*// @ts-ignore*/}
        <Slider {...settings}>
          {sliders.map((slider, key) =>
            <div
              key={key}
              className={S.Items}
              onClick={() => onPreviewClick(slider.link)}
            >
              <div className={S.Frame}>
                <img
                  src={slider.src}
                  alt="helpua.io"
                />
              </div>
            </div>
          )}
        </Slider>
      </div>
      {isVideoPopup &&
        <Popup
          clickOnClose={() => setVideoPopup(false)}
          isIFrame={true}
        >
          <iframe
            id="player"
            width="320"
            height="420"
            src={videoValue}
            frameBorder="0"
            allowFullScreen
          />
        </Popup>
      }
    </>
  );
};
