import React from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  title: string;
  icon?: React.ReactNode;
  underTitle: string;
  description?: string;
  mode?: "white" | "black" | "gray";
  filters?: React.ReactNode;
  explore?: React.ReactNode;
  paddingBottom?: "normal" | "large";
}

export const Title = ({className, title, icon, underTitle, mode, filters, explore, paddingBottom, description}: IProps) => {
  return (
    <div
      className={classNames(S.Title, className)}
      title={title}
    >
      {/*<p className={classNames(S.UnderTitle, S[mode])}>{underTitle}</p>*/}
      <div className={classNames(S.ContainerTitleBlock, paddingBottom ? S[paddingBottom] : "")}>
        <div className={S.Flex}>
          <div className={classNames(S.TextRow, mode ? S[mode] : "")}>
            <div className={S.underTitle}>{underTitle}</div>
            {icon ? (
              <div className={S.Icon}>{icon && icon}</div>
            ) : ("")}
            <div className={S.Text}>
              {title}
              {description && (
                <p className={"relative w-full text-xl leading-6 font-bold text-text_description max1024:!text-sm"}>
                  {description}
                </p>
              )}
            </div>
          </div>
          {/*we have filters: Blockchain, SaleType, LastDate*/}
          {filters ? (
            <div className={S.FilterRow}>{filters}</div>
          ) : ""}
          {explore ? explore : ""}
        </div>
      </div>
    </div>
  );
};
