import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const VisaIcon = ({size = 36, fill = "#0072FF"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2437 13.5562L13.7812 22.4437H16.0875L17.55 13.5562H15.2437Z"
        fill={fill}
      />
      <path
        d="M28.575 13.5562C28.0125 13.5562 27.6188 13.7249 27.3938 14.2874L23.9625 22.4999H26.3813C26.3813 22.4999 26.775 21.3749 26.8875 21.1499C27.1688 21.1499 29.475 21.1499 29.8125 21.1499C29.8688 21.4874 30.0938 22.4999 30.0938 22.4999H32.2313L30.375 13.5562H28.575ZM27.5063 19.2937C27.675 18.7874 28.4063 16.8187 28.4063 16.8187C28.4063 16.8187 28.575 16.3124 28.6875 15.9749L28.8563 16.7624C28.8563 16.7624 29.3063 18.8999 29.4188 19.3499H27.5063V19.2937Z"
        fill={fill}
      />
      <path
        d="M22.1625 17.1562C21.375 16.7625 20.8688 16.4812 20.8688 16.0312C20.8688 15.6375 21.2625 15.2437 22.1625 15.2437C22.8938 15.2437 23.4563 15.4125 23.9063 15.5812L24.1313 15.6937L24.4688 13.7812C24.0188 13.6125 23.2875 13.3875 22.3875 13.3875C20.1375 13.3875 18.5063 14.625 18.5063 16.3125C18.5063 17.6062 19.6313 18.3375 20.5313 18.7312C21.4313 19.1812 21.7125 19.4625 21.7125 19.8562C21.7125 20.475 20.9813 20.7 20.3063 20.7C19.4063 20.7 18.9 20.5875 18.1125 20.25L17.8313 20.0812L17.4938 22.05C18.0563 22.275 19.0125 22.5 20.0813 22.5C22.5 22.5 24.075 21.3187 24.075 19.4625C24.1313 18.5625 23.5125 17.775 22.1625 17.1562Z"
        fill={fill}
      />
      <path
        d="M11.8688 13.5562L9.6188 19.6312L9.3938 18.3937L8.6063 14.2874C8.4938 13.7249 8.0438 13.5562 7.53755 13.5562H3.8813C3.82505 13.5562 3.82505 13.6124 3.7688 13.6124C3.7688 13.6687 3.7688 13.7249 3.82505 13.7249C4.38755 13.8937 4.8938 14.0624 5.3438 14.2874C5.85005 14.5124 6.18755 14.9624 6.3563 15.4687L8.21255 22.4437H10.6313L14.2313 13.5562H11.8688Z"
        fill={fill}
      />
    </svg>
  );
};
