import React from "react";

import BannerBg from "assets/img/charity/imgDecoProfile.jpg";

import {EditProfile} from "../../components/EditProfile";

import S from "./style.module.scss";

// TODO modal to photo change logic to crop img and request to send to backend
export const Settings = () => {

  return (
    <div className={S.Container}>
      <div className={S.Wrapper}>
        <div
          className={S.Header}
          style={{backgroundImage: `url(${BannerBg})`}}
        />

        <EditProfile />
      </div>
    </div>
  );
};
