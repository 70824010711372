import {errorChecker} from "utils";

import {IRefreshTokenRequest, IRefreshTokenResponse} from "../types";

import {ApiConfig, TApiConfigHeadersProps} from "./api.config";

const headers: TApiConfigHeadersProps = {
  ["Content-Type"]: "application/json;charset=UTF-8",
  ["X-Content-Type-Options"]: "nosniff",
  ["Content-Security-Policy"]: "default-src",
  ["Strict-Transport-Security"]: "max-age=31536000;includeSubDomains",
  ["X-Frame-Options"]: "SAMEORIGIN",
};
const baseUrl = process.env.REACT_APP_API_URL || "https://api.nftim.io/";

export interface IExtraPrams {
  auth?: boolean;
}

const beforeRequestHandle = async (updateHeader: any, url?: string, params?: any) => {
  let token = sessionStorage.getItem("token");

  if (params?.extraParams?.auth) {
    if (!token) {
      throw new Error(`You not authorized url:${url}`);
    }
  }

  let refreshToken = sessionStorage.getItem("refreshToken");
  let expirationDate = sessionStorage.getItem("expirationDate");

  if (token && refreshToken && expirationDate) {
    let date = new Date().getTime();

    let expiredMinutes = new Date(expirationDate).getTime() - date;

    if (expiredMinutes < 60000) {

      try {
        let res = await new ApiConfig({
          baseUrl,
          config: {
            headers: {
              ...headers,
              ["Authorization"]: `Token ${token}`
            }
          }
        }).post<IRefreshTokenRequest, IRefreshTokenResponse>(`api/v2/refresh_token/`, {
          body: {
            key: token,
            refreshToken: refreshToken
          }
        });

        sessionStorage.setItem("token", res?.key);
        sessionStorage.setItem("refreshToken", res?.refreshToken);
        sessionStorage.setItem("expirationDate", res?.expirationDate);

        updateHeader({
          headers: {
            ...headers,
            ["Authorization"]: `Token ${res?.key}`
          }
        });
      } catch (err: any) {
        errorChecker(err);
      }
    }
  }
};

if (localStorage.getItem("lang")) {
  headers["Accept-Language"] = localStorage.getItem("lang");
}

if (sessionStorage.getItem("token")) {
  headers["Authorization"] = `Token ${sessionStorage.getItem("token")}`;
}

headers["Marketplace-Name"] = process.env.REACT_APP_MARKETPLACE_NAME || "help_ua";

export const http = new ApiConfig<IExtraPrams>({
  baseUrl,
  config: {
    headers
  },
  handlers: {
    beforeRequestHandle
  }
});
