import React from "react";
import {useNavigate} from "react-router-dom";

import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

import S from "./style.module.scss";
import pages from "./pages.json";

export const HelpCenterPageFAQ = () => {
  const navigate = useNavigate();

  return (
    <>
      <HelpCenterPageHeader
        title={"FAQ"}
      />
      <ul className={S.LinkList}>
        {Object.keys(pages).map((el, key) => {
          return (
            <li
              key={key}
              onClick={() => navigate(pages[el].path)}
            >
              {pages[el].title}
            </li>
          );
        })}
      </ul>
    </>
  );
};
