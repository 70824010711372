import React, {ReactNode, useMemo} from "react";

import {classNames} from "utils";
import {CheckMarkAvatar} from "assets/icons/CheckMarkAvatar";
import {ClockIcon} from "assets/icons/ClockIcon";
import {useMyUser} from "providers";
import {TUserVerificationStatus} from "types";

import S from "./style.module.scss";

interface IVerificationStatus {
  classNameType: string;
  icon: ReactNode;
  text: string;
}

const verificationStatus: Record<TUserVerificationStatus, IVerificationStatus> = {
  none: {
    classNameType: S.None,
    icon: <CheckMarkAvatar
      size={14}
      fill={"#36364B"}
    />,
    text: "Not verified"
  },
  inProgress: {
    classNameType: S.InProgress,
    icon: <ClockIcon
      size={14}
      fill={"#E2761B"}
    />,
    text: "In Process"
  },
  rejected: {
    classNameType: S.Rejected,
    icon: <ClockIcon size={14}/>,
    text: "Rejected"
  },
  incomplete: {
    classNameType: S.InComplete,
    icon: <CheckMarkAvatar
      size={14}
      fill={"#30D158"}
    />,
    text: "In Complete"
  },
  verified: {
    classNameType: S.Verified,
    icon: <CheckMarkAvatar size={14}/>,
    text: "Verified"
  }
};

export const Status = () => {
  const {user} = useMyUser();

  const status = useMemo(() => {
    return verificationStatus[user.verificationStatus || "none"];
  }, [user.verificationStatus]);

  return (
    <div className={S.AccountStatus}>
      <div className={S.TitleStatus}>Account status</div>
      <div className={classNames(S.Status, status.classNameType)}>
        {status.icon}
        <span>{status.text}</span>
      </div>
    </div>
  );
};
