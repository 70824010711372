import React from "react";

import {Container} from "components/Container";
import {CharitySupportPeople} from "components/CharityHome/CharitySupportPeople";

export const SupportPeoplePage = () => {
  return (
    <Container>
      <CharitySupportPeople/>
    </Container>
  );
};
