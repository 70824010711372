import React, {useState} from "react";
import {Link} from "react-router-dom";

import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {useMyUser, useWeb3} from "providers";
import {Status} from "components/Header/MenuContent/MenuProfileDesktop/status";
import {StatusButton} from "components/Header/MenuContent/MenuProfileDesktop/button";
import {EditProfilePopup} from "components/Popups/EditProfilePopup";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  onLogout?: () => void;
  showPopup?: () => void;
  showSetting?: () => void;
}

export const MenuProfileDesktop = (
  {
    className,
    onLogout,
    showPopup,
    showSetting
  }: IProps) => {
  const {web3State} = useWeb3();
  const theme = useTheme();
  const {user} = useMyUser();

  return (
    <div className={classNames(S.DropContent, className)}>
      {theme === "nftim" && <Status/>}
      <div className={S.UserBalance}>
        <span>Balance:</span>
        <p>{web3State?.balance} {web3State?.symbol}</p>
      </div>
      {theme === "nftim" && <StatusButton showPopup={showPopup}/>}
      <ul className={S.UserList}>
        {theme === "nftim" && (
          <li className={location.pathname === `/profile/${user?.id}` && location.hash === "#profile" ? S.ActiveLi : ""}>
            <Link
              to={`/profile/${user?.id}#profile`}
            >
              <span>•</span> Profile
            </Link>
          </li>
        )}
        <li className={location.pathname === `/profile/${user?.id}` && location.hash === "#nft" ? S.ActiveLi : ""}>
          <Link
            to={`/profile/${user?.id}#nft`}
          >
            <span>•</span> My NFTs
          </Link>
        </li>
        <li className={location.pathname === `/profile/${user?.id}` && location.hash === "#project" ? S.ActiveLi : ""}>
          <Link
            to={`/profile/${user?.id}#project`}
          >
            <span>•</span> Projects
          </Link>
        </li>
        <li>
          <a
            onClick={showSetting}
            className={"cursor-pointer"}
          >
            <span>•</span> Setting
          </a>
        </li>
      </ul>
      <ul className={S.LogOut}>
        <li onClick={onLogout}><span>Log Out</span></li>
      </ul>
    </div>
  );
};
