import React, {useCallback, useEffect, useState} from "react";

import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";
import {Price} from "components/Price";
import {ICollection, IUserGetById} from "types";
import {CheckMarkAvatar} from "assets/icons/CheckMarkAvatar";
import {useApi} from "api";

import S from "./style.module.scss";

interface IProps {
  collection: Partial<ICollection>;
}

export const CollectionHeader = ({
  collection
}: IProps) => {

  const [isShortText, setIsShortText] = useState<boolean>(true);

  const onClickReadMore = () => setIsShortText(!isShortText);

  const theme = useTheme();

  const [ObjectItem, setObject] = useState<any>();

  const volumeTradedRequest = useCallback(async () => {
    let result = collection?.volumeTraded;
    const resultItem = result && result.find((item, index) => index === 0);
    setObject(resultItem);
  }, [collection?.volumeTraded]);

  useEffect(() => {
    (async () => volumeTradedRequest())();
  }, [volumeTradedRequest]);

  const textMaxLength = 170;

  return (
    <>
      <div
        className={classNames(S.Frame, S[theme])}
        style={{backgroundImage: `url(${collection?.cover})`}}
      >
      </div>
      <div className={S.CollectionHeaderWrapper}>
        <div className={S.PhotoWrapper}>
          <span>
            <img
              src={collection?.photo}
              alt={collection?.name}
            />
          </span>
          <CheckMarkAvatar className={S.CheckMark}/>
        </div>
        <h1 className={S.Title}>{collection?.name}</h1>
        <div className={classNames(S.TextRow, S[theme])}>
          {collection?.description?.substring(
            0,
            (isShortText && collection?.description?.length >= textMaxLength ? textMaxLength : 999999)
          )}
          {isShortText && collection?.description && collection?.description?.length >= textMaxLength ? "..." : ""}
        </div>
        {(collection?.description && collection?.description?.length >= textMaxLength) &&
          <span
            onClick={onClickReadMore}
            className={classNames(S.MoreButton, S[theme])}
          >{isShortText ? "Read more" : "Show less"}
          </span>
        }
        <div className={classNames(S.CollectionInformation, S[theme])}>
          <ul>
            <li>
              <span>{collection?.nftCount || 0}</span>
              <p>items</p>
            </li>
            <li>
              <span>{collection?.numberOfOwners || 0}</span>
              <p>owners</p>
            </li>
            <li>
              <span>
                <Price
                  symbol={collection?.floorPrice?.currency?.symbol}
                  cryptoImg={collection?.floorPrice?.currency?.image}
                  decimals={collection?.floorPrice?.currency?.decimals}
                  isShowFiat={false}
                  price={collection?.floorPrice?.weiPrice}
                  isShowSymbol={false}
                />
              </span>
              <p>floor price</p>
            </li>
            <li>
              <span>
                <Price
                  symbol={ObjectItem?.currency?.symbol}
                  cryptoImg={ObjectItem?.currency?.image}
                  decimals={ObjectItem?.currency?.decimals}
                  isShowFiat={false}
                  price={ObjectItem?.volumeTradedWei}
                  isShowSymbol={false}
                />
              </span>
              <p>volume traded</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
