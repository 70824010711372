import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

import S from "components/Profile/ProfileMyBids/style.module.scss";
import {useWeb3Contract} from "providers/Web3Contract";
import {ProfileMyBidsAuctionBlockStatus} from "components/Profile/ProfileMyBids/AuctionStatus";
import {UserBlock} from "components/UserBlock";
import {ProfileMyBidsAuctionBlockYourBid} from "components/Profile/ProfileMyBids/YourBid";
import {ProfileMyBidsAuctionBlockButtons} from "components/Profile/ProfileMyBids/Buttons";
import {ProfileMyBidsAuctionBlockAssetPreview} from "components/Profile/ProfileMyBids/AssetPreview";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

export const ProfileBidsMobile = () => {
  const {auction} = useWeb3Contract();
  const theme = useTheme();

  return (
    <AccordionItem activeClassName={S.Active}>
      <div className={classNames(S.AccordionItemMobileContainer, S[theme])}>
        <AccordionItemHeading>
          <AccordionItemButton>
            <ProfileMyBidsAuctionBlockAssetPreview classNameInfo={S.Wrap}/>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className={S.MyBidsWrapperMobile}>
            <article>
              <UserBlock
                id={auction?.beneficiaryUser.id}
                thumbnailPhoto={auction?.beneficiaryUser.thumbnailPhoto}
                classNameTitle={"text-left"}
                title={"Seller:"}
                displayName={auction?.beneficiaryUser.displayName}
              />
              <ProfileMyBidsAuctionBlockStatus/>
            </article>
            <ProfileMyBidsAuctionBlockYourBid/>
            <ProfileMyBidsAuctionBlockButtons/>
          </div>
        </AccordionItemPanel>
      </div>
    </AccordionItem>
  );
};
