import React from "react";

interface IProps {
  fill?: string
  size?: number
}

const QuestionMarkIcon = ({fill = "#fff", size = 20}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="10"
        cy="10"
        r="10"
        fill="#939CB4"
      />
      <path
        d="M9.689 12.7461C9.30962 12.7461 9.02087 12.8409 8.82254 13.0305C8.624 13.2201 8.52441 13.5003 8.52441 13.8703C8.52441 14.2278 8.62608 14.5048 8.829 14.7007C9.03191 14.8971 9.31837 14.9953 9.689 14.9953C10.0507 14.9953 10.333 14.8948 10.5359 14.6944C10.7388 14.4936 10.84 14.219 10.84 13.8703C10.84 13.5092 10.7396 13.2313 10.5394 13.0369C10.3384 12.8432 10.055 12.7461 9.689 12.7461Z"
        fill="white"/>
      <path
        d="M12.2462 5.65988C11.7104 5.22322 10.9879 5.00488 10.0796 5.00488C8.97707 5.00488 7.93353 5.28051 6.9502 5.83197L7.67124 7.28072C8.5179 6.84863 9.27228 6.63238 9.93374 6.63238C10.3133 6.63238 10.6087 6.70738 10.82 6.85738C11.0321 7.00738 11.1375 7.22572 11.1375 7.51238C11.1375 7.76822 11.0637 7.99967 10.916 8.20697C10.7683 8.41426 10.4631 8.68322 9.99999 9.01426C9.51936 9.36697 9.18832 9.70009 9.0079 10.0132C8.82665 10.3263 8.73645 10.6949 8.73645 11.1184V11.6078H10.4898V11.2111C10.4898 10.9463 10.5462 10.7326 10.6587 10.569C10.771 10.4063 11.0387 10.1678 11.4623 9.85467C12.0796 9.41363 12.4987 9.01676 12.7192 8.66363C12.9398 8.31072 13.0504 7.89155 13.0504 7.40655C13.0504 6.67905 12.7823 6.09655 12.2462 5.65988Z"
        fill="white"/>
    </svg>

  );
};

export default React.memo(QuestionMarkIcon);
