import React from "react";

export const NotificationNewSubs = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26345)"
      />
      <path
        d="M18 18.5C20.0711 18.5 21.75 16.4853 21.75 14C21.75 11.5147 20.0711 9.5 18 9.5C15.9289 9.5 14.25 11.5147 14.25 14C14.25 16.4853 15.9289 18.5 18 18.5Z" fill="white"/>
      <path
        d="M25.35 22.3249C24.675 20.9749 23.4 19.8499 21.75 19.1749C21.3 19.0249 20.775 19.0249 20.4 19.2499C19.65 19.6999 18.9 19.9249 18 19.9249C17.1 19.9249 16.35 19.6999 15.6 19.2499C15.225 19.0999 14.7 19.0249 14.25 19.2499C12.6 19.9249 11.325 21.0499 10.65 22.3999C10.125 23.3749 10.95 24.4999 12.075 24.4999H23.925C25.05 24.4999 25.875 23.3749 25.35 22.3249Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26345"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#00F260"
          />
          <stop
            offset="1"
            stopColor="#0575E6"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
