import React from "react";

export const NotificationImportant = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2901_23926)"
      />
      <path
        d="M12.375 19.125H17.543L15.7465 25.875L23.625 16.875H18.457L20.25 10.125L12.375 19.125Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2901_23926"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8E2DE2"/>
          <stop
            offset="1"
            stopColor="#4A00E0"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
