import React, {useMemo} from "react";

import {StepTitle} from "components/CreateNft/StepContent/StepTitle";
import {StepScale} from "components/CreateNft/StepContent/StepScale";
import {StepFirst} from "components/CreateNft/Steps/StepFirst";
import {StepSecond} from "components/CreateNft/Steps/StepSecond";
import {StepThird} from "components/CreateNft/Steps/StepThird";
import {StepFour} from "components/CreateNft/Steps/StepFour";
import {useCreateAsset} from "providers/CreateAsset";

export const CreateNFTSteps = () => {
  const {step, setStep} = useCreateAsset();

  const showStep = useMemo(() => {
    switch (step) {
      case "choseChain": {
        return (
          <StepFirst/>
        );
      }
      case "uploadFile": {
        return (
          <StepSecond/>
        );
      }
      case "addInformation": {
        return (
          <StepThird/>
        );
      }
      case "done": {
        return (
          <StepFour/>
        );
      }
      default : {
        return (<>Empty Step</>);
      }
    }
  }, [step]);

  return (
    <>
      {step === "choseChain" &&
        <StepTitle
          title={"Step 1. Choose Blockchain"}
        />
      }
      {step === "uploadFile" &&
        <StepTitle
          title={"Step 2. Upload NFT"}
          prevStep={() => setStep("choseChain")}
        />
      }
      {step === "addInformation" &&
        <StepTitle
          title={"Step 3. NFT Description"}
          prevStep={() => setStep("uploadFile")}
        />
      }
      {step !== "done" &&
        <StepScale/>
      }
      {showStep}
    </>
  );
};
