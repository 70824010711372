import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  onClick?: () => void;
}

export const AssetBlueArrow = ({size = 24, onClick, className}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_318:18335)">
      <path
        d="M7.54 8.58496L12.125 13.17L16.71 8.58496L18.125 9.99996L12.125 16L6.125 9.99996L7.54 8.58496Z"
        fill="#0A84FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_318:18335">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);
