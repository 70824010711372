import React from "react";
import {Link} from "react-router-dom";

import {IBid} from "components/Home/types";
import {Price} from "components/Price";
import {Amount} from "components/layout/Amount";
import {TimerWrapper} from "components/Timer";
import {UserInfo} from "pages/Asset/UserInfo";
import {AssetsTables} from "components/Asset/AssetTabs/Tables";
import {Popup, PopupSubContentHeader} from "components/Popups";

interface IProps {
  onClickClose: () => void;
  bidsList: Record<number, IBid[]>;
  auctionId?: number;
}

export const AuctionOffersPopup = ({
  onClickClose,
  bidsList,
  auctionId
}: IProps) => {
  return (
    <Popup
      clickOnClose={onClickClose}
      maxWidth={"900px"}
      noModal={true}
    >
      <PopupSubContentHeader
        title={"Auction offers"}
        titleLabel={"Find best offer for you"}
      />
      <AssetsTables>
        {auctionId && bidsList[auctionId] && bidsList[auctionId].map((bidItem, index) =>
          <tr key={index}>
            <td>
              <div>
                <UserInfo
                  avatar={bidItem.user?.thumbnailPhoto}
                  title={bidItem.user?.displayName}
                  text={
                    <>
                      <Link to={`/profile/${bidItem.user?.id}`}>
                        {bidItem.user?.displayName}
                      </Link>
                    </>
                  }
                />
              </div>
            </td>
            <td
              width={130}
              align={"center"}
            >
              <div>
                <Price
                  cryptoImg={bidItem.auction.currency?.image}
                  symbol={bidItem.auction?.currency?.symbol}
                  decimals={bidItem.auction?.currency?.decimals}
                  isShowFiat={false}
                  price={bidItem.bid}
                />
              </div>
            </td>
            <td
              width={100}
              align={"center"}
            >
              <div>
                <Amount count={Number(bidItem?.auction?.number)}/>
              </div>
            </td>
            <td
              width={130}
              align={"right"}
            >
              <div>
                <TimerWrapper
                  expiryTimestamp={bidItem.auction.auctionEnd}
                  isSmall={true}
                />
              </div>
            </td>
          </tr>
        )}
      </AssetsTables>
    </Popup>
  );
};
