import React from "react";
import ContentLoader from "react-content-loader";

export const AssetDescriptionSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={266}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%", margin: "12px auto 0"}}
  >
    <rect
      x="0"
      y="0"
      rx="0"
      ry="0"
      width="100%"
      height="14"
    />
    <rect
      x="0"
      y="52"
      rx="0"
      ry="0"
      width="100"
      height="10"
    />
    <rect
      x="0"
      y="72"
      rx="0"
      ry="0"
      width="100%"
      height="14"
    />
    <rect
      x="0"
      y="112"
      rx="0"
      ry="0"
      width="100"
      height="10"
    />
    <rect
      x="0"
      y="132"
      rx="0"
      ry="0"
      width="100%"
      height="14"
    />
    <rect
      x="0"
      y="172"
      rx="0"
      ry="0"
      width="100"
      height="10"
    />
    <rect
      x="0"
      y="192"
      rx="0"
      ry="0"
      width="100%"
      height="14"
    />
    <rect
      x="0"
      y="232"
      rx="0"
      ry="0"
      width="100"
      height="10"
    />
    <rect
      x="0"
      y="252"
      rx="0"
      ry="0"
      width="100%"
      height="14"
    />
  </ContentLoader>
);


