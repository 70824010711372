import React, {useCallback, useMemo} from "react";

import {IAuction} from "components/Home/types";
import {Button} from "components/Button";
import {useAsset, usePopup, useWeb3} from "providers";
import {errorChecker} from "utils";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";

interface IProps {
  className?: string;
  auction?: IAuction;
}

export const AuctionButton = ({className, auction}: IProps) => {
  const {asset, setCurrentBidId} = useAsset();

  const {checkAuth, isCheckUserChain, web3State} = useWeb3();
  const {cancelAuction, endAuction} = useWeb3Contract();
  const {togglePlaceBidPopup, openBuyNowPopup} = useWeb3ContractPopup();
  const {showPopup} = usePopup();

  const checkChain = useCallback(async () => {
    try {
      await isCheckUserChain(asset?.chainId || 0);
      if (auction?.auctionContractId) {
        setCurrentBidId(auction?.auctionContractId);
        togglePlaceBidPopup();
      }
    } catch (err) {
      errorChecker(err);
    }
  }, [asset?.chainId, isCheckUserChain, togglePlaceBidPopup, setCurrentBidId, auction?.auctionContractId]);

  const AuctionAction = useMemo(
    () => {
      if (web3State?.wallet) {
        if (web3State?.wallet === auction?.beneficiary) {
          if (auction?.isEndedTimer) {
            return {
              title: "button.cancelAuction",
              disabled: false,
              onClick: () => cancelAuction({
                auctionAddress: auction?.auctionAddress,
                auctionId: auction?.auctionContractId,
              })
            };
          }

          return {
            title: "button.cancelAuction",
            disabled: false,
            onClick: () => showPopup(() => cancelAuction({
              auctionAddress: auction?.auctionAddress,
              auctionId: auction?.auctionContractId,
            }))
          };
        }

        if (auction?.isEndedTimer) {
          if (web3State?.wallet === auction?.highestBidder) {
            return {
              title: "button.endAuction",
              disabled: false,
              onClick: () => endAuction({
                auctionAddress: auction?.auctionAddress,
                auctionId: auction?.auctionContractId,
              })
            };
          }

          return {
            title: "button.placeBid",
            disabled: true,
            onClick: () => {
            }
          };
        }

        return {
          title: "button.placeBid",
          disabled: false,
          onClick: checkChain
        };
      }

      if (auction?.isEndedTimer) {
        return {
          title: "button.placeBid",
          disabled: true,
          onClick: () => {
          }
        };
      }

      return {
        title: "button.placeBid",
        disabled: false,
        onClick: () => openBuyNowPopup({
          type: "buy_now_auction",
          id: auction?.auctionContractId,
          address: auction?.auctionAddress,
          price: auction?.immediatePrice,
          currencyAddress: auction?.currency?.address
        })
      };
    },
    [
      auction?.auctionAddress,
      auction?.auctionContractId,
      auction?.beneficiary,
      auction?.currency?.address,
      auction?.highestBidder,
      auction?.immediatePrice,
      auction?.isEndedTimer,
      cancelAuction,
      checkChain,
      endAuction,
      openBuyNowPopup,
      showPopup,
      web3State?.wallet
    ]
  );

  return (
    <Button
      className={className}
      color="primary"
      disabled={AuctionAction.disabled}
      title={AuctionAction.title}
      onClick={() => checkAuth(() => AuctionAction.onClick())}
    />
  );
};
