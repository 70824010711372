import React from "react";

import {useAsset} from "providers";
import {MediaBlock} from "components/MediaBlock";
import {AssetExtension} from "pages/Asset/Extension";
import {AssetDescription} from "pages/Asset/Description";
import {ResponsiveDesktop} from "components/Responsive";

import S from "../style.module.scss";
import {AssetDescriptionSkeleton} from "../AssetSkeleton/AssetDescriptionSkeleton";

export const AssetLeft = () => {
  const {asset} = useAsset();

  return (
    <div className={S.ContentLeft}>
      <MediaBlock
        asset={asset}
      />

      <ResponsiveDesktop>
        {asset ? (
          <div>
            <div className={S.totalZoomDiv}>
              <AssetExtension/>
            </div>
            <AssetDescription/>
          </div>
        ) :
          <AssetDescriptionSkeleton/>
        }
      </ResponsiveDesktop>
    </div>
  );
};
