import React from "react";

import {Popup, PopupSubContentHeader} from "components/Popups";
import {ResponsiveDesktop, ResponsiveMobile} from "components/Responsive";

import S from "./style.module.scss";
import {OtherOffersPopupDesktop} from "./Desktop";
import {OtherOffersPopupMobile} from "./Mobile";

interface IProps {
  clickOnClose: () => void;
}

export const OtherOffersPopup = ({clickOnClose}: IProps) => {
  return (
    <Popup
      clickOnClose={clickOnClose}
      maxWidth={"780px"}
    >
      <PopupSubContentHeader title={"Other offers"}/>
      <div className={S.TotalCount}>
        Total offers count - <span>17</span>
      </div>
      <ResponsiveDesktop>
        <>
          <OtherOffersPopupDesktop/>
          <OtherOffersPopupDesktop/>
          <OtherOffersPopupDesktop/>
        </>
      </ResponsiveDesktop>
      <ResponsiveMobile>
        <>
          <OtherOffersPopupMobile/>
          <OtherOffersPopupMobile/>
          <OtherOffersPopupMobile/>
        </>
      </ResponsiveMobile>
    </Popup>
  );
};
