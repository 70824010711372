import React, {useEffect} from "react";

/**
 * React hook to detect clicks (taps) outside of a specific element.
 *
 * @param ref
 * @param onClick
 */

export const useClickOutside = (ref: any, onClick: () => void) => {
  useEffect(() => {
    function handleClickOutside (event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
};
