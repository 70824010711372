import React from "react";

export const NotificationButtonIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.9001 18.7534L21.6734 18.5534C21.0304 17.9804 20.4676 17.3234 20.0001 16.6C19.4896 15.6017 19.1836 14.5115 19.1001 13.3934V10.1C19.1045 8.34376 18.4675 6.64633 17.3086 5.32667C16.1498 4.007 14.5489 3.15592 12.8068 2.93335V2.07335C12.8068 1.83731 12.713 1.61093 12.5461 1.44402C12.3792 1.27712 12.1528 1.18335 11.9168 1.18335C11.6807 1.18335 11.4544 1.27712 11.2875 1.44402C11.1205 1.61093 11.0268 1.83731 11.0268 2.07335V2.94668C9.30029 3.1853 7.71876 4.04152 6.57513 5.35675C5.43151 6.67199 4.80327 8.35711 4.80678 10.1V13.3934C4.72328 14.5115 4.4173 15.6017 3.90678 16.6C3.44737 17.3216 2.89358 17.9785 2.26011 18.5534L2.03345 18.7534V20.6334H21.9001V18.7534Z" fill="white"/>
      <path d="M10.2134 21.3333C10.2718 21.7558 10.4812 22.143 10.8029 22.4232C11.1246 22.7034 11.5368 22.8578 11.9634 22.8578C12.39 22.8578 12.8022 22.7034 13.1239 22.4232C13.4455 22.143 13.6549 21.7558 13.7134 21.3333H10.2134Z" fill="white"/>
    </svg>
  );
};
