import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const YouTubeIcon = ({size = 24, fill = "white"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4986 6.22346C23.2226 5.1908 22.4094 4.37766 21.3768 4.10166C19.505 3.6001 12 3.6001 12 3.6001C12 3.6001 4.49498 3.6001 2.62317 4.10166C1.5907 4.37766 0.777375 5.1908 0.501422 6.22346C0 8.09508 0 12.0002 0 12.0002C0 12.0002 0 15.9053 0.501422 17.7767C0.777375 18.8094 1.5907 19.6228 2.62317 19.8987C4.49498 20.4001 12 20.4001 12 20.4001C12 20.4001 19.505 20.4001 21.3768 19.8987C22.4094 19.6228 23.2226 18.8094 23.4986 17.7767C24 15.9053 24 12.0002 24 12.0002C24 12.0002 24 8.09508 23.4986 6.22346ZM9.59986 15.6003V8.40014L15.8351 12.0002L9.59986 15.6003Z"
        fill={fill}
      />
    </svg>
  );
};
