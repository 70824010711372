import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  borderColor?: string;
}

export const HeartIcon = ({
  onClick,
  size = 14,
  className,
  fill = "black",
}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.68827 13.2863C6.77052 13.3703 6.88252 13.417 6.99977 13.417C7.11702 13.417 7.22902 13.3703 7.31127 13.2863L12.9078 7.61574C14.3609 6.14399 14.3609 3.74824 12.9078 2.27591C12.2019 1.56074 11.2634 1.16699 10.2641 1.16699C9.26485 1.16699 8.32685 1.56074 7.62102 2.27533L6.99977 2.90533L6.37852 2.27591C5.67268 1.56074 4.7341 1.16699 3.73543 1.16699C2.73618 1.16699 1.7976 1.56074 1.09177 2.27591C-0.361318 3.74824 -0.361318 6.14399 1.09177 7.61516L6.68827 13.2863ZM1.71418 2.89016C2.25435 2.34358 2.97243 2.04199 3.73485 2.04199C4.49785 2.04199 5.21535 2.34358 5.75493 2.89074L6.68768 3.83574C6.8516 4.00199 7.14618 4.00199 7.3101 3.83574L8.24285 2.89016C8.78418 2.34358 9.50168 2.04199 10.2641 2.04199C11.0271 2.04199 11.7446 2.34358 12.2848 2.89016C13.403 4.02358 13.403 5.86749 12.2848 7.00149L6.99977 12.3571L1.71418 7.00091C0.595932 5.86808 0.595932 4.02358 1.71418 2.89016V2.89016Z"
      fill={fill}
    />
  </svg>
);
