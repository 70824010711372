import React from "react";
import {Link} from "react-router-dom";

import {HelpPageHeader} from "pages/HelpFAQ/Layout/Header";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";


export const PageFaq = () => {
  const theme = useTheme();

  const FaqItem = [
    {to: "wallet-coinbase", question: "How to connect my wallet via Coinbase"},
    {to: "wallet-metamask", question: "How to connect my wallet via Metamask"},
    {to: "what-nft", question: "What is an NFT?"},
    {
      to: "blockchain",
      question: `What is blockchain and which of them does ${theme === "nftim" ? "NFTIM" : "HelpUA"} support?`
    },
    {to: "crypto", question: "What is a crypto wallet and how to get one?"},
  ];

  return (
    <div>
      <HelpPageHeader
        title="FAQ - answers to popular questions"
        breadcrumb="FAQ"
      />
      <ul className={S.LinkList}>
        {FaqItem.map((item, index) => (
          <li key={index}>
            <Link
              to={item.to}
              className={S[theme]}
            >{item.question}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
