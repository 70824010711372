import React from "react";
import {format, formatDistance, subDays} from "date-fns";
// import {ru, enUS} from "date-fns/locale";

import {classNames} from "utils";

import S from "./style.module.scss";

type TTypeProps = "base" | "passed";

interface IProps {
  date?: Date | number;
  form?: string;
  className?: string;
  type?: TTypeProps;
}

// TODO change to base Intl and get information ago
export const Time = ({date, form = " LLL dd yyyy, HH:mm", className, type = "base"}: IProps) => {
  if (date) {
    return (
      <time
        className={classNames(S.Time, className)}
        title={String(date)}
      >
        {type === "base" && format(new Date(date), form)}
        {type === "passed" && formatDistance(subDays(new Date(date), 0), new Date(), {addSuffix: true})}
      </time>
    );
  }

  return (
    <time className={classNames(S.Time, className)}>No updates yet</time>
  );
};
