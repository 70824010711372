import React from "react";
import Lottie from "lottie-react";

import {useTheme} from "hooks/useTheme";
import {ViewBlock} from "components/ViewBlock";
import {LogoNftim} from "assets/icons/Logo";

import Preload from "./lottie.json";

export const FirstLoad = () => {
  const theme = useTheme();

  return (
    <div className={`flex w-screen h-screen items-center justify-center ${theme === "nftim" && "bg-black"}`}>
      <ViewBlock value={["nftim"]}>
        <LogoNftim size={100}/>
      </ViewBlock>
      <ViewBlock value={["help_ua", "help_ua_dev"]}>
        <Lottie
          animationData={Preload}
          loop={true}
          autoplay={true}
        />
      </ViewBlock>
    </div>
  );
};
