import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {IAsset} from "types";
import {useApi} from "api";
import {assetAuctionCheckService, availableAuctionCheckService, errorChecker, resultsAuctionCheckService} from "utils";
import {IAuction, IAvailable, IBid, IOwner} from "components/Home/types";
import {useWeb3} from "providers/Web3";
import {IWssAsset} from "types/wss/asset";
import {useDebug, useStore} from "providers";
import {IAssetHistory} from "types/nft/getNFTHistory";

interface IUseAsset {
  asset?: IAsset;
  updateAsset: () => void;
  available?: Partial<IAvailable>;
  bidsList: Record<number, IBid[]>;
  owners?: IOwner[];
  histories: IAssetHistory[];
  assetLike?: () => void;
  currentBidId?: number;
  setCurrentBidId: (bid: number) => void;
  isLightbox: boolean;
  toggleLightbox: (isLightbox: boolean) => void;
}

const HocAssetContext = React.createContext<IUseAsset>({
  bidsList: {},
  setCurrentBidId (): void {
  },
  updateAsset (): void {
  },
  isLightbox: false,
  toggleLightbox (): void {
  },
  histories: []
});
HocAssetContext.displayName = "HocAssetContext";
const HocAssetContextProvide = HocAssetContext.Provider;

export function useAsset (): IUseAsset {
  const hocAssetData = useContext(HocAssetContext);
  if (hocAssetData === null) {
    throw new Error("Hook available only in nested to the <Asset/> components");
  }
  return hocAssetData;
}

interface IProps {
  children?: React.ReactNode;
}

export const AssetProvider = ({children}: IProps) => {
  const {id} = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const {api} = useApi();
  const {checkAuth} = useWeb3();
  const {token} = useStore();
  const {debug} = useDebug();

  const [asset, setAsset] = useState<IAsset>();
  const [bidsList, setBidsList] = useState<Record<number, IBid[]>>({});
  const [available, setAvailable] = useState<Partial<IAvailable>>({});
  const [histories, setHistories] = useState<IAssetHistory[]>([]);
  const [owners, setOwners] = useState<IOwner[]>([]);
  const [currentBidId, setCurrentBidId] = useState<number | undefined>();
  const [isLightbox, toggleLightbox] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const wsRef = useRef<WebSocket>();

  const requestAndUpdateBids = useCallback(async (auctionId: number) => {
    try {
      const res = await api.bids.getBids({auctionId});
      setBidsList(prevState => {
        if (res.results.length > 0) {
          return {...prevState, [auctionId]: res.results};
        }

        return prevState;
      });
    } catch (err) {
      errorChecker(err);
    }
  }, [api.bids]);

  const requestAvailable = useCallback(async () => {
    let availableAsset = await api.asset.checkAvailable(Number(id));
    availableAsset = availableAuctionCheckService(availableAsset);
    setAvailable(availableAsset);

    const auctionIds = availableAsset.auctions.map(el => el.id);

    let bids = {};

    for (let i = 0; i < auctionIds.length; i++) {
      let res = await api.bids.getBids({
        auctionId: Number(auctionIds[i])
      });
      res = resultsAuctionCheckService(res);

      bids[auctionIds[i]] = res.results;
    }

    setBidsList(bids);

    setOwners(availableAsset?.owners?.filter(item => !item.isFromMarketplace) || []);
  }, [api.asset, api.bids, id]);

  const requestHistory = useCallback(async () => {
    try {
      let res = await api.nft.getNFTHistory({
        assetId: Number(id)
      });
      setHistories(res.results);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.nft, id]);

  const initTimer = useCallback(async (callback?: (getAsset: IAsset) => void) => {
    let getAsset = await api.asset.getAsset(Number(id));
    getAsset = assetAuctionCheckService(getAsset);
    setAsset(getAsset);

    callback && callback(getAsset);

    await requestAvailable();
    await requestHistory();
  }, [api.asset, id, requestAvailable, requestHistory]);

  const updateAsset = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await initTimer((getAsset) => {
        if (getAsset.currentAuction && !getAsset.currentAuction.isEndedTimer) {
          const delay = Number(getAsset?.currentAuction?.differentInMillisecondsTimer);
          // delay int32
          if (delay < 2147483647) {
            setTimeout(() => {
              initTimer();
            }, delay + 2000);
          }
        }
      });
    } catch (err) {
      // @ts-ignore
      if (err && err.status === 404) {
        navigate("/404", {replace: true});
      } else {
        errorChecker(err);
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, initTimer, navigate]);

  const assetLike = useCallback(async () => {
    await checkAuth(async () => {
      if (asset) {
        setAsset({
          ...asset,
          likeCount: asset.isLike ? Number(asset?.likeCount) - 1 : Number(asset?.likeCount) + 1,
          isLike: !asset.isLike
        });

        try {
          await api.asset.like(Number(asset.id), {isLike: !asset.isLike});
        } catch (err) {
          setAsset({
            ...asset,
            likeCount: asset.isLike ? Number(asset?.likeCount) - 1 : Number(asset.likeCount) + 1,
            isLike: asset.isLike
          });
        }
      }
    });
  }, [api.asset, asset, checkAuth]);

  const updateAssetBidsInformation = useCallback(({auction}: { auction: IAuction }) => {
    setAsset(prevState => {
      if (prevState?.currentAuction) {
        const isAuctionInCurrent = prevState?.currentAuction.id === auction.id;
        if (isAuctionInCurrent) {
          return {
            ...prevState,
            currentAuction: auction
          };
        }
      }
      return prevState;
    });

    setAvailable(prevState => {
      if (prevState?.auctions && prevState?.auctions?.length > 0) {
        const newAvailableAuction = prevState?.auctions.map((el) => {
          if (el.id === auction.id) {
            return auction;
          }

          return el;
        });

        return {
          ...prevState,
          auctions: newAvailableAuction
        };
      }

      return prevState;
    });
  }, []);

  const connectWS = useCallback(async (event: any) => {
    const data = JSON.parse(event.data) as IWssAsset;

    debug({title: "AssetProvider-connectWS-data", desc: data});

    if (data.type === "blockchain") {
      switch (data.data.type) {
        case "bid": {
          if ("auction" in data.data) {
            updateAssetBidsInformation({auction: data.data.auction});
            await requestAndUpdateBids(data.data.auction.id);
          }
        }
          break;
        case "auction": {
          await updateAsset();
        }
      }
    }
  }, [debug, requestAndUpdateBids, updateAsset, updateAssetBidsInformation]);

  useEffect(() => {
    (async () => {
      await updateAsset();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (token) {
      wsRef.current = new WebSocket(`${process.env.REACT_APP_WSS_URL}ws/assets/${id}?token=${token}&marketplaceName=${process.env.REACT_APP_MARKETPLACE_NAME}`);
      wsRef.current.onmessage = (message) => connectWS(message);
    }

    return () => {
      wsRef.current?.close();
    };
  }, [connectWS, id, token]);


  return (
    <HocAssetContextProvide
      value={{
        asset,
        updateAsset,
        available,
        bidsList,
        owners,
        histories,
        assetLike,
        currentBidId,
        setCurrentBidId,
        isLightbox,
        toggleLightbox
      }}
    >
      {children}
    </HocAssetContextProvide>
  );
};
