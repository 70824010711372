import React, {useCallback, useContext, useEffect, useState} from "react";

import {useWeb3Contract} from "providers/Web3Contract";
import {errorChecker} from "utils";
import {IWeb3CreateAsset, TCreateFileType} from "pages/Asset/types";
import {useApi} from "api";
import {ICategory} from "components/Home/types";

export type TStep = "choseChain" | "uploadFile" | "addInformation" | "done";

export type TOmitIWeb3CreateAsset = Omit<IWeb3CreateAsset, "file" | "ipfsType">;

export enum EStep {
  choseChain = 1,
  uploadFile,
  addInformation,
  done
}

interface IUseCreateAsset {
  step: TStep,
  setStep: (step: TStep) => void;
  setFile: (file: File) => void;
  cover: File | undefined;
  setCover: (file?: File) => void;
  ipfsType: TCreateFileType;
  setIpfsType: (type: TCreateFileType) => void;
  ipfsExtends: string;
  setIpfsExtends: (type: string) => void;
  ipfsMimeImageView: string;
  ipfsMimeImageType: string;
  filePreview: string;
  setFilePreview: (preview: string) => void;
  requestCreateAsset: (payload: TOmitIWeb3CreateAsset) => Promise<void>;
  ipfsMimeTypesFull: string[];
  categories: ICategory[];
  isLoading: boolean;
}

export const CreateAssetContext = React.createContext<IUseCreateAsset>({
  step: "choseChain",
  setStep () {
  },
  filePreview: "",
  setFile (): void {
  },
  cover: undefined,
  setCover (): void {
  },
  ipfsType: "other",
  setIpfsType (): void {
  },
  ipfsExtends: "",
  setIpfsExtends (): void {
  },
  ipfsMimeImageView: "",
  ipfsMimeImageType: "",
  setFilePreview (): void {
  },
  requestCreateAsset () {
    return Promise.resolve(undefined);
  },
  ipfsMimeTypesFull: [],
  categories: [],
  isLoading: false
});
CreateAssetContext.displayName = "CreateAssetContext";

export function useCreateAsset (): IUseCreateAsset {
  const CreateAssetData = useContext(CreateAssetContext);
  if (CreateAssetData === null) {
    throw new Error("CreateAssetData");
  }
  return CreateAssetData;
}


interface IProps {
  children: React.ReactNode;
}

export const CreateAssetProvider = ({children}: IProps) => {
  const {api} = useApi();
  const {createAsset} = useWeb3Contract();

  const [step, setStep] = useState<TStep>("choseChain");
  const [file, setFile] = useState<File>();
  const [cover, setCover] = useState<File | undefined>();
  const [filePreview, setFilePreview] = useState<string>("");
  const [ipfsType, setIpfsType] = useState<TCreateFileType>("other");
  const [ipfsExtends, setIpfsExtends] = useState<string>("");
  const [ipfsMimeTypesFull, setIpfsMimeTypesFull] = useState<string[]>([]);
  const [ipfsMimeImageView, setIpfsMimeImageView] = useState<string>("");
  const [ipfsMimeImageType, setIpfsMimeImageType] = useState<string>("");
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const requestCreateAsset = async (payload: TOmitIWeb3CreateAsset) => {
    setIsLoading(true);

    try {
      await createAsset({
        ...payload,
        file,
        cover,
        ipfsType
      });
      setStep("done");
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const requestServiceInformation = useCallback(async () => {
    try {
      const [resMimeType, resCategory] = await Promise.all([
        api.other.getIPFSMimeType(),
        api.categories.getCategories()
      ]);

      resCategory.shift();

      const mimeView: string[] = [];
      const mimeType: string[] = [];

      resMimeType.forEach((el) => {
        if (el.split("/")[0] === "image") {
          if (el.split("/")[1] !== "gif") {
            mimeView.push(el.split("/")[1].toUpperCase());
            mimeType.push(el);
          }
        }
      });

      setIpfsMimeTypesFull(resMimeType);
      setIpfsMimeImageView(mimeView.join(","));
      setIpfsMimeImageType(mimeType.join(","));
      setCategories(resCategory);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.categories, api.other]);

  useEffect(() => {
    requestServiceInformation();
  }, [requestServiceInformation]);

  return (
    <CreateAssetContext.Provider
      value={{
        step,
        setStep,
        setFile,
        cover,
        setCover,
        ipfsType,
        setIpfsType,
        ipfsExtends,
        setIpfsExtends,
        ipfsMimeImageType,
        ipfsMimeImageView,
        filePreview,
        setFilePreview,
        requestCreateAsset,
        ipfsMimeTypesFull,
        categories,
        isLoading
      }}
    >
      {children}
    </CreateAssetContext.Provider>
  );
};
