import React from "react";
import {Link} from "react-router-dom";

import {ICelebrity} from "components/NFTIM/Home/Celebrities/index";

import S from "./style.module.scss";

interface IProps {
  celebrity: ICelebrity;
}

export const HomeNFTIMCelebrityBlock = ({celebrity}: IProps) => {
  return (
    <div className={S.CelebrityBlock}>
      <div className={S.avatar}>
        <img
          src={celebrity.img}
          alt={celebrity.name}
        />
      </div>
      <div className={S.name}>{celebrity.name}</div>
      <div className={S.desc}>{celebrity.title}</div>
      <ul className={S.list}>
        {celebrity.social.map((social, key) => {
          const Icon = social.icon;

          return (
            <li  key={key}>
              <span data-count={social.amount}>
                {
                  social.icon == "InstagramIcon" ?  React.createElement(Icon, {size: 20, fill: "white"}) :
                    social.icon == "YouTubeIcon" ?  React.createElement(Icon, {size: 24, fill: "white"}) :
                      social.icon == "TikTokIcon" ?  React.createElement(Icon, {size: 24, fill: "white"}) :
                        React.createElement(Icon, {size: 24, fill: "white"})
                }
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
