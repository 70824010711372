import {
  IAsset,
  TGetAssetRequest,
  IPaginationResponse,
  TPaginationRequest,
  IGetBids,
  IActionWithNumberRequest,
  IActionWithNumberResponse,
  IMarketPlace, TRequestEsNews, IUserStatistics, IUserGetById, IUser, TFiatCurrency, ICollection, IAssetObject,
} from "types";
import {IAvailable, IBid, ICategory} from "components/Home/types";
import {IUserAssetCount} from "types/user";
import {IGetPriceList} from "types/other/getPriceList";
import {IGetChainIdList} from "types/other/getChainIdList";
import {TRequestEsAssets} from "types/es/assets";
import {IAssetHistory} from "types/nft/getNFTHistory";
import {ICreateAsset} from "types/asset/createAsset";
import {ApiCoinbase} from "api/src/api/api.coinbase";
import {ApiWYRE} from "api/src/api/wyre/api";
import {
  IAssetLike,
  IAssets,
  IFilters,
  ILoginGetTokenResponse,
  ILoginRequest,
  ILoginResponse,
  ITotalCurrency,
  IUserFollowRequest
} from "api/src/types";

import {http} from "./api.init";

// TODO fix all any
export const API = {
  asset: {
    getAssetsByFilters: async (payload?: TRequestEsAssets) =>
      http.get<IAssets>(`api/v2/es/assets`, {
        params: payload
      }),
    getAsset: async (assetId: number) =>
      http.get<IAsset>(`api/v2/assets/${assetId}`),
    like: async (id: number, payload: IAssetLike) =>
      http.post<IAssetLike, any>(`api/v2/assets/${id}/like`, {body: payload}),
    checkAvailable: async (assetId: number) =>
      http.get<IAvailable>(`api/v2/assets/${assetId}/available`),
    // TODO wallet this just special params to create one system to auto pagination
    getAssets: async (payload: TGetAssetRequest) => http.get<IPaginationResponse<IAsset[]>>(
      `api/v2/assets`,
      {
        params: {
          ...payload
        }
      }
    ),
    actionWithNumber: async (payload: IActionWithNumberRequest) =>
      http.post<IActionWithNumberRequest, IActionWithNumberResponse>(`api/v2/compute`, {
        body: payload
      }),
    createAsset: async (formData: FormData) =>
      http.post<FormData, ICreateAsset>(`api/v2/ipfs/upload`, {
        body: formData
      }),
  },
  auth: {
    getToken: async () =>
      http.get<ILoginGetTokenResponse>("api/v2/login/"),
    login: async (payload: ILoginRequest) =>
      http.post<ILoginRequest, ILoginResponse>("api/v2/login/", {body: payload})
  },
  auction: {
    getAuctions: async () =>
      http.get<any>("api/v2/auctions"),
  },
  bids: {
    getBids: async (payload: TPaginationRequest<IGetBids>) => http.get<IPaginationResponse<IBid[]>>(
      "api/v2/bids",
      {params: payload}
    )
  },
  categories: {
    getCategories: async () =>
      http.get<ICategory[]>("api/v2/categories")
  },
  collections: {
    getCollections: async (params?: any) =>
      http.get<IPaginationResponse<ICollection[]>>("api/v2/es/collections", {
        params
      }),
    getCollectionById: async (collectionId: number) =>
      http.get<ICollection>(`api/v2/es/collections/${collectionId}`),
    getCollectionOwnerId: async (id: number | undefined) =>
      http.get<any>(`api/v2/collections?ownerId=${id}`),
    createCollection: async (body: FormData) =>
      http.post<FormData, ICollection>(`api/v2/collections/create`, {
        body
      }),
  },
  filters: {
    getFiltersStructure: async () =>
      http.get<IFilters>("api/v2/es/news/structure")
  },
  marketplace: {
    getMarketplace1155: async (payload: { chain?: string }) => http.get<IMarketPlace>(
      `api/v2/nft/build/marketplace1155`,
      {params: payload}
    ),
    getMarketplace1155ERC20: async (payload: { chain?: string }) => http.get<IMarketPlace>(
      `api/v2/nft/build/marketplace1155/erc20`,
      {params: payload}
    ),
    // ONLY FOR CREATE
    getERC1155: async (payload: { chain?: string }) => http.get<IMarketPlace>(
      `api/v2/nft/build/erc1155`,
      {params: payload}
    ),
    getERC20: async (payload: { currencyAddress?: string, chain?: string }) => http.get<IMarketPlace>(
      `api/v2/currencies/build/${payload.currencyAddress}`,
      {params: payload}
    ),
    // TO GET ABI by auctionAddress by marketplaceAddress by tokenAddress
    getBuild: async (tokenAddress: string, payload: { chain?: string }) =>
      http.get<IMarketPlace>(`api/v2/nft/build/${tokenAddress}`, {
        params: payload
      }),
  },
  news: {
    getNewsFromES: async (payload?: TRequestEsNews) =>
      http.get<IPaginationResponse<IAssetObject[]>>("api/v2/es/news", {
        params: payload
      })
  },
  nft: {
    getNFTHistory: async (payload: { assetId: number }) =>
      http.get<IPaginationResponse<IAssetHistory[]>>(`api/v2/history/nft`, {params: payload}),
    getNFTCreatorList: async (wallet: string, payload?: any) =>
      http.get<any>(`api/v2/nft/creator/${wallet}/list`, {
        params: payload
      }),
    getOwnerNews: async (wallet: string, payload?: any) =>
      http.get<any>(`api/v2/nft/owner/${wallet}/list`, {
        params: payload
      })
  },
  notifications: {
    getNotifications: async () =>
      http.get<any>(`api/v2/notifications`),
    getLatestNotifications: async (payload: {}) =>
      http.get<any>(`api/v2/notifications/latest`, {params: payload}),
    getNotificationById: async (id: number) =>
      http.get<any>(`api/v2/notifications${id}`),
    readNotification: async (payload?: { ids?: number[] | string[] }) =>
      http.post<any, any>(`api/v2/notifications/read`, {body: payload}),
    deleteNotificationById: async (id: number) =>
      http.delete<any, any>(`api/v2/notifications${id}`),
  },
  user: {
    getUser: async () =>
      http.get<IUser>("api/v2/user/", {extraParams: {auth: true}}),
    updateUser: async (payload: any) =>
      http.patch<any, any>("api/v2/user/", {
        body: payload
      }),
    getUserById: async (id: number) =>
      http.get<IUserGetById>(`api/v2/users/${id}`),
    getUserAssetsCount: async (id: number) =>
      http.get<IUserAssetCount>(`api/v2/users/${id}/count`),
    getUserStatistics: async (id: number) =>
      http.get<IUserStatistics>(`api/v2/users/${id}/statistic`),
    follow: async (payload: IUserFollowRequest) =>
      http.post<IUserFollowRequest, any>(`api/v2/users/${payload.id}/follow`, {body: payload}),
    // TODO not used ?
    getUserWallet: async (wallet: string) =>
      http.get<any>(`api/v2/users/wallet/${wallet}`)
  },
  other: {
    getChainIdList: async () =>
      http.get<IGetChainIdList[]>(`api/v2/chains/data`),
    getPriceList: async (fiatValue: TFiatCurrency = "USD") =>
      http.get<IGetPriceList[]>(`api/v2/crypto/prices`, {
        params: {
          currency: fiatValue
        }
      }),
    getIPFSMimeType: async () =>
      http.get<string[]>(`api/v2/ipfs/mime-types`)
  },
  slider: {
    getSlides: async (params?: any) =>
      http.get<any>("api/v2/slides", {
        params
      })
  },
  revenues: {
    getRevenues: async () =>
      http.get<ITotalCurrency>("api/v2/statistics/revenue")
  },
  goals: {
    getGoals: async () =>
      http.get<any>("api/v1/es/goals")
  },
  goalsId: {
    getGoalsId: async (id: string | undefined) =>
      http.get<any>(`api/v1/es/goals/${id}`),
    getAssetsByFilters: async (payload?: TRequestEsAssets) =>
      http.get<IAssets>(`api/v2/es/assets`, {
        params: payload
      })
  },
  verificationDocument: {
    setVerificationDocument: async (payload: FormData) =>
      http.post("api/v2/verification/add_document", {body: payload}),
    getVerificationDetail: async () =>
      http.get<any>("api/v2/verification/detail")
  },
  search: {
    users: async () =>
      http.get<any>("api/v2/es/users")
  },
  ...ApiCoinbase,
  ...ApiWYRE
};
