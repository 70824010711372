import React from "react";

import {Price} from "components/Price";
import {Time} from "components/Time";
import {useWeb3Contract} from "providers/Web3Contract";
import {classNames} from "utils";

import S from "./style.module.scss";

export const ProfileMyBidsAuctionBlockYourBid = () => {
  const {auction, bid} = useWeb3Contract();

  return (
    <div className={S.MyBidDataWrapper}>
      <h4>Your bid:</h4>
      <Price
        cryptoImg={auction?.currency?.image}
        symbol={auction?.currency?.symbol}
        decimals={auction?.currency?.decimals}
        isShowFiat={false}
        price={bid?.bid}
        priceCryptoSize={"text-xs"}
        classNamePriceCrypto={S.MyBidPrice}
      />
    </div>
  );
};

export const ProfileMyBidsAuctionBlockYourBidTime = () => {
  const {bid} = useWeb3Contract();

  return (
    <Time
      className={classNames(S.MyBidTime, "!text-xs")}
      date={bid?.updatedAt}
    />
  );
};
