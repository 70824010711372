import React from "react";

import {useAsset} from "providers";
import {AssetSellingActionBuying} from "pages/Asset/SellingAction/Buying";
import {AssetSellingActionAuction} from "pages/Asset/SellingAction/Auction";
import {BigDesktopForBids, Mobile} from "components/Responsive";

import {
  AssetSellingActionSkeleton,
  AssetSellingActionSkeletonMobile
} from "../AssetSkeleton/AssetSellingActionSkeleton";

export const AssetSellingAction = () => {
  const {available} = useAsset();
  return (
    <>
      {!available?.auctions && !available?.marketplaces && (
        <>
          <BigDesktopForBids>
            <AssetSellingActionSkeleton/>
          </BigDesktopForBids>
          <Mobile>
            <AssetSellingActionSkeletonMobile/>
          </Mobile>
        </>
      )}

      {available?.auctions && available?.auctions?.length > 0 && (
        <AssetSellingActionAuction/>
      )}

      {available?.marketplaces && available?.marketplaces?.length > 0 && (
        <AssetSellingActionBuying/>
      )}
    </>
  );
};
