import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const OpenIcon = ({size = 14, fill = "#0A84FF"}: IProps) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.25 12.25H1.75V1.75H7V0.25H1.75C0.92125 0.25 0.25 0.92125 0.25 1.75V12.25C0.25 13.0787 0.92125 13.75 1.75 13.75H12.25C13.0787 13.75 13.75 13.0787 13.75 12.25V7H12.25V12.25ZM8.5 0.25V1.75H11.1887L3.81625 9.1225L4.8775 10.1838L12.25 2.81125V5.5H13.75V0.25H8.5Z"
          fill={fill}
        />
      </svg>

    </>
  );
};
