import React from "react";

import {Table} from "components/Table";
import {ListView} from "components/ListView";
import {SpoilerView} from "components/SpoilerView";
import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

export const HelpCenterPageAbout = () => {
  return (
    <>
      <HelpCenterPageHeader
        title={"About platform"}
      />
      <SpoilerView
        title={"Spoiler view"}
        body={[
          {
            title: "Bitski",
            description: "A simple-to-use wallet allowing users to sign up with an email and password.",
            spoiler: "WalletConnect - WalletConnect is a protocol supported by many different Dapps and wallets. Install any mobile wallets supporting WalletConnect protocol."
          },
          {
            title: "Bitski",
            description: "A simple-to-use wallet allowing users to sign up with an email and password.",
            spoiler: "WalletConnect - WalletConnect is a protocol supported by many different Dapps and wallets. Install any mobile wallets supporting WalletConnect protocol."
          }
        ]}
      />
      <ListView
        title={"List view"}
        body={[
          {
            title: "Bitski",
            text: "A simple-to-use wallet allowing users to sign up with an email and password."
          },
          {
            title: "Dapper",
            text: "A browser extension that pays gas fees for you."
          },
          {
            title: "Kaikas",
            text: "A browser extension that interacts with Klaytn network from websites. You can download Kaikas for Chrome here."
          },
          {
            title: "OperaTouch",
            text: "Opera Crypto Wallet works with both your Opera computer browser and your Opera Touch for iOS browser"
          },
          {
            title: "Torus",
            text: "A low friction wallet that allows you to login with Facebook, Google, and other OAuth providers."
          },
        ]}
      />
      <Table
        title={"Table view"}
        tableBlock={[
          {title: "Endpoint", body: ["/api/v2/ipfs/upload"], align: "left"},
          {title: "Method", body: ["POST"]},
          {
            title: "Request",
            body: [
              "file: file",
              "file_extension?: string",
              "wallet_address: string",
              "title: string",
              "descriptions?: string"
            ],
            align: "left"
          },
          {title: "Response", body: ["IPFS object"]}
        ]}
      />
    </>
  );
};
