import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {}

export const MarketplaceButtonIcon = (
  {
    size = 38
  }: IProps
) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19"
        cy="19"
        r="19"
        fill="#409CFF"
      />
      <path
        d="M15.9426 23.1459L20.7187 18.3699L15.9426 13.5938L17.4166 12.1199L23.6666 18.3699L17.4166 24.6199L15.9426 23.1459Z"
        fill="white"
      />
    </svg>
  );
};

