import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const ShareIconPopupWhatsApp = ({
  size = 50,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="black"
      />
      <path
        d="M25.5946 11.837C18.7147 11.837 13.1193 17.4319 13.1167 24.3088C13.1157 26.6656 13.7752 28.9607 15.0236 30.9466L15.3203 31.4185L14.0603 36.021L18.781 34.7827L19.2363 35.0529C21.1514 36.1893 23.3466 36.7906 25.5846 36.7916H25.5896C32.4644 36.7916 38.0595 31.1962 38.0623 24.3187C38.0636 20.9861 36.7674 17.8523 34.4127 15.4947C32.0578 13.1373 28.9261 11.8381 25.5946 11.837ZM32.9311 29.6714C32.6187 30.5472 31.1206 31.3466 30.4002 31.4542C29.7541 31.5508 28.9367 31.591 28.0385 31.3057C27.4939 31.1329 26.7956 30.9021 25.9009 30.5158C22.1395 28.8916 19.6829 25.1044 19.4955 24.8541C19.3081 24.6039 17.9644 22.8209 17.9644 20.9752C17.9644 19.1298 18.9332 18.2225 19.2768 17.8471C19.6204 17.4719 20.0267 17.378 20.2766 17.378C20.5266 17.378 20.7769 17.3803 20.9952 17.3912C21.2254 17.4028 21.5345 17.3037 21.839 18.0348C22.1514 18.7857 22.9013 20.6313 22.9951 20.819C23.0889 21.0067 23.1513 21.2257 23.0263 21.4759C22.9014 21.726 22.8389 21.8825 22.6514 22.1014C22.464 22.3204 22.2576 22.5902 22.089 22.7583C21.9012 22.9453 21.7058 23.1482 21.9245 23.5235C22.1433 23.899 22.8958 25.1267 24.0105 26.1211C25.4426 27.3985 26.6509 27.7944 27.0258 27.9821C27.4007 28.17 27.6195 28.1384 27.8382 27.8883C28.057 27.6381 28.7756 26.7934 29.0255 26.418C29.2755 26.0428 29.5255 26.1053 29.8691 26.2304C30.2128 26.3557 32.0564 27.2626 32.4313 27.4502C32.8062 27.638 33.0562 27.7319 33.1499 27.8883C33.2436 28.0446 33.2436 28.7953 32.9311 29.6714Z"
        fill="white"
      />
      <path
        d="M25.041 -0.142822C11.2341 -0.142822 0.0405273 11.0487 0.0405273 24.8557C0.0405273 38.6626 11.2341 49.8562 25.041 49.8562C38.847 49.8562 50.0405 38.6626 50.0405 24.8557C50.0405 11.0487 38.847 -0.142822 25.041 -0.142822ZM25.5897 39.326C25.5892 39.326 25.59 39.326 25.5897 39.326H25.5835C23.072 39.3251 20.604 38.6949 18.4123 37.4995L10.4571 39.5862L12.5861 31.81C11.2728 29.5342 10.5819 26.9528 10.583 24.3078C10.5862 16.034 17.3182 9.30245 25.5896 9.30245C29.6039 9.30407 33.3718 10.8668 36.2049 13.7033C39.0382 16.5398 40.5977 20.31 40.5962 24.3198C40.5926 32.5938 33.8602 39.326 25.5897 39.326Z"
        fill="white"
      />
    </svg>
  );
};

export const ShareIconPopupFacebook = ({
  size = 50,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2186 39.4286V24.9992H18.2861V20.2162H21.2186V17.3237C21.2186 13.4222 22.3851 10.6089 26.6568 10.6089H31.7387V15.3821H28.1603C26.3683 15.3821 25.96 16.5729 25.96 17.8198V20.2162H31.4746L30.7219 24.9992H25.96V39.4286H21.2186Z"
        fill="black"
      />
    </svg>
  );
};

export const ShareIconPopupTelegram = ({
  size = 50,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="black"
      />
      <path
        d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM37.994 15.8141L33.5538 37.0117C33.5001 37.2681 33.3828 37.5069 33.2127 37.7061C33.0426 37.9053 32.8252 38.0586 32.5804 38.1519C32.3356 38.2452 32.0713 38.2754 31.8118 38.2399C31.5523 38.2044 31.3058 38.1042 31.0951 37.9485L24.6365 33.177L20.7236 36.8684C20.6724 36.9167 20.6121 36.9543 20.5462 36.9791C20.4802 37.0038 20.41 37.0152 20.3397 37.0125C20.2693 37.0098 20.2002 36.9932 20.1363 36.9635C20.0725 36.9338 20.0152 36.8917 19.9678 36.8396L19.8837 36.7473L20.5636 30.1677L32.8179 18.9713C32.8785 18.916 32.9162 18.84 32.9235 18.7583C32.9308 18.6766 32.9073 18.5952 32.8575 18.53C32.8077 18.4648 32.7353 18.4206 32.6545 18.4062C32.5738 18.3918 32.4906 18.4081 32.4213 18.4521L16.7495 28.371L10 26.1042C9.82053 26.0439 9.66412 25.9296 9.55221 25.7769C9.44031 25.6241 9.3784 25.4405 9.37498 25.2513C9.37155 25.062 9.42678 24.8763 9.53309 24.7196C9.6394 24.5629 9.79158 24.443 9.96875 24.3763L36.377 14.4281C36.5807 14.3515 36.8012 14.3309 37.0156 14.3687C37.2299 14.4064 37.4302 14.5011 37.5955 14.6427C37.7608 14.7844 37.8849 14.9678 37.955 15.1739C38.0252 15.3799 38.0386 15.601 37.994 15.8141Z"
        fill="white"
      />
    </svg>
  );
};

export const ShareIconPopupFacebookMessenger = ({
  size = 50,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 0C38.7979 0 50 11.2021 50 25C50 38.7979 38.7979 50 25 50C11.2021 50 0 38.7979 0 25C0 11.2021 11.2021 0 25 0ZM25 10.2339C16.3348 10.2339 9.61914 16.5813 9.61914 25.1537C9.61914 29.638 11.4574 33.5126 14.4501 36.1896C14.7007 36.4154 14.8523 36.728 14.8647 37.0653L14.9483 39.8011C14.9762 40.6738 15.8767 41.2401 16.6752 40.8904L19.7266 39.5442C19.9865 39.4298 20.2743 39.4081 20.5467 39.4823C21.9485 39.8691 23.4434 40.0734 25 40.0734C33.6652 40.0734 40.3809 33.7261 40.3809 25.1537C40.3809 16.5813 33.6652 10.2339 25 10.2339V10.2339ZM15.7653 29.5173L20.2836 22.3498C21.0016 21.211 22.5428 20.9263 23.6197 21.734L27.2127 24.4295C27.5438 24.6771 27.9957 24.674 28.3237 24.4265L33.1763 20.7437C33.823 20.2517 34.6711 21.0284 34.2347 21.7154L29.7194 28.8798C29.0015 30.0187 27.4604 30.3033 26.3834 29.4956L22.7904 26.8001C22.4592 26.5524 22.0074 26.5556 21.6794 26.8032L16.8237 30.4891C16.177 30.9811 15.3289 30.2043 15.7653 29.5173V29.5173Z"
        fill="white"
      />
    </svg>
  );
};
