import React from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  classRow?: string;
  children?: React.ReactNode;
}

export const WrapperComponentFAQ = ({children, classRow}: IProps) => {
  return (
    <div className={classNames(S.WrapperRow, classRow)}>
      {children}
    </div>
  );
};

export const TextComponentFAQ = ({children, classRow}: IProps) => {
  return (
    <div className={classNames(S.TextRow, classRow)}>
      {children}
    </div>
  );
};

export const ImgComponentFAQ = ({children, classRow}: IProps) => {
  return (
    <div
      className={classNames(
        S.ImgRow,
        classRow == "Right" ? S.Right : classRow == "Left" ? S.Left : classRow == "Center" ? S.Center : ""
      )}
    >
      {/* Classes = Left, Right, Center */}
      {children}
    </div>
  );
};

export const IcoComponentFAQ = ({children, classRow}: IProps) => {
  return (
    <div className={classNames(S.IcoRow, classRow)}>
      {children}
    </div>
  );
};
