import React from "react";


import imgVal1 from "assets/img/charity/val/ico-val-1.png";
import imgVal2 from "assets/img/charity/val/ico-val-2.png";
import imgVal3 from "assets/img/charity/val/ico-val-3.png";
import {HelpPageHeader} from "pages/HelpFAQ/Layout/Header";
import {useTheme} from "hooks/useTheme";
import {IcoComponentFAQ, TextComponentFAQ, WrapperComponentFAQ} from "pages/HelpFAQ/Layout/Components";

export const BlockchainFAQ = () => {
  const theme = useTheme();
  return (
    <>
      <HelpPageHeader
        title={`What is blockchain and which of them does ${theme === "nftim"
          ? "NFTIM"
          : "HelpUA"} support?`}
        breadcrumb="blockchain"
      />
      <WrapperComponentFAQ>
        <TextComponentFAQ>
          <p>
            <b>Blockchain</b> is the sequence of all blocks that have been committed to the network in the history of the network. So-named because each block contains a reference to the previous block, which helps us maintain an ordering over all blocks (and thus over the precise history).
          </p>
          <p>
            So with simpler words, basically, blockchain is a database that is stored on many-many computers, and to add some information to that database, you need to go through a verification process on most of them. Also, you cannot change any information that has been written earlier in that database.
          </p>
          <br/>
          <p>
            For this moment, <b>{theme === "nftim" ? "NFTIM" : "HelpUA"} supports Ethereum, Polygon, and BNB Chain.</b> Here you can see brief information about them.
          </p>
        </TextComponentFAQ>
        <br/>
        <IcoComponentFAQ>
          <img
            src={imgVal1}
            alt=""
          />
        </IcoComponentFAQ>
        <TextComponentFAQ>
          <p><b>Ethereum</b></p>
          <p>Official Website: <a
            href="https://ethereum.org"
            target="_blank"
          >ethereum.org</a></p>
          <br/>
          <p>Token: <b>ETH</b></p>
          <br/>
          <p>
            Ethereum is a decentralized, open-source blockchain with smart contract functionality launched in 2015. Ether or ETH is the native cryptocurrency of the Ethereum blockchain.
          </p>
          <p>
            ETH is used to pay transaction fees (known as gas fees) on the Ethereum blockchain. Gas fees - are determined by supply/demand and fluctuate according to network usage.
          </p>
        </TextComponentFAQ>
        <br/>
        <IcoComponentFAQ>
          <img
            src={imgVal2}
            alt=""
          />
        </IcoComponentFAQ>
        <TextComponentFAQ>
          <p><b>Polygon</b></p>
          <p>Official Website: <a
            href="https://polygon.technology"
            target="_blank"
          >polygon.technology</a></p>
          <br/>
          <p>Token: <b>MATIC</b></p>
          <br/>
          <p>
            Polygon (previously known as the Matic Network) is a separate blockchain that provides scalable, secure, and instant transactions with Ethereum currencies like ETH, USDC and DAI.
          </p>
          <p>
            As one of the first scaling solutions for Ethereum, cryptocurrencies must be &quot;bridged&quot; from Ethereum&apos;s blockchain to Polygon. Think of Polygon as a sibling to Ethereum, the currencies are similar, but the two blockchains have slight differences.
          </p>
          <p>
            Beginning in July 2021, users can use Polygon to create, buy and sell NFTs without paying transaction fees, essentially creating a gas-free marketplace.
          </p>
        </TextComponentFAQ>
        <br/>
        <IcoComponentFAQ>
          <img
            src={imgVal3}
            alt=""
          />
        </IcoComponentFAQ>
        <TextComponentFAQ>
          <p><b>BNB chain</b></p>
          <p>Official Website: <a
            href="https://www.binance.com/"
            target="_blank"
          >binance.com</a></p>
          <br/>
          <p>Token: <b>BNB</b></p>
          <br/>
          <p>
            BNB Chain is a blockchain software system developed by Binance and its community.(its first-ever blockchain launched in 2017) BNB, which stands for “Build and Build” (formerly called Binance Coin), is the blockchain gas token that “fuels” transactions on BNB Chain. BNB Chain is a community-driven, open-sourced and decentralized ecosystem.
          </p>
          <p>
            This blockchain is now competing with Ethereum as one of the most popular blockchains for developing decentralized projects.
          </p>
        </TextComponentFAQ>
      </WrapperComponentFAQ>
    </>
  );
};
