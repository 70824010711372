import React from "react";
import "react-image-lightbox/style.css";

import {AssetProvider, useAsset} from "providers";
import {AssetDesc} from "pages/Asset/AssetDesc";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {Web3ContractProvider} from "providers/Web3Contract";

import S from "./style.module.scss";

export type TSaleType = "Auction" | "FixedPrice";

const AssetProviders = () => {
  const theme = useTheme();
  const {asset, updateAsset} = useAsset();

  return (
    <Web3ContractProvider
      asset={asset}
      chain={asset?.chain}
      chainId={asset?.chainId}
      auction={asset?.currentAuction}
      tokenId={asset?.tokenId}
      tokenAddress={asset?.tokenAddress}
      extraCallback={updateAsset}
    >
      <div className={classNames(S.AssetWrapper, S[theme])}>
        <AssetDesc/>
      </div>
    </Web3ContractProvider>
  );
};

export const Asset = () => {
  return (
    <AssetProvider>
      <AssetProviders/>
    </AssetProvider>
  );
};
