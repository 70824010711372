import React, {useCallback, useContext} from "react";
import {isMobile} from "react-device-detect";

import {useApi} from "api";
import {ECryptoCurrency, ECryptoCurrencyNameConvert, IOrdersReserveOmitRequest, IOrdersReserveRequest} from "types";
import {errorChecker} from "utils";
import {useWeb3} from "providers/Web3";

export interface IUseWyre {
  buyFiatViaWyre: (payload?: IOrdersReserveOmitRequest) => void;
}

const HocWyreContext = React.createContext<IUseWyre>({
  buyFiatViaWyre (): void {
  }
});
HocWyreContext.displayName = "HocWyreContext";
const HocWyreProvider = HocWyreContext.Provider;

export function useWyre (): IUseWyre {
  const hocWyreData = useContext(HocWyreContext);
  if (hocWyreData === null) {
    throw new Error("Hook Wyre available only is nested components Web3");
  }
  return hocWyreData;
}

interface IProps {
  children?: React.ReactNode;
}

export const WyreProvider = ({children}: IProps) => {
  const {api} = useApi();
  const {web3State} = useWeb3();

  const buyFiatViaWyre = useCallback(async (payload?: IOrdersReserveOmitRequest) => {
    // TODO start global loader

    try {
      // TODO check isMobile and create different flow for mobile and desc version
      const body: IOrdersReserveRequest = {
        referrerAccountId: process.env.REACT_APP_WYRE_AC || "",
        sourceCurrency: "USD",
        destCurrency: ECryptoCurrencyNameConvert[web3State?.currency || ""],
        paymentMethod: "debit-card",
        dest: `${ECryptoCurrency[web3State?.currency || ""]}:${web3State?.wallet}` || "",
        lockFields: ["destCurrency", "dest"],
        ...payload
      };

      if (isMobile) {
        body.redirectUrl = window.location.href;
        body.failureRedirectUrl = window.location.href;
      }

      const {url} = await api.wyre.ordersReserve(body);

      if (isMobile) {
        window.location.href = url;
      } else {
        window.open(url, "", "toolbar=0,status=0,width=350,height=600");
      }
    } catch (err) {
      errorChecker(err);
    } finally {
      // TODO end global loader
    }
  }, [api.wyre, web3State?.currency, web3State?.wallet]);

  return (
    <HocWyreProvider
      value={{
        buyFiatViaWyre
      }}
    >
      {children}
    </HocWyreProvider>
  );
};
