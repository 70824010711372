import React from "react";
import {Link} from "react-router-dom";

import {TGetAssetKeys} from "types";
import {useMyUser, useProfile} from "providers";
import {ScrollBlock} from "components/ScrollBlock";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {TGetActiveContent} from "components/Profile/ProfileContent";
import {SelectArrow} from "assets/icons/SelectArrow";
import {useToggle} from "hooks/useToggle";

import S from "./style.module.scss";

interface IProps {
  currentTab: TGetAssetKeys;
  onTabClick: (filter: TGetAssetKeys) => void;
  onClickBidTab: () => void;
  userWallet?: string;
  isActiveContent?: TGetActiveContent;
  setActiveContent?: (filter: TGetActiveContent) => void;
}

export const ProfileHeader = ({
  currentTab,
  onTabClick,
  onClickBidTab,
  isActiveContent,
  setActiveContent
}: IProps) => {

  const {user} = useMyUser();
  const {profile, profileAssetCount} = useProfile();
  const theme = useTheme();

  const listContent = [
    {
      tab: "profile",
      title: "Profile",
      theme: "nftim"
    },
    {
      tab: "nft",
      title: "NFT",
      theme: "all"
    },
    {
      tab: "project",
      title: "Projects",
      theme: "all"
    },
  ];

  const listTabs = [
    {
      tab: "owner",
      title: "Owner",
      count: profileAssetCount?.owned || 0,
      terms: null,
    },
    {
      tab: "onSaleBy",
      title: "On Sale",
      count: profileAssetCount?.onSale || 0,
      terms: null,
    },
    {
      tab: "creator",
      title: "Created",
      count: profileAssetCount?.created || 0,
      terms: null,
    },
    {
      tab: "myBids",
      title: "My Bids",
      count: profileAssetCount.placedBids || 0,
      terms: "myBids",
    },
  ];

  const [isToggle, setToggle] = useToggle(false);

  return (
    <div className={"relative block w-full py-7 z-[5]"} id={"project"}>
      <div className={"relative flex w-full items-center gap-x-5 gap-y-4 max768:flex-col"}>
        <ScrollBlock
          scrollOrientation={"x"}
          className={"relative block w-[unset] max-w-full min-w-0 flex-1 pr-0"}
        >
          <div className={"relative block"}>
            <ul className={"relative flex gap-9 items-center min-h-[40px]"}>
              {listContent && listContent.map((item: any, index) => {
                if (item.theme === "all" || item.theme === theme) {
                  return (
                    <li
                      key={index}
                      className={"relative block"}
                    >
                      <a
                        className={`relative flex items-center justify-center cursor-pointer px-0 ease-all
                        ${isActiveContent === item.tab && `h-8  !px-6 bg-light_gray rounded-[10px]`}
                        ${theme === "nftim" && "hover:span:!text-dark"}
                        ${theme === "nftim" && isActiveContent === item.tab && "span:!text-dark"}
                        ${theme === "help_ua" && "hover:span:!text-blue_static"}
                        ${theme === "help_ua" && isActiveContent === item.tab &&  "span:!text-blue_static"}
                        `}
                        onClick={(event) => {
                          setActiveContent && setActiveContent(item.tab);
                          history.replaceState(null, `${item.tab}`, `#${item.tab}`);
                        }}
                      >
                        <span className={"relative block whitespace-nowrap font-bold text-sm leading-4 text-text_description ease-all"}>
                          {item.title}
                        </span>
                      </a>
                    </li>
                  );
                } else {
                  return false;
                }
              })}
            </ul>
          </div>
        </ScrollBlock>
        {isActiveContent === "nft" && (
          <div className={S.TabsSelect}>
            {listTabs && listTabs.filter(item => item.tab === currentTab).map((item, index) => {
              return (
                <div
                  className={classNames(S.CurrentSelect, {[S.Toggle]: isToggle}, S[theme])}
                  key={index}
                  onClick={() => setToggle()}
                >
                  <span>{item.title}</span>
                  <p>{item.count}</p>
                  <SelectArrow fill={"#000"}/>
                </div>
              );
            })}
            <div className={classNames(S.FiltersSelect, {[S.Toggle]: isToggle}, S[theme])}>
              <ul>
                {listTabs && listTabs.filter(item => item.terms === null).map((item: any, index) => {
                  return (
                    <li
                      key={index}
                      className={classNames({[S.Active]: currentTab === item.tab})}
                      onClick={() => {
                        onTabClick(item.tab);
                        setToggle();
                      }}
                    >
                      <span>{item.title}</span>
                      <p>{item.count}</p>
                    </li>
                  );
                })}
                {(user.cryptoWallet && profile.cryptoWallet === user.cryptoWallet && profileAssetCount.placedBids !== 0) && (
                  <>
                    {listTabs && listTabs.filter(item => item.terms === "myBids").map((item: any, index) => {
                      return (
                        <li
                          key={index}
                          className={classNames({[S.Active]: currentTab === item.tab})}
                          onClick={() => {
                            onTabClick("myBids");
                            onClickBidTab();
                            setToggle();
                          }}
                        >
                          <span>{item.title}</span>
                          <p>{item.count}</p>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
