import React from "react";
import ContentLoader from "react-content-loader";

export const AssetTabsItemSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={144}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%"}}
  >
    <rect
      x="0"
      y="16"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="calc(16 + 4)"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="calc(16 + 22)"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
    <rect
      x="0"
      y="88"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <rect
      x="52"
      y="calc(88 + 4)"
      rx="0"
      ry="0"
      width="70"
      height="10"
    />
    <rect
      x="52"
      y="calc(88 + 22)"
      rx="0"
      ry="0"
      width="150"
      height="10"
    />
  </ContentLoader>
);
