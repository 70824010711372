import React from "react";

import {Container} from "components/Container";
import {CreateAssetProvider} from "providers/CreateAsset";
import {CreateNFTSteps} from "components/CreateNft/Steps";
import {Web3ContractProvider} from "providers/Web3Contract";

import S from "./style.module.scss";

export const CreateNft = () => {
  return (
    <Container>
      <Web3ContractProvider>
        <CreateAssetProvider>
          <div className={S.StepContent}>
            <CreateNFTSteps/>
          </div>
        </CreateAssetProvider>
      </Web3ContractProvider>
    </Container>
  );
};
