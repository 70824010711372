import React, {
  useMemo,
} from "react";
import {Link} from "react-router-dom";

import {Time} from "components/Time";
import {Price} from "components/Price";
import {useAsset} from "providers";
import {UserInfo} from "pages/Asset/UserInfo";

import S from "../style.module.scss";

interface IProps {
  auctionId: number;
}

export const Bids = ({auctionId}: IProps) => {
  const {asset, bidsList} = useAsset();

  const bids = useMemo(() => {
    return bidsList[auctionId];
  }, [auctionId, bidsList]);

  return (
    <>
      {(bids && bids?.length > 0) && bids?.map((bid) => {
        return (
          <tr key={bid.id}>
            <td>
              <UserInfo
                avatar={bid.user?.thumbnailPhoto}
                title={bid.user?.displayName}
                text={
                  <Link to={`/profile/${bid.user?.id}`}>
                    {bid.user?.displayName}
                  </Link>
                }
                label={
                  <Time
                    date={bid.updatedAt}
                    className={S.Time}
                  />
                }
              />
            </td>
            <td>
              <div className={"relative w-full flex justify-end"}>
                <Price
                  symbol={asset?.defaultCurrency?.symbol}
                  decimals={asset?.defaultCurrency?.decimals}
                  price={bid.bid}
                  priceFiatSize={"text-xs"}
                />
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};
