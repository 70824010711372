import React from "react";

import {HeartIconActive} from "assets/icons/HeartIconActive";
import {HeartIcon} from "assets/icons/HeartIcon";
import {INFTNews} from "components/Home/types";
import {IAsset} from "types";

import S from "./style.module.scss";

interface IProps {
  onLike?: (isLike: boolean, assetId: number) => void;
  asset: INFTNews | IAsset;
}

export const CardLike = ({onLike, asset}: IProps) => {
  return (
    <div
      className={S.LikeButton}
      onClick={() => onLike && onLike(asset.isLike, asset.id)}
    >
      {asset.isLike
        ? <HeartIconActive/>
        : <HeartIcon fill="white"/>
      }
      <span>{asset.likeCount}</span>
    </div>
  );
};
