import React from "react";

export const NotificationBackgroundIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.625 39.0695L45.1528 38.6528C43.8131 37.4592 42.6405 36.0904 41.6666 34.5834C40.6031 32.5036 39.9656 30.2323 39.7916 27.9028V21.0417C39.8008 17.3828 38.4736 13.8465 36.0593 11.0972C33.6451 8.34793 30.3099 6.57486 26.6805 6.11117V4.3195C26.6805 3.82774 26.4852 3.35613 26.1375 3.00841C25.7897 2.66068 25.3181 2.46533 24.8264 2.46533C24.3346 2.46533 23.863 2.66068 23.5153 3.00841C23.1675 3.35613 22.9722 3.82774 22.9722 4.3195V6.13894C19.3753 6.63606 16.0805 8.41985 13.6979 11.1599C11.3154 13.9 10.0065 17.4107 10.0139 21.0417V27.9028C9.83991 30.2323 9.20244 32.5036 8.13886 34.5834C7.18175 36.0867 6.02802 37.4552 4.70831 38.6528L4.23608 39.0695V42.9862H45.625V39.0695Z" fill="#1C1C1E"/>
      <path
        d="M21.2778 44.4443C21.3996 45.3247 21.8358 46.1313 22.5059 46.7151C23.1761 47.2989 24.0348 47.6205 24.9236 47.6205C25.8124 47.6205 26.6711 47.2989 27.3413 46.7151C28.0114 46.1313 28.4476 45.3247 28.5694 44.4443H21.2778Z" fill="#1C1C1E"/>
    </svg>

  );
};
