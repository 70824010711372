import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  borderColor?: string;
}

export const ImageIcon = ({
  onClick,
  className,
  size = 50,
}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18:3098)">
      <path
        d="M44.2707 43.75H5.72929C2.56882 43.75 0 41.1812 0 38.0207V11.9793C0 8.81882 2.56882 6.25 5.72929 6.25H44.2707C47.4312 6.25 50 8.81882 50 11.9793V38.0207C50 41.1812 47.4312 43.75 44.2707 43.75ZM5.72929 9.375C4.29382 9.375 3.125 10.5438 3.125 11.9793V38.0207C3.125 39.4562 4.29382 40.625 5.72929 40.625H44.2707C45.7062 40.625 46.875 39.4562 46.875 38.0207V11.9793C46.875 10.5438 45.7062 9.375 44.2707 9.375H5.72929Z"
        fill="white"
      />
      <path
        d="M10.4168 20.8332C8.11882 20.8332 6.25 18.9648 6.25 16.6668C6.25 14.3688 8.11882 12.5 10.4168 12.5C12.7148 12.5 14.5832 14.3688 14.5832 16.6668C14.5832 18.9648 12.7148 20.8332 10.4168 20.8332ZM10.4168 15.625C9.84154 15.625 9.375 16.0915 9.375 16.6668C9.375 17.2417 9.84154 17.7082 10.4168 17.7082C10.9917 17.7082 11.4582 17.2417 11.4582 16.6668C11.4582 16.0915 10.9917 15.625 10.4168 15.625Z"
        fill="white"
      />
      <path
        d="M1.56236 39.583C1.1622 39.583 0.762421 39.4312 0.458008 39.1249C-0.152344 38.5145 -0.152344 37.525 0.458008 36.9142L11.9998 25.3729C13.4249 23.9477 15.7435 23.9477 17.1664 25.3729L19.7917 27.9978L29.7083 18.0811C31.1331 16.6559 33.4521 16.6559 34.875 18.0811L49.5417 32.7478C50.1521 33.3582 50.1521 34.3477 49.5417 34.9581C48.931 35.5684 47.9414 35.5684 47.3311 34.9581L32.6643 20.2913C32.4603 20.0872 32.1185 20.0872 31.9167 20.2913L20.8956 31.3124C20.2853 31.9227 19.2957 31.9227 18.6854 31.3124L14.9561 27.5831C14.6914 27.3184 14.4728 27.3184 14.2081 27.5831L2.66672 39.1249C2.3623 39.4312 1.96252 39.583 1.56236 39.583Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18:3098">
        <rect
          width="50"
          height="50"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
