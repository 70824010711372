// TODO move to yup
export const priceValidator = (text: string) => {
  return text.replace(/([^\d]*)(\d*(\.\d{0,5})?)(.*)/, "$2");
};

// TODO move to yup
export const emailValidator = (email: string) => {
  const re = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return re.test(String(email).toLowerCase());
};

// TODO move to yup
export const bioValidator = (bio: string) => {
  return bio.length < 400;
};

// TODO move to yup
export const nameValidator = (name: string) => {
  const re = new RegExp(/^[\w\s(а-яА-ЯёЁіІїЇґҐ)!()_?.@]+$/);
  return re.test(String(name).toLowerCase());
};
