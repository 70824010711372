import React, {useCallback, useEffect, useRef, useState} from "react";
import Slider from "react-slick";

import {Container} from "components/Container";
import {ButtonLink} from "components/ButtonLink";
import {CardTopList} from "components/CardTopList";
import {useApi} from "api";
import {classNames, errorChecker} from "utils";
import {IUserDocument} from "types";
import {IconArrowSliderCollection} from "assets/icons/SliderArrowCollection";

interface IProps {}

export const TopCelebrityMarketplace = (
  {}:IProps) => {
  const {api} = useApi();
  const [isUsers, setUsers] = useState<IUserDocument[]>([]);

  const requestData = useCallback(async () => {
    try {
      let result = await api.search.users();
      setUsers(result.results);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.search]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  let count = isUsers && isUsers.length;
  const sliderArrows = useRef(null);

  const classArrow = `absolute hidden items-center justify-center w-[42px] h-[42px] bg-white border border-solid border-inner_border z-10 top-[50%] translate-y-[-50%] rounded-full cursor-pointer tr-all min1025:hover:bg-light_gray svg:tr-all min1025:svg:hover:scale-x-[1.2] 
  ${count > 1 && "max480:!flex"} 
  ${count > 2 && "min481:max768:!flex"} 
  ${count > 3 && "min769:max1024:!flex"} 
  ${count > 2 && "min1025:max1360:!flex"} 
  ${count > 3 && "min1361:!flex"}`;

  const PreviousArrow = () => {
    return (
      <div
        className={classNames(classArrow, "left-[-21px] max1360:!left-[-16px]")}
        // @ts-ignore
        onClick={() => sliderArrows?.current?.slickPrev()}
      >
        <IconArrowSliderCollection/>
      </div>
    );
  };

  const NextArrow = () => {
    return (
      <div
        className={classNames(classArrow, "right-[-21px] max1360:!right-[-16px]")}
        // @ts-ignore
        onClick={() => sliderArrows?.current?.slickNext()}
      >
        <IconArrowSliderCollection className={"rotate-180"}/>
      </div>
    );
  };

  const settings = {
    arrows: false,
    dots: count > 3,
    infinite: count > 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 30000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    initialSlide: 0,
    variableWidth: true,
    appendDots: (dots: any) => {
      return (
        <>
          <div className={"relative w-full mt-4 max640:!px-0"}>
            <ul className={"relative flex w-full h-[5px] bg-grey_border li:relative li:flex-1 liButton:absolute liButton:w-full liButton:h-full liButton:text-[0px] liButton:tr-all liSlickButton:bg-black min1025:hover:liButton:bg-black liButton:before:absolute liButton:before:left-0 liButton:before:right-0 liButton:before:top-[-5px] liButton:before:bottom-[-5px]"}>
              {dots}
            </ul>
          </div>
        </>
      );
    },
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          dots: count > 2,
          infinite: count > 2,
          swipe: false,
          variableWidth: true,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1050,
        settings: {
          dots: count > 2,
          infinite: count > 2,
          swipe: false,
          variableWidth: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: count > 3,
          infinite: count > 3,
          swipe: true,
          variableWidth: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          dots: count > 2,
          infinite: count > 2,
          swipe: true,
          variableWidth: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: count > 1,
          infinite: count > 1,
          swipe: true,
          variableWidth: false,
          slidesToShow: 1
        }
      },
    ]
  };

  return (
    <div className={"relative w-full bg-light_gray mt-[94px]"}>
      <Container>
        <div className={"relative flex w-full gap-9 items-center py-[90px] max1024:flex-col"}>
          <div className={"relative block w-full max-w-[362px] max1024:!max-w-full"}>
            <div className={"relative block text-[32px] leading-10 font-bold mb-8 text-black"}>
              Top celebrity on our marketplace
            </div>
            <div className={"relative block text-base text-text_description leading-5 font-semibold"}>
              Follow your favorite stars and influencers, forge nft and take part in celebrity activities
            </div>
            {/* comment until the page appears */}
            {/*<ButtonLink to={"/"} className={"mt-9 !w-full !max-w-[254px] px-2"}>*/}
            {/*  View all celebrity list*/}
            {/*</ButtonLink>*/}
          </div>

          <div className={"relative block w-full min-w-0"}>
            <PreviousArrow/>
            <div className={"relative block w-full overflow-hidden"}>
              {isUsers.length > 0 && (
                //@ts-ignore
                <Slider
                  className={"relative block w-full slick-track:!flex w-[calc(100%+8px)] div:relative div:text-[0px] slick-track:min-w-full"}
                  ref={sliderArrows}
                  {...settings}
                >
                  {isUsers && isUsers.filter(item => item.userType === "content_creator").splice(0, 10).map((item, index) => {
                    return (
                      <aside
                        key={index}
                        className={"relative flex pr-2 h-full min1153:!w-[308px] max1152:min1025:!w-[300px] flex-none"}
                      >
                        <CardTopList
                          id={item.id}
                        />
                      </aside>
                    );
                  })}
                </Slider>
              )}
            </div>
            <NextArrow/>
          </div>
        </div>
      </Container>
    </div>
  );
};
