import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const CrossIcon = ({
  fill = "#565661",
  onClick,
  size = 16,
  className,
}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.21967 1.12409C0.512563 0.837828 0.987437 0.837828 1.28033 1.12409L8 7.69157L14.7197 1.12409C15.0126 0.837828 15.4874 0.837828 15.7803 1.12409C16.0732 1.41035 16.0732 1.87447 15.7803 2.16073L9.06066 8.72821L15.7803 15.2957C16.0732 15.5819 16.0732 16.0461 15.7803 16.3323C15.4874 16.6186 15.0126 16.6186 14.7197 16.3323L8 9.76484L1.28033 16.3323C0.987437 16.6186 0.512563 16.6186 0.21967 16.3323C-0.0732233 16.0461 -0.0732233 15.5819 0.21967 15.2957L6.93934 8.72821L0.21967 2.16073C-0.0732233 1.87447 -0.0732233 1.41035 0.21967 1.12409Z"
      fill={fill}
    />
  </svg>
);
