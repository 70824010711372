import React from "react";

import {classNames} from "utils";
import {SupportHeader} from "components/CharityHome/CharitySupportPeople/SupportHeader";
import {SupportBody} from "components/CharityHome/CharitySupportPeople/SupportBody";

import S from "./style.module.scss";

interface IProps {
  className?: string;
}

export const CharitySupportPeople = (
  {
    className
  }: IProps) => {

  
  return (
    <div className={classNames(S.SupportPeople, className)}>
      <SupportHeader/>
      <SupportBody/>
    </div>
  );
};
