import React from "react";
import {Link} from "react-router-dom";

import imgNews1 from "assets/img/portfolio/img-1.png";
import imgNews2 from "assets/img/portfolio/img-2.png";
import imgNews3 from "assets/img/portfolio/img-3.png";
import imgNews4 from "assets/img/portfolio/img-4.png";
import imgNews5 from "assets/img/portfolio/img-5.png";
import coverNews1 from "assets/img/portfolio/img-min-1.png";
import coverNews2 from "assets/img/portfolio/img-min-2.png";
import coverNews3 from "assets/img/portfolio/img-min-3.png";

export const allNewsPortfolio = [
  {
    id: 1,
    image: imgNews1,
    cover: coverNews1,
    title: "Homych Family",
    innerTitle: "A multicooker - the only reminder of home.",
    text: <>
      <p>It is the Khomych family in the picture. The family is small - only mother with a disability and two children aged 8 and 12. In their hometown Berdyansk, they had everything they needed: a cozy home, a recreation center as a business, and their beloved around.But everything has changed. The mother was forced to leave everything she had earned so hard in the occupied city to save her children. Now the family is in Chernihiv, trying to start over. They only had time to take the most necessities with them, and they managed to save only one valuable thing - a multicooker. Now, this multicooker is the only reminder of their happy past life.#HelpUA joined in helping this family - with the funds received from the sale of NFT-arts, we bought the necessary household items, kitchen utensils, and food. This contribution will help them start a new life in another city.This family is one of many who were forced to leave everything they had at home. Now all these families are starting from scratch in other places and need support from caring people.Everyone can help these families! Join HelpUA to contribute the new life of these people.</p>
    </>
  },
  {
    id: 2,
    image: imgNews2,
    cover: coverNews2,
    title: "Future turned into ashes",
    innerTitle: "",
    text: <>
      <p>Anna is 68 years old. Together with her husband they built their house when they were young, and these walls were their home fortress for 46 years. She put her soul into the erection of the house and did her best to make the family nest even cozier every day.</p>
      <p>But the phenomenon, which is too scary to utter, has no sympathy. 4 enemy missiles hit the family&apos;s house and burned it to the ground, together with the family&apos;s previous happy life. Now Anna and her son, earlier diagnosed a cerebral palsy, are living in a dilapidated garage, which miraculously partially survived. The woman is trying to make even this temporary shelter more comfortable, but it is impossible to cope without the help of caring people.</p>
      <p>With the funds raised from the sales of NFT arts on HelpUA.io, we were able to purchase refrigerator, electric kettle, dishes, and a set of necessary products for Anna and her son. This support is essential for the family and will hopefully help them to get through these difficult times.</p>
      <p>If you want to help this family personally, please write to us</p>
      <p>Unfortunately, similar stories are becoming more frequent due to the situation in Ukraine. Every family with similar problems needs support and the hope for a better future.</p>
    </>
  },
  {
    id: 3,
    image: imgNews4,
    cover: "",
    title: "More then 300kg products",
    innerTitle: "",
    text: <>
      <p><Link to="/">Helpua.io</Link> have provided more than 300 kg of food for war-affected families!</p>
      <p>Our team works every day on the development of new NFTs intended to help Ukrainians. Thanks to your support, we have purchased and delivered over 500 kg of groceries to needy families during the last few days!</p>
      <p>Unfortunately, so many people in Ukrainian regions still need constant support from volunteers. They lost their homes and all their money, so they can&apos;t cope without our joint help! It is essential for these families to feel supported and that they are not alone with their problems.</p>
      <p>You are able to help them! It is enough just to purchase any art on our website. All funds raised from the sale of NFTs will go to necessary grocery and belongings for people in difficult situations.</p>
      <p>Thank you for your support and your big, full of kindness hearts!</p>
    </>
  },
  {
    id: 4,
    image: imgNews3,
    cover: coverNews3,
    title: "Two months in a tent in the ashes of a burned house",
    innerTitle: "",
    text: <>
      <p>Angelina is eight years old. She would have been an average happy child if the enemy hadn&lsquo;t come to her country. A shell hit her family&lsquo;s house, caused a fire, and their entire lives burned to the ground in just a few hours.</p>
      <p>Angelina&lsquo;s mother had to live for two months in a tent right on the ashes of their happy past life. All this time, she has been removing the wreckage of the house. The family has just renovated their home before the shelling.</p>
      <p>Thanks to the support of caring people, HelpUA bought for the family some necessary belongings: household items, grocery, and stationery. Young Angelina will attend school in the next academic year with new notebooks, albums, and books.</p>
      <p>Unfortunately, there are many families with similar stories. And they all need our help!</p>
    </>
  },
  {
    id: 5,
    image: imgNews5,
    cover: "",
    title: "More then 500kg products",
    innerTitle: "",
    text: <>
      <p><Link to="/">Helpua.io</Link> have provided more than 500 kg of food for war-affected families!</p>
      <p>Our team works every day on the development of new NFTs intended to help Ukrainians. Thanks to your support, we have purchased and delivered over 500 kg of groceries to needy families during the last few days!</p>
      <p>Unfortunately, so many people in Ukrainian regions still need constant support from volunteers. They lost their homes and all their money, so they can&apos;t cope without our joint help! It is essential for these families to feel supported and that they are not alone with their problems.</p>
      <p>You are able to help them! It is enough just to purchase any art on our website. All funds raised from the sale of NFTs will go to necessary grocery and belongings for people in difficult situations.</p>
      <p>Thank you for your support and your big, full of kindness hearts!</p>
    </>
  }
  // {
  //   id: 3,
  //   image: imgNews3,
  //   cover: coverNews3,
  //   title: "",
  //   innerTitle: "",
  //   text: <>
  //
  //   </>
  // }
];
