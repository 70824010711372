import React, {useContext, useState} from "react";

import {Popup, PopupSubContentHeader, PopupSubContentText, PopupSubContentTitle} from "components/Popups";
import {Button} from "components/Button";
import {BuyPopup, IBuyPopupDataProps} from "components/Popups/BuyPopup";
import {useToggle} from "hooks/useToggle";
import {ResponsiveDesktop, ResponsiveMobile} from "components/Responsive";
import {OtherOffersPopup} from "components/Popups/OtherOffersPopup";

import S from "./style.module.scss";

interface IUsePopup {
  showPopup: (actionYes: Function) => void;
  showSuccessPurchasePopup: (data: IBuyPopupDataProps) => void;
}

const HocPopupContext = React.createContext<IUsePopup>({
  showPopup (actionYes: Function): void {
  },
  showSuccessPurchasePopup (data: IBuyPopupDataProps): void {
  }
});

HocPopupContext.displayName = "HocPopupContext";
const HocPopupContextProvide = HocPopupContext.Provider;

export function usePopup (): IUsePopup {
  const hocPopupData = useContext(HocPopupContext);
  if (hocPopupData === null) {
    throw new Error("Hook available only in nested components");
  }
  return hocPopupData;
}

interface IProps {
  children?: React.ReactNode;
}

export const PopupProvide = ({children}: IProps) => {
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState<boolean>(false);
  const [actionYes, setActionYes] = useState<Function>();

  const [isBuyPopup, , setBuyPopup] = useToggle(false);
  const [buyPopupData, setBuyPopupData] = useState<IBuyPopupDataProps>();

  const showSuccessPurchasePopup = (data: IBuyPopupDataProps) => {
    setBuyPopup(true);
    setBuyPopupData(data);
  };

  const showPopup = (callbackYes: Function) => {
    setIsShowConfirmPopup(true);
    setActionYes(() => callbackYes);
  };

  const onClose = () => {
    setIsShowConfirmPopup(false);
  };

  const [isOtherOffersPopup, setIsOtherOfferPopup] = useState<boolean>(false);
  const onCloseOffers = () => {
    setIsOtherOfferPopup(false);
  };

  return (
    <HocPopupContextProvide
      value={{
        showPopup,
        showSuccessPurchasePopup
      }}
    >
      {children}
      {isShowConfirmPopup &&
        <Popup
          name="#confirm"
          clickOnClose={onClose}
          maxWidth={"604px"}
        >
          <ResponsiveDesktop>
            <PopupSubContentHeader
              title={"Are you sure?"}
              className={S.Title}
            />
          </ResponsiveDesktop>
          <ResponsiveMobile>
            <PopupSubContentTitle setTop={"98px"}>
              Are you sure?
            </PopupSubContentTitle>
          </ResponsiveMobile>
          <PopupSubContentText children={"Do you agree this action?"}/>
          <div className={S.Buttons}>
            <Button
              color={"secondary"}
              onClick={onClose}
              title={"NO, CANCEL"}
            />
            <Button
              onClick={() => {
                actionYes && actionYes();
                onClose();
              }}
              title={"YES, I’M SURE"}
            />
          </div>
        </Popup>
      }
      {isBuyPopup &&
        <BuyPopup
          onClose={() => setBuyPopup(false)}
          data={buyPopupData}
        />
      }
      {/*NEW MODAL FOR ASSET PAGE > OTHER OFFERS*/}
      {isOtherOffersPopup &&
        <OtherOffersPopup clickOnClose={onCloseOffers}/>
      }
    </HocPopupContextProvide>
  );
};
