import React from "react";


import dorofeevaImg from "assets/img/charity/stars/dorofeeva.png";
import potapImg from "assets/img/charity/stars/potap.png";
import kamenskiImg from "assets/img/charity/stars/kamenski.png";
import polyakovaImg from "assets/img/charity/stars/polyakova.png";
import monatikImg from "assets/img/charity/stars/monatik.png";
import positifImg from "assets/img/charity/stars/positif.png";
import dornImg from "assets/img/charity/stars/dorn.png";
import efrosininaImg from "assets/img/charity/stars/efrosinina.png";
import dantesVImg from "assets/img/charity/stars/dantesV.png";
import durnevImg from "assets/img/charity/stars/durnev.png";
import osadchaImg from "assets/img/charity/stars/osadcha.png";
import {classNames} from "utils";

import S from "./style.module.scss";

const ListStars = [
  {
    img: dorofeevaImg,
    firstname: "",
    secondname: "DOROFEEVA",
    desc: "Ukrainian singer",
    subs: "5.2 M",
    link: "https://instagram.com/nadyadorofeeva?utm_medium=copy_link"
  },
  {
    img: potapImg,
    firstname: "",
    secondname: "POTAP",
    desc: "Singer/Producer",
    subs: "2 M",
    link: "https://instagram.com/realpotap?utm_medium=copy_link"
  },
  {
    img: kamenskiImg,
    firstname: "",
    secondname: "NK",
    desc: "Ukrainian singer",
    subs: "5.4 M",
    link: "https://instagram.com/kamenskux?utm_medium=copy_link"
  },
  {
    img: polyakovaImg,
    firstname: "Olya",
    secondname: "POLYAKOVA",
    desc: "Ukrainian singer",
    subs: "2.6 M",
    link: "https://instagram.com/polyakovamusic?utm_medium=copy_link"
  },
  {
    img: monatikImg,
    firstname: "",
    secondname: "MONATIK",
    desc: "Ukrainian singer",
    subs: "2.7 M",
    link: "https://instagram.com/monatik_official?utm_medium=copy_link"
  },
  {
    img: positifImg,
    firstname: "",
    secondname: "POSITIFF",
    desc: "Ukrainian singer",
    subs: "578 K",
    link: "https://instagram.com/positiff?utm_medium=copy_link"
  },
  {
    img: dornImg,
    firstname: "",
    secondname: "IVAN DORN",
    desc: "Ukrainian singer",
    subs: "845 K",
    link: "https://instagram.com/dorn_ivan?utm_medium=copy_link"
  },
  {
    img: efrosininaImg,
    firstname: "Masha",
    secondname: "EFROSININA",
    desc: "Actress • TV Host",
    subs: "2 M",
    link: "https://instagram.com/mashaefrosinina?utm_medium=copy_link"
  },
  {
    img: dantesVImg,
    firstname: "",
    secondname: "DANTES",
    desc: "Ukrainian singer",
    subs: "685 K",
    link: "https://instagram.com/vladimirdantes?utm_medium=copy_link"
  },
  {
    img: durnevImg,
    firstname: "Oleksii",
    secondname: "DURNEV",
    desc: "Ukrainian showman",
    subs: "768 K",
    link: "https://instagram.com/aleksey_durnev?utm_medium=copy_link"
  },
  {
    img: osadchaImg,
    firstname: "Kateryna",
    secondname: "OSADCHA",
    desc: "Tv presenter",
    subs: "1.8 M",
    link: "https://instagram.com/kosadcha?utm_medium=copy_link"
  },
];

export const CharityWorldStars = () => {
  return (
    <div className={classNames(S.CharityWorldStars, "CharityWrapper")}>
      <div className={classNames(S.InnerBefore, "CharityInnerWrapper")}>
        <div className={S.Title}>
          <span>HelpUA and Ukraine are supported by</span>
          <p>WORLD STARS AND FAMOUS PEOPLE</p>
        </div>
        <ul className={S.ListStars}>
          {ListStars.map((item, index) => (
            <li key={index}>
              <a
                href={item.link}
                className={S.Item}
                target="_blank"
              >
                <span className={S.Img}><img
                  src={item.img}
                  alt=""
                /></span>
                <span className={S.Info}>
                  <span className={S.Left}>
                    <span className={S.FirstName}>{item.firstname}</span>
                    <span className={S.SecondName}>{item.secondname}</span>
                    <span className={S.Desc}>{item.desc}</span>
                  </span>
                  <span className={S.Right}>
                    <span className={S.Ico}>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9375 5.06885C7.97456 5.06885 5.51953 7.48155 5.51953 10.4868C5.51953 13.4921 7.93223 15.9048 10.9375 15.9048C13.9428 15.9048 16.3555 13.4498 16.3555 10.4868C16.3555 7.52387 13.9005 5.06885 10.9375 5.06885ZM10.9375 13.9577C9.03276 13.9577 7.46662 12.3916 7.46662 10.4868C7.46662 8.58207 9.03276 7.01594 10.9375 7.01594C12.8423 7.01594 14.4084 8.58207 14.4084 10.4868C14.4084 12.3916 12.8423 13.9577 10.9375 13.9577Z"
                          fill="black"
                        />
                        <path
                          d="M16.5674 6.16938C17.2453 6.16938 17.7949 5.61981 17.7949 4.94187C17.7949 4.26393 17.2453 3.71436 16.5674 3.71436C15.8894 3.71436 15.3398 4.26393 15.3398 4.94187C15.3398 5.61981 15.8894 6.16938 16.5674 6.16938Z"
                          fill="black"
                        />
                        <path
                          d="M19.741 1.76719C18.6405 0.624331 17.0744 0.0317383 15.2966 0.0317383H6.57702C2.89448 0.0317383 0.439453 2.48676 0.439453 6.1693V14.8466C0.439453 16.6667 1.03205 18.2328 2.21723 19.3757C3.36009 20.4762 4.8839 21.0264 6.61935 21.0264H15.2543C17.0744 21.0264 18.5982 20.4339 19.6987 19.3757C20.8416 18.2751 21.4342 16.709 21.4342 14.8889V6.1693C21.4342 4.39153 20.8416 2.86772 19.741 1.76719ZM19.5717 14.8889C19.5717 16.2011 19.1061 17.2593 18.3442 17.9788C17.5823 18.6984 16.5241 19.0794 15.2543 19.0794H6.61935C5.34951 19.0794 4.2913 18.6984 3.5294 17.9788C2.76749 17.2169 2.38654 16.1587 2.38654 14.8466V6.1693C2.38654 4.89946 2.76749 3.84126 3.5294 3.07936C4.24898 2.35978 5.34951 1.97883 6.61935 1.97883H15.3389C16.6088 1.97883 17.667 2.35978 18.4289 3.12169C19.1484 3.88359 19.5717 4.94179 19.5717 6.1693V14.8889Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <span className={S.Subs}>{item.subs}</span>
                  </span>
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
