import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {classNames, errorChecker} from "utils";
import {IGoalDocument, useApi} from "api";
import {useTheme} from "hooks/useTheme";
import {CheckMarkAvatar} from "assets/icons/CheckMarkAvatar";

import S from "./style.module.scss";

interface IProps {
  className?: string;
}

export const SupportHeader = (
  {
    className
  }: IProps) => {
  const {api} = useApi();
  const {id} = useParams();
  const [isGoals, setGoals] = useState<IGoalDocument>();
  const [isPrice, setPrice] = useState<number>(0);
  const [isProgress, setProgress] = useState<number>(0);

  const [isShortText, setIsShortText] = useState<boolean>(true);
  const onClickReadMore = () => setIsShortText(!isShortText);
  const textMaxLength = 170;

  const theme = useTheme();

  const requestData = useCallback(async () => {
    try {
      let result = await api.goalsId.getGoalsId(id);
      setGoals(result);
      setPrice(result.goalPrice);
      setProgress(result.progressPrice);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.goalsId, id]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const [isPercent, sePercent] = useState<number>(0);

  useEffect(() => {
    let percent = Math.round(isProgress / isPrice * 100);
    sePercent(percent > 100 ? 100 : percent);
  }, [isPrice, isProgress]);

  return (
    <>
      <div className={classNames(S.Frame, S[theme])}>
        <img
          src={isGoals?.photo}
          alt={isGoals?.name}
        />
      </div>
      <div className={S.SupportWrapper}>
        <div className={S.PhotoWrapper}>
          <span style={{backgroundImage: `url(${isGoals?.thumbnailPhoto})`}}/>
          <CheckMarkAvatar className={S.CheckMark}/>
        </div>
        <div className={S.Title}>{isGoals?.name}</div>
        <div className={S.Status}>
          <div className={S.Count}>{isPercent}% ( ${isProgress}/${isPrice})</div>
          <div className={S.Load}><span style={{width: `${isPercent}%`}}/></div>
        </div>
        <div className={classNames(S.TextRow, S[theme])}>
          {isGoals?.description?.substring(
            0,
            (isShortText && isGoals?.description?.length >= textMaxLength ? textMaxLength : 999999)
          )}
          {isShortText && isGoals?.description && isGoals?.description?.length >= textMaxLength ? "..." : ""}
        </div>
        {(isGoals?.description && isGoals?.description?.length >= textMaxLength) &&
          <span
            onClick={onClickReadMore}
            className={classNames(S.MoreButton, S[theme])}
          >
            {isShortText ? "Read more" : "Show less"}
          </span>
        }
      </div>
    </>
  );
};
