import React from "react";

import {useWeb3Contract} from "providers/Web3Contract";
import {ProfileMyBidsAuctionBlockStatus} from "components/Profile/ProfileMyBids/AuctionStatus";
import {UserBlock} from "components/UserBlock";
import {
  ProfileMyBidsAuctionBlockYourBid,
  ProfileMyBidsAuctionBlockYourBidTime
} from "components/Profile/ProfileMyBids/YourBid";
import {ProfileMyBidsAuctionBlockButtons} from "components/Profile/ProfileMyBids/Buttons";
import {ProfileMyBidsAuctionBlockAssetPreview} from "components/Profile/ProfileMyBids/AssetPreview";

import S from "./style.module.scss";

export const ProfileBidsBlock = () => {
  const {auction} = useWeb3Contract();

  return (
    <tr>
      <td>
        <div>
          <ProfileMyBidsAuctionBlockAssetPreview/>
        </div>
      </td>
      <td>
        <div>
          <UserBlock
            id={auction?.beneficiaryUser.id}
            thumbnailPhoto={auction?.beneficiaryUser.thumbnailPhoto}
            title={"Seller:"}
            displayName={auction?.beneficiaryUser.displayName}
            classNameTitle={"!text-xs"}
            classNameUserName={"!max-w-[110px] !text-xs"}
          />
        </div>
      </td>
      <td>
        <div>
          <ProfileMyBidsAuctionBlockStatus/>
        </div>
      </td>
      <td>
        <div>
          <ProfileMyBidsAuctionBlockYourBid/>
          <ProfileMyBidsAuctionBlockYourBidTime/>
        </div>
      </td>
      <td align={"right"}>
        <div>
          <ProfileMyBidsAuctionBlockButtons/>
        </div>
      </td>
    </tr>
  );
};
