import React from "react";

import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

interface IProps {

}

export const HelpCenterPageTermsOfUse = (props: IProps) => {
  return (
    <>
      <HelpCenterPageHeader
        title={"Terms of use"}
      />
    </>
  );
};
