import React from "react";
import ContentLoader from "react-content-loader";

import S from "./style.module.scss";

interface IProps {
  width?: number;
  height?: number;
}

export const CardSkeleton = ({width, height} : IProps) => (
  <div className={S.CardSkeleton}>
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="calc(100% - 102px)" />
      <rect x="24" y="calc(100% - 122px)" rx="50%" ry="50%" width="40" height="40" />
      <rect x="24" y="calc(100% - 70px)" rx="0" ry="0" width="44%" height="17" />
      <rect x="calc(100% - 108px)" y="calc(100% - 70px)" rx="0" ry="0" width="84" height="17" />
      <rect x="24" y="calc(100% - 38px)" rx="0" ry="0" width="38%" height="17" />
      <rect x="calc(100% - 88px)" y="calc(100% - 38px)" rx="0" ry="0" width="64" height="17" />
    </ContentLoader>
  </div>
);

export const CardSkeletonResponsive = () => (
  <div className={S.CardSkeleton}>
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{position: "absolute", top: "0", left: "0", maxWidth: "100%"}}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="calc(100% - 102px)" />
      <rect x="24" y="calc(100% - 122px)" rx="50%" ry="50%" width="40" height="40" />
      <rect x="24" y="calc(100% - 70px)" rx="0" ry="0" width="44%" height="17" />
      <rect x="calc(100% - 108px)" y="calc(100% - 70px)" rx="0" ry="0" width="84" height="17" />
      <rect x="24" y="calc(100% - 38px)" rx="0" ry="0" width="38%" height="17" />
      <rect x="calc(100% - 88px)" y="calc(100% - 38px)" rx="0" ry="0" width="64" height="17" />
    </ContentLoader>
  </div>
);
