import React from "react";

import {Button} from "components/Button";

import {PopupSubContentText, PopupSubContentTitle} from "../index";

import S from "./style.module.scss";

interface IProps {
  onClickClose: () => void;
  onClickSubmitButton: () => void;
  networkName?: string;
  activeProviderName?: string;
}

export const ChangeIdPopup = ({
  onClickClose,
  onClickSubmitButton,
  networkName,
  activeProviderName
}: IProps) => {
  return (
    <div className={S.ChangeIdWrapper}>
      <PopupSubContentTitle position={"center"}>
        <h2>Please switch to <span>{networkName}</span> network</h2>
      </PopupSubContentTitle>
      <PopupSubContentText position={"center"}>
        In order to trade items, please switch to <b>{networkName}</b> network within your <b>{activeProviderName}</b> wallet.
      </PopupSubContentText>
      <div className={S.PopupButtons}>
        <Button
          className={S.Clear}
          type={"button"}
          title={"Cancel"}
          onClick={onClickClose}
        />
        <Button
          type={"button"}
          title={"Switch network"}
          onClick={onClickSubmitButton}
        />
      </div>
    </div>
  );
};
