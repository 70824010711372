import React from "react";
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";

import "./i18n";
import "./tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {AppWrapper} from "AppWrapper";

render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWrapper/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
