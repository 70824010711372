import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {
}

export const IconArrowSliderCollection = (
  {
    size = 22,
    fill = "#000",
    className
  }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.25 4.90283L20.75 5.09783"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 4.90283L4.2125 8.68283"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.25 4.90262L4.2875 1.18262"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
