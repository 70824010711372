import React, {memo, useCallback, useContext, useState} from "react";
import isEqual from "react-fast-compare";

import {IAsset, TAddress, TCryptoCurrency} from "types";
import {useWeb3} from "providers/Web3";
import {
  IERC20Pipe,
  IGasPipe,
  IWeb3ButItem,
  IWeb3CancelAuction,
  IWeb3CancelMarket, IWeb3CreateAsset,
  IWeb3MarkForSale,
  IWeb3Withdraw
} from "pages/Asset/types";
import {useDebug} from "providers/Debug";
import {showNotification} from "components/Notification";
import {errorChecker} from "utils";
import {useApi} from "api";
import {usePrice, useStore} from "providers";
import {TSaleType} from "pages/Asset/Asset";
import {Web3ContractPopupProvider} from "providers/Web3Contract/popup";
import {IAuction, IBid} from "components/Home/types";
import {useTheme} from "hooks/useTheme";

interface IUseWeb3Contract {
  buyItem: (payload: IWeb3ButItem) => Promise<void>;
  buyNowAuctionItem: (payload: IWeb3ButItem) => Promise<void>;
  addItemsForMarket: (
    payload: IWeb3MarkForSale,
    marketplace1155: any,
    marketplaceAddress: any,
    weiPrice: any
  ) => Promise<void>;
  cancelMarketplace: (payload: IWeb3CancelMarket) => Promise<void>;
  addItemForAuction: (
    payload: IWeb3MarkForSale,
    marketplace1155: any,
    marketplaceAddress: any,
    weiPrice: any
  ) => Promise<void>;
  placeBid: (payload: IWeb3CancelAuction) => Promise<void>;
  withdraw: (payload: IWeb3Withdraw) => Promise<void>;
  cancelAuction: (payload: IWeb3CancelAuction) => Promise<void>;
  endAuction: (payload: IWeb3CancelAuction) => Promise<void>;
  markForSale: (payload: IWeb3MarkForSale) => Promise<void>;
  setSaleType: (value: TSaleType) => void;
  createAsset: (payload: IWeb3CreateAsset) => Promise<void>;
  symbol: TCryptoCurrency;
  asset?: IAsset;
  auction?: IAuction;
  bid?: IBid;
}

export const Web3ContractContext = React.createContext<IUseWeb3Contract>({
  addItemForAuction () {
    return Promise.resolve(undefined);
  },
  addItemsForMarket () {
    return Promise.resolve(undefined);
  },
  buyItem () {
    return Promise.resolve(undefined);
  },
  buyNowAuctionItem () {
    return Promise.resolve(undefined);
  },
  cancelAuction () {
    return Promise.resolve(undefined);
  },
  cancelMarketplace () {
    return Promise.resolve(undefined);
  },
  endAuction () {
    return Promise.resolve(undefined);
  },
  markForSale () {
    return Promise.resolve(undefined);
  },
  placeBid () {
    return Promise.resolve(undefined);
  },
  withdraw () {
    return Promise.resolve(undefined);
  },
  setSaleType () {
  },
  createAsset () {
    return Promise.resolve(undefined);
  },
  symbol: "",
  asset: undefined,
  auction: undefined,
  bid: undefined
});
Web3ContractContext.displayName = "Web3ContractContext";

export function useWeb3Contract (): IUseWeb3Contract {
  const Web3ContractData = useContext(Web3ContractContext);
  if (Web3ContractData === null) {
    throw new Error("Web3ContractData");
  }
  return Web3ContractData;
}

interface IProps {
  chainId?: number;
  chain?: string;
  tokenId?: number;
  tokenAddress?: TAddress;
  symbol?: TCryptoCurrency;
  asset?: IAsset;
  auction?: IAuction;
  bid?: IBid;
  children: React.ReactNode;
  extraCallback?: Function;
}

export const Web3ContractProvider = memo(({
  chainId,
  chain,
  tokenId,
  tokenAddress,
  symbol = "",
  asset,
  auction,
  bid,
  children,
  extraCallback
}: IProps) => {
  const {web3, isCheckUserChain, getBalance, web3State, toWei} = useWeb3();
  const {chainList} = usePrice();
  const {api} = useApi();
  const {setIsLoader} = useStore();
  const {setIsAlerts} = useStore();
  const theme = useTheme();

  const {debug} = useDebug();

  const [saleType, setSaleType] = useState<TSaleType>();

  const requestCreateABI = useCallback(async (): Promise<{ abi?: any, address?: any }> => {
    try {
      const res = await api.marketplace.getERC1155({
        chain: chainList[Number(web3State?.chainId)]
      });

      return {abi: res.abi, address: res.networks[String(web3State?.networkId)].address};
    } catch (err) {
      errorChecker(err);
    }

    return {};
  }, [api.marketplace, chainList, web3State?.chainId, web3State?.networkId]);

  const requestABI = useCallback(async (currencyAddress?: IWeb3ButItem["currencyAddress"]): Promise<any> => {
    try {
      if (!!currencyAddress) {
        const res = await api.marketplace.getMarketplace1155ERC20({
          chain: chain
        });

        return res?.abi;
      } else {
        const res = await api.marketplace.getMarketplace1155({
          chain: chain
        });

        return res?.abi;
      }
    } catch (err) {
      errorChecker(err);
    }
  }, [api.marketplace, chain]);

  const showRequestNotification = () => {
    showNotification({
      title: "Request sent",
      subtitle: "Wait to confirmation"
    });
    setIsAlerts(false);
    setIsLoader(true);
  };

  const hideRequestNotification = () => {
    setIsLoader(false);
  };

  const successBuyItem = (receipt: any, title = `Your block hash`) => {
    hideRequestNotification();
    showNotification({
      title: "Success",
      subtitle: `${title} ${receipt.blockHash}`
    });
  };

  const buySuccess = (receipt: any, title = `Your block hash`) => {
    debug({title: "Web3Contract-buySuccess-receipt", desc: receipt});

    hideRequestNotification();
    showNotification({
      title: "Success",
      subtitle: `${title} ${receipt.blockHash}`
    });
  };

  const buyError = (error: any) => {
    hideRequestNotification();
    errorChecker(error);
  };

  const ERC20Pipe = async ({
    currencyAddress,
    address
  }: IERC20Pipe) => {
    if (!!currencyAddress) {
      const abiERC20 = await api.marketplace.getERC20({chain, currencyAddress});

      const ContractMethodERC20 = await new web3.eth.Contract(abiERC20.abi || [], currencyAddress);

      const allowed = await ContractMethodERC20.methods.allowance(web3State?.wallet, address).call();

      if (allowed === "0") {
        const totalSupply = await ContractMethodERC20.methods.totalSupply().call();
        await ContractMethodERC20.methods.approve(address, totalSupply).send({from: web3State?.wallet});
      }
    }
  };

  const gasPipe = async ({
    ContractMethod,
    value,
    successFunction = () => {
    },
    currencyAddress
  }: IGasPipe): Promise<void> => {
    debug({title: "Web3Contract-gasPipe-chain", desc: web3State?.chainId});
    debug({title: "Web3Contract-gasPipe-price", desc: value});

    if (!!currencyAddress) {
      await ContractMethod
        .send({
          from: web3State?.wallet
        }, showRequestNotification)
        .once("receipt", successFunction);
    } else {
      const gasPrice = await web3.eth.getGasPrice();
      debug({title: "Web3Contract-gasPipe-gasPrice", desc: gasPrice});

      if (value) {
        const gasLimit = await ContractMethod.estimateGas({from: web3State?.wallet, value});
        debug({title: "Web3Contract-gasPipe-gasLimit", desc: gasLimit});

        await ContractMethod
          .send({
            from: web3State?.wallet,
            value,
            gasPrice,
            gasLimit
          }, showRequestNotification)
          .once("receipt", successFunction);
      } else {
        await ContractMethod
          .send({
            from: web3State?.wallet,
            gasPrice
          }, showRequestNotification)
          .once("receipt", successFunction);
      }
    }

    await getBalance(web3State?.wallet || "");
  };

  const buyItem = async ({price, amount = 1, marketplaceContractId, address, currencyAddress}: IWeb3ButItem) => {
    try {
      await isCheckUserChain(Number(chainId));

      const abi = await requestABI(currencyAddress);

      await ERC20Pipe({currencyAddress, address});

      if (!!currencyAddress) {
        const ContractMethod = await new web3.eth.Contract(abi || [], address).methods.buyItems(
          marketplaceContractId,
          amount,
          currencyAddress
        );

        await gasPipe({ContractMethod, successFunction: successBuyItem, currencyAddress});
      } else {
        let mulPrice = await api.asset.actionWithNumber({
          firstOperand: String(price),
          secondOperand: String(amount),
          operation: "mul"
        });

        const ContractMethod = await new web3.eth.Contract(abi || [], address).methods.buyItems(
          marketplaceContractId,
          amount
        );

        await gasPipe({ContractMethod, value: mulPrice.result, successFunction: successBuyItem});
      }

    } catch (err) {
      buyError(err);
    }
  };

  const buyNowAuctionItem = async ({price, auctionId, address, currencyAddress}: IWeb3ButItem) => {
    try {
      await isCheckUserChain(Number(chainId));

      const abi = await requestABI(currencyAddress);

      const ContractMethod = await new web3.eth.Contract(
        abi || [],
        address
      ).methods.buyImmediately(
        auctionId
      );

      await gasPipe({ContractMethod, value: price, successFunction: successBuyItem});

    } catch (err) {
      buyError(err);
    }
  };

  const addItemsForMarket = async (
    payload: IWeb3MarkForSale,
    abi: any,
    marketplaceAddress: any,
    weiPrice: string
  ) => {
    debug({title: "Web3Contract-addItemsForMarket-START"});

    try {
      const {amount = 1} = payload;
      await isCheckUserChain(Number(chainId));

      let ContractMethod = await new web3.eth.Contract(abi || [], marketplaceAddress)
        .methods
        .addItemsForMarket(tokenId, tokenAddress, weiPrice, amount);

      debug({title: "Web3Contract-addItemsForMarket-ContractMethod", desc: ContractMethod});

      await gasPipe({ContractMethod, successFunction: buySuccess});

      extraCallback && extraCallback();
      debug({title: "Web3Contract-cancelMarketplace-SUCCESS"});
    } catch (err) {
      debug({title: "Web3Contract-addItemsForMarket-ERROR"});
      throw err;
    }
  };

  const cancelMarketplace = async (payload: IWeb3CancelMarket) => {
    setIsAlerts(true);

    try {
      debug({title: "Web3Contract-cancelMarketplace-START"});

      await isCheckUserChain(Number(chainId));

      const build = await api.marketplace.getBuild(payload.marketplaceAddress, {
        chain: chain
      });

      let ContractMethod = await new web3.eth.Contract(build?.abi || [], payload.marketplaceAddress)
        .methods
        .cancelMarketplace(payload.marketplaceId);

      await gasPipe({ContractMethod, successFunction: buySuccess});

      extraCallback && extraCallback();
      debug({title: "Web3Contract-cancelMarketplace-SUCCESS"});
    } catch (err) {
      debug({title: "Web3Contract-cancelMarketplace-ERROR"});
      buyError(err);
      setIsAlerts(false);
    }
  };

  const addItemForAuction = async (
    payload: IWeb3MarkForSale,
    abi: any,
    marketplaceAddress: any,
    weiPrice: any
  ) => {
    debug({title: "Web3Contract-addItemForAuction-START"});

    try {
      await isCheckUserChain(Number(chainId));

      const maxAuctionOffset = 31536000 * 1000;

      // no more than 1 year by now time > maxAuctionOffset;
      const auctionStartOffset = (payload.auctionStartOffset || 0) > 0 ? payload.auctionStartOffset : 0;
      // no more than 1 year date from start auction > maxAuctionOffset;
      const auctionEndOffset = (payload.auctionEndOffset || 0) > 0 ? payload.auctionEndOffset : 36000;

      const payloadAuction = {
        tokenId: tokenId,
        tokenAddress: tokenAddress || "",
        minimumBid: weiPrice,
        amount: payload.amount,
        immediatePrice: toWei({price: payload.immediatePrice || "0"}),
        auctionStartOffset,
        auctionEndOffset,
      };

      debug({title: "Web3Contract-addItemForAuction-payloadAuction", desc: payloadAuction});

      const ContractMethod = await new web3.eth.Contract(abi || [], marketplaceAddress)
        .methods
        .addItemForAuction(payloadAuction);

      await gasPipe({ContractMethod, successFunction: buySuccess});

      extraCallback && extraCallback();
      debug({title: "Web3Contract-addItemForAuction-SUCCESS"});
    } catch (err) {
      debug({title: "Web3Contract-addItemForAuction-ERROR"});
      throw err;
    }
  };

  const placeBid = async (payload: IWeb3CancelAuction) => {
    const {price, auctionAddress, auctionId, currencyAddress} = payload;

    try {
      await isCheckUserChain(Number(chainId));

      const abi = await requestABI(currencyAddress);

      const ContractMethod = await new web3.eth.Contract(abi || [], auctionAddress)
        .methods
        .placeBid(auctionId);

      await gasPipe({ContractMethod, value: toWei({price})});
    } catch (err) {
      buyError(err);
    }
  };

  const withdraw = async (payload: IWeb3Withdraw) => {
    const {auctionAddress, auctionId, currencyAddress} = payload;

    debug({title: "Web3Contract-withdraw-payload", desc: payload});

    try {
      await isCheckUserChain(chainId || 0);

      const abi = await requestABI(currencyAddress);

      const ContractMethod = await new web3.eth.Contract(abi || [], auctionAddress)
        .methods
        .withdraw(auctionId);

      await gasPipe({ContractMethod});
    } catch (err) {
      buyError(err);
    }
  };

  const cancelAuction = async (payload: IWeb3CancelAuction) => {
    try {
      await isCheckUserChain(Number(chainId));

      const build = await api.marketplace.getBuild(payload.auctionAddress, {
        chain: chain
      });

      const ContractMethod = await new web3.eth.Contract(build.abi, payload.auctionAddress)
        .methods
        .cancelAuction(payload.auctionId);

      await gasPipe({ContractMethod, successFunction: buySuccess});

      extraCallback && extraCallback();
    } catch (err) {
      buyError(err);
    }
  };

  const endAuction = async (payload: IWeb3CancelAuction) => {
    const {auctionAddress, auctionId, currencyAddress} = payload;

    try {
      await isCheckUserChain(Number(chainId));

      const abi = await requestABI(currencyAddress);

      const ContractMethod = await new web3.eth.Contract(abi || [], auctionAddress)
        .methods
        .endAuction(auctionId);

      await gasPipe({ContractMethod, successFunction: (receipt: any) => buySuccess(receipt, "Your auction ended")});

      extraCallback && extraCallback();
    } catch (err) {
      buyError(err);
    }
  };

  const markForSale = async (payload: IWeb3MarkForSale) => {
    debug({title: "Web3Contract-markForSale-START"});
    debug({title: "Web3Contract-markForSale-payload", desc: payload});

    try {
      await isCheckUserChain(Number(chainId));

      const weiPrice = toWei({price: payload.price});

      debug({title: "Web3Contract-markForSale-weiPrice", desc: weiPrice});

      const {abi, networks} = await api.marketplace.getMarketplace1155({
        chain: chain
      });

      debug({title: "Web3Contract-markForSale-abi", desc: abi});

      const marketPlaceAddress = networks[Number(web3State?.networkId)].address;

      debug({title: "Web3Contract-markForSale-marketPlaceAddress", desc: marketPlaceAddress});

      const buildAddress = await api.marketplace.getBuild(tokenAddress || "", {
        chain: chain
      });

      debug({title: "Web3Contract-markForSale-buildAddress", desc: buildAddress});

      const tokenContract = new web3.eth.Contract(
        buildAddress.abi,
        tokenAddress
      );

      // Check of this asset already on marketplace
      const isApproved =
        await tokenContract.methods
          .isApprovedForAll(web3State?.wallet, marketPlaceAddress)
          .call();

      debug({title: "Web3Contract-markForSale-isApproved", desc: isApproved});

      if (!isApproved) {
        // Set property to use this asset on marketplace action
        await tokenContract.methods
          .setApprovalForAll(marketPlaceAddress, true)
          .send({from: web3State?.wallet});
      }

      if (saleType === "Auction") {
        await addItemForAuction(payload, abi, marketPlaceAddress, weiPrice);
      } else {
        await addItemsForMarket(payload, abi, marketPlaceAddress, weiPrice);
      }

      debug({title: "Web3Contract-markForSale-SUCCESS"});
    } catch (err) {
      debug({title: "Web3Contract-markForSale-ERROR"});
      buyError(err);
    }
  };

  const createAsset = async ({
    file,
    cover,
    title,
    description,
    ipfsType,
    royaltyPercentage,
    initialAmount,
    category,
    metadataCollection
  }: IWeb3CreateAsset) => {
    debug({title: "Web3Contract-createAsset-START"});

    const formData = new FormData();

    if (file) {
      formData.append("file", file, `${file?.name}`);
    }
    formData.append("wallet_address", String(web3State?.wallet));
    formData.append("title", title);
    formData.append("description", description);
    formData.append("ipfs_type", ipfsType || "other");
    formData.append("marketplace_name", theme);
    formData.append("nft_categories", String(category));
    if (metadataCollection) {
      formData.append("metadata_collection", String(metadataCollection));
    }
    if (cover) {
      formData.append("cover", cover, `${cover.name}`);
    }

    try {
      debug({title: "Web3Contract-createAsset-START-BE-CreateAsset"});

      const res = await api.asset.createAsset(formData);

      debug({title: "Web3Contract-createAsset-START-BE-CreateABI"});

      const {abi, address} = await requestCreateABI();

      debug({title: "Web3Contract-createAsset-START-Contract"});

      const ContractMethod = await new web3.eth.Contract(abi || [], address)
        .methods
        .createAsset(res.IpfsHash, royaltyPercentage * 10, initialAmount);

      debug({title: "Web3Contract-createAsset-START-GasPipe"});

      await gasPipe({ContractMethod});
      debug({title: "Web3Contract-createAsset-SUCCESS"});
    } catch (err) {
      debug({title: "Web3Contract-createAsset-ERROR"});
      buyError(err);
      throw new Error("");
    }
  };

  return (
    <Web3ContractContext.Provider
      value={{
        buyItem,
        addItemsForMarket,
        addItemForAuction,
        buyNowAuctionItem,
        cancelMarketplace,
        placeBid,
        withdraw,
        cancelAuction,
        endAuction,
        markForSale,
        setSaleType,
        createAsset,
        symbol,
        asset,
        auction,
        bid
      }}
    >
      <Web3ContractPopupProvider>
        {children}
      </Web3ContractPopupProvider>
    </Web3ContractContext.Provider>
  );
}, isEqual);
