import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

import {IAsset} from "types";
import {IAuction, IAvailable} from "components/Home/types";

const recalculateObject = (auction: IAuction): IAuction => {
  const differentInMillisecondsTimer = differenceInMilliseconds(
    new Date(auction?.auctionEnd || 0),
    new Date()
  );
  const isEndedTimer = (differentInMillisecondsTimer || -1) < 0;

  return {
    ...auction,
    isEndedTimer,
    differentInMillisecondsTimer: differentInMillisecondsTimer
  };
};

export const availableAuctionCheckService = (payload: IAvailable): IAvailable => {
  if (payload.auctions.length > 0) {
    return {
      ...payload, auctions: payload.auctions.map((el) => {
        return {
          ...el,
          ...recalculateObject(el)
        };
      })
    };
  }

  return payload;
};

export const assetAuctionCheckService = (payload: IAsset): IAsset => {
  if (payload.currentAuction) {
    return {
      ...payload,
      currentAuction: {
        ...payload.currentAuction,
        ...recalculateObject(payload.currentAuction)
      }
    };
  }

  return payload;
};

export const resultsAuctionCheckService = (payload: any) => {
  return {
    ...payload, results: payload.results?.map((el: any) => {
      if (el.auction) {
        return {
          ...el,
          auction: {
            ...el.auction,
            ...recalculateObject(el.auction)
          }
        };
      }

      return el;
    })
  };
};
