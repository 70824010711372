import React from "react";

import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

interface IProps {

}

export const HelpCenterPageSearch = (props: IProps) => {
  return (
    <>
      <HelpCenterPageHeader
        title={"{Some amount of result} results for \"wallet\" in All Categories"}
      />
      // I have many questions about this page <br/>
      // They create after I first time see design and not discussion with me, need some update to view on this page and global change logic this page before create this layout
    </>
  );
};
