import React, {FormEvent, useCallback, useEffect, useState} from "react";

import {Input} from "components/Input";
import {Textarea} from "components/Textarea";
import {CustomSelect} from "components/Select";
import {Button} from "components/Button";
import {PopupCreateCollection} from "components/Popups/CreateCollection";
import {useCreateAsset} from "providers/CreateAsset";
import {useApi} from "api";
import {errorChecker} from "utils";
import {useMyUser} from "providers";
import {StepImage} from "components/CreateNft/StepContent/StepImage";

import S from "./style.module.scss";

export const StepForm = () => {
  const {api} = useApi();
  const {user} = useMyUser();
  const {categories, requestCreateAsset, isLoading, ipfsType, ipfsExtends, cover} = useCreateAsset();

  const [isPopup, setPopup] = useState<boolean>(false);
  const [collections, setCollections] = useState<any[]>([]);
  const [errorCategory, setErrorCategory] = useState<string>("");
  const [errorCoverText, setErrorCoverText] = useState<string>("");

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    setErrorCategory("");
    const formData = new FormData(event.target as HTMLFormElement);
    // @ts-ignore
    const formProps = Object.fromEntries(formData);

    if (!formProps.category) {
      setErrorCategory("Required field");
      return;
    }

    if ((ipfsType !== "image" || ipfsExtends === "gif") && cover === undefined) {
      setErrorCoverText("Required field");
      return;
    }

    requestCreateAsset({
      title: formProps.title,
      description: formProps.description,
      royaltyPercentage: Number(formProps.royaltyPercentage),
      category: Number(formProps.category),
      initialAmount: Number(formProps.initialAmount),
      metadataCollection: formProps.collection
    });
  };


  const requestCollectionList = useCallback(async () => {
    try {
      const res = await api.collections.getCollections({
        ownerId: user.id
      });
      setCollections(res.results);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.collections, user.id]);

  useEffect(() => {
    requestCollectionList();
  }, [requestCollectionList]);

  return (
    <>
      <form
        className={"relative flex w-full gap-x-5 gap-y-10 justify-between max768:flex-col"}
        onSubmit={onSubmit}
      >
        <div className={"relative block w-full max-w-[432px] max768:max-w-full **:mt-4"}>
          <fieldset disabled={isLoading} className={"div:relative div:block div:w-full"}>
            <div>
              <Input
                name={"title"}
                label={"Name"}
                type={"text"}
                placeholder={"e.g. Priority pass, ticket, NFT, etc."}
                required={true}
              />
            </div>
            <div>
              <Textarea
                label={"Description"}
                name={"description"}
                placeholder={"e.g. After purchase you’ll be able to get Priority Pass or etc."}
                minRows={6}
                maxRows={12}
                required={true}
              />
            </div>
            <div>
              <Input
                name={"royaltyPercentage"}
                label={"Royalties (At the time of the resale)"}
                type={"number"}
                min={1}
                max={50}
                required={true}
              >
                <p className={"mt-3 text-text_description text-xs leading-4"}>
                  Suggested: 0%, 10%, 20%, 30%. Maximum is 50%
                </p>
                <i className={"absolute flex w-10 h-[42px] items-center justify-center top-5 right-0 text-text_description text-base not-italic"}>%</i>
              </Input>
            </div>
            <div>
              <CustomSelect
                name={"category"}
                label={"Choose category"}
                className={"w-full cursor-pointer"}
                options={categories}
                styleValue={{paddingLeft: "21px"}}
                styleControl={{borderRadius: "7px"}}
                optionLabel={"name"}
                optionValue={"id"}
                error={errorCategory}
              />
            </div>
            <div>
              <Input
                name={"initialAmount"}
                label={"Amount (count of NFT copies)"}
                type={"number"}
                min={1}
                required={true}
              />
            </div>
            <div>
              <CustomSelect
                name={"collection"}
                label={"Collection"}
                className={S.Select}
                options={collections}
                placeholder={"Choose collection"}
                styleValue={{paddingLeft: "21px"}}
                styleControl={{borderRadius: "7px"}}
                optionValue={"id"}
                optionLabel={"name"}
              />
            </div>
            <div>
              <Button
                children={"Create New Collection"}
                color={"secondary"}
                className={S.CollButton}
                classNameText={S.CollButtonText}
                onClick={() => setPopup(true)}
              />
            </div>
            <div>
              <Button
                type={"submit"}
                className={S.CreateButton}
                classNameText={S.CreateButtonText}
                children={"Create NFT"}
              />
            </div>
          </fieldset>
        </div>
        <StepImage
          errorCoverText={errorCoverText}
          setErrorCoverText={setErrorCoverText}
        />
      </form>
      {isPopup && (
        <PopupCreateCollection
          onClose={() => setPopup(false)}
          updateCollectionList={requestCollectionList}
        />
      )}
    </>
  );
};
