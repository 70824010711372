import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// @ts-ignore
import SlideToggle from "react-slide-toggle";

import {PlusIconNoPadding} from "assets/icons/PlusIcon";
import {Button} from "components/Button";
import {BurgerCrossIconNoPadding} from "assets/icons/BurgerCrossIcon";
import {IUser} from "types";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  onWalletClick: () => void;
  auth: boolean;
  onClose: () => void;
  onLogout: () => void;
  user?: IUser;
  navigationList?: any[];
  classStatus?: boolean;
}

const NavigationChildren = ({item, onClose}: { item: any, onClose: () => void }) => {
  return (
    <li
      className={classNames(
        item.active === false ? S.DeActive : "",
        location.pathname === item.link ? S.ActiveLi : ""
      )}
    >
      {item.target ? (
        <a
          href={item.link}
          target={"_blank"}
          onClick={onClose}
        >{item.title}</a>
      ) : (
        <Link
          to={item.link}
          onClick={onClose}
        >{item.title}</Link>
      )}
    </li>
  );
};

export const BurgerMenu = ({
  onWalletClick,
  auth,
  onClose,
  navigationList,
  classStatus
}: IProps) => {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <div className={classNames(S.DropWrapper, S[theme], classStatus ? S.OpenAnimate : S.CloseAnimate)}>
      <div className={S.DropHead}>
        <BurgerCrossIconNoPadding onClick={onClose}/>
      </div>
      <div className={S.DropBody}>
        <div className={S.DropBodyScrolled}>
          <div className={S.List}>
            <ul className={S.NaviList}>
              {navigationList?.map((navigation, navigationIndex) => {
                return (
                  <li
                    className={location.pathname == navigation.link ? S.ActiveLi : ""}
                    key={navigationIndex}
                  >
                    {navigation.children ? (
                      <>
                        <SlideToggle
                          duration={200}
                          collapsed={false}
                          render={({toggle, setCollapsibleElement, toggleState}: any) => (
                            <div className={S.SlideToggleContainer}>
                              <div
                                onClick={toggle}
                                className={classNames(S.SlideToggleButton, toggleState == "EXPANDED" ? S.Active : "")}
                              >
                                {t(navigation.title)}
                              </div>
                              <div
                                ref={setCollapsibleElement}
                                className={S.SlideToggleDropList}
                              >
                                <ul>
                                  {navigation.children && navigation.children.map((
                                    navigationChildren: any,
                                    index: number
                                  ) => {
                                    return (
                                      <>
                                        {navigationChildren.theme === "all" ? (
                                          <NavigationChildren
                                            item={navigationChildren}
                                            key={index}
                                            onClose={onClose}
                                          />
                                        ) : (
                                          <>
                                            {navigationChildren.theme === theme && (
                                              <NavigationChildren
                                                item={navigationChildren}
                                                key={index}
                                                onClose={onClose}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>

                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {navigation.theme === "all" && (
                          <Link
                            to={navigation.link}
                            className={S.Child}
                            onClick={onClose}
                          >
                            {t(navigation.title)}
                          </Link>
                        )}
                        {navigation.theme === theme && (
                          <Link
                            to={navigation.link}
                            className={S.Child}
                            onClick={onClose}
                          >
                            {t(navigation.title)}
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>

            {!auth && (
              <Button
                onClick={onWalletClick}
                color="static"
                className={classNames(theme == "nftim" ? S.DropLoginNftim : S.DropLoginHelpUa)}
              >
                <PlusIconNoPadding size={theme == "nftim" ? 14 : 10}/>
                {t("header.connectWallet.title")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
