import React, {useCallback, useEffect, useRef, useState} from "react";
import Slider from "react-slick";

import {NFTIMLaunchpadCard} from "components/NFTIM/Launchpad/Card";
import {useApi} from "api";
import {errorChecker} from "utils";
import {IAssetObject} from "types";
import {IconArrowSliderCollection} from "assets/icons/SliderArrowCollection";
import {ArrowIcon} from "assets/icons/Arrow";

interface IProps {

}

export const NFTIMLaunchpadContainer = (props: IProps) => {
  const [assets, setAssets] = useState<IAssetObject[]>([]);

  const {api} = useApi();

  const requestAsset = useCallback(async () => {
    try {
      let res = await api.news.getNewsFromES({
        limit: 10
      });
      setAssets(res.results);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.news]);

  useEffect(() => {
    requestAsset();
  }, [requestAsset]);

  let count = assets && assets.length;

  const sliderLaunchpad = useRef(null);

  const PreviousArrow = () => {
    return (
      <div
        className={`absolute hidden items-center justify-center w-[38px] h-[38px] bg-white border border-solid border-inner_border z-10 top-[calc(50%-25px)] left-[-21px] max1360:!left-[-16px] translate-y-[-50%] rounded-full cursor-pointer tr-all min1025:hover:bg-light_gray svg:tr-all min1025:svg:hover:scale-[1.2] ${count > 1 && "max768:!flex"} ${count > 2 && "max1024:!flex"} ${count > 3 && "max1360:!flex"} ${count > 4 && "min1361:!flex"}`}
        // @ts-ignore
        onClick={() => sliderLaunchpad?.current?.slickPrev()}
      >
        <ArrowIcon fill={"#4F4F4F"} size={24} className={"rotate-90"}/>
      </div>
    );
  };

  const NextArrow = () => {
    return (
      <div
        className={`absolute hidden items-center justify-center w-[38px] h-[38px] bg-white border border-solid border-inner_border z-10 top-[calc(50%-25px)] right-[-21px] max1360:!right-[-16px] translate-y-[-50%] rounded-full cursor-pointer tr-all min1025:hover:bg-light_gray svg:tr-all min1025:svg:hover:scale-[1.2] ${count > 1 && "max768:!flex"} ${count > 2 && "max1024:!flex"} ${count > 3 && "max1360:!flex"} ${count > 4 && "min1361:!flex"}`}
        // @ts-ignore
        onClick={() => sliderLaunchpad?.current?.slickNext()}
      >
        <ArrowIcon fill={"#4F4F4F"} size={24} className={"rotate-[-90deg]"}/>
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: count > 4,
    infinite: count > 4,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 30000,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: false,
    initialSlide: 0,
    variableWidth: false,
    appendDots: (dots: any) => {
      return (
        <>
          <div className={"relative w-full mt-4 pr-6"}>
            <ul className={"relative flex w-full h-[5px] bg-grey_border li:relative li:flex-1 liButton:absolute liButton:w-full liButton:h-full liButton:text-[0px] liButton:tr-all liSlickButton:bg-black min1025:hover:liButton:bg-black liButton:before:absolute liButton:before:left-0 liButton:before:right-0 liButton:before:top-[-5px] liButton:before:bottom-[-5px]"}>
              {dots}
            </ul>
          </div>
        </>
      );
    },
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          dots: count > 3,
          infinite: count > 3,
          swipe: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1024,
        settings: {
          dots: count > 2,
          infinite: count > 2,
          swipe: true,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          dots: count > 1,
          infinite: count > 1,
          swipe: true,
          slidesToShow: 1
        }
      }
    ]
  };


  return (
    <div className={"relative block w-full"}>

      {assets.length > 0 && (
        <>
          <PreviousArrow/>
          {/*@ts-ignore*/}
          <Slider
            className={"relative w-[calc(100%+24px)] slick-track:!flex slick-track:!min-w-full pb-5"}
            {...settings}
            ref={sliderLaunchpad}
          >
            {assets && assets.map((item, index) => {
              return (
                <article key={index} className={"pr-6"}>
                  <NFTIMLaunchpadCard
                    asset={item}
                  />
                </article>
              );
            })}
          </Slider>
          <NextArrow/>
        </>
      )}
    </div>
  );
};
