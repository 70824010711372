import React from "react";

import {BlockchainIcon} from "assets/icons/BlockchainIconFilters";
import {LightningIcon} from "assets/icons/LightningIconFilters";

interface IFilterIcon {
  className?: string;
  size?: number;
  filterType: string;
}

const iconsTypes = {
  "blockchain_filter" : <BlockchainIcon />,
  "sale_filter" : <LightningIcon />
};


export const FastFilterIcon = ({filterType, className, size}: IFilterIcon) => {
  return React.cloneElement(
    iconsTypes?.[filterType],
    {className, size}
  );
};
