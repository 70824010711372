import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const TikTokIcon = ({size = 24, fill = "white"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9.83953C18.9363 9.84447 16.9232 9.20088 15.2451 7.99969V16.3772C15.2446 17.9288 14.7703 19.4432 13.8858 20.718C13.0013 21.9928 11.7487 22.9672 10.2954 23.5109C8.84219 24.0545 7.2576 24.1416 5.75356 23.7604C4.24952 23.3791 2.8977 22.5478 1.87887 21.3776C0.860045 20.2074 0.222765 18.754 0.0522471 17.2118C-0.118271 15.6696 0.186102 14.112 0.924665 12.7475C1.66323 11.3829 2.80078 10.2764 4.18521 9.5758C5.56964 8.87521 7.13496 8.61398 8.67187 8.82703V13.0406C7.96858 12.8194 7.21337 12.8261 6.51409 13.0597C5.81481 13.2933 5.20723 13.7418 4.7781 14.3413C4.34897 14.9408 4.12025 15.6606 4.12459 16.3979C4.12893 17.1351 4.36612 17.8521 4.80228 18.4466C5.23844 19.041 5.85127 19.4824 6.55325 19.7077C7.25523 19.933 8.01047 19.9308 8.7111 19.7013C9.41174 19.4718 10.0219 19.0268 10.4546 18.4298C10.8872 17.8329 11.1202 17.1145 11.1201 16.3772V0H15.2451C15.2423 0.348347 15.2715 0.696219 15.3323 1.03922C15.4757 1.80491 15.7737 2.53332 16.2082 3.17988C16.6427 3.82643 17.2046 4.37756 17.8594 4.79953C18.791 5.41551 19.8832 5.74383 21 5.74359V9.83953Z"
        fill={fill}
      />
    </svg>
  );
};
