import React, {useState} from "react";

import {Container} from "components/Container";
import {Title} from "components/HELPUA/Title";
import {TUrl} from "types";
import {HomeNFTIMCelebrityBlock} from "components/NFTIM/Home/Celebrities/Block";
import {InstagramIcon} from "assets/icons/Social/Instagram";
import TillImg from "assets/img/nftim/home/celebrities/till.png";
import NkImg from "assets/img/nftim/home/celebrities/nk.png";
import KarolImg from "assets/img/nftim/home/celebrities/karol.png";
import LoaizaImg from "assets/img/nftim/home/celebrities/loaiza.png";
import AnittaImg from "assets/img/nftim/home/celebrities/anitta.png";
import DorofeevaImg from "assets/img/nftim/home/celebrities/dorofeeva.png";
import DantesImg from "assets/img/nftim/home/celebrities/dantes.png";
import PolyakovaImg from "assets/img/nftim/home/celebrities/polyakova.png";
import AndradeImg from "assets/img/nftim/home/celebrities/andrade.png";
import ZinaImg from "assets/img/nftim/home/celebrities/zina.png";
import OkeanImg from "assets/img/nftim/home/celebrities/okean.png";
import PotapImg from "assets/img/nftim/home/celebrities/potap.png";
import {YouTubeIcon} from "assets/icons/Social/YouTube";
import {TikTokIcon} from "assets/icons/Social/TikTok";

import S from "./style.module.scss";

export interface ICelebrity {
  img: TUrl;
  name: string;
  title: string;
  social: {
    icon: any;
    // link: TUrl;
    amount: string;
  }[];
}

const celebrities: ICelebrity[] = [
  {
    img: PotapImg,
    name: "POTAP",
    title: "CCO of NFTIM",
    social: [
      {icon: InstagramIcon, amount: "2.1 M"},
      {icon: TikTokIcon, amount: "750 K"},
      {icon: YouTubeIcon, amount: "58 K"}
    ]
  },
  {
    img: NkImg,
    name: "NK",
    title: "Ukrainian singer",
    social: [
      {icon: InstagramIcon, amount: "5.4 M"},
      {icon: TikTokIcon, amount: "2.7 M"},
      {icon: YouTubeIcon, amount: "1.5 M"}
    ]
  },
  {
    img: DorofeevaImg,
    name: "DOROFEEVA",
    title: "World superstar",
    social: [
      {icon: InstagramIcon, amount: "5.2 M"},
      {icon: TikTokIcon, amount: "3.2 M"},
      {icon: YouTubeIcon, amount: "1 M"}
    ]
  },
  {
    img: KarolImg,
    name: "TINA KAROL",
    title: "Ukrainian singer",
    social: [
      {icon: InstagramIcon, amount: "2 M"},
      {icon: TikTokIcon, amount: "360 K"},
      {icon: YouTubeIcon, amount: "350 K"}
    ]
  },
  {
    img: PolyakovaImg,
    name: "POLYAKOVA",
    title: "Singer, TV presenter",
    social: [
      {icon: InstagramIcon, amount: "2.5 M"},
      {icon: TikTokIcon, amount: "640 K"},
      {icon: YouTubeIcon, amount: "580 K"}
    ]
  },
  {
    img: OkeanImg,
    name: "OKEAN ELZY",
    title: "Rock band",
    social: [
      {icon: InstagramIcon, amount: "82 K"},
      {icon: YouTubeIcon, amount: "520 K"},
    ]
  },
  {
    img: ZinaImg,
    name: "ZINA",
    title: "Professional soccer player",
    social: [
      {icon: InstagramIcon, amount: "1.4 M"},
    ]
  },
  {
    img: AndradeImg,
    name: "MICHELLE ANDRADE",
    title: "Latin American Singer",
    social: [
      {icon: InstagramIcon, amount: "520 K"},
      {icon: TikTokIcon, amount: "120 K"}
    ]
  },
];

const listTabs = [
  {name: "CELEBRITIES"},
  {name: "BRANDS"},
  {name: "COMPANIES"},
  {name: "INFLUENCERS"}
];

export const HomeNFTIMCelebrity = () => {
  const [isActive, setActive] = useState<number>(0);
  return (
    <section className={"relative block bg-dark_background pb-12"}>
      <Container>
        <Title
          title={"ON BOARD"}
          underTitle={"BOARD"}
          mode={"white"}
          paddingBottom={"large"}
        />
        <div className={"relative flex items-center gap-3 w-full mb-10 pb-2 overflow-x-auto"}>
          {listTabs.map((item, index) => {
            return (
              <div
                key={index}
                className={`relative flex h-9 px-5 items-center justify-center text-center text-sm text-white font-bold bg-grey_deco rounded-[10px] min1025:hover:bg-dark_border cursor-pointer tr-all ${isActive === index && "bg-dark_border"}`}
                onClick={() => setActive(index)}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <div className={S.Celebrities}>
          {isActive === 0 && celebrities.map((celebrity, key) =>
            <div
              className={"col-span-1"}
              key={key}
            >
              <HomeNFTIMCelebrityBlock
                celebrity={celebrity}
              />
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};
