import React, {ReactNode} from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  children?: ReactNode;
}

export const StepText = (
  {
    className,
    children
  }: IProps) => {
  return (
    <div className={classNames(S.StepText, className)}>
      {children}
    </div>
  );
};
