import React from "react";

import {priceValidator} from "common/regEx";
import {classNames} from "utils";
import {Button} from "components/Button";
import {Input} from "components/Input";
import {Counter} from "components/Counter";
import {Price, useConvertFiat} from "components/Price";
import {useAsset} from "providers";

import {PopupSubContentHeader, PopupSubContentInput} from "../index";

import S from "./style.module.scss";


interface IProps {
  isError: boolean;
  isLoading: boolean;
  maxQuantity: number;
  setAmount: (value: number) => void;
  price: string;
  setPrice: (price: string) => void;
  onSaleSubmit: () => void;
}

export const SalePopupListForSale = ({
  isError,
  isLoading,
  maxQuantity,
  setAmount,
  price,
  setPrice,
  onSaleSubmit
}: IProps) => {
  const {asset} = useAsset();

  const [priceFiat] = useConvertFiat(price, asset?.defaultCurrency?.symbol);

  return (
    <div className={S.SalePopup}>
      <PopupSubContentHeader
        title={"List for sale"}
        titleLabel={"Publication of a token with a fixed price"}
      />
      <div className={S.InputWrapper}>
        <PopupSubContentInput
          label={"Price"}
          labelDesc={priceFiat}
        >
          <Input
            value={price}
            onChange={(value) => {
              setPrice(priceValidator(value));
            }}
            placeholder={"Enter your price"}
          />
        </PopupSubContentInput>
        {isError &&
          <div className={classNames(S.InputSubtitle, S.Error)}>
            The price must be greater than 0
          </div>
        }
        <Counter
          tooltipText={"Please, set how many items you want to list for sale."}
          maxQuantity={maxQuantity}
          setAmount={setAmount}
        />
        <Button
          disabled={isLoading}
          color={"primary"}
          onClick={onSaleSubmit}
          title={"List for sale"}
        />
      </div>
    </div>
  );
};
