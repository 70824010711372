import React from "react";
import {Link} from "react-router-dom";

import {Button} from "components/Button";
import {useAsset, useMyUser, useWeb3} from "providers";
import {Time} from "components/Time";
import {UserInfo} from "pages/Asset/UserInfo";
import {AssetTabsItemSkeleton} from "pages/Asset/AssetSkeleton/AssetTabsItemSkeleton";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";
import {IOwner} from "components/Home/types";

import {AssetsTables} from "../Tables";

import S from "./style.module.scss";
import {AvailableAssetMarketplace} from "./AvailableAssetMarketplace";

interface IUserProps {
  owner: IOwner;
}

const AvailableUser = ({owner}: IUserProps) => {
  const {asset} = useAsset();
  const {toggleSalePopup} = useWeb3ContractPopup();
  const {web3State} = useWeb3();
  const {user} = useMyUser();

  return (
    <tr>
      <td colSpan={2}>
        <UserInfo
          avatar={owner.user?.thumbnailPhoto}
          title={owner.user?.displayName}
          label={
            <>
              Owned {owner.number}&nbsp;
              {owner.number > 1
                ? "copies"
                : "copy"}
              ; <Time date={owner.receivedAt}/>
            </>
          }
          text={
            <>
              {asset && (
                <Link to={`/profile/${owner.user?.id}`}>
                  {owner.user?.displayName}
                </Link>
              )}
            </>
          }
        />
      </td>
      <td
        width={"180px"}
        align={"right"}
      >
        {(user.verificationStatus === "verified" && owner.number > 0) &&
          <div className={S.PriceOptions}>
            {web3State?.wallet && web3State?.wallet === owner.userAddress && (
              <Button
                className={S.StyledButton}
                onClick={toggleSalePopup}
                color="primary"
                title={"button.listForSale"}
              />
            )}
          </div>
        }
      </td>
    </tr>
  );
};

// TODO auction array show
export const OwnersContent = () => {
  const {available} = useAsset();

  if (!available) {
    return null;
  }

  return (
    <>
      <AssetsTables>
        {available?.auctions &&
          available.auctions?.length > 0 &&
          available?.auctions?.map((auction, key) =>
            <AvailableAssetMarketplace
              key={key}
              auction={auction}
            />
          )}

        {available?.marketplaces &&
          available.marketplaces?.length > 0 &&
          available?.marketplaces?.map((marketplace, key) =>
            <AvailableAssetMarketplace
              key={key}
              marketplace={marketplace}
            />
          )}

        {available?.owners &&
          available.owners?.length > 0 &&
          available?.owners?.map((owner, key) => {
            return (
              <AvailableUser
                owner={owner}
                key={key}
              />
            );
          })
        }
      </AssetsTables>
      {!available?.owners && !available?.marketplaces && <AssetTabsItemSkeleton/>}
    </>
  );
};
