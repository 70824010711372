import React, {useCallback, useEffect, useState} from "react";
import {Accordion} from "react-accessible-accordion";

import {BigDesktopForBids, Mobile} from "components/Responsive";
import {errorChecker, resultsAuctionCheckService} from "utils";
import {IBid, ISocketNotification} from "components/Home/types";
import {useApi} from "api";
import {TGetAssetKeys} from "types";
import {ProfileBidsBlock} from "components/Profile/ProfileMyBids/BlockDesc";
import {Web3ContractProvider} from "providers/Web3Contract";
import {ProfileBidsMobile} from "components/Profile/ProfileMyBids/BlockMobile";
import {AssetsTables} from "components/Asset/AssetTabs/Tables";

import S from "./style.module.scss";

interface IProps {
  wallet?: string;
  bidsTab: boolean;
  currentTab: TGetAssetKeys;
}

export const ProfileBids = ({wallet, bidsTab, currentTab}: IProps) => {
  const {api} = useApi();

  const [myBids, setMyBids] = useState<IBid[]>([]);

  const bidsRequest = useCallback(async () => {
    if (bidsTab) {
      try {
        let requestRes = await api.bids.getBids({
          bidder: wallet,
          status: "placed",
          ordering: "-updatedAt"
        });
        requestRes = resultsAuctionCheckService(requestRes);

        setMyBids(requestRes.results);
      } catch (err) {
        errorChecker(err);
      }
    }
  }, [api.bids, bidsTab, wallet]);

  const eventSocket = useCallback(async (value) => {
    const detail: ISocketNotification = value.detail;

    if (detail.type === "blockchain") {
      if ("transactionHash" in detail.data) {
        await bidsRequest();
      }
    }
  }, [bidsRequest]);

  useEffect(() => {
    window.addEventListener("socketCustomEvent", eventSocket);

    return () => {
      window.removeEventListener("socketCustomEvent", eventSocket);
    };
  }, [eventSocket]);

  useEffect(() => {
    (async () => bidsRequest())();
  }, [bidsRequest]);

  return (
    <>
      {wallet && currentTab === "myBids" && (
        <>
          <BigDesktopForBids>
            <AssetsTables>
              {(wallet && currentTab === "myBids") && myBids && myBids.map(bid =>
                <Web3ContractProvider
                  key={bid.id}
                  chain={bid.chain}
                  chainId={bid.chainId}
                  symbol={bid.auction.currency?.symbol}
                  auction={bid.auction}
                  bid={bid}
                >
                  <ProfileBidsBlock/>
                </Web3ContractProvider>
              )}
            </AssetsTables>
          </BigDesktopForBids>
          <Mobile>
            <Accordion>
              {wallet && currentTab === "myBids" && myBids.map(bid =>
                <Web3ContractProvider
                  key={bid.id}
                  chain={bid.chain}
                  chainId={bid.chainId}
                  symbol={bid.auction.currency?.symbol}
                  auction={bid.auction}
                  bid={bid}
                >
                  <ProfileBidsMobile/>
                </Web3ContractProvider>
              )}
            </Accordion>
          </Mobile>
        </>
      )}
    </>
  );
};
