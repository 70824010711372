import React, {useContext} from "react";

interface IListInfo {
  title?: string;
  desc?: string | number | string[] | number[] | unknown;
}

interface IUseDebug {
  debug: (payload: IListInfo) => void;
  clearInfo: () => void;
}

export const DebugContext = React.createContext<IUseDebug>({
  debug (): void {
  },
  clearInfo (): void {
  }
});
DebugContext.displayName = "DebugContext";

export function useDebug (): IUseDebug {
  const DebugData = useContext(DebugContext);
  if (DebugData === null) {
    throw new Error("DebugData global");
  }
  return DebugData;
}

interface IProps {
  children: React.ReactNode;
}

export const DebugProvider = ({children}: IProps) => {
  // const [listOfInformation, setListOfInformation] = useCurrentState<IListInfo[]>([]);
  // const [showModal, toggleShowModal] = useToggle(false);

  const debug = (payload: IListInfo) => {
    const debugValue = localStorage.getItem("debugValue") || "0";

    if (debugValue === "1") {
      // eslint-disable-next-line no-console
      console.log(payload.title, payload.desc ? "==========>>>" : "", payload.desc || "");
      // if (typeof payload.desc === "string" || typeof payload.desc === "number" || Array.isArray(payload.desc)) {
      //   setListOfInformation((prevState => {
      //     return {
      //       ...prevState,
      //       ...{title: payload.title || "Empty title", desc: JSON.stringify(payload.desc)}
      //     };
      //   }));
      // }
    }
  };

  // useEffect(() => {
  //   window.addEventListener("keyup", (e) => {
  //     let debug = localStorage.getItem("debugValue") || "0";
  //
  //     if (debug === "1") {
  //       if (e.code === "F4") {
  //         toggleShowModal();
  //       }
  //     }
  //   });
  //   // dont add any deps
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <DebugContext.Provider
      value={{
        debug,
        clearInfo: () => {
        }
      }}
    >
      {children}
      {/*{showModal &&*/}
      {/*  <Popup clickOnClose={toggleShowModal}>*/}
      {/*    <>*/}
      {/*      {listOfInformation.length > 0 && listOfInformation?.map((el, key) =>*/}
      {/*        <pre key={key}><>{el.title}: {el.desc}</></pre>*/}
      {/*      )}*/}
      {/*    </>*/}
      {/*  </Popup>*/}
      {/*}*/}
    </DebugContext.Provider>
  );
};
