import React from "react";
import {useParams} from "react-router-dom";

import {classNames} from "utils";
import {useApi} from "api";
import {Cards} from "components/Cards";

import S from "./style.module.scss";

interface IProps {
  className?: string;
}

export const SupportBody = ({className}: IProps) => {
  const {api} = useApi();
  const {id} = useParams();

  return (
    <div className={classNames(S.SupportBody, className)}>
      <Cards
        request={api.goalsId.getAssetsByFilters}
        query={{
          goal: String(id),
          isForSale: String(true)
        }}
        stateType={"base"}
        theme={"nftim"}
      />
    </div>
  );
};
