import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {allNewsPortfolio} from "components/Portfolio/Item/allNews";

const HOCPortfolioContext = React.createContext<any>({});
HOCPortfolioContext.displayName = "HOCProfileContext";
const HOCPortfolioProvider = HOCPortfolioContext.Provider;

export function usePortfolio (): any {
  const hocPortfolioData = useContext(HOCPortfolioContext);
  if (hocPortfolioData === null) {
    throw new Error("ERROR");
  }
  return hocPortfolioData;
}

interface IProps {
  children?: ReactNode;
}

export const PortfolioProvider = (
  {
    children
  }:IProps) => {
  const {id} = useParams<{ id: string }>();
  const [isObject, setObject] = useState<any>();

  const pageObject = useCallback(() => {
    const object = allNewsPortfolio.find((item: any) => `${item.id}` === id);
    setObject(object);
  }, [id]);

  useEffect(() => {
    pageObject();
  }, [id, pageObject]);


  return (
    <HOCPortfolioProvider
      value={{
        isObject
      }}
    >
      {children}
    </HOCPortfolioProvider>
  );
};
