import React from "react";

interface IProps {
  fill?: string;
  size?: number;
}

export const ClockIcon = ({ fill = "#000", size = 18 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.038 0 0 4.038 0 9C0 13.962 4.038 18 9 18C13.962 18 18 13.962 18 9C18 4.038 13.962 0 9 0ZM9 15.9999C5.14003 15.9999 1.99997 12.86 1.99997 8.99991C1.99997 5.13994 5.14003 1.99997 9 1.99997C12.86 1.99997 16 5.13994 16 9C16 12.8601 12.86 15.9999 9 15.9999Z"
        fill={fill}
      />
      <path
        d="M13.447 10.106L9.99899 8.38205V5.00002C9.99899 4.44802 9.55096 3.99998 8.99896 3.99998C8.44696 3.99998 7.99902 4.44802 7.99902 5.00002V9.00005C7.99902 9.37805 8.21305 9.72408 8.55299 9.89405L12.553 11.894C12.697 11.966 12.849 12 12.999 12C13.365 12 13.717 11.798 13.895 11.448C14.1411 10.954 13.9411 10.354 13.447 10.106Z"
        fill={fill}
      />
    </svg>
  );
};

export const ClockIconLight = ({ fill = "#000", size = 22 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 22C8.82441 22 6.69767 21.3549 4.88873 20.1462C3.07979 18.9375 1.66989 17.2195 0.83733 15.2095C0.00476623 13.1995 -0.213071 10.9878 0.211367 8.85401C0.635804 6.72022 1.68345 4.76021 3.22183 3.22183C4.76021 1.68345 6.72022 0.635804 8.85401 0.211367C10.9878 -0.213071 13.1995 0.00476623 15.2095 0.83733C17.2195 1.66989 18.9375 3.07979 20.1462 4.88873C21.3549 6.69767 22 8.82441 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22ZM11 1.46667C9.11449 1.46667 7.27132 2.02579 5.70357 3.07333C4.13582 4.12086 2.91391 5.60977 2.19235 7.35175C1.4708 9.09374 1.28201 11.0106 1.64985 12.8599C2.0177 14.7091 2.92566 16.4078 4.25892 17.7411C5.59218 19.0743 7.29086 19.9823 9.14014 20.3502C10.9894 20.718 12.9063 20.5292 14.6483 19.8077C16.3902 19.0861 17.8791 17.8642 18.9267 16.2964C19.9742 14.7287 20.5333 12.8855 20.5333 11C20.5333 8.47161 19.5289 6.04677 17.7411 4.25892C15.9532 2.47107 13.5284 1.46667 11 1.46667V1.46667Z"
        fill={fill}
      />
      <path
        d="M14.1093 15.1511L10.4793 11.5211C10.4113 11.4526 10.3575 11.3713 10.321 11.282C10.2846 11.1926 10.2661 11.097 10.2666 11.0005V5.13379H11.7333V10.6998L15.1506 14.1098L14.1093 15.1511Z"
        fill={fill}
      />
    </svg>
  );
};
