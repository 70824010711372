import React from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  height?: number | string;
  children?: React.ReactNode;
  scrollOrientation?: string;
}

export const ScrollBlock = ({className, height = "auto", children, scrollOrientation}: IProps) => {
  return (
    <div
      className={classNames(S.ScrollBlock, className, scrollOrientation === "x" ? S.ScrollX : S.ScrollY)}
      style={{maxHeight: height}}
    >
      {children}
    </div>
  );
};
