import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {CopiesIcon} from "assets/icons/CopiesIcons";
import {IAssetObject} from "types";
import {classNames} from "utils";
import {CalendarBlock} from "components/layout/CalendarBlock";
import {Price} from "components/Price";
import {useAssetBlockPrice} from "hooks/useAssetBlockPrice";
import {CardImg} from "components/layout/Card/Img";
import {HotIcon} from "assets/icons/Hot";

import S from "./style.module.scss";

interface IProps {
  asset: IAssetObject;
}

export const NFTIMLaunchpadCard = ({asset}: IProps) => {
  const price = useAssetBlockPrice(asset.object);

  const defaultCurrency = useMemo(() => {
    return asset.object.currentMarketplace?.currency || asset.object.currentAuction?.currency || asset.object.defaultCurrency;
  }, [asset.object.currentMarketplace?.currency, asset.object.currentAuction?.currency, asset.object.defaultCurrency]);

  const renderPriceBlock = () => {
    if (asset.object.hasAuction) {
      return (<div className={S.LaunchpadCardInfoAuction}><HotIcon size={14}/> <p>auction</p></div>);
    }

    return (
      <Price
        symbol={defaultCurrency?.symbol}
        cryptoImg={defaultCurrency?.image}
        decimals={defaultCurrency?.decimals}
        classNamePriceCrypto={S.LaunchpadCardInfoPrice}
        price={price}
        isShowFiat={false}
      />
    );
  };

  return (
    <aside className={"relative block w-full min1025:hover:!bg-light_gray ease-all min1025:firstParent:hover:translate-x-3"}>
      <Link
        to={`/asset/${asset.object.id}`}
        className={"relative block overflow-hidden"}
        title={"view asset"}
      >
        <CardImg
          asset={asset.object}
          className={"relative flex items-center justify-center aspect-video !h-[175px] !p-0 bg-light_gray"}
          aspectRatio={"aspect-video"}
        />
        <CalendarBlock
          className={"!absolute top-[14px] right-6 z-50"}
          date={new Date()}
        />
      </Link>
      <Link
        to={`/profile/${asset?.object?.author?.id}`}
        className={"relative block w-full"}
        title={"view profile"}
      >
        <div className={"relative block pt-[30px] pb-3 pr-6 min-h-[86px] ease-all firstChild"}>
          <div
            className={"relative block w-max max-w-full min-w-0 truncate text-lg leading-[22px] font-medium text-black "}
          >
            {asset?.object?.title}
          </div>
          <div className={"relative flex items-center mt-[2px] gap-x-8"}>
            <div className={"relative flex items-center gap-1"}>
              <CopiesIcon/>
              <p className={"text-sm text-text_description leading-4"}>
                {asset?.object?.available} items
              </p>
            </div>
            <div className={"relative *p:text-sm *p:text-text_description *p:leading-4"}>
              {renderPriceBlock()}
            </div>
          </div>
        </div>
        <div
          className={"absolute block w-[60px] h-[60px] bottom-full right-6 translate-y-2/4 bg-cover bg-no-repeat bg-center z-10 rounded-full border border-solid border-white"}
          style={{backgroundImage: `url(${asset?.object?.author?.thumbnailPhoto})`}}
        />
      </Link>
    </aside>
  );
};
