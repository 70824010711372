import React from "react";

import S from "./style.module.scss";
import {SliderStars} from "./CharitySupportSlider";

export const CharitySupportBlock = () => {

  return (
    <div className={S.Wrapper}>
      <div className={S.SliderWrapperStar}>
        <div className={S.Title}>We ARE supported <br/><span>by world stars</span></div>
        <SliderStars/>
      </div>
    </div>
  );
};
