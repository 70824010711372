import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";
import {useNavigate} from "react-router-dom";

import {useApi} from "api";
import {userSocketFunction} from "api/src/socket";
import {NotificationBackgroundIcon} from "assets/icons/NotificationIcons/NotificationBackgroundIcon";
import {NotificationNonReadIcon} from "assets/icons/NotificationIcons/NotificationNonRead";
import {classNames, errorChecker} from "utils";
import {ReadAll} from "assets/icons/ReadAll";
import {Time} from "components/Time";
import {INotificationObject} from "components/Home/types";
import {useWeb3} from "providers";
import {useTheme} from "hooks/useTheme";

import {NotificationIcon} from "./NotificationIcon";
import S from "./style.module.scss";

interface INotificationCenter {
  token?: string | null;
  setIcon: Dispatch<SetStateAction<number>>;
}

export const NotificationCenter = ({token, setIcon}: INotificationCenter) => {
  const navigate = useNavigate();
  const {api} = useApi();
  const {web3State} = useWeb3();

  const [notificationsData, setNotificationsData] = useState<INotificationObject[]>([]);
  const [isNotificationsReadAllValue, setIsNotificationsReadAllValue] = useState<boolean>(false);

  const notificationList = useCallback(async () => {
    try {
      let notifications = await api.notifications.getNotifications();
      setNotificationsData(notifications.results);
    } catch (err) {
      // @ts-ignore
      errorChecker(err);
    }
  }, [api]);

  const readNotification = useCallback(async (ids?: number[] | string[]) => {
    try {
      await api.notifications.readNotification({ids});
    } catch (err) {
      // @ts-ignore
      errorChecker(err);
    }
  }, [api]);

  const onClickNavigation = (notification: INotificationObject) => {
    switch (notification.place) {
      case "asset": {
        navigate(`/asset/${notification.data?.assetId || 0}`);
      }
        break;
      default: {
        // other type
      }
    }
  };

  const onNotificationClick = (id: number, index: number) => {
    (async () => readNotification([id]))();
    notificationsData[index].readAt = String(new Date());
  };

  const onMarkAllNotificationsRead = () => {
    (async () => readNotification())();
    setIsNotificationsReadAllValue(true);
  };

  useEffect(() => {
    token && userSocketFunction({token}, (data) => {
      if (data?.data?.notification !== null) {
        // @ts-ignore
        setNotificationsData(prevState => [...data?.data?.notification, ...prevState]);
      }
    });
  }, [token]);

  useEffect(() => {
    if (web3State?.wallet) {
      (async () => notificationList())();
      setIcon(0);
    }
  }, [notificationList, web3State?.wallet, setIcon]);

  const theme = useTheme();

  return (
    <div className={classNames(S.NotificationCenterMain, S.NotifiCenter)}>
      <div className={S.NotificationCenterHeader}>
        <h2 className={S.NotificationCenterTitle}>Notification center</h2>
        <button
          onClick={onMarkAllNotificationsRead}
          className={S.NotificationMarkAllAsRead}
        >
          <ReadAll size={12}/>
          <p>Mark all as read</p>
        </button>
        {/*<span>*/}
        {/*  <NotificationSettings/>*/}
        {/*</span>*/}
      </div>
      <div className={S.NotificationCenterContent}>
        {notificationsData.length
          ? notificationsData?.map((notification, index) => {
            return (
              <ol
                className={S.NotificationList}
                key={notification.id}
                onClick={() => onClickNavigation(notification)}
              >
                <li
                  className={S.NotificationItem}
                  onClick={() => onNotificationClick(notification.id, index)}
                >
                  <NotificationIcon notificationType={notification.type}/>
                  <div className={S.NotificationItemCenter}>
                    <span>{notification?.text}</span>
                    <Time
                      date={notification?.createdAt}
                      type={"passed"}
                    />
                  </div>
                  <span className={S.NotificationNonRead}>
                    {!isNotificationsReadAllValue && !notification?.readAt &&
                      <NotificationNonReadIcon/>
                    }
                  </span>
                </li>
              </ol>
            );
          })
          :
          <div className={S.NotificationCenterEmptyBlock}>
            <NotificationBackgroundIcon/>
            <p className={S.NotificationCenterEmptyBlockText}>No new notifications</p>
          </div>
        }
      </div>
    </div>
  );
};
