import React, {ReactNode, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

import {CrossIcon} from "assets/icons/CrossIcon";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  children: ReactNode;
  selector: string;
}

/**
 * @Documentation https://uk.reactjs.org/docs/portals.html
 * @param children
 * @param selector
 * @constructor
 */
export function ClientOnlyPortal ({children, selector}: IProps) {
  const ref = useRef<Element | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setIsMounted(true);
  }, [selector]);

  if (ref.current) {
    return isMounted ? createPortal(children, ref.current) : null;
  }

  return (<></>);
}

interface IModalProps {
  name?: string;
  children: ReactNode;
  classNameModal?: string;
  classNameContent?: string;
  classNameBody?: string;
  clickOnClose?: () => void;
  maxWidth?: string;
  noModal?: boolean;
  isIFrame?: boolean;
}

export const Popup = ({
  name = "#modal",
  children,
  classNameModal,
  classNameContent,
  classNameBody,
  clickOnClose,
  maxWidth,
  noModal = false,
  isIFrame = false,
}: IModalProps) => {
  const [body] = useState(() => document.body);

  const onClose = () => {
    clickOnClose && clickOnClose();
  };

  useEffect(() => {
    body?.classList.add(S.OpenModal);

    return (() => {
      body?.classList.remove(S.OpenModal);
    });
  }, [body]);

  const theme = useTheme();

  return (
    <>
      <ClientOnlyPortal selector={name}>
        <div className={classNames(S.Modal, classNameModal, {[S.noModal]: noModal})}>
          <div
            className={S.BG}
            onClick={onClose}
          />
          {isIFrame ? (
            <div className={classNames(S.ModalIFrameContent, classNameContent)}>
              {clickOnClose && <CrossIcon
                className={S.CloseButton}
                onClick={onClose}
                size={14}
                fill={"#000"}
              />}
              <div className={classNames(S.ModalIFrameBody, classNameBody)}>
                {children}
              </div>
            </div>
          ) : (
            <div
              className={classNames(S.ModalContent, classNameContent, maxWidth && S.Width, S[theme])}
              style={{maxWidth: maxWidth}}
            >
              {clickOnClose && <CrossIcon
                className={S.CloseButton}
                onClick={onClose}
                size={14}
                fill={"#000"}
              />}
              <div className={classNames(S.ModalBody, classNameBody)}>
                {children}
              </div>
            </div>
          )}
        </div>
      </ClientOnlyPortal>
    </>
  );
};

interface IPropsOther {
  children?: ReactNode;
  title?: string;
  titleLabel?: string;
  setTop?: string;
  position?: "left" | "center" | "right";
  className?: string;
  classText?: string;
  label?: string;
  labelDesc?: string;
}

export const PopupSubContentHeaderWithChildren = ({
  title,
  className,
  titleLabel,
  children
}: IPropsOther) => {
  return (
    <div className={classNames(S.ModalHeader, S.ModalHeaderWithChild, className)}>
      {title && <div className={S.Title}>{title}</div>}
      {titleLabel && <div className={S.titleLabel}>{titleLabel}</div>}
      {children && <>{children}</>}
    </div>
  );
};

export const PopupSubContentHeader = ({
  title,
  className,
  titleLabel
}: IPropsOther) => {
  return (
    <div className={classNames(S.ModalHeader, className)}>
      {title && <div className={S.Title}>{title}</div>}
      {titleLabel && <div className={S.titleLabel}>{titleLabel}</div>}
    </div>
  );
};
export const PopupSubContentTitle = ({
  children,
  setTop,
  position = "left",
  className
}: IPropsOther) => {
  return (
    <div
      className={classNames(S.subTitle, S[position], className)}
      style={{marginTop: setTop}}
    >{children}</div>
  );
};
export const PopupSubContentText = ({
  children,
  position = "left",
  className
}: IPropsOther) => {
  return (
    <div className={classNames(S.subText, S[position], className)}>{children}</div>
  );
};

export const PopupSubContentInput = ({
  children,
  className,
  label,
  labelDesc
}: IPropsOther) => {
  return (
    <div className={classNames(S.InputContent, className)}>
      <div className={S.LabelRow}>
        {label && <div className={S.Label}>{label}</div>}
        {labelDesc && <div className={S.labelDesc}>{labelDesc}</div>}
      </div>
      <div className={S.InputRow}>{children}</div>
    </div>
  );
};

export const PopupSubContentPriceBlock = ({
  children,
  className,
  classText,
}: IPropsOther) => {
  return (
    <div className={classNames(S.PriceBlock, className, classText && S[classText])}>
      {children}
    </div>
  );
};

export const PopupSubContentPriceChild = ({
  children,
  className,
  label
}: IPropsOther) => {
  return (
    <div className={classNames(S.CurrentPrice, className)}>
      {label && <div className={S.Label}>{label}</div>}
      <div className={S.Price}>
        {children}
      </div>
    </div>
  );
};

interface IButton {
  href?: string;
  onClick?: () => void;
  title?: string;
  icon?: JSX.Element;
  text?: string;
}

export const ButtonPopup = (
  {
    href,
    onClick,
    title,
    icon,
    text
  }: IButton) => {
  return (
    <>
      {href && (
        <a
          className={"relative flex flex-col items-center rounded-lg w-[calc(50%_-_8px)] h-[132px] tr-all bg-[#F4F5F6] hover:bg-[#E5EAEE] max1024:w-full"}
          title={title}
          href={href}
        >
          <span className={"relative flex items-center justify-center h-[88px]"}>{icon}</span>
          <span className={"relative text-center text-[24px] leading-5 font-extrabold"}>{text}</span>
        </a>
      )}
      {onClick && (
        <div
          className={"relative flex flex-col items-center rounded-lg w-[calc(50%_-_8px)] h-[132px] tr-all bg-[#F4F5F6] hover:bg-[#E5EAEE] max1024:w-full"}
          onClick={onClick}
          title={title}
        >
          <span className={"relative flex items-center justify-center h-[88px]"}>{icon}</span>
          <span className={"relative text-center text-[24px] leading-5 font-extrabold"}>{text}</span>
        </div>
      )}
    </>
  );
};
