import React, {useMemo} from "react";

import {Button} from "components/Button";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";
import {useWeb3} from "providers";

import S from "./style.module.scss";

export const ProfileMyBidsAuctionBlockButtons = () => {
  const {web3State, checkAuth} = useWeb3();
  const {withdraw, endAuction, auction, bid, setSaleType, cancelAuction} = useWeb3Contract();
  const {togglePlaceBidPopup} = useWeb3ContractPopup();

  const auctionAction = useMemo(
    () => {
      if (web3State?.wallet === auction?.beneficiary) {
        if (auction?.isEndedTimer) {
          // Если ты автор аукциона и он закончен КНопка Finish auction синяя (Заканчивает аукцион)
          return {
            title: "Finish auction",
            disabled: false,
            onClick: () => cancelAuction({
              auctionAddress: auction?.auctionAddress,
              auctionId: auction?.auctionContractId,
              currencyAddress: auction.currency?.address
            })
          };
        }

        // Если ты автор аукциона и он НЕ закончен КНопка Cancel auction - Серая
        return {
          title: "button.cancelAuction",
          disabled: true,
          onClick: () => {
          }
        };
      }

      if (auction?.isEndedTimer) {
        if (web3State?.wallet === auction?.highestBidder) {
          // Если ты победил - кнопка Finish auction синяя (Заканчивает аукцион)
          return {
            title: "Finish auction",
            disabled: false,
            onClick: () => endAuction({
              auctionAddress: auction?.auctionAddress,
              auctionId: auction?.auctionContractId,
              currencyAddress: auction?.currency?.address
            })
          };
        }

        return {
          title: "button.placeBid",
          disabled: true,
          onClick: () => {
          }
        };
      }

      // Если Твоя ставка не самая высокая и аукцион не закончен пишем - Revoke bid синяя (Забираем ставку)  + рядом кнопка Increase bid Синяя (Попап увеличения ставки)
      // Если Твоя ставка самая высокая и аукцион не закончен пишем - Revoke bid серая
      return {
        title: "Revoke bid",
        disabled: bid?.isHighest,
        onClick: () => withdraw({
          auctionAddress: auction?.auctionAddress,
          auctionId: auction?.auctionContractId || 0,
          currencyAddress: auction?.currency?.address
        })
      };
    },
    [
      auction?.currency?.address,
      auction?.auctionAddress,
      auction?.auctionContractId,
      auction?.beneficiary,
      auction?.highestBidder,
      auction?.isEndedTimer,
      bid?.isHighest,
      cancelAuction,
      endAuction,
      web3State?.wallet,
      withdraw
    ]
  );

  const renderActionButton = useMemo(() => {
    return (
      <Button
        className={"!min-w-[unset]"}
        title={auctionAction.title}
        disabled={auctionAction.disabled}
        onClick={() => checkAuth(() => auctionAction.onClick())}
      />
    );
  }, [auctionAction, checkAuth]);

  // Если Твоя ставка не самая высокая и аукцион не закончен пишем - Revoke bid синяя (Забираем ставку)  + рядом кнопка Increase bid Синяя (Попап увеличения ставки)
  const renderIncreaseBid = useMemo(() => {
    return (
      <Button
        title={"Increase bet"}
        color={"primary_o"}
        onClick={() => {
          setSaleType("Auction");
          togglePlaceBidPopup();
        }}
        className={"!min-w-[unset]"}
      />
    );
  }, [setSaleType, togglePlaceBidPopup]);

  const renderButton = useMemo(
    () => {
      return (
        <>
          {renderActionButton}
          {!auction?.isEndedTimer && (bid && !bid?.isHighest) && renderIncreaseBid}
        </>
      );
    },
    [auction?.isEndedTimer, bid, renderActionButton, renderIncreaseBid]
  );

  return (
    <div className={S.Buttons}>
      {renderButton}
    </div>
  );
};
