import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {
}

export const Delete = ({onClick, className, fill = "white"}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 13.6666H2.66667C2.31304 13.6666 1.97391 13.5262 1.72386 13.2761C1.47381 13.0261 1.33333 12.6869 1.33333 12.3333V3.66665H0V2.33331H2.66667V1.66665C2.66667 1.31302 2.80714 0.973886 3.05719 0.723837C3.30724 0.473789 3.64638 0.333313 4 0.333313H8C8.35362 0.333313 8.69276 0.473789 8.94281 0.723837C9.19286 0.973886 9.33333 1.31302 9.33333 1.66665V2.33331H12V3.66665H10.6667V12.3333C10.6667 12.6869 10.5262 13.0261 10.2761 13.2761C10.0261 13.5262 9.68696 13.6666 9.33333 13.6666ZM2.66667 3.66665V12.3333H9.33333V3.66665H2.66667ZM4 1.66665V2.33331H8V1.66665H4ZM8 11H6.66667V4.99998H8V11ZM5.33333 11H4V4.99998H5.33333V11Z"
      fill={fill}
    />
  </svg>
);
