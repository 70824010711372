import React from "react";

import {Container} from "components/Container";
import {Title} from "components/HELPUA/Title";
import {PtNftBinance, PtNftKuna, PtNftLamborghini, PtNftWhiteBit} from "assets/icons/PartnerListNftim";
import {AboutHelpUa} from "components/NFTIM/Home/About/AboutHelpUa";

interface IProps {}

const listPartners = [
  {icon: <PtNftLamborghini/>},
  {icon: <PtNftKuna/>},
  {icon: <PtNftBinance/>},
  {icon: <PtNftWhiteBit/>},
];

export const HomeNftimPartners = (
  {}:IProps) => {
  return (
    <Container className={"!pb-[72px]"}>
      <Title title={"OUR PARTNERS"} underTitle={""}/>
      <div className={"relative block py-12 px-5 before:absolute before:block before:inset-0 before:bg-black before:rounded-xl max768:before:inset-x-[-20px]"}>
        <div className={"relative flex gap-x-5 gap-y-10 justify-around max768:flex-wrap items-center"}>
          {listPartners.map((item, index) => {
            return (
              <div
                key={index}
                className={"text-white text-center svg:max-w-full svg:w-auto svg:h-auto min-w-0 max768:svg:max-h-[30px]"}
              >
                {item.icon}
              </div>
            );
          })}
        </div>
      </div>
      <AboutHelpUa/>
    </Container>
  );
};
