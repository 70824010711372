import React from "react";
import {Link, useLocation} from "react-router-dom";

import S from "./style.module.scss";

interface IProps {
  title?: string;
  date?: string;
}

export const HelpCenterPageHeader = ({title, date}: IProps) => {
  return (
    <div className={S.HelpCenterPageHeaderWrapper}>
      <div className={S.HelpCenterPageHeader}>
        <ul>
          <li><Link to={"/"}>NFTIM</Link></li>
          <li><span>{title}</span></li>
        </ul>
      </div>
      <div className={S.HelpCenterPageHeaderTitle}>
        <h6>{title}</h6>
        {date &&
          <div className={S.Date}>
            <ul>
              <li>{date}</li>
              <li>Updated</li>
            </ul>
          </div>
        }
      </div>
    </div>
  );
};
