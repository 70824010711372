import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";

import Avatar from "assets/img/photo-card.png";
import {CopyIconLarge} from "assets/icons/CopyIcon";
import {showNotification} from "components/Notification";
import {Popup, PopupSubContentTitle} from "components/Popups";
import {UserBlock} from "components/UserBlock";
import {Price} from "components/Price";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";
import {MediaBlock} from "components/MediaBlock";
import {OpenIcon} from "assets/icons/OpenIcon";
import {ArrowRight} from "assets/icons";

import S from "./style.module.scss";

export interface IBuyPopupDataProps {
  blockHash: string;
  title: string;
  buyer: string;
}

interface IProps {
  data?: IBuyPopupDataProps;
  onClose: () => void;
}

export const BuyPopup = ({onClose}: IProps) => {
  const blockHash = "d2daC32dc3f23fr23ff32ffdf23f32fdf23fdf2354324fff";
  const theme = useTheme();
  return (
    <Popup
      maxWidth={"612px"}
    >
      <PopupSubContentTitle
        position={"center"}
        className={S.PopupTitle}
      >
        {blockHash ? "Your purchase was successful" : "Waiting confirmation"}
      </PopupSubContentTitle>
      <div className={S.InfoRow}>
        <div className={S.Child}>
          <div className={S.Title}>Creator</div>
          <UserBlock
            className={classNames(S.UserBlock, S[theme])}
            id={1}
            thumbnailPhoto={Avatar}
            displayName={"Dwayne Rock Johnson"}
          />
        </div>
        <div className={S.Child}>
          <div className={S.Title}>Price</div>
          <Price
            cryptoImg={"https://helpuadevmedia.blob.core.windows.net/static/images/ethereum.svg"}
            symbol={"ETH"}
            isShowFiat={false}
            price={"99999999999999"}
            className={classNames(S.AssetsPrice, S[theme])}
          />
        </div>
        <div className={S.Child}>
          <div className={S.Title}>Count</div>
          <div className={classNames(S.Count, S[theme])}>
            10000 / 10000
          </div>
        </div>
      </div>
      <div className={S.MediaRow}>
        <MediaBlock/>
      </div>
      <div className={classNames(classNames(S.HashRow, S[theme]))}>
        <div className={S.HashChild}>
          <div className={S.Label}>Status</div>
          <div className={classNames(S.Text, blockHash && S.Green)}>
            {blockHash ? "Ready" : "Processing"}
          </div>
        </div>
        <div className={S.HashChild}>
          <div className={S.Label}>Transaction hash</div>
          {blockHash ? (
            <>
              {/*// @ts-ignore*/}
              <CopyToClipboard
                onCopy={() =>
                  showNotification({
                    title: "Success",
                    subtitle: "Copied successfully"
                  })
                }
                text={blockHash}

              >
                <div className={S.CopyButton}>
                  <span>{blockHash}</span>
                  <CopyIconLarge fill="#0A84FF"/>
                </div>
              </CopyToClipboard>
            </>
          ) : (
            <a
              href="#"
              className={S.Link}
            >
              <span>View transaction on etherscan</span>
              <OpenIcon/>
            </a>
          )}
        </div>
      </div>
      <div
        className={classNames(S.BuyButton, S[theme])}
        onClick={onClose}
      >
        <span>CONTINUE</span>
        <span className={S.IcoSvg}><ArrowRight/></span>
      </div>
    </Popup>
  );
};
