import React, {useCallback, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import {Header} from "components/Header";
import {showNotification} from "components/Notification";
import {Footer} from "components/Footer";
import {CharityFooter} from "components/CharityFooter";
import {AppRoutes} from "AppRoutes";
import {ViewBlock} from "components/ViewBlock";
import {useWeb3} from "providers";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";
import {ISocketNotification} from "components/Home/types";
import {useStore} from "providers";
import "api/src/socket";
import {Alerts} from "components/Alerts";

import S from "./components/Header/style.module.scss";

export const App = () => {
  const location = useLocation();
  const {i18n} = useTranslation();
  const {auth} = useWeb3();
  const {setIsLoader} = useStore();

  const lang = localStorage.getItem("lang");

  useEffect(() => {

    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      localStorage.setItem("lang", "en");
    }

    (async () => {
      await auth();
    })();

    // DONT add auth to deps, max dept memory leak
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, lang]);

  const theme = useTheme();

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const eventSocket = useCallback((value) => {
    const detail: ISocketNotification = value.detail;

    if (detail.type === "blockchain") {
      if ("transactionHash" in detail.data) {
        setIsLoader(false);
        showNotification({
          title: "Success",
          subtitle: `Your block hash ${detail.data.transactionHash}`
        });
      }
    }
  }, [setIsLoader]);

  useEffect(() => {
    window.addEventListener("socketCustomEvent", eventSocket);

    return () => {
      window.removeEventListener("socketCustomEvent", eventSocket);
    };
  }, [eventSocket]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const {setIsFooterVisible} = useStore();
  const {isFooterVisible} = useStore();

  const refFoo = useRef<HTMLElement>(null);
  const getTop = useCallback(() => {
    let setWindow = window.pageYOffset + window.innerHeight;
    let setFooter = refFoo.current?.offsetTop;
    if (setWindow >= Number(setFooter)) {
      if (!isFooterVisible) {
        setIsFooterVisible(true);
      }
    } else {
      if (isFooterVisible) {
        setIsFooterVisible(false);
      }
    }
  }, [isFooterVisible, setIsFooterVisible]);

  useEffect(() => {
    window.removeEventListener("scroll", getTop);
    window.addEventListener("scroll", getTop, {passive: true});
    return () => window.removeEventListener("scroll", getTop);
  }, [getTop]);

  return (
    <div className={S.RootMainBlock}>
      <Header/>
      <section
        className={classNames(
          location.pathname == "/" ? S.SectionMain : S.SectionStatic,
        )}
      >
        <AppRoutes/>
      </section>
      <ViewBlock
        value={["nftim"]}
      >
        <Footer refAttr={refFoo}/>
      </ViewBlock>
      <ViewBlock
        value={["help_ua", "help_ua_dev"]}
      >
        <CharityFooter refAttr={refFoo}/>
      </ViewBlock>
      <Alerts/>
    </div>
  );
};
