import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const CopiesIcon = ({size = 14, fill = "black"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72727 0.636364H0.636364V5.72727H5.72727V0.636364Z"
        fill={fill}
      />
      <path
        d="M13.3636 0.636364H8.27273V5.72727H13.3636V0.636364Z"
        fill={fill}
      />
      <path
        d="M5.72727 8.27273H0.636364V13.3636H5.72727V8.27273Z"
        fill={fill}
      />
      <path
        d="M13.3636 8.27273H8.27273V13.3636H13.3636V8.27273Z"
        fill={fill}
      />
      <path
        d="M0 6.36364H6.36364V0H0V6.36364ZM1.27273 1.27273H5.09091V5.09091H1.27273V1.27273Z"
        fill={fill}
      />
      <path
        d="M7.63636 0V6.36364H14V0H7.63636ZM12.7273 5.09091H8.90909V1.27273H12.7273V5.09091Z"
        fill={fill}
      />
      <path
        d="M0 14H6.36364V7.63636H0V14ZM1.27273 8.90909H5.09091V12.7273H1.27273V8.90909Z"
        fill={fill}
      />
      <path
        d="M7.63636 14H14V7.63636H7.63636V14ZM8.90909 8.90909H12.7273V12.7273H8.90909V8.90909Z"
        fill={fill}
      />
    </svg>
  );
};

