import React, {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import isEqual from "react-fast-compare";
import {use} from "i18next";

import {TQuery, useApi} from "api";
import {errorChecker} from "utils";
import {TIsLoading} from "types";
import {useStore} from "providers/Store";

export interface IUseInfinityScroll {
  state: any[];
  onClickLike: (isLike: boolean, assetId: number) => Promise<void>;
  isLoading: TIsLoading;
  requestNextPage: () => void;
}

export const InfinityScrollContext = React.createContext<IUseInfinityScroll>({
  state: [],
  onClickLike () {
    return Promise.resolve(undefined);
  },
  isLoading: "first",
  requestNextPage () {
  }
});
InfinityScrollContext.displayName = "HocInfinityScrollContext";

export function useInfinityScroll (): IUseInfinityScroll {
  const InfinityScrollData = useContext(InfinityScrollContext);
  if (InfinityScrollData === null) {
    throw new Error("useInfinityScroll must be used within a InfinityScrollProvider");
  }
  return InfinityScrollData;
}

export interface IInfinityScrollProviderProps {
  children: React.ReactNode;
  offsetBottom?: number;
  // only in some request if child have object key in asset object
  stateType?: "base" | "object";
  limit?: number;
  removeInfinityScroll?: boolean;
  request: (...rest: any) => Promise<any>;
  query?: TQuery;
}

const useInfinityScrollStore = (limit: number) => {
  const [amountPage, setAmountPage] = useState<number>(0);
  const [activePage, setActivePage] = useState<number>(0);
  const [limitStore] = useState<number>(limit);

  return {amountPage, setAmountPage, activePage, setActivePage, limitStore};
};

export const InfinityScrollProvider = memo(({
  children,
  offsetBottom = 200,
  stateType = "base",
  limit = 20,
  removeInfinityScroll,
  request,
  query
}: IInfinityScrollProviderProps) => {
  const {api} = useApi();
  const {amountPage, setAmountPage, activePage, setActivePage, limitStore} = useInfinityScrollStore(limit);

  const [isLoading, setIsLoading] = useState<TIsLoading>("first");
  const [state, setState] = useState<any[]>([]);

  const offset = useMemo(() => limitStore * activePage, [activePage, limitStore]);

  const {setIsAlerts} = useStore();

  const requestInformation = useCallback(async () => {
    setIsLoading("first");

    try {
      let res = await request({
        ...query,
        limit: limitStore
      });
      if (stateType === "base") {
        setState(res.results);
      }

      if (stateType === "object") {
        let resResult = res.results.map((el: any) => el.object);
        setState(resResult);
      }

      setAmountPage(Math.ceil(res.count / limitStore));
      setActivePage(1);
      setIsLoading(res.results.length === 0 ? "empty" : res.results.length < limitStore ? "loaded_last" : "loaded");
    } catch (err) {
      errorChecker(err);
      setIsLoading("empty");
    }
  }, [request, query, limitStore, stateType, setAmountPage, setActivePage]);

  const requestNextPage = useCallback(async () => {
    if (isLoading !== "loading") {
      setIsLoading("loading");

      try {
        let res = await request({
          ...query,
          offset,
          limit: limitStore
        });

        let cur = await request({
          ...query
        });

        if (stateType === "base") {
          setState([...state, ...res.results]);
        }

        if (stateType === "object") {
          let resResult = res.results.map((el: any) => el.object);
          setState([...state, ...resResult]);
        }

        setActivePage(activePage + 1);

        let num = cur.results.length - limitStore - res.results.length;
        setIsLoading(
          res.results.length === 0
            ? "empty"
            : num <= 0 || res.results.length < limitStore
              ? "loaded_last"
              : "loaded"
        );

        // TODO loaded_last if load last page
      } catch (err) {
        setIsAlerts(false);
        errorChecker(err);
      } finally {
        // setIsLoading("loaded");
      }
    }
  }, [isLoading, setIsAlerts, request, query, offset, limitStore, stateType, setActivePage, activePage, state]);

  const onScroll = useCallback(async () => {
    if (!removeInfinityScroll) {
      if (isLoading !== "loading") {
        if (amountPage > activePage) {
          const {innerHeight, scrollY} = window;
          const {body} = document;
          const {offsetHeight} = body;

          if ((innerHeight + scrollY) >= (offsetHeight - offsetBottom)) {
            await requestNextPage();
          }
        }
      }
    }
  }, [removeInfinityScroll, isLoading, amountPage, activePage, offsetBottom, requestNextPage]);

  useEffect(() => {
    setState([]);
    setAmountPage(0);
    setActivePage(0);
    setIsLoading("first");

    (async () => requestInformation())();
  }, [requestInformation, setActivePage, setAmountPage]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, false);

    return () => {
      window.removeEventListener("scroll", onScroll, false);
    };
  }, [onScroll]);

  const onClickLike = useCallback(async (isLike: boolean, assetId: number) => {
    let newState = state.map((el) =>
      el.id === assetId
        ? {
          ...el,
          isLike: !el.isLike,
          likeCount: !el.isLike ? el.likeCount + 1 : el.likeCount - 1
        }
        : el
    );
    setState(newState);

    try {
      await api.asset.like(assetId, {
        isLike: !isLike
      });
    } catch (err) {
      errorChecker(err);
      newState = state.map((el) => el.id === assetId ? el.isLike = !isLike : el);
      setState(newState);
    }
  }, [api, state]);

  return (
    <InfinityScrollContext.Provider
      value={{
        state,
        onClickLike,
        isLoading,
        requestNextPage
      }}
    >
      {children}
    </InfinityScrollContext.Provider>
  );
}, isEqual);
