import React from "react";
import ContentLoader from "react-content-loader";

export const HeaderSkeleton = () => (
  <ContentLoader
    speed={2}
    width={1440}
    height={442}
    viewBox="0 0 1440 442"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{marginBottom: "50px"}}
  >
    <rect
      x="0"
      y="0"
      rx="15"
      ry="15"
      width="1440"
      height="200"
    />
    <circle
      cx="721"
      cy="199"
      r="60"
    />
    <rect
      x="595"
      y="288"
      rx="5"
      ry="5"
      width="250"
      height="50"
    />
    <rect
      x="420"
      y="362"
      rx="5"
      ry="5"
      width="600"
      height="80"
    />
  </ContentLoader>
);


export const HeaderSkeletonTablet = () => (
  <ContentLoader
    speed={2}
    width={728}
    height={443}
    viewBox="0 0 728 443"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{marginBottom: "50px"}}
  >
    <rect
      x="0"
      y="0"
      rx="15"
      ry="15"
      width="728"
      height="200"
    />
    <circle
      cx="361"
      cy="200"
      r="60"
    />
    <rect
      x="235"
      y="289"
      rx="5"
      ry="5"
      width="250"
      height="50"
    />
    <rect
      x="60"
      y="363"
      rx="5"
      ry="5"
      width="600"
      height="80"
    />
  </ContentLoader>
);


export const HeaderSkeletonMobile = () => (
  <ContentLoader
    speed={2}
    width={335}
    height={443}
    viewBox="0 0 335 443"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{marginBottom: "50px"}}
  >
    <rect
      x="0"
      y="0"
      rx="15"
      ry="15"
      width="335"
      height="200"
    />
    <circle
      cx="169"
      cy="200"
      r="60"
    />
    <rect
      x="43"
      y="289"
      rx="5"
      ry="5"
      width="250"
      height="50"
    />
    <path d="M 0 368 a 5 5 0 15 -5 h 325 a 5 5 0 15 5 v 70 a 5 5 0 1 -5 5 H 5 a 5 5 0 1 -5 -5 v -70 z"/>
  </ContentLoader>
);
