import React, {
  useCallback,
  useEffect,
  useState,
} from "react";

import {FilterIcon} from "assets/icons/FilterIcon";
import {IStructureFilter, TQuery, useApi} from "api";
import {TypeDropdown} from "components/Filters/Popup/TypeDropdown";
import {FiltersPopup} from "components/Filters/Popup/FiltersPopup";
import {FilterProvider} from "components/Filters/provider";
import {useToggle} from "hooks/useToggle";
import {ArrowBottom} from "assets/icons";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {useStore} from "providers";

import {FastFilterIcon} from "./FastFilterIcons";
import S from "./style.module.scss";

interface IProps {
  setQuery: (values: TQuery) => void;
}

export const Filters = ({setQuery}: IProps) => {
  const {api} = useApi();

  const theme = useTheme();

  const [filtersStructureData, setFiltersStructureData] = useState<IStructureFilter[]>([]);
  const [fastFiltersData, setFastFiltersData] = useState<IStructureFilter[]>([]);

  const filterStructureRequest = useCallback(async () => {
    let filtersRequestResult = await api.filters.getFiltersStructure();
    setFiltersStructureData(filtersRequestResult?.filters);
    setFastFiltersData(filtersRequestResult?.fastFilters);
  }, [api]);

  useEffect(() => {
    (async () => filterStructureRequest())();
  }, [filterStructureRequest]);

  const [isFilterPopupValue, toggleFilters, setFilterPopupValue] = useToggle(false);
  const [, toggleTypeDropdown] = useToggle(false);

  const onClickClose = () => setFilterPopupValue(false);

  const {isFooterVisible} = useStore();

  return (
    <div className={classNames(S.FilterWrapper, theme === "help_ua" ? S.FilterHelpUa : "")}>
      <FilterProvider
        onChangeFilter={setQuery}
      >
        <div className={classNames(S.filterChild, S.FixedChild, {[S.FixedHidden]: isFooterVisible})}>
          <button
            className={S.FilterButton}
            onClick={toggleFilters}
          >
            <FilterIcon/>
            <span>Filter</span>
          </button>
        </div>
        {fastFiltersData && fastFiltersData.map((filterItem, index) =>
          <div
            key={index}
            className={classNames(S.filterChild, S.ChildMobile)}
          >
            <TypeDropdown
              dropdownData={filterItem.filters}
              className={index == 0 ? S.TypeDropdownLeft : index == 1 ? S.TypeDropdownRight : ""}
              title={
                <button
                  className={S.FiltersSelect}
                  onClick={toggleTypeDropdown}
                >
                  <span className={S.Icon}>
                    <FastFilterIcon filterType={filterItem.content}/>
                  </span>
                  <span className={S.Text}>{filterItem.title}</span>
                  <span className={S.Arrow}><ArrowBottom/></span>
                </button>
              }
              type={filterItem.type}
            />
          </div>
        )}
        {isFilterPopupValue && (
          <FiltersPopup
            filterInputData={filtersStructureData}
            onClickClose={onClickClose}
          />
        )}
      </FilterProvider>
    </div>
  );
};
