import React, {useEffect, useState} from "react";

import {IFiltersObject, TStructureFilterType} from "api";
import {Dropdown} from "components/Dropdown";
import S from "components/Filters/Popup/style.module.scss";
import {PopupButtons} from "components/Filters/Popup/PopupButtons";
import {TSetFilterValue, useFilter} from "components/Filters/provider";
import {CheckBox} from "assets/icons/CheckBox";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

interface IProps {
  title: string | React.ReactNode;
  dropdownData: IFiltersObject[];
  type: TStructureFilterType;
  className?: string;
}

export const TypeDropdown = ({
  title,
  dropdownData,
  type,
  className,
}: IProps) => {
  const [filters, setFilters] = useState<TSetFilterValue[]>([]);
  const {baseFilterState, setFilter} = useFilter();

  useEffect(() => {
    setFilters(baseFilterState);
  }, [baseFilterState]);

  const [isOpenedDropdown, setIsOpenedDropdown] = useState<boolean>(false);
  const theme = useTheme();
  return (
    <Dropdown
      title={title}
      opened={isOpenedDropdown}
      setOpened={setIsOpenedDropdown}
    >
      <div className={classNames(S.TypeDropdownWrapper, className, theme == "help_ua" ? S.DropdownHelpUa : "")}>
        {dropdownData && dropdownData.map((dropdownItem, key) => {
          const isCheckValue = Boolean(filters?.filter(el => (dropdownItem?.value === el?.value && dropdownItem?.key === el?.key)).length > 0);

          return (
            <label
              className={S.TypeDropdownItem}
              key={key}
            >
              <div className={S.InputCheckBox}>
                <input
                  type="checkbox"
                  checked={isCheckValue}
                  value={dropdownItem.value}
                  onChange={() => setFilter(dropdownItem, isCheckValue, type)}
                />
                <div className={S.Check}><CheckBox/></div>
              </div>
              <div className={S.Title}>
                {dropdownItem?.icon &&
                  <div className={S.Icon}>
                    <img
                      src={dropdownItem.icon}
                      alt={dropdownItem.title}
                    />
                  </div>
                }
                <div className={S.Text}>{dropdownItem.title}</div>
              </div>
            </label>
          );
        })}
        <PopupButtons
          filterClearLists={dropdownData}
          setOpened={setIsOpenedDropdown}
          className={S.PopupButtons}
        />
      </div>
    </Dropdown>
  );
};
