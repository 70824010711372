import React from "react";

import {ClockIcon} from "assets/icons/ClockIcon";
import {INFTNews} from "components/Home/types";
import {IAsset} from "types";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {useTimer} from "hooks/useTimer";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  asset: INFTNews | IAsset;
}

export const CardTimer = ({className, asset}: IProps) => {
  const theme = useTheme();

  const {
    days,
    hours,
    minutes,
    seconds
  } = useTimer({date: asset.currentAuction?.auctionEnd});

  return (
    <div className={classNames(S.TimerWrapper, S[theme], className)}>
      <ClockIcon fill="white"/>
      <div className={S.TimerItem}>
        {days}d&nbsp;
      </div>
      <div className={S.TimerItem}>
        {hours}:
      </div>
      <div className={S.TimerItem}>
        {minutes}:
      </div>
      <div className={S.TimerItem}>
        {seconds}
      </div>
    </div>
  );
};
