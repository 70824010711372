import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const QuestionIcon = ({className, fill = "var(--blue_static)", size = 16}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3585 5.89248C12.0381 5.78749 12.7344 5.86771 13.3714 6.12508C14.0085 6.38251 14.563 6.80796 14.972 7.35706C15.3811 7.90637 15.628 8.55749 15.683 9.23867C15.7379 9.91984 15.5986 10.6017 15.2817 11.2082C14.965 11.8146 14.4842 12.3208 13.8949 12.6731C13.7386 12.7664 13.5688 12.854 13.4321 12.9246C13.3929 12.9449 13.3564 12.9637 13.3236 12.9809C13.1563 13.0688 13.0476 13.1331 12.9671 13.1964C12.8944 13.2534 12.8632 13.2958 12.8452 13.3311C12.8278 13.3653 12.7998 13.4383 12.7998 13.5892V14.6636C12.7998 14.9477 12.5695 15.1779 12.2855 15.1779H11.5998C11.3158 15.1779 11.0855 14.9477 11.0855 14.6636V13.5892C11.0855 13.2105 11.1595 12.8643 11.3169 12.5545C11.4737 12.2458 11.69 12.0195 11.9085 11.848C12.119 11.6826 12.3448 11.5586 12.5264 11.4633C12.5824 11.4339 12.6336 11.4074 12.6814 11.3827C12.8062 11.3183 12.9091 11.265 13.0152 11.2016C13.3365 11.0096 13.5941 10.7364 13.7622 10.4146C13.9302 10.0929 14.0031 9.73384 13.9743 9.3765C13.9454 9.01907 13.8157 8.6745 13.5971 8.38103C13.3784 8.08734 13.0786 7.85574 12.7291 7.71451C12.3795 7.57322 11.9955 7.5287 11.6203 7.58666C11.2451 7.64463 10.8959 7.80238 10.6107 8.04002C10.4611 8.16463 10.3324 8.30852 10.2272 8.46657C10.0699 8.70304 9.78353 8.8545 9.51404 8.76476L8.86347 8.54805C8.59399 8.45829 8.44519 8.16484 8.57196 7.91067C8.79876 7.45607 9.11907 7.05151 9.51336 6.72297C10.0402 6.28406 10.6789 5.99749 11.3585 5.89248Z"
        fill={fill}
      />
      <path
        d="M11.238 17.0379C11.238 16.8013 11.43 16.6094 11.6666 16.6094H12.2217C12.4584 16.6094 12.6503 16.8013 12.6503 17.0379V17.593C12.6503 17.8297 12.4584 18.0216 12.2217 18.0216H11.6666C11.43 18.0216 11.238 17.8297 11.238 17.593V17.0379Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.855469C5.84601 0.855469 0.857178 5.8443 0.857178 11.9983C0.857178 18.1524 5.84601 23.1412 12 23.1412C18.1541 23.1412 23.1429 18.1524 23.1429 11.9983C23.1429 5.8443 18.1541 0.855469 12 0.855469ZM2.57146 11.9983C2.57146 6.79107 6.79278 2.56975 12 2.56975C17.2073 2.56975 21.4286 6.79107 21.4286 11.9983C21.4286 17.2056 17.2073 21.4269 12 21.4269C6.79278 21.4269 2.57146 17.2056 2.57146 11.9983Z"
        fill={fill}
      />
    </svg>
  );
};
