import React from "react";
import ReactTooltip, {Place, Type, Effect} from "react-tooltip";

import QuestionMarkIcon from "assets/icons/QuestionMarkIcon";
import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  id?: string;
  text?: string;
  place?: Place;
  type?: Type;
  effect?: Effect;
  textColor?: string;
  className?: string;
}

export const Tooltip = ({
  id = "base",
  text,
  place = "top",
  type,
  effect = "solid",
  textColor = "var(--text-description)",
  className
}: IProps) => {
  return (
    <div className={classNames(S.TooltipWrapper, className)}>
      <p
        data-tip={text}
        data-for={id}
      >
        <QuestionMarkIcon/>
      </p>
      {/*// @ts-ignore*/}
      <ReactTooltip
        id={id}
        place={place}
        effect={effect}
        type={type}
        backgroundColor="white"
        textColor={textColor}
        multiline={true}
      />
    </div>
  );
};
