import React from "react";
import Lottie from "lottie-react";

import {classNames} from "utils";

import Done from "./done.json";
import S from "./style.module.scss";

interface IProps {
  className?: string;
}

export const StepDone = (
  {
    className
  }: IProps) => {
  return (
    <div className={classNames(S.StepDone, className)}>
      <Lottie
        animationData={Done}
        loop={true}
        autoplay={true}
        className={S.Lottie}
      />
    </div>
  );
};
