import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  borderColor?: string;
}

export const HeartIconActive = ({
  onClick,
  size = 14,
  fill = "#FF2D55",
  className,
}: IProps) => (
  <svg
    onClick={onClick}
    className={className}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99994 2.25411L6.57294 1.83411C5.84488 1.14562 4.87708 0.768106 3.87513 0.781773C2.87318 0.795439 1.91603 1.19921 1.20703 1.9073C0.49802 2.6154 0.0930213 3.57202 0.078066 4.57395C0.0631108 5.57589 0.439378 6.54417 1.12694 7.27311L6.99994 13.1461L12.8729 7.26611C13.5605 6.53717 13.9368 5.56889 13.9218 4.56695C13.9068 3.56502 13.5019 2.6084 12.7928 1.9003C12.0838 1.19221 11.1267 0.788439 10.1247 0.774773C9.12279 0.761106 8.15499 1.13862 7.42694 1.82711L6.99994 2.25411Z"
      fill={fill}
    />
  </svg>
);
