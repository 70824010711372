import React from "react";

import {Container} from "components/Container";
import {Title} from "components/HELPUA/Title";

const listRoadMap = [
  {
    id: 1,
    date: "January - March 2022",
    point: [
      "Website launch 1",
      "IDO",
      "Released whitepapper",
      "Announced Partnerships"
    ]
  },
  {
    id: 2,
    date: "January - March 2022",
    point: [
      "Website launch 2",
      "IDO",
      "Released whitepapper",
      "Announced Partnerships"
    ]
  },
  {
    id: 3,
    date: "January - March 2022",
    point: [
      "Website launch 3",
      "IDO",
      "Released whitepapper",
      "Announced Partnerships"
    ]
  },
  {
    id: 4,
    date: "January - March 2022",
    point: [
      "Website launch 4",
      "IDO",
      "Released whitepapper",
      "Announced Partnerships"
    ]
  },
  {
    id: 5,
    date: "January - March 2022",
    point: [
      "Website launch 5",
      "IDO",
      "Released whitepapper",
      "Announced Partnerships"
    ]
  },
];

export const HomeNFTIMRoadMap = () => {
  return (
    <Container className={"pb-11"}>
      <Title
        title={"ROADMAP STAGE 2"}
        underTitle={"ROADMAP"}
      />
      <div className={"relative block w-full pt-24 pb-6 max1024:pt-10"}>
        <div className={"relative grid grid-cols-5 gap-y-20 w-full max-w-[1400px] mx-auto max1024:grid-cols-2 max1024:max-w-[600px] max1024:gap-y-10 max1024:gap-x-14 max480:grid-cols-1 max480:pl-16"}>
          {listRoadMap.map((item, index) => {
            return (
              <div
                key={item.id}
                className={"relative block min1025:!row-[1] min1025:even:!row-[2] justify-self-center max1024:!col-[1] max1024:even:!col-[2] max480:even:!col-[1] max480:justify-self-start"}
                style={{gridColumn: index+1, gridRow: index+1}}
              >
                <div className={"w-full text-lg leading-5 mb-4 font-bold text-black"}>
                  {item.date}
                </div>
                <div className={"w-full"}>
                  {item.point.map((point) => {
                    return (
                      <div key={point} className={"flex gap-x-2 span:text-text_description span:text-sm span:font-medium span:leading-[17px]"}>
                        <span>•</span>
                        <span>{point}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className={"absolute block w-full min481:center-xy max1024:w-[unset] max1024:h-full"}>
            <div className={"relative grid w-full gap-y-10 before:absolute before:block min1025:before:w-[calc(100%-calc(100%/5))] min1025:before:h-1 before:bg-black before:center-xy grid-cols-5 max1024:grid-cols-1 max1024:before:w-1 max1024:before:h-[calc(100%-calc(100%/5-24px))] max1024:w-[unset] max1024:h-full"}>
              {listRoadMap.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={"relative flex items-center justify-center w-10 h-10 rounded-[50%] bg-black text-white text-lg font-bold justify-self-center self-center"}
                  >
                      Q{index+1}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
