import React, {FormEvent, ReactNode} from "react";
import {useTranslation} from "react-i18next";

import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  type?: "submit" | "button";
  color?: "primary" | "primary_o" | "secondary" | "secondary_o" | "ghost" | "error" | "static";
  onClick?: (e?: FormEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children?: ReactNode | any;
  title?: string;
  className?: string;
  classNameText?: string;
  disabled?: boolean;
  doubleHover?: "colorWhite" | "colorBlack" | "colorDefault" | "colorWhiteIcon" | "colorBlackIcon";
}

export const Button = ({
  type = "button",
  color = "primary",
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  title,
  className,
  classNameText,
  disabled,
  doubleHover = "colorDefault",
}: IProps) => {
  const {t} = useTranslation();

  const theme = useTheme();

  return (
    <button
      className={classNames(S.Button, S[theme], S[color], className, S[doubleHover])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <div className={classNames(S.Title, S[color], classNameText)}>
        <>
          {children && children}
          {title && t(title || "")}
        </>
      </div>
    </button>
  );
};
