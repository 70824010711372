import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  borderColor?: string;
}

export const ShareIcon = ({
  onClick,
  size = 14,
  className,
  fill = "black"
}: IProps) => (
  <svg
    className={className}
    onClick={onClick}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.97 2.3333C12.97 3.38037 12.1213 4.2292 11.0742 4.2292C10.0271 4.2292 9.17841 3.38037 9.17841 2.3333C9.17841 1.28633 10.0271 0.4375 11.0742 0.4375C12.1213 0.4375 12.97 1.28633 12.97 2.3333V2.3333Z"
      fill={fill}
    />
    <path
      d="M11.0742 4.6667C9.78734 4.6667 8.74091 3.62016 8.74091 2.3333C8.74091 1.04654 9.78734 0 11.0742 0C12.3611 0 13.4075 1.04654 13.4075 2.3333C13.4075 3.62016 12.3611 4.6667 11.0742 4.6667ZM11.0742 0.875C10.2698 0.875 9.61591 1.52954 9.61591 2.3333C9.61591 3.13716 10.2698 3.7917 11.0742 3.7917C11.8786 3.7917 12.5325 3.13716 12.5325 2.3333C12.5325 1.52954 11.8786 0.875 11.0742 0.875Z"
      fill={fill}
    />
    <path
      d="M12.97 11.6664C12.97 12.7134 12.1213 13.5622 11.0742 13.5622C10.0271 13.5622 9.17841 12.7134 9.17841 11.6664C9.17841 10.6193 10.0271 9.77051 11.0742 9.77051C12.1213 9.77051 12.97 10.6193 12.97 11.6664V11.6664Z"
      fill={fill}
    />
    <path
      d="M11.0742 13.9997C9.78734 13.9997 8.74091 12.9532 8.74091 11.6664C8.74091 10.3795 9.78734 9.33301 11.0742 9.33301C12.3611 9.33301 13.4075 10.3795 13.4075 11.6664C13.4075 12.9532 12.3611 13.9997 11.0742 13.9997ZM11.0742 10.208C10.2698 10.208 9.61591 10.8625 9.61591 11.6664C9.61591 12.4702 10.2698 13.1247 11.0742 13.1247C11.8786 13.1247 12.5325 12.4702 12.5325 11.6664C12.5325 10.8625 11.8786 10.208 11.0742 10.208V10.208Z"
      fill={fill}
    />
    <path
      d="M4.80342 7.00029C4.80342 8.04736 3.95459 8.89609 2.90752 8.89609C1.86055 8.89609 1.01172 8.04736 1.01172 7.00029C1.01172 5.95322 1.86055 5.10449 2.90752 5.10449C3.95459 5.10449 4.80342 5.95322 4.80342 7.00029Z"
      fill={fill}
    />
    <path
      d="M2.90752 9.33359C1.62076 9.33359 0.574219 8.28716 0.574219 7.00029C0.574219 5.71342 1.62076 4.66699 2.90752 4.66699C4.19438 4.66699 5.24092 5.71342 5.24092 7.00029C5.24092 8.28716 4.19438 9.33359 2.90752 9.33359ZM2.90752 5.54199C2.10312 5.54199 1.44922 6.19643 1.44922 7.00029C1.44922 7.80415 2.10312 8.45859 2.90752 8.45859C3.71202 8.45859 4.36592 7.80415 4.36592 7.00029C4.36592 6.19643 3.71202 5.54199 2.90752 5.54199Z"
      fill={fill}
    />
    <path
      d="M4.28484 6.72004C4.08179 6.72004 3.88462 6.6144 3.77727 6.42545C3.61802 6.14603 3.71607 5.7896 3.99549 5.62971L9.40816 2.54392C9.68758 2.38349 10.044 2.48154 10.2039 2.76203C10.3632 3.04145 10.2651 3.39788 9.98569 3.55778L4.57291 6.64356C4.48191 6.69547 4.38279 6.72004 4.28484 6.72004V6.72004Z"
      fill={fill}
    />
    <path
      d="M9.69697 11.5324C9.59891 11.5324 9.49979 11.5079 9.40879 11.456L3.99602 8.37017C3.7166 8.21091 3.61866 7.85448 3.77791 7.57442C3.93653 7.29446 4.29349 7.19588 4.57355 7.35631L9.98632 10.4421C10.2657 10.6014 10.3637 10.9578 10.2044 11.2379C10.0965 11.4268 9.89937 11.5324 9.69697 11.5324V11.5324Z"
      fill={fill}
    />
  </svg>
);
