import React, {useRef} from "react";

import {EditProfile} from "components/EditProfile";
import {Button} from "components/Button";
import {Popup, PopupSubContentHeaderWithChildren} from "components/Popups";

import S from "./style.module.scss";


interface IProps {
  onClose?: () => void;
  closePopup?: () => void;
}

interface ISubmitForm {
  submitForm (): void;
}

export const EditProfilePopup = (
  {
    onClose,
  }: IProps) => {

  const editProfileRef = useRef<ISubmitForm>();

  const handleSubmit = () => {
    editProfileRef?.current?.submitForm();
  };

  return (
    <Popup
      maxWidth={"576px"}
      clickOnClose={onClose}
      classNameContent={S.Modal}
    >
      <PopupSubContentHeaderWithChildren
        title={"Edit profile"}
        className={S.Header}
      >
        <Button
          color="primary"
          onClick={handleSubmit}
          className={S.SaveButton}
        >
          <span>Save<i> changes</i></span>
        </Button>
      </PopupSubContentHeaderWithChildren>

      <EditProfile ref={editProfileRef}/>
    </Popup>
  );
};
