import React, {useMemo} from "react";

import {classNames} from "utils";
import {useTimer} from "hooks/useTimer";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  classNameTimer?: string;
  expiryTimestamp?: Date;
  isSmall?: boolean;
  isEnded?: boolean;
}

export const TimerWrapper = ({
  className,
  expiryTimestamp = new Date(),
  isSmall = false,
  isEnded = false,
}: IProps) => {

  const {
    days,
    hours,
    minutes,
    seconds,
  } = useTimer({date: expiryTimestamp});

  const classNameTimerItem = useMemo(() => classNames(S.TimerTime, {[S.Small]: isSmall}), [isSmall]);
  const classNameTimerName = useMemo(() => classNames(S.TimerName, {[S.Small]: isSmall}), [isSmall]);
  const classNameTimerDots = useMemo(() => classNames(S.TimerDots, {[S.Small]: isSmall}), [isSmall]);

  if (isEnded) {
    return (
      <div className={classNames(className, S.Timer, {[S.Small]: isSmall})}/>
    );
  }

  return (
    <div className={classNames(className, S.Timer, {[S.Small]: isSmall})}>
      <div className={S.TimerItem}>
        <b className={classNameTimerItem}>{days}</b>
        <span className={classNameTimerName}>days</span>
      </div>
      <p className={classNameTimerDots}>:</p>
      <div className={S.TimerItem}>
        <b className={classNameTimerItem}>{hours}</b>
        <span className={classNameTimerName}>hours</span>
      </div>
      <p className={classNameTimerDots}>:</p>
      <div className={S.TimerItem}>
        <b className={classNameTimerItem}>{minutes}</b>
        <span className={classNameTimerName}>minutes</span>
      </div>
      <p className={classNameTimerDots}>:</p>
      <div className={S.TimerItem}>
        <b className={classNameTimerItem}>{seconds}</b>
        <span className={classNameTimerName}>seconds</span>
      </div>
    </div>
  );
};
