import React from "react";

import {HomeNFTIMHeader} from "components/NFTIM/Home/Header";
import {HomeNFTIMTrendingCollection} from "components/NFTIM/Home/TrendingCollection";
import {HomeNFTIMHotAuction} from "components/NFTIM/Home/HotAuction";
import {HomeNFTIMExploreNFTs} from "components/NFTIM/Home/ExploreNFTs";
import {HomeNFTIMAbout} from "components/NFTIM/Home/About";
import {HomeNFTIMCelebrity} from "components/NFTIM/Home/Celebrities";
import {classNames} from "utils";
import {HomeNFTIMRoadMap} from "components/NFTIM/Home/RoadMap";
import {HomeNftimPartners} from "components/NFTIM/Home/Partners";
import {TopCelebrityMarketplace} from "components/NFTIM/Home/TopCelebrityMarketplace";
import {HomeNFTIMLaunchpad} from "components/NFTIM/Home/Launchpad";
import {TopBrandMarketplace} from "components/NFTIM/Home/TopBrandMarketplace";
import {TopUser} from "components/NFTIM/Home/TopUser";

import S from "./style.module.scss";

export const HomeNFTIM = () => {
  return (
    <div className={classNames("overflow-hidden", S.MainMarginBottom)}>
      <HomeNFTIMHeader/>
      <HomeNFTIMTrendingCollection/>
      {/*<HomeNFTIMHotAuction/>*/}
      <TopCelebrityMarketplace/>
      <HomeNFTIMLaunchpad/>
      {/*<TopUser/>*/}
      <TopBrandMarketplace/>
      <HomeNFTIMExploreNFTs limit={8}/>
      <HomeNFTIMAbout/>
      <HomeNFTIMCelebrity/>
      <HomeNFTIMRoadMap/>
      <HomeNftimPartners/>
    </div>
  );
};

