import React from "react";

import {classNames} from "utils";
import {useCreateAsset} from "providers/CreateAsset";

import S from "./style.module.scss";

interface IProps {
  title?: string;
  prevStep?: () => void;
}

export const StepTitle = ({title, prevStep}: IProps) => {
  const {isLoading} = useCreateAsset();

  return (
    <div className={classNames(S.StepTitle)}>
      <div className={S.Title}>{title}</div>
      {prevStep && (
        <div
          className={S.BackStep}
          onClick={() => isLoading ? () => {
          } : prevStep()}
        >
          {`<`} Back to previous step
        </div>
      )}
    </div>
  );
};
