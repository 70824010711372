import React from "react";

import S from "./style.module.scss";

interface IProps {
  list: string[];
}

export const List = ({list}: IProps) => {
  return (
    <ul className={S.List}>
      {list.map((el, key) => {
        return (
          <li key={key}>{el}</li>
        );
      })}
    </ul>
  );
};
