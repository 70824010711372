import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const ExploreIcon = ({size = 36, fill = "black"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 16.35C17.0925 16.35 16.35 17.0925 16.35 18C16.35 18.9075 17.0925 19.65 18 19.65C18.915 19.65 19.65 18.9075 19.65 18C19.65 17.0925 18.915 16.35 18 16.35ZM18 3C9.7125 3 3 9.7125 3 18C3 26.28 9.7125 33 18 33C26.2875 33 33 26.28 33 18C33 9.7125 26.2875 3 18 3ZM21.285 21.285L9 27L14.715 14.715L27 9L21.285 21.285Z"
        fill={fill}
      />
    </svg>
  );
};
