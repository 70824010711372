import React from "react";
import {Link} from "react-router-dom";

import {Time} from "components/Time";
import {useAsset} from "providers";
import {Price} from "components/Price";
import {UserInfo} from "pages/Asset/UserInfo";
import {AssetTabsItemSkeleton} from "pages/Asset/AssetSkeleton/AssetTabsItemSkeleton";
import {AssetsTables} from "components/Asset/AssetTabs/Tables/index";

export const HistoryContent = () => {
  const {asset, histories} = useAsset();

  return (
    <>
      <AssetsTables>
        {(histories && histories.length > 0) && histories?.map((history) => {
          return (
            <tr key={history.id}>
              <td>
                <UserInfo
                  avatar={history.user?.thumbnailPhoto || history.userFrom?.thumbnailPhoto}
                  title={history.user?.displayName || history.userFrom?.displayName}
                  text={
                    <>
                      {asset && (
                        <Link to={`/profile/${history?.user?.id || history.userFrom?.id}`}>
                          {history.user?.displayName || history.userFrom?.displayName}
                        </Link>
                      )
                      }
                    </>
                  }
                  label={
                    <>
                      {history?.name}, <Time date={history?.createdAt}/>
                    </>
                  }
                />
              </td>
              <td>
                <div className={"relative w-full flex justify-end"}>
                  {history.price &&
                    <Price
                      symbol={history?.currency?.symbol}
                      decimals={history?.currency?.decimals}
                      price={history.price}
                      priceFiatSize={"text-xs"}
                    />
                  }
                </div>
              </td>
            </tr>
          );
        })}
      </AssetsTables>
      {!histories && <AssetTabsItemSkeleton/>}
    </>
  );
};
