import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export interface IFAQBlock {
  path: string;
  title: string;
  description?: string;
  children?: Record<string, IFAQBlock>;
}

export type TFAQBlock = Record<string, IFAQBlock>

import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";
import {objectDeepValue} from "utils/src/object-utils";
import S from "pages/HelpCenter/Pages/FAQ/style.module.scss";

import pages from "./pages.json";

interface IProps {

}

// TODO get info from pages.json filter and show only needed information
export const HelpCenterPageFAQChildren = (props: IProps) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [currentPage, setCurrentPage] = useState<IFAQBlock | undefined>();

  useEffect(() => {
    const activePath = pathname.split("/").splice(3).join(".children.");
    setCurrentPage(objectDeepValue(pages, activePath));
  }, [pathname]);

  return (
    <>
      {currentPage &&
        <div className={S.FAQPage}>
          <HelpCenterPageHeader
            title={currentPage.title}
          />
          {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
          <div dangerouslySetInnerHTML={{__html: currentPage.description || ""}}/>
          {currentPage.children &&
            <ul className={S.LinkList}>
              {Object.keys(currentPage.children).map((el, key) => {
                return (
                  <li
                    key={key}
                    onClick={() => navigate(`${pathname}/${currentPage?.children?.[el]?.path}`)}
                  >
                    {currentPage?.children?.[el]?.title}
                  </li>
                );
              })}
            </ul>
          }
        </div>
      }
    </>
  );
};
