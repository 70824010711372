import React from "react";

import {Container} from "components/Container";
import {NFTIMCollectionContainer} from "components/NFTIM/Collection/Container";

import S from "./style.module.scss";

export const AllCollectionPage = () => {

  return (
    <Container className={S.Container}>
      <div className={S.Title}>Collections NFTs</div>
      <NFTIMCollectionContainer className={S.ContainerCollection}/>
    </Container>
  );
};
