import {useMemo} from "react";

import {INFTNews} from "components/Home/types";
import {IAsset} from "types";

export const useAssetBlockPrice = (asset: INFTNews | IAsset): string => {
  return useMemo(
    () => {
      if (asset.hasAuction && asset.currentAuction?.highestBid === "0") {
        return asset?.currentAuction?.minimumBid || asset.currentMarketplace?.price || "0";
      } else if (asset.hasAuction && asset.currentAuction?.highestBid !== "0") {
        return asset?.currentAuction?.highestBid || asset.currentMarketplace?.price || "0";
      } else if (asset.hasMarketplace) {
        return asset.currentMarketplace?.price || "0";
      }
      return "0";
    },
    [
      asset.currentAuction?.highestBid,
      asset.currentAuction?.minimumBid,
      asset.currentMarketplace?.price,
      asset.hasAuction,
      asset.hasMarketplace
    ]
  );
};
