import {ApiConfig, TApiConfigHeadersProps} from "api/src/api/api.config";
import {IExtraPrams} from "api/src/api/api.init";

const headers: TApiConfigHeadersProps = {};
const baseUrl = process.env.REACT_APP_WYRE_LINK || "";

headers["Accept"] = `application/json`;
headers["Content-Type"] = `application/json`;
headers["Authorization"] = `Bearer ${process.env.REACT_APP_WYRE_SK}`;

export const httpWyre = new ApiConfig<IExtraPrams>({
  baseUrl,
  config: {
    headers
  }
});
