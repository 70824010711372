import React from "react";

import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export const AssetsTables = ({children, className}: IProps) => {
  const theme = useTheme();

  return (
    <>
      <div className={classNames(S.AssetsContentWrapper, className)}>
        <table className={S[theme]}>
          <tbody>
            {children}
          </tbody>
        </table>
      </div>
    </>
  );
};
