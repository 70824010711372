import React from "react";

export const NotificationTransferred = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26385)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 22.0624L18 22.6991C18 23.1137 17.7716 23.4951 17.406 23.6908C17.0398 23.8866 16.596 23.8652 16.2512 23.6351L12.7648 21.3109C12.4521 21.1022 12.2642 20.7512 12.2642 20.3749C12.2642 19.9986 12.4521 19.6476 12.7648 19.4389L16.2512 17.1146C16.596 16.8846 17.0398 16.8632 17.406 17.059C17.7716 17.2547 18 17.6361 18 18.0506L18 18.6874L22.5 18.6874C23.1216 18.6874 23.625 19.1908 23.625 19.8124L23.625 20.9374C23.625 21.559 23.1216 22.0624 22.5 22.0624L18 22.0624ZM18 15.3124L13.5 15.3124C12.8784 15.3124 12.375 14.809 12.375 14.1874L12.375 13.0624C12.375 12.4408 12.8784 11.9374 13.5 11.9374L18 11.9374L18 11.3006C18 10.8861 18.2284 10.5047 18.594 10.309C18.9602 10.1132 19.404 10.1346 19.7488 10.3646L23.2352 12.6889C23.5479 12.8976 23.7358 13.2486 23.7358 13.6249C23.7358 14.0012 23.5479 14.3522 23.2352 14.5609L19.7488 16.8851C19.404 17.1152 18.9602 17.1366 18.594 16.9408C18.2284 16.7451 18 16.3637 18 15.9491L18 15.3124Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26385"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6441A5"/>
          <stop
            offset="1"
            stopColor="#2A0845"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
