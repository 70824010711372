import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import Slider from "react-slick";

import {useApi} from "api";
import {errorChecker} from "utils";
import {TIsLoading} from "types";
import {CardSkeleton} from "components/Home/CardBlockNew/CardSkeleton";
import {CardCarouselBig, CardCarouselMacBook} from "components/Responsive";
import {CardNFTIM} from "components/NFTIM/Card";

import S from "./style.module.scss";

interface IProps {
  isFade?: boolean;
}

const NextArrow = (props: any) => {
  const {onClick} = props;
  return (
    <div
      className={S.Next}
      onClick={onClick}
    />
  );
};

const PreviousArrow = (props: any) => {
  const {onClick} = props;
  return (
    <div
      className={S.Prev}
      onClick={onClick}
    />
  );
};

export const Carousel = ({isFade = false}: IProps) => {
  const {api} = useApi();

  const [assets, setAssets] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<TIsLoading>("first");

  const assetsData = useCallback(async () => {
    setIsLoading("first");

    try {
      let res = await api.slider.getSlides({
        limit: 1
      });
      let resResult = res.map((item: any) => item?.object);
      setAssets(resResult);
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoading("loaded");
    }
  }, [api]);

  useEffect(() => {
    (async () => assetsData())();
  }, [assetsData]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (<NextArrow/>),
    prevArrow: (<PreviousArrow/>),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ]
  };

  const renderSlider = () => {
    if (isLoading === "first") {
      return (
        <>
          <CardCarouselBig>
            <CardSkeleton
              width={380}
              height={487}
            />
          </CardCarouselBig>
          <CardCarouselMacBook>
            <CardSkeleton
              width={300}
              height={407}
            />
          </CardCarouselMacBook>
        </>
      );
    }

    if (isLoading === "loaded") {
      return (
        // @ts-ignore
        <Slider {...settings} fade={isFade}>
          {assets.map((asset, index) =>
            <CardNFTIM
              key={index}
              asset={asset}
              staticWidth={true}
              className={S.CarouselItem}
            />
          )}
        </Slider>
      );
    }

    return <></>;
  };

  return (
    <div className={S.Wrapper}>
      {renderSlider()}
    </div>
  );
};
