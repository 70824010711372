import React, {useCallback, useMemo, useRef, useState} from "react";

import {classNames} from "utils";
import S from "components/CreateNft/StepContent/StepLoad/style.module.scss";
import {useCreateAsset} from "providers/CreateAsset";

export const StepLoadCollection = ({description, name}: { description: string, name: string }) => {
  const {ipfsMimeTypesFull} = useCreateAsset();

  const fileRef = useRef<HTMLInputElement>(null);
  const [errorText, setErrorText] = useState<string>("");
  const [isName, setName] = useState<string>();
  const [isImage, setImage] = useState<string>();

  const filterImageType = useMemo(() => {
    return ipfsMimeTypesFull.filter((el) => el.split("/")[0] === "image");
  }, [ipfsMimeTypesFull]);

  const addFile = useCallback(() => {
    if (fileRef.current?.files && fileRef.current?.files[0]) {
      let fileArray = fileRef.current?.files[0];
      if (filterImageType.includes(fileArray.type)) {
        setErrorText("");
        setName(fileArray.name);
        setImage(URL.createObjectURL(fileArray));
      } else {
        setErrorText(`${filterImageType.map((el) => el.split("/")[1].toUpperCase()).join(", ")}.`);
      }
    } else {
      setErrorText("");
      setName("");
      setImage("");
    }
  }, [filterImageType]);

  return (
    <div className={classNames(S.StepLoad, S.StepLoadSecond, {[S.Error]: !!errorText}, "min-w-0")}>
      {isImage && (
        <div
          className={"absolute w-full h-full top-0 left-0 rounded-[7px] bg-cover bg-no-repeat bg-center"}
          style={{backgroundImage: `url(${isImage})`}}
        />
      )}
      <div className={classNames(S.Text, "relative block max-w-full min-w-0")}>
        {isName ? (
          <div
            className={classNames(S.NoWrap, "max-w-full min-w-0 relative block")}
            title={isName}
          >
            {isName}
          </div>
        ) : (
          <>
            {description}
            {errorText && (
              <p>{errorText}.</p>
            )}
          </>
        )}
      </div>
      <div className={S.LoadButton}>
        <span>{isName ? "Change File" : "Choose File"}</span>
        <input
          name={name}
          type="file"
          onChange={addFile}
          ref={fileRef}
          accept={filterImageType.join(",")}
          required={true}
        />
      </div>
    </div>
  );
};
