export function objectDeepValue<T> (object: Record<string, any>, path: string): T | undefined {
  try {
    const splitPath = path.split(".");
    const pathLength = splitPath.length;
    for (let i = 0; i < pathLength; i++) {
      object = object[splitPath[i]];
    }

    return object as T;
  } catch (e) {
    return undefined;
  }
}
