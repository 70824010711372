import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const ArrowIcon = ({size = 16, fill = "var(--blue_static)", className}: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.425 10.7305L15.1562 16.4617L20.8875 10.7305L22.6562 12.4992L15.1562 19.9992L7.65625 12.4992L9.425 10.7305Z"
        fill={fill}
      />
    </svg>
  );
};
