import React from "react";

import {EStep, useCreateAsset} from "providers/CreateAsset";

import S from "./style.module.scss";

export const StepScale = () => {
  const {step} = useCreateAsset();

  return (
    <div className={S.StepScale}>
      <ul>
        {Array.from(["choseChain", "uploadFile", "addInformation", "done"]).map((item, index) => {
          return (
            <li
              key={index}
              className={EStep[step] > index ? S.Active : ""}
            >
              <span/>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
