import React from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  count: number;
}

export const Amount = ({className, count}: IProps) => {
  return (
    <div className={classNames(S.AmountBlock, className)}>
      <p><span>{count}</span> {count > 1 ? "Items" : "Item"}</p>
    </div>
  );
};
