import React, {useRef} from "react";
import Slider from "react-slick";

import {ICollection, TIsLoading} from "types";
import {NFTIMCollectionCard} from "components/NFTIM/Collection/Card";
import {IconArrowSliderCollection} from "assets/icons/SliderArrowCollection";
import {EmptyItems} from "components/EmptyItems";

interface IProps {
  className?: string;
  collection?: ICollection[];
  status?: TIsLoading;
}

export const CardsCollections = (
  {
    collection,
  }: IProps) => {
  const sliderArrows = useRef(null);

  const PreviousArrow = () => {
    return (
      <div
        className={`absolute hidden items-center justify-center w-[42px] h-[42px] bg-white border border-solid border-inner_border z-10 top-[50%] left-[-21px] max1360:!left-[-16px] translate-y-[-50%] rounded-full cursor-pointer tr-all min1025:hover:bg-light_gray svg:tr-all min1025:svg:hover:scale-x-[1.2] ${collection && collection?.length > 1 && "max640:!flex"} ${collection && collection?.length > 2 && "max1024:!flex"} ${collection && collection?.length > 3 && "max1280:!flex"} ${collection && collection?.length > 4 && "min1281:!flex"}`}
        // @ts-ignore
        onClick={() => sliderArrows?.current?.slickPrev()}
      >
        <IconArrowSliderCollection/>
      </div>
    );
  };

  const NextArrow = () => {
    return (
      <div
        className={`absolute hidden items-center justify-center w-[42px] h-[42px] bg-white border border-solid border-inner_border z-10 top-[50%] right-[-21px] max1360:!right-[-16px] translate-y-[-50%] rounded-full cursor-pointer tr-all min1025:hover:bg-light_gray svg:tr-all min1025:svg:hover:scale-x-[1.2] ${collection && collection?.length > 1 && "max640:!flex"} ${collection && collection?.length > 2 && "max1024:!flex"} ${collection && collection?.length > 3 && "max1280:!flex"} ${collection && collection?.length > 4 && "min1281:!flex"}`}
        // @ts-ignore
        onClick={() => sliderArrows?.current?.slickNext()}
      >
        <IconArrowSliderCollection className={"rotate-180"}/>
      </div>
    );
  };

  const settings = {
    arrows: true,
    dots: true,
    infinite: collection && collection?.length > 4,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: collection && collection?.length > 4 ? 4.2 : 4,
    slidesToScroll: 1,
    swipe: false,
    initialSlide: 0,
    appendDots: (dots: any) => {
      return (
        <>
          <div className={"relative w-full mt-4 max640:!px-0"}>
            <ul className={"relative flex w-full h-[5px] bg-grey_border li:relative li:flex-1 liButton:relative liButton:w-full liButton:h-full liButton:text-[0px] liButton:tr-all liSlickButton:bg-black"}>
              {dots}
            </ul>
          </div>
        </>
      );
    },
    responsive: [
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 4,
          infinite: collection && collection?.length > 3,
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          infinite: collection && collection?.length > 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: collection && collection?.length > 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          infinite: collection && collection?.length > 1,
        }
      }
    ]
  };

  return (
    <>
      {collection && collection.length > 0 && (
        <>
          <PreviousArrow/>
          <div className={"relative w-full overflow-hidden"}>
            {/*@ts-ignore*/}
            <Slider
              className={`${collection && collection?.length > 4 && "min1361:slick-track:left-[74px]"} w-[calc(100%+16px)] div:relative div:text-[0px]  slick-track:min-w-full`}

              {...settings}
              ref={sliderArrows}
            >
              {collection && collection.map((item) =>
                <aside key={item.id} className={"w-full pr-4"}>
                  <NFTIMCollectionCard collection={item}/>
                </aside>
              )}
            </Slider>
          </div>
          <NextArrow/>
        </>
      )}
      {collection && collection.length === 0 && (
        <EmptyItems/>
      )}
    </>
  );
};
