import React, {FormEvent} from "react";

import {Input} from "components/Input";
import {Button} from "components/Button";
import {ContactUsIcon} from "assets/icons/ContactUsIcon";
import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

// TODO request to send information to BE
export const HelpCenterPageContactUs = () => {
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <HelpCenterPageHeader
        title={"Contact us"}
      />
      <form onSubmit={onSubmit}>
        <Input label={"Your email address"}/>
        // select component no in component list <b>create task</b>
        {/*<Select label={"What can we help with?"}/>*/}
        <Input
          label={"Description"}
          afterLabel={"Please enter as many details as you can. We will respond as soon as possible."}
        />
        // input file this only fiction need <b>create task</b> and add file component
        <Input
          type={"file"}
          label={"Attachments(optional)"}
        />
        <Button
          className={"w-1/4"}
          type={"submit"}
        >
          <ContactUsIcon
            size={24}
            fill={"var(--white)"}
          />&nbsp;&nbsp;Submit message
        </Button>
      </form>
    </>
  );
};
