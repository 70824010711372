import React from "react";

export const NotificationAuction = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26375)"
      />
      <path
        d="M18 25.4375C16.3312 25.4375 14.6999 24.9427 13.3124 24.0155C11.9248 23.0884 10.8434 21.7706 10.2048 20.2289C9.56616 18.6871 9.39907 16.9906 9.72463 15.3539C10.0502 13.7172 10.8538 12.2138 12.0338 11.0338C13.2138 9.85378 14.7172 9.05019 16.3539 8.72463C17.9906 8.39907 19.6871 8.56616 21.2289 9.20477C22.7706 9.84338 24.0884 10.9248 25.0155 12.3124C25.9427 13.6999 26.4375 15.3312 26.4375 17C26.4375 19.2378 25.5486 21.3839 23.9662 22.9662C22.3839 24.5486 20.2378 25.4375 18 25.4375ZM18 9.6875C16.5537 9.6875 15.1399 10.1164 13.9374 10.9199C12.7349 11.7234 11.7976 12.8654 11.2441 14.2016C10.6907 15.5378 10.5459 17.0081 10.828 18.4266C11.1102 19.8451 11.8066 21.1481 12.8293 22.1707C13.852 23.1934 15.1549 23.8898 16.5734 24.172C17.9919 24.4542 19.4622 24.3093 20.7984 23.7559C22.1346 23.2024 23.2766 22.2651 24.0801 21.0626C24.8836 19.8601 25.3125 18.4463 25.3125 17C25.3125 15.0606 24.5421 13.2006 23.1707 11.8293C21.7994 10.4579 19.9394 9.6875 18 9.6875Z" fill="white"/>
      <path
        d="M20.385 20.1838L17.6006 17.3994C17.5485 17.3468 17.5073 17.2845 17.4793 17.2159C17.4513 17.1474 17.4371 17.074 17.4375 17V12.5H18.5625V16.7694L21.1838 19.385L20.385 20.1838Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26375"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE35B"/>
          <stop
            offset="1"
            stopColor="#45B649"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
