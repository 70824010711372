import React, {ChangeEvent, useEffect, useState} from "react";

import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

// TODO create self component for file
interface IProps {
  className?: string;
  label?: string;
  type?: "text" | "number" | "email" | "password" | "file";
  style?: "normal" | "unset";
  name?: string;
  value?: string;
  placeholder?: string;
  afterLabel?: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isError?: boolean;
  ref?: any;
  children?: React.ReactNode;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  step?: number | string;
  min?: number;
  max?: number;
  textarea?: boolean;
  rows?: number;
  required?: boolean;
}

export const Input = ({
  className,
  afterLabel,
  label,
  isError = false,
  onBlur = () => {
  },
  onClick,
  onChange,
  readOnly,
  placeholder,
  type,
  style = "normal",
  value,
  name,
  onFocus,
  ref,
  children,
  minLength,
  maxLength,
  pattern,
  min,
  max,
  rows,
  textarea = false,
  required = false,
  step
}: IProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const theme = useTheme();

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setInputValue(v);
    onChange && onChange(v);
  };


  return (
    <fieldset className={classNames("relative w-full mt-0 mb-6", className)}>
      {label && <label
        className={"text-black font-bold mb-[6px]"}
        htmlFor={name}
      >
        {label}
      </label>}
      {textarea ? (
        <div className={classNames(S.Textarea, S[theme])}>
          <textarea
            minLength={minLength}
            maxLength={maxLength}
            className={classNames({[S.error]: isError}, S[theme])}
            id={name}
            name={name}
            ref={ref}
            value={inputValue}
            // @ts-ignore
            onChange={updateValue}
            placeholder={placeholder}
            onClick={onClick}
            // @ts-ignore
            onBlur={(e) => onBlur(e)}
            readOnly={readOnly}
            onFocus={onFocus}
            rows={rows}
            required={required}
          />
          <span/>
        </div>
      ) : (
        <input
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          className={`relative block w-full h-[42px] outline-0 ease-all text-sm leading-4 text-dark px-4 rounded-lg inputPlaceholder 
          ${style === "normal" && "border border-solid border-[#E5EAEE] bg-white focus:shadow-[-4px_4px_5px_rgba(0,0,0,0.1)]"}
          ${style === "unset" && "border-none bg-transparent"}
          ${isError && "text-red"}
          `}
          id={name}
          name={name}
          type={type}
          ref={ref}
          value={inputValue}
          onChange={updateValue}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={(e) => onBlur(e)}
          readOnly={readOnly}
          onFocus={onFocus}
          min={min}
          max={max}
          required={required}
          step={step}
        />
      )}

      {children && children}
      {isError && <div className={classNames(S.UnderInputLabel, {[S.error]: isError})}>
        {afterLabel}
      </div>}
    </fieldset>
  );
};
