export interface ICookiesOptions {
  path?: string;
  domain?: string;
  expirationDate?: string;
}

export interface ICookiesReturn {
  setItem (name: string, value?: string, payload?: ICookiesOptions): void;

  getItem (name: string): string;

  check (name: string): any;

  remove (name: string, payload?: ICookiesOptions): any;

  removeAll (): any;
}

export const cookies = (function (): ICookiesReturn {
  const check = (name: string): boolean => {
    if (typeof window === "object") {
      return document.cookie.split(";").some(c => {
        return c.trim().startsWith(name + "=");
      });
    }

    return false;
  };

  const remove = (name: string, payload?: ICookiesOptions) => {
    if (typeof window === "object") {
      if (check(name)) {
        document.cookie = name + "=" +
          ((payload?.path)
            ? ";path=" + payload?.path
            : ";path=/") +
          ((payload?.domain)
            ? ";domain=" + payload?.domain
            : "") +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    }
  };

  const removeAll = () => {
    if (typeof window === "object") {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    }
  };

  const set = (name: string, value: string, payload?: ICookiesOptions): void => {
    if (typeof window === "object") {
      let d = new Date();
      d.setDate(d.getDate() + 1);

      remove(name, {
        path: payload?.path
      });
      document.cookie = `${name}=${value}; path=${payload?.path || "/"}; expires=${payload?.expirationDate || d}`;
    }
  };

  const get = (name: string): string => {
    if (typeof window === "object") {
      let getValue = document.cookie.match(new RegExp(
        "(?:^|; )" + `${name}`.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"
      )) || "";
      return getValue?.[1] || "";
    }

    return "";
  };

  return {
    setItem: set,
    getItem: get,
    check,
    remove,
    removeAll
  };
})();
