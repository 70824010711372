import React, {useState} from "react";

import {EyeIcon} from "assets/icons/EyeIcon";
import {HeartIcon} from "assets/icons/HeartIcon";
import {ShareIcon} from "assets/icons/ShareIcon";
import {SharePopup} from "components/Popups/SharePopup";

interface IProps {
  object?: any;
}

export const PostInner = (
  {
    object
  }:IProps) => {
  const [isShare, setShare] = useState<boolean>(false);

  return (
    <div className={"relative flex items-start gap-4 next:mt-10 max480:gap-2"}>
      <div
        className={"relative block w-12 h-12 bg-cover bg-no-repeat bg-center flex-none rounded-full max480:w-8 max480:h-8 max480:absolute"}
        style={{backgroundImage: `url(${object?.avatar})`}}
      />
      <div className={"relative block flex-1 min-w-0 pt-4 max480:pt-2"}>
        <div className={"relative block mb-[10px] text-sm font-medium leading-4 text-black max480:pl-10 max480:mb-3"}>
          {object?.name}
        </div>
        <div className={"relative block text-lg leading-[22px] font-medium text-black max480:text-base max480:leading-5"}>
          {object?.title}
        </div>
        <div className={"relative block mt-4 *in:text-sm *in:leading-4 *in:font-medium *in:text-black *:text-sm *:leading-4 *:font-medium ain:text-blue_static hover:ain:underline imgIn:max-w-full imgIn:rounded-[10px] max480:*:text-xs"}>
          {object?.text}
        </div>
        <div className={"relative flex items-center flex-wrap gap-5 mt-[22px]"}>
          <div className={"relative flex items-center gap-2 *:flex-none"}>
            <EyeIcon fill={"#939CB4"} size={18}/>
            <div className={"relative block text-text_description text-xs leading-[19px]"}
            >
              Views
            </div>
            <div className={"relative block text-black text-xs leading-[19px]"}>
              {object?.views}
            </div>
          </div>
          <div className={"relative flex items-center *:flex-none gap-2 firstParent:hover:opacity-100 cursor-pointer svgPath:hover:!fill-white div:hover:!text-white"}>
            <div className={"absolute block h-6 w-[calc(100%+16px)] bg-black rounded opacity-0 firstChild center-xy"}/>
            <HeartIcon fill={"#939CB4"} size={14} className={"relative"}/>
            <div className={"relative block text-black text-xs leading-[19px]"}>
              1{object?.likes}
            </div>
          </div>
          <div
            className={"relative flex flex-none firstParent:hover:opacity-100 cursor-pointer svgPath:hover:!fill-white"}
            onClick={() => setShare(true)}
          >
            <div className={"absolute block h-6 w-[calc(100%+16px)] bg-black rounded opacity-0 firstChild center-xy"}/>
            <ShareIcon fill={"#939CB4"} size={14} className={"relative"}/>
          </div>
        </div>
      </div>
      {isShare && <SharePopup onClose={() => setShare(false)}/>}
    </div>
  );
};
