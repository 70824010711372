import React from "react";
import ContentLoader from "react-content-loader";

export const AssetSellingActionSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={128}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%"}}
  >
    <rect
      x="0"
      y="20"
      rx="0"
      ry="0"
      width="100"
      height="24"
    />
    <rect
      x="0"
      y="58"
      rx="0"
      ry="0"
      width="210"
      height="28"
    />
    <rect
      x="0"
      y="98"
      rx="0"
      ry="0"
      width="210"
      height="10"
    />
    <rect
      x="calc(100% - 90px)"
      y="20"
      rx="0"
      ry="0"
      width="90"
      height="10"
    />
    <rect
      x="calc(100% - 220px)"
      y="20"
      rx="0"
      ry="0"
      width="120"
      height="10"
    />
    <rect
      x="calc(100% - 320px)"
      y="20"
      rx="0"
      ry="0"
      width="90"
      height="10"
    />
    <rect
      x="calc(100% - 156px)"
      y="52"
      rx="0"
      ry="0"
      width="156"
      height="48"
    />
  </ContentLoader>
);

export const AssetSellingActionSkeletonMobile = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={199}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{position: "relative", left: "0px", maxWidth: "100%"}}
  >
    <rect
      x="0"
      y="16"
      rx="0"
      ry="0"
      width="92"
      height="20"
    />
    <rect
      x="calc(100% - 92px)"
      y="21"
      rx="0"
      ry="0"
      width="92"
      height="10"
    />
    <rect
      x="10%"
      y="55"
      rx="0"
      ry="0"
      width="80%"
      height="10"
    />
    <rect
      x="10%"
      y="75"
      rx="0"
      ry="0"
      width="80%"
      height="20"
    />
    <rect
      x="10%"
      y="105"
      rx="0"
      ry="0"
      width="80%"
      height="10"
    />
    <rect
      x="0"
      y="135"
      rx="0"
      ry="0"
      width="100%"
      height="48"
    />
  </ContentLoader>
);
