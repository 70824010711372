import React, {FC} from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const LinkIcon: FC<IProps> = ({size = 14, fill = "#0A84FF", onClick, className}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3263 6.5583L10.6765 8.20811C10.5826 8.30218 10.4857 8.38866 10.3845 8.46901C9.99896 8.77832 9.56277 8.99197 9.10587 9.11243C8.69127 9.22574 8.25829 9.25884 7.83246 9.21276C7.43637 9.17149 7.04466 9.06211 6.67848 8.88376C6.33402 8.71853 6.01129 8.49263 5.72677 8.20811C5.44225 7.92359 5.21635 7.60086 5.05127 7.25641C5.50714 6.93266 6.14444 6.97597 6.55175 7.38328C6.97044 7.80197 7.50871 8.02874 8.05631 8.06286C8.44714 8.08649 8.84512 8.01211 9.20621 7.83901C9.44012 7.72876 9.65887 7.57607 9.85167 7.38328L11.5015 5.73332C12.4131 4.82186 12.4194 3.35128 11.5077 2.43968C10.5972 1.52909 9.11331 1.52195 8.20171 2.43355L6.93339 3.70186C6.45375 3.57295 5.95879 3.52351 5.46602 3.55034C5.54652 3.44928 5.63314 3.3523 5.72692 3.25853L7.37673 1.60872C8.74304 0.242406 10.9601 0.242406 12.3265 1.60872C13.6928 2.97503 13.6928 5.19199 12.3263 6.5583Z"
        fill={fill}
      />
      <path
        d="M8.20162 10.6825L6.55166 12.3325C5.18535 13.6988 2.98166 13.7122 1.61433 12.3447C0.248015 10.9784 0.235619 8.74908 1.60193 7.38277L3.25174 5.73281C3.34566 5.63889 3.44249 5.55241 3.54356 5.47191C3.92929 5.16246 4.36547 4.9491 4.82237 4.82835C5.23683 4.71489 5.66995 4.68194 6.09593 4.72831C6.49187 4.76958 6.88372 4.87896 7.24991 5.05716C7.59422 5.22225 7.9171 5.44814 8.20162 5.73266C8.48614 6.01733 8.71204 6.34006 8.87697 6.68437C8.42124 7.00827 7.7841 6.96496 7.37664 6.5575C6.95795 6.13881 6.41968 5.91204 5.87222 5.87791C5.48139 5.85414 5.08341 5.92852 4.72247 6.10177C4.48841 6.21202 4.26981 6.36471 4.07702 6.5575L2.42691 8.2076C1.51545 9.11935 1.51545 10.5959 2.42691 11.5074C3.33852 12.4191 4.81522 12.4191 5.72668 11.5074L6.99499 10.2391C7.47347 10.367 7.97047 10.4165 8.46237 10.3909C8.38216 10.4919 8.29554 10.5888 8.20162 10.6825Z"
        fill={fill}
      />
    </svg>
  );
};
