import {useCallback, useEffect, useState} from "react";

interface IUseTimer {
  date?: Date,
  isFormatValue?: boolean
}

interface IReturnUseTimer {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  isEnded?: boolean;
}

export const useTimer = ({date = new Date(), isFormatValue = true}: IUseTimer): IReturnUseTimer => {
  const [timer, setTimer] = useState<Record<string, string>>({
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0"
  });
  const [isEnded, setIsEnded] = useState<boolean>(false);

  const calculateValue = useCallback(() => {
    const difference = +new Date(date) - +new Date();

    const formatValue = (value: number): string => {
      if (isFormatValue) {
        if (value < 10) {
          return `0${value}`;
        }
      }

      return `${value}`;
    };

    if (difference <= 0) {
      setIsEnded(true);
      return {
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0"
      };
    }
    return {
      days: formatValue(Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: formatValue(Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: formatValue(Math.floor((difference / 1000 / 60) % 60)),
      seconds: formatValue(Math.floor((difference / 1000) % 60))
    };
  }, [date, isFormatValue]);

  useEffect(() => {
    setIsEnded(false);
    setTimer(calculateValue());
    let t = setInterval(() => setTimer(calculateValue()), 1000);
    return () => clearTimeout(t);
  }, [calculateValue]);

  return {days: timer?.days, hours: timer?.hours, minutes: timer?.minutes, seconds: timer?.seconds, isEnded};
};
