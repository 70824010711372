import React from "react";

export const NotificationBidIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26380)"
      />
      <path
        d="M18 24.5C13.8578 24.5 10.5 21.1423 10.5 17C10.5 12.8578 13.8578 9.5 18 9.5C22.1423 9.5 25.5 12.8578 25.5 17C25.5 21.1423 22.1423 24.5 18 24.5ZM18 13.8177L14.8177 17L18 20.1823L21.1823 17L18 13.8177Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26380"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F6D"/>
          <stop
            offset="1"
            stopColor="#FFC371"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
