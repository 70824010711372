import React, {useCallback, useEffect, useRef, useState} from "react";

import {classNames, errorChecker} from "utils";
import {Popup, PopupSubContentTitle} from "components/Popups/index";
import Style from "components/CreateNft/StepContent/StepLoad/style.module.scss";
import {Button} from "components/Button";
import {useMyUser} from "providers";
import {showNotification} from "components/Notification";
import {IVerificationDocument, useApi} from "api";

import S from "./style.module.scss";

interface IProps {
  onClose?: () => void;
}

export const VerifiedPopup = (
  {
    onClose
  }: IProps) => {
  const {api} = useApi();
  const {user} = useMyUser();
  const fileRef = useRef<HTMLInputElement>(null);
  const [isError, setError] = useState<boolean>(false);
  const [isImage, setImage] = useState<any>();
  const [isImageName, setImageName] = useState<any>();

  const onChangeFile = () => {
    if (fileRef.current?.files && fileRef.current?.files[0]) {
      let fileArray = fileRef.current?.files[0];
      if (
        fileArray.type === "image/jpeg" ||
        fileArray.type === "image/png" ||
        fileArray.type === "image/webp"
      ) {
        setError(false);
        setImage(fileArray);
        setImageName(fileArray.name);
      } else {
        showNotification({
          title: "Error",
          subtitle: "Enter Only JPG, PNG, WEBP",
          type: "error"
        });
        setError(true);
        setImage("");
        setImageName("");
      }
    }
  };


  const onSubmit = async () => {
    if (!isError && isImage) {
      const formData = new FormData();
      formData.append("image", isImage);
      await api.verificationDocument.setVerificationDocument(formData);
      showNotification({
        title: "Success",
        subtitle: "File is upload",
      });
      setImage("");
      setImageName("");
      if (onClose) {
        onClose();
      }
    } else {
      setError(true);
    }
  };

  const [isDetail, setDetail] = useState<IVerificationDocument>();

  const verificationDetails = useCallback(async () => {
    try {
      let result = await api.verificationDocument.getVerificationDetail();
      setDetail(result);
    } catch (err) {
      // errorChecker(err);
    }
  }, [api.verificationDocument]);

  useEffect(() => {
    verificationDetails();
  }, [verificationDetails]);

  return (
    <Popup
      maxWidth={"500px"}
      clickOnClose={onClose}
    >
      <PopupSubContentTitle children={"Verification"}/>
      {user.cryptoWallet && user.verificationStatus === "none" && (
        <div className={S.FormLoads}>
          <div className={S.Item}>
            <div className={classNames(Style.StepLoad, Style.StepLoadSecond, {[Style.Error]: isError})}>
              {isImageName ? (
                <div className={classNames(Style.Text, S.Name)}>{isImageName}</div>
              ) : (
                <div className={Style.Text}>JPG, PNG, WEBP</div>
              )}

              <div className={Style.LoadButton}>
                <span>Choose File</span>
                <input
                  type="file"
                  name={"image"}
                  onChange={onChangeFile}
                  ref={fileRef}
                  accept="image/jpeg,image/png,image/webp"
                />
              </div>
            </div>
          </div>
          <div className={S.Item}>
            <Button
              type={"button"}
              children={"SEND"}
              onClick={onSubmit}
              color={"static"}
              className={S.SendButton}
            />
          </div>
        </div>
      )}
      {user.cryptoWallet && user.verificationStatus === "rejected" && (
        <div className={S.RejectedDescription}>
          {isDetail?.note}
        </div>
      )}
    </Popup>
  );
};
