import React from "react";
import {Route, Routes} from "react-router-dom";

import {HelpCenterHeader} from "pages/HelpCenter/Header";
import {HelpCenterNavigation} from "pages/HelpCenter/Navigation";
import {HelpCenterPageAbout} from "pages/HelpCenter/Pages/About";
import {HelpCenterPageFAQ} from "pages/HelpCenter/Pages/FAQ";
import {HelpCenterPageSearch} from "pages/HelpCenter/Pages/Search";
import {HelpCenterPageContactUs} from "pages/HelpCenter/Pages/ContactUs";
import {HelpCenterPageTermsOfUse} from "pages/HelpCenter/Pages/TermsOfUse";
import {HelpCenterPageOurPartners} from "pages/HelpCenter/Pages/OurPartners";
import {HelpCenterPageFAQChildren} from "pages/HelpCenter/Pages/FAQ/pages";

import S from "./style.module.scss";

// TODO maybe remove BreadCrumb to top component like App and add crumb automatic on every page ? - talk with designer
// TODO maybe remove title from page like base, useless information
// TODO maybe remove update date or remove in footer on information page
// TODO base page named help, with base information
export const HelpCenterPage = () => {
  return (
    <div className={S.HelpCenterContent}>
      <div className={S.HelpCenterContainer}>
        {/*<HelpCenterHeader/>*/}
        <HelpCenterNavigation/>
        <section>
          <Routes>
            <Route
              path={"/"}
              element={<HelpCenterPageAbout/>}
            />
            <Route
              path={"/FAQ"}
              element={<HelpCenterPageFAQ/>}
            />
            <Route
              path={"/FAQ/*"}
              element={<HelpCenterPageFAQChildren/>}
            />
            <Route
              path={"/our-partners"}
              element={<HelpCenterPageOurPartners/>}
            />
            <Route
              path={"/contact-us"}
              element={<HelpCenterPageContactUs/>}
            />
            <Route
              path={"/terms-of-use"}
              element={<HelpCenterPageTermsOfUse/>}
            />
            <Route
              path={"/search"}
              element={<HelpCenterPageSearch/>}
            />
          </Routes>
        </section>
      </div>
    </div>
  );
};
