import React, {useCallback, useMemo} from "react";

import {INFTNews} from "components/Home/types";
import {IAsset} from "types";
import {classNames} from "utils";
import {Video} from "components/Video";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  asset: INFTNews | IAsset;
  children?: React.ReactNode;
  aspectRatio?: "aspect-auto" | "aspect-square" | "aspect-video";
}

export const CardImg = ({className, asset, children, aspectRatio = "aspect-square"}: IProps) => {
  const img = asset.thumbnail || asset.ipfsFull || "";

  const renderMedia = useMemo(() => {
    switch (asset?.fileExtension) {
      case "mp4": {
        return (
          <Video
            className={S.CardMedia}
            src={asset.ipfsFull || ""}
            poster={asset?.thumbnail || ""}
            autoplay={false}
          />
        );
      }
      default: {
        return (
          <div className={S.CardMedia}>
            <img
              src={img}
              alt="NFTim"
            />
          </div>
        );
      }
    }
  }, [asset?.fileExtension, asset.ipfsFull, asset?.thumbnail, img]);

  const theme = useTheme();

  const aspectByTheme = useMemo(() => {
    if (theme === "nftim") {
      return aspectRatio;
    }

    return "";
  }, [aspectRatio, theme]);

  return (
    <picture className={classNames(S.CardImgWrapper, S[theme], aspectByTheme, className)}>
      <div
        className={S.CardImgBlur}
        style={{backgroundImage: `url(${img})`}}
      />
      {renderMedia}
      {children}
    </picture>
  );
};
