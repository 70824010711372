import {showNotification} from "components/Notification";

type TShowErrorKey = "message" | any;

type TShowError = Record<TShowErrorKey, any>;

interface IShowError {
  title?: string;
  subtitle?: string;
}

const debug = localStorage.getItem("debugValue");

const showError = ({title, subtitle}: IShowError) => {
  showNotification({
    title: title || "Error",
    subtitle: subtitle || "Fatal Error",
    type: "error",
  });
};

// https://github.com/MetaMask/eth-rpc-errors/blob/main/src/error-constants.ts
const errorList = (code: 3 | 32603 | -32603 | 32000 | -32000 | number) => {
  if (code === 3) {
    showError({
      subtitle: "Your bid is not the highest anymore. Please, try again"
    });
    return;
  }

  if (code === 32603 || code === -32603) {
    showError({
      subtitle: "Not enough gas fee for this transaction"
    });
    return;
  }

  if (code === 32000 || code === -32000) {
    showError({
      subtitle: "Insufficient funds on the wallet"
    });
    return;
  }

  if (code !== 0) {
    showError({
      subtitle: "Unexpected error"
    });
  }
};

const checkIfObject = (str: string) => {
  const regex = /"code": -?(\d{1,5}),\n/gi;
  const parse = Number(regex.exec(str)?.[1]) || 0;
  errorList(parse);

  return parse !== 0;
};

export const errorChecker = (error: TShowError | unknown) => {
  if (debug === "1") {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  const parseError = async (value: TShowError | string) => {
    let errors: TShowError = {};

    if (typeof value === "object") {
      errors = value;
    }

    if (typeof value === "string") {
      const isParse = checkIfObject(value);

      if (isParse) {
        return;
      }

      let isObject = value.charAt(1) === "{";

      if (isObject) {
        errors = JSON.parse(value);
      } else {
        showError({
          subtitle: value
        });
        return;
      }
    }

    Object.keys(errors).map((el) => {
      if (typeof errors[el] === "string") {
        if (el !== "stack") {
          showError({
            subtitle: errors[el]
          });
        }
      }

      if (Array.isArray(errors[el])) {
        errors[el].map((arrayEl: TShowError) => {
          parseError(arrayEl);
        });
        return;
      }

      if (typeof errors[el] === "object") {
        parseError(errors[el]);
      }
    });
  };

  if (error && typeof error === "object") {
    if ("status" in error) {
      // @ts-ignore
      if (Number(error.status) === 401) {
        sessionStorage.clear();

        localStorage.clear();
        if (debug === "1") {
          localStorage.setItem("debugValue", "1");
        }

        window.location.reload();
        return;
      }
    }

    if ("code" in error) {
      // @ts-ignore
      if (Number(error.code) === 3 || Number(error.code) === -32603 || Number(error.code) === -32000) {
        // @ts-ignore
        errorList(error.code);
        return;
      }
    }

    if ("data" in error) {
      let err = error as TShowError;
      if (err?.data) {
        parseError(err?.data);
      }
    }

    if ("message" in error) {
      let err = error as TShowError;
      if (err?.message) {
        parseError(err?.message);
      }
    }
  }
};
