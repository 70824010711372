import React from "react";

import {IOwner} from "components/Home/types";
import {useClickOutside} from "hooks/useClickOutside";
import {UserBlock} from "components/UserBlock";
import {Cross} from "assets/icons/Cross";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";

import S from "./style.module.scss";


interface IProps {
  ownersList: IOwner[];
  onClose?: () => void;
}

export const OwnersPopup = ({ownersList, onClose}: IProps) => {
  const refPopup = React.useRef(null);
  useClickOutside(refPopup, () => onClose && onClose());

  const theme = useTheme();

  return (
    <div className={"absolute"}>
      <div className={S.OwnerPopupOverlay}/>
      <div
        className={classNames(S.Wrapper, S[theme])}
        ref={refPopup}
      >
        <Cross
          className={S.OwnerPopupCross}
          onClick={onClose}
        />
        <div className={S.Triangle}/>
        <div className={S.WrapperScroll}>
          {ownersList.slice(0, 5).map((item) => (
            <>
              <div className={S.UserBlock}>
                <UserBlock
                  key={item?.user?.id}
                  id={item?.user?.id}
                  thumbnailPhoto={item.user?.thumbnailPhoto}
                  displayName={item.user?.displayName}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
