import React, {useMemo, useState} from "react";

import {Tabs} from "components/Tabs";
import {useAsset} from "providers";

import {HistoryContent} from "./Tables/HistoryContent";
import {OwnersContent} from "./OwnersContent";
import S from "./style.module.scss";
import {BidsContent} from "./BidsContent";

type TTabs = "owners" | "history" | "bids";

export const AssetTabs = () => {
  const [activeTab, setActiveTab] = useState<TTabs>("owners");
  const {available} = useAsset();

  const renderTabs = useMemo(() => {
    switch (activeTab) {
      case "owners": {
        return (
          <OwnersContent/>
        );
      }
      case "history": {
        return (
          <HistoryContent/>
        );
      }
      case "bids": {
        return (
          <BidsContent/>
        );
      }
      default: {
        return <></>;
      }
    }
  }, [activeTab]);

  return (
    <div className={S.AssetTabsWrapper}>
      <div className={S.AssetTabsList}>
        {available?.auctions && available.auctions?.length > 0 ? (
          <Tabs<TTabs>
            activeTabId={activeTab}
            tabs={[
              {
                key: "owners",
                title: "Owners"
              },
              {
                key: "bids",
                title: "Bids"
              },
              {
                key: "history",
                title: "History"
              }
            ]}
            onTabClick={setActiveTab}
          />
        ) : (
          <Tabs<TTabs>
            activeTabId={activeTab}
            tabs={[
              {
                key: "owners",
                title: "Owners"
              },
              {
                key: "history",
                title: "History"
              }
            ]}
            onTabClick={setActiveTab}
          />
        )
        }
      </div>
      <div className={S.Content}>
        {renderTabs}
      </div>
    </div>
  );
};
