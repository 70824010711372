import React, {useContext} from "react";

import {API} from "api";

const ApiContext = React.createContext({});
ApiContext.displayName = "ApiContext";

// TODO integrate provider to exists api
export function useApi (): { api: typeof API } {
  const api = useContext(ApiContext);
  if (!api) {
    throw new Error("Api provider Error");
  }
  return {api: API};
}
