import React from "react";

export const NotificationNewLike = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26365)"
      />
      <path
        d="M18 11.8981L17.451 11.3581C16.5149 10.4729 15.2706 9.9875 13.9824 10.0051C12.6942 10.0226 11.4636 10.5418 10.552 11.4522C9.64041 12.3626 9.11969 13.5925 9.10047 14.8807C9.08124 16.1689 9.56501 17.4139 10.449 18.3511L18 25.9021L25.551 18.3421C26.435 17.4049 26.9188 16.1599 26.8996 14.8717C26.8803 13.5835 26.3596 12.3536 25.448 11.4432C24.5365 10.5328 23.3058 10.0136 22.0176 9.99607C20.7294 9.9785 19.4851 10.4639 18.549 11.3491L18 11.8981Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26365"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#FC4A1A"
          />
          <stop
            offset="1"
            stopColor="#F7B733"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
