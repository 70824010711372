import React from "react";

import {TimerWrapper} from "components/Timer";
import {useWeb3Contract} from "providers/Web3Contract";
import {classNames} from "utils";

import S from "./style.module.scss";

export const ProfileMyBidsAuctionBlockStatus = () => {
  const {auction} = useWeb3Contract();

  return (
    <div className={classNames(S.MyBidsAssetAuctionStatus)}>
      <h4 className={"!text-xs"}>Auction status</h4>
      {auction?.isEndedTimer
        ? <p className={classNames(S.Text, "!text-xs")}>Ended</p>
        : <TimerWrapper
          expiryTimestamp={auction?.auctionEnd}
          isSmall={true}
          className={"!text-xs small-timer min-w-[100px]"}
        />
      }
    </div>
  );
};
