import React, {
  Dispatch,
  SetStateAction
} from "react";

import S from "components/Filters/Popup/style.module.scss";
import {useFilter} from "components/Filters/provider";
import {Button} from "components/Button";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {IFiltersObject} from "api";

interface IProps {
  filterClearLists?: IFiltersObject[];
  onClickClose?: () => void;
  setOpened?: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

export const PopupButtons = ({
  filterClearLists,
  onClickClose,
  setOpened,
  className,
}: IProps) => {
  const {applyFilter, clearFilter} = useFilter();
  const theme = useTheme();

  return (
    <div className={classNames(S.PopupButtonsWrapper, className, {[S.HelpUa]: theme === "help_ua"})}>
      <Button
        color={"primary_o"}
        onClick={() => {
          onClickClose && onClickClose();
          setOpened && setOpened((value) => !value);
          clearFilter(filterClearLists);
        }}
        className={S.Clear}
      >
        Clear
      </Button>
      <Button
        color={"primary"}
        onClick={() => {
          onClickClose && onClickClose();
          setOpened && setOpened((value) => !value);
          applyFilter();
        }}
      >
        Apply
      </Button>
    </div>
  );
};
