import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const CheckMarkAvatar = ({
  className,
  size = 28,
  fill = "#0A84FF"
}: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_292_8703)">
        <path
          d="M28 13.9997L24.8303 10.4804L25.3266 5.76909L20.6916 4.78784L18.3273 0.680664L14 2.61459L9.67274 0.680664L7.30844 4.78784L2.67349 5.76909L3.16973 10.4804L0 13.9997L3.16967 17.5189L2.67344 22.2302L7.30839 23.2115L9.67268 27.3186L14 25.3847L18.3273 27.3186L20.6916 23.2115L25.3265 22.2302L24.8302 17.5189L28 13.9997ZM19.8691 10.9342L12.8417 18.8075L7.95838 13.9242L9.11988 12.7626L12.7739 16.4166L18.6437 9.84041L19.8691 10.9342Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_292_8703">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckMarkAvatarWhite = (
  {
    className,
    size = 15,
    fill = "#0A84FF"
  }: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7.5" r="4" fill="white"/>
      <path
        d="M14 7.49995L12.4151 5.74034L12.6633 3.38467L10.3458 2.89404L9.16366 0.840454L7.00001 1.80742L4.83637 0.840454L3.65422 2.89404L1.33675 3.38467L1.58486 5.74034L0 7.49995L1.58484 9.25956L1.33672 11.6152L3.6542 12.1059L4.83634 14.1594L6.99999 13.1925L9.16363 14.1594L10.3458 12.1059L12.6633 11.6152L12.4151 9.25956L14 7.49995ZM9.93457 5.96724L6.42085 9.90386L3.97919 7.4622L4.55994 6.88145L6.38693 8.70843L9.32184 5.42033L9.93457 5.96724Z"
        fill={fill}
      />
    </svg>
  );
};
