import React from "react";
import {Link} from "react-router-dom";

import {TextComponentFAQ, WrapperComponentFAQ} from "pages/HelpFAQ/Layout/Components";
import {HelpPageHeader} from "pages/HelpFAQ/Layout/Header";
import {useTheme} from "hooks/useTheme";


export const WhatNftFAQ = () => {
  const theme = useTheme();

  return (
    <>
      <HelpPageHeader
        title="What is an NFT?"
        breadcrumb="NFT"
      />
      <WrapperComponentFAQ>
        <TextComponentFAQ>
          <p>
            <b>NFT stands for a non-fungible token,</b> which means that it’s a one-of-a-kind digital asset that belongs
            to you and you only. The most popular NFTs right now include artwork, music, and videos but also they can
            include any type of digital asset, even tweets.
          </p>
          <br/>
          <p>
            <b>“Non-fungible”</b> more or less means that it’s unique and can’t be replaced with something else. For
            example, a bitcoin is fungible — trade one for another bitcoin, and you’ll have exactly the same thing. A
            one-of-a-kind trading card, however, is non-fungible. If you traded it for a different card, you’d have
            something completely different.
          </p>
          <br/>
          <p>
            If you buy a physical painting, you know it’s real because you see the artist’s signature on the canvas.
            Somebody can photocopy the painting, but they don’t own it — you do. Thanks to NFTs, digital assets now also
            have unique individual signatures that are encrypted via <Link to="/helpFAQ/blockchain">blockchain</Link>. This
            means that all NFTs store this kind of information: When it was minted; Who created it; Who bought it (and
            when); The price(s) it sold for; Who owns it now; etc. And most important to know, that because of
            blockchain technology it’s impossible to counterfeit NFT.
          </p>
          <br/>
          <p>
            For now, <b>{theme === "nftim" ? "NFTIM" : "HelpUA"}</b> supports artworks, photos, animations, music, and videos. In the future, more types
            of NFT will be available on our platform.
          </p>
        </TextComponentFAQ>
      </WrapperComponentFAQ>
    </>
  );
};
