export enum EChain {
  "mainnet" = 1,
  "ropsten" = 3,
  "rinkeby" = 4,
  "goerli" = 5,
  "kovan" = 42,
  "bsc_mainnet" = 56,
  "bsc_testnet" = 97,
}

export type TChain = "mainnet" | "ropsten" | "rinkeby" | "goerli" | "kovan" | "bsc_mainnet" | "bsc_testnet";
