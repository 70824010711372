import React from "react";

import icoCurrency1 from "assets/img/nftim/home/about/ico-currency-1.png";
import icoCurrency2 from "assets/img/nftim/home/about/ico-currency-2.png";
import icoCurrency3 from "assets/img/nftim/home/about/ico-currency-3.png";
import icoCurrency4 from "assets/img/nftim/home/about/ico-currency-4.png";
import icoCurrency5 from "assets/img/nftim/home/about/ico-currency-5.png";
import icoCurrency6 from "assets/img/nftim/home/about/ico-currency-6.png";
import icoCurrency7 from "assets/img/nftim/home/about/ico-currency-7.png";
import icoLogo1 from "assets/img/nftim/home/about/logo-1.png";
import icoLogo2 from "assets/img/nftim/home/about/logo-2.png";
import icoLogo3 from "assets/img/nftim/home/about/logo-3.png";
import imgItem1 from "assets/img/nftim/home/about/item-1.png";
import imgItem2 from "assets/img/nftim/home/about/item-2.png";
import imgItem3 from "assets/img/nftim/home/about/item-3.png";

interface IProps {}

const listCurrency = [
  {icon: icoCurrency1},
  {icon: icoCurrency2},
  {icon: icoCurrency3},
  {icon: icoCurrency4},
  {icon: icoCurrency5},
  {icon: icoCurrency6},
  {icon: icoCurrency7},
];

const listStep = [
  {
    icon: icoLogo1,
    image: imgItem1,
    title: "HELPUA",
    text: "GO TO BUY CHARITY NFT",
    link: "https://helpua.io/"
  },
  {
    icon: icoLogo2,
    image: imgItem2,
    title: "NFC",
    text: "COMING SOON",
    link: ""
  },
  {
    icon: icoLogo3,
    image: imgItem3,
    title: "TOKEN",
    text: "COMING SOON",
    link: ""
  }
];

export const AboutNftim = (
  {}:IProps) => {
  return (
    <div className={"relative flex gap-14 w-full py-12 pl-16 max1680:px-10 bg-black rounded-xl items-center justify-between max1360:flex-col"}>
      <div className={"relative block w-full max-w-[632px]"}>
        <div className={"relative block w-full pb-[52px] text-4xl font-bold text-white max1360:text-center max480:text-3xl max480:pb-8"}>
          WHAT IS NFTIM?
        </div>
        <div className={"relative block w-full text-text_description text-sm leading-6 max1360:text-center"}>
          NFTim is the first platform that provides a unique way of celebrity and fans interaction. Here you can find limited collections of digital art created by our team with world-renowned stars and top global brands. The platform can be joined by invitation only, where we guarantee the exclusivity of content.
        </div>
        {listCurrency.length > 0 && (
          <div className={"relative w-full flex gap-2 flex-wrap pt-14 max480:pt-8 max1360:justify-center"}>
            {listCurrency.map((item, index) => {
              return (
                <div key={index} className={"relative block w-[calc(100%/7-calc(8px/7*6))] max480:w-[calc(100%/4-calc(8px/4*3))] max-w-[75px]"}>
                  <div className={"relative flex w-full h-0 pb-[100%]"}>
                    <div className={"absolute w-full h-full top-0 left-0 flex items-center justify-center p-2 bg-dark_background rounded-xl"}>
                      <img
                        src={item.icon}
                        alt=""
                        className={"relative block w-auto h-auto max-w-full max-h-full"}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={"relative block w-full"}>
        <div className={"relative block w-[calc(100%+66px)] min1025:min-w-[680px] max1680:w-full"}>
          <div className={"relative flex w-full gap-[18px] max1360:justify-center max768:flex-col max768:items-center"}>
            {listStep.map((item, index) => {
              return (
                <a
                  href={item.link}
                  target={"_blank"}
                  className={"relative block w-[calc(100%/3-calc(18px/3*2))] div:tr-all hover:div:scale-110 hover:z-10 div:will-change-transform max-w-[270px] max768:w-full"}
                  onClick={(event) => {
                    !item.link && event.preventDefault();
                  }}
                  key={index}
                >
                  <div
                    className={"relative block w-full bg-cover bg-no-repeat bg-center bg-black border border-solid border-dark_background rounded-xl pt-3"}
                    style={{backgroundImage: `url(${item.image})`}}
                  >
                    <div className={"relative block w-full h-0 pb-[calc(100%+8px)]"}>
                      <div className={"absolute flex w-full h-full top-0 left-0 items-center justify-center p-2"}>
                        <img
                          src={item.icon}
                          alt=""
                          className={"relative block w-auto h-auto max-w-full max-h-full"}
                        />
                      </div>
                    </div>
                    <div className={"relative block w-full px-8 pb-2 min-h-[88px]"}>
                      <div className={"relative block text-center text-white text-sm leading-4 font-bold pb-[14px]"}>
                        {item.title}
                      </div>
                      <div className={`relative block text-center text-grey_button text-xs ${item.link && "text-blue_static"}`}>
                        {item.text}
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
