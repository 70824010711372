import React from "react";

import {StepText} from "components/CreateNft/StepContent/StepText";
import {StepLoad} from "components/CreateNft/StepContent/StepLoad";

export const StepSecond = () => {
  return (
    <>
      <StepText>
        <p>Upload your work to create NFT.</p>
        <p>Loading occurs 1 file</p>
      </StepText>
      <StepLoad/>
    </>
  );
};
