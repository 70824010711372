import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {BurgerIconDesign} from "assets/icons/BurgerIcon";
import {PlusIconNoPadding} from "assets/icons/PlusIcon";
import {ROUTES} from "routingConstants";
import {showNotification} from "components/Notification";
import {Button} from "components/Button";
import {LogoIcon} from "assets/icons/LogoIcon";
import {useMyUser, useWeb3} from "providers";
import {Loader} from "components/Loader";
import {BurgerMenu} from "components/Header/BurgerMenu";
import {userSocketFunction} from "api/src/socket";
import {NotificationButtonIcon} from "assets/icons/NotificationIcons/NotificationButtonIcon";
import {NotificationActive} from "assets/icons/NotificationIcons/NotificationActive";
import {useToggle} from "hooks/useToggle";
import {useViewBlock, ViewBlock} from "components/ViewBlock";
import {CharityMainIconMini} from "assets/icons/CharityMainIcon";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {useStore} from "providers";
import {ResponsiveDesktop, ResponsiveMobile} from "components/Responsive";
import {ArrowBottom} from "assets/icons";
import {Dropdown} from "components/Dropdown";
import {NotificationCenter} from "components/NotificationCenter/NotificationCenter";
import {
  DropMenuArrowSeeMore,
  DropMEnuIconDesc,
  DropMenuIconsCollection,
  DropMenuIconsLaunchPad,
  DropMenuIconsMarketPlace, DropMenuIconsTarget
} from "assets/icons/DropMenuIcons";
import {BurgerMenuProfile} from "components/Header/BurgerProfile";
import {MenuProfileDesktop} from "components/Header/MenuContent/MenuProfileDesktop";
import {VerifiedPopup} from "components/Popups/VerifiedPopup";
import {EditProfilePopup} from "components/Popups/EditProfilePopup";

import S from "./style.module.scss";

interface IProps {
  icon?: React.ReactNode;
  link?: string;
  title?: string;
  text?: string;
  target?: boolean;
  className?: string;
}

const MenuListChild = ({
  link,
  icon,
  title,
  text,
  target = false,
  className,
}: IProps) => {
  return (
    <li className={className}>
      {target ? (
        <a
          href={link}
          target={"_blank"}
        >
          <div className={S.Icon}>{icon}</div>
          <div className={S.Desc}><span>{title}</span><p>{text}</p></div>
        </a>
      ) : (
        <Link to={`${link}`}>
          <div className={S.Icon}>{icon}</div>
          <div className={S.Desc}><span>{title}</span><p>{text}</p></div>
        </Link>
      )}
    </li>
  );
};

export const Header = () => {
  const {t} = useTranslation();
  const {logout, checkAuth, clear} = useWeb3();
  const {isLoader, token} = useStore();

  const theme = useTheme();

  const {user} = useMyUser();

  const [nonReadNotificationsCount, setNonReadNotificationsCount] = useState<number>(0);
  const [isOpenedProfile, setIsOpenedProfile] = useState(false);
  const [isOpenedNotificationCenter, setIsOpenedNotificationCenter] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isOpenMobileMenu, toggleMobileMenu] = useToggle(false);
  const [isToggleClass, toggleClassButton] = useToggle(false);
  const [isOpenMobileProfile, toggleMobileProfile] = useToggle(false);
  const [isVerifiedPopup, setVerifiedPopup] = useState<boolean>(false);
  const [isSettingPopup, setSettingPopup] = useState<boolean>(false);

  const isViewBlock = useViewBlock({
    value: ["nftim"]
  });

  const menus = useMemo(() => {
    return [
      {
        title: "Explore",
        link: "#",
        theme: "all",
        children: [
          {
            title: "Collections",
            link: isViewBlock ? ROUTES.allCollection : "#",
            desc: "Check all collections and find your favorite",
            icon: <DropMenuIconsCollection
              size={36}
              fill={isViewBlock ? "#000000" : "#0A84FF"}
            />,
            active: true,
            target: false,
            theme: false
          },
          {
            title: "Marketplace",
            link: ROUTES.marketplace,
            desc: "Find NFTs from celebrities and companies",
            icon: <DropMenuIconsMarketPlace
              size={36}
              fill={isViewBlock ? "#000000" : "#0A84FF"}
            />,
            active: true,
            target: false,
            theme: "all"
          },
          {
            title: "Launchpad",
            link: "#",
            desc: "Announcement of future NFT drops",
            icon: <DropMenuIconsLaunchPad
              size={36}
              fill={isViewBlock ? "#000000" : "#0A84FF"}
            />,
            active: false,
            target: false,
            theme: "nftim"
          },
          {
            title: "Charity",
            link: "https://helpua.io/",
            desc: "Buy NFT and help people",
            icon: <DropMenuIconsTarget
              size={36}
              fill={isViewBlock ? "#000000" : "#0A84FF"}
            />,
            active: true,
            target: true,
            theme: "nftim"
          },
          {
            title: "Portfolio",
            link: ROUTES.portfolio,
            desc: "List of what we have done",
            icon: <DropMenuIconsTarget
              size={36}
              fill={isViewBlock ? "#000000" : "#0A84FF"}
            />,
            active: true,
            target: false,
            theme: false
          }
        ]
      },
      {
        title: "header.about.title",
        link: isViewBlock ? ROUTES.aboutUs : ROUTES.aboutHelpUs,
        theme: "help_ua"
      },
      {
        title: "header.helpFAQ.title",
        link: ROUTES.HelpFAQ,
        theme: "all"
      },
      {
        title: "Portfolio",
        link: ROUTES.portfolio,
        theme: "help_ua"
      }
    ];
  }, [isViewBlock]);

  const onLogout = async () => {
    await logout();
    showNotification({
      title: "Success logout",
      subtitle: "Your logout is success"
    });
  };

  const toggleNavigation = () => {
    const classToggle = setTimeout(() => {
      toggleClassButton();
    }, 0);
    const timeout = setTimeout(() => {
      toggleMobileMenu();
    }, isOpenMobileMenu ? 500 : 0);
    return () => {
      clearTimeout(timeout);
      clearTimeout(classToggle);
    };
  };

  const toggleNavigationProfile = () => {
    const classToggle = setTimeout(() => {
      toggleClassButton();
    }, 0);
    const timeout = setTimeout(() => {
      toggleMobileProfile();
    }, isOpenMobileProfile ? 500 : 0);
    return () => {
      clearTimeout(timeout);
      clearTimeout(classToggle);
    };
  };

  useEffect(() => {
    token && userSocketFunction({token}, (data) => {
      if (data?.data) {
        setNonReadNotificationsCount(data?.data?.nonReadCount || 0);
      }
    });
  }, [token]);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, {passive: true});
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={classNames(S.Header, S[theme])}>
      <header
        className={classNames(
          location.pathname === "/" ? offset > 0 ? "" : S.MainHeaderPage : "",
          S.HeaderContentMain,
          isToggleClass ? S.OpenAnimate : S.CloseAnimate
        )}
      >
        <div className={S.Content}>
          <div className={S.HeaderLeft}>
            <Link
              to={"/"}
              className={classNames(S.Logo, theme === "nftim" ? S.nftim : S.help_ua)}
            >
              <ViewBlock value={["nftim"]}><LogoIcon/></ViewBlock>
              <ViewBlock value={["help_ua", "help_ua_dev"]}><CharityMainIconMini/></ViewBlock>
            </Link>
            <div className={S.NaviRow}>
              <ul className={S.NaviListItems}>
                {menus.filter(item => item.theme === theme || item.theme === "all").map((menu, index) => {
                  return (
                    <li key={index}>
                      {menu.children ? (
                        <>
                          <span className={S.Child}>{t(menu.title)}</span>
                          <div className={S.DropDownMenu}>
                            <div className={classNames(S.DropDownMenuFlex, S[theme])}>
                              <div className={S.DropDownMenuFlexLeft}>
                                <ul>
                                  {menu.children && menu.children.filter(menuChildren => menuChildren.theme === theme || menuChildren.theme === "all")
                                    .map((item, childIndex) => {
                                      return (
                                        <MenuListChild
                                          key={`100${childIndex}`}
                                          className={item.active === false ? S.DeActive : ""}
                                          link={item.link}
                                          title={item.title}
                                          text={item.desc}
                                          icon={item.icon}
                                          target={item.target}
                                        />
                                      );
                                    })}
                                </ul>
                              </div>
                              <div className={S.Divider}/>
                              <div className={S.DropDownMenuFlexRight}>
                                <div className={S.DMImages}>
                                  <img
                                    src="https://i.pinimg.com/originals/66/97/ef/6697ef01825f6ea6b643132923575112.gif"
                                    alt=""
                                  />
                                </div>
                                <div className={S.DMDesc}>
                                  <DropMEnuIconDesc/>
                                  <p>You can search and find your favorite star or companies NFT, and start collectible it</p>
                                </div>
                              </div>
                            </div>
                            <div className={classNames(S.SeeMore, S[theme])}>
                              <Link to={"/marketplace"}>
                                <span>View all NFTs</span>
                                <DropMenuArrowSeeMore/>
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Link
                          to={menu.link}
                          className={S.Child}
                        >{t(menu.title)}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className={S.HeaderRight}>
            <div className={S.InfoBlock}>
              {token && (
                <Dropdown
                  opened={isOpenedNotificationCenter}
                  setOpened={setIsOpenedNotificationCenter}
                  title={nonReadNotificationsCount ? <NotificationActive/> : <NotificationButtonIcon/>}
                  className={S.Alert}
                  onClick={true}
                >
                  <NotificationCenter setIcon={setNonReadNotificationsCount}/>
                </Dropdown>
              )}
              {isLoader && (
                <Loader className={S.LoaderHeader}/>
              )}
            </div>
            {token ? (
              <>
                <ResponsiveDesktop>
                  <Dropdown
                    opened={isOpenedProfile}
                    setOpened={setIsOpenedProfile}
                    title={
                      <div className={S.UserAvatar}>
                        <div className={"relative flex items-center justify-center w-10 h-10 rounded-full bg-[#14141D]"}>
                          <span className={"relative flex items-center justify-center w-9 h-9 rounded-full bg-[#14141D] border border-[#14141D] overflow-hidden"}>
                            <img
                              src={user?.photo}
                              alt=""
                              className={"relative block w-full h-auto min-h-full"}
                            />
                          </span>
                        </div>
                        <ArrowBottom/>
                      </div>
                    }
                    onClick={true}
                  >
                    <MenuProfileDesktop
                      onLogout={onLogout}
                      showPopup={() => setVerifiedPopup(true)}
                      showSetting={() => setSettingPopup(true)}
                    />
                  </Dropdown>
                </ResponsiveDesktop>
                <ResponsiveMobile>
                  <div
                    className={S.UserAvatar}
                    onClick={toggleNavigationProfile}
                  >
                    <div className={"relative flex items-center justify-center w-10 h-10 rounded-full bg-[#14141D]"}>
                      <span className={"relative flex items-center justify-center w-9 h-9 rounded-full bg-[#14141D] border border-[#14141D] overflow-hidden"}>
                        <img
                          src={user?.photo}
                          alt=""
                          className={"relative block w-full h-auto min-h-full"}
                        />
                      </span>
                    </div>
                  </div>
                </ResponsiveMobile>
              </>
            ) : (
              <Button
                onClick={() => checkAuth(clear)}
                color="static"
                className={classNames(theme === "nftim" ? S.HeaderLoginNftim : S.HeaderLoginHelpUa)}
              >
                {theme === "help_ua" && <PlusIconNoPadding size={10}/>}
                {t("header.connectWallet.title")}
              </Button>
            )}
            <div className={classNames(S.ToggleButton, "laptop:hidden")}>
              {isOpenMobileMenu ? (
                <>
                  <div
                    className={S.fadeOpenMobileMenu}
                    onClick={toggleNavigation}
                  />
                  <BurgerMenu
                    auth={!!user?.displayName}
                    onClose={toggleNavigation}
                    onWalletClick={async () => {
                      await checkAuth();
                      toggleNavigation();
                    }}
                    user={user}
                    onLogout={onLogout}
                    navigationList={menus}
                    classStatus={isToggleClass}
                  />
                </>
              ) : ("")
              }
              {isOpenMobileProfile ? (
                <>
                  <div
                    className={S.fadeOpenMobileMenu}
                    onClick={toggleNavigationProfile}
                  />
                  <BurgerMenuProfile
                    auth={!!user?.displayName}
                    onClose={toggleNavigationProfile}
                    onWalletClick={async () => {
                      await checkAuth();
                      toggleNavigationProfile();
                    }}
                    user={user}
                    onLogout={onLogout}
                    classStatus={isToggleClass}
                    showPopup={() => setVerifiedPopup(true)}
                    showSetting={() => setSettingPopup(true)}
                  />
                </>
              ) : ("")
              }
              <BurgerIconDesign onClick={toggleNavigation}/>
            </div>
          </div>
        </div>
      </header>
      {isVerifiedPopup && <VerifiedPopup onClose={() => setVerifiedPopup(false)}/>}
      {isSettingPopup && (
        <EditProfilePopup
          onClose={() => setSettingPopup(false)}
          closePopup={() => setSettingPopup(false)}
        />
      )}
    </div>
  );
};
