import React from "react";

interface IProps {
  fill?: string;
  onClick?: () => void;
  size?: number;
  className?: string;
}

export const CheckMark = ({fill = "var(--blue_static)", onClick, size = 14, className}: IProps) => (
  <svg
    className={className}
    onClick={onClick}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.32312 12.25C4.16509 12.25 4.00692 12.1886 3.88674 12.0653L0.180947 8.28518C-0.0603156 8.03893 -0.0603156 7.63994 0.180947 7.39384C0.422209 7.14774 0.813356 7.14774 1.05462 7.39384L4.32402 10.7288L12.9452 1.93457C13.1866 1.68848 13.5778 1.68848 13.8191 1.93457C14.0603 2.18067 14.0603 2.57966 13.8191 2.82576L4.76041 12.066C4.63932 12.1886 4.4813 12.25 4.32312 12.25Z"
      fill={fill}
    />
  </svg>
);
