import React, {useState} from "react";
import {useParams} from "react-router-dom";

import BonusImg from "assets/img/new/profile-bonus.png";
import {usePriceFiat} from "components/Price";
import {useMyUser, useProfile} from "providers";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";
import {InstagramIcon} from "assets/icons/Social/Instagram";
import {TwitterIcon} from "assets/icons/Social/Twitter";
import {Button} from "components/Button";
import {ArrowRight} from "assets/icons";
import {CheckMarkAvatar} from "assets/icons/CheckMarkAvatar";
import {DevMode} from "components/DevMode";
import {EditProfilePopup} from "components/Popups/EditProfilePopup";

import S from "./style.module.scss";

interface IProps {
}

export const ProfileSideBar = ({}: IProps) => {
  const {profile, profileStatistic} = useProfile();
  const params = useParams();
  const {user} = useMyUser();

  const [isProfileEditPopup, setIsProfileEditPopup] = useState<boolean>(false);
  const closePopup = () => {
    setIsProfileEditPopup(false);
  };

  const theme = useTheme();

  const minFloorPriceUsd = usePriceFiat(profileStatistic?.minFloorPriceUsd, "0.00");
  const totalVolumeTradeUsd = usePriceFiat(profileStatistic?.totalVolumeTradeUsd, "0.00");

  return (
    <>
      <div className={classNames(S.SideBar, S[theme])}>
        {profile ? (
          <>
            <div className={S.Avatar}>
              <span>
                <img
                  src={profile?.photo}
                  alt={profile.displayName}
                />
              </span>
              <CheckMarkAvatar className={S.CheckMark}/>
            </div>
            <div
              className={S.Name}
              title={profile?.displayName}
            >{profile?.displayName}</div>
            <div className={S.Description}>{profile?.description || "Empty description"}</div>
            <ul className={S.StatisticsList}>
              <li>
                <span>{profileStatistic?.numberOfMinted || 0}</span>
                <p>items</p>
              </li>
              <li>
                <span>{profileStatistic?.numberOfOwners || 0}</span>
                <p>owners</p>
              </li>
              <li>
                <span>{minFloorPriceUsd}</span>
                <p>floor price</p>
              </li>
              <li>
                <span>{totalVolumeTradeUsd}</span>
                <p>volume traded</p>
              </li>
            </ul>
            {user.id > 0 && (
              <>
                {params?.id === user?.id.toString() ?
                  <Button
                    title={"Edit profile"}
                    className={classNames(S.EditProfile, S[theme])}
                    classNameText={S.ButtonTitle}
                    onClick={() => {
                      setIsProfileEditPopup(true);
                    }}
                  >
                    <span><ArrowRight fill={theme === "nftim" ? "#000000" : "#0A84FF"}/></span>
                  </Button> :
                  <DevMode>
                    <Button
                      title={"Follow author"}
                      className={classNames(S.FollowButton, S[theme])}
                      classNameText={S.ButtonTitle}
                    >
                      <span><ArrowRight fill={theme === "nftim" ? "#000000" : "#0A84FF"}/></span>
                    </Button>
                  </DevMode>
                }
              </>
            )}
            {theme === "nftim" && (
              <>
                <ul className={classNames(S.BonusList, S[theme])}>
                  <div className={S.Title}>Each owner NFT from NK received:</div>
                  <li>Priority pass to all concert and show</li>
                  <li>Priority pass to all next NFT drop</li>
                  <li>Physical autograph from NK</li>
                  <li>Discount to merch</li>
                </ul>
                {BonusImg && (
                  <div className={S.BonusImg}>
                    <img
                      src={BonusImg}
                      alt=""
                    />
                  </div>
                )}
              </>
            )}
            {profile?.instagramUrl || profile?.twitterUrl ? (
              <ul className={classNames(S.SocialList, S[theme])}>
                {profile?.instagramUrl && (
                  <li>
                    <a
                      href={profile?.instagramUrl}
                      target={"_blank"}
                    >
                      <InstagramIcon
                        fill={theme == "nftim" ? "#000000" : "#0A84FF"}
                        size={24}
                      />
                      <span>Instagram</span>
                    </a>
                  </li>
                )}
                {profile?.twitterUrl && (
                  <li>
                    <a
                      href={profile?.twitterUrl}
                      target={"_blank"}
                    >
                      <TwitterIcon
                        fill={theme == "nftim" ? "#000000" : "#0A84FF"}
                        size={24}
                      />
                      <span>Twitter</span>
                    </a>
                  </li>
                )}
              </ul>
            ) : ""}
          </>
        ) : (<></>)}
        {isProfileEditPopup && <EditProfilePopup
          closePopup={closePopup}
          onClose={() => setIsProfileEditPopup(false)}
        />}
      </div>
    </>
  );
};
