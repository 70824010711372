import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {
}

export const PopupClockIcon = ({
  size = 40,
  fill = "#1C1C1E"
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 38.75C16.2916 38.75 12.6665 37.6503 9.58307 35.5901C6.49965 33.5298 4.09641 30.6014 2.67727 27.1753C1.25812 23.7492 0.886811 19.9792 1.61028 16.3421C2.33376 12.7049 4.11952 9.36399 6.74176 6.74176C9.36399 4.11952 12.7049 2.33376 16.3421 1.61028C19.9792 0.886811 23.7492 1.25812 27.1753 2.67727C30.6014 4.09641 33.5298 6.49965 35.5901 9.58307C37.6503 12.6665 38.75 16.2916 38.75 20C38.75 24.9728 36.7746 29.742 33.2583 33.2583C29.742 36.7746 24.9728 38.75 20 38.75ZM20 3.75001C16.7861 3.75001 13.6443 4.70305 10.972 6.48863C8.29969 8.2742 6.21689 10.8121 4.98697 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3108 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.0131C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.214 36.25 20C36.25 15.6902 34.538 11.557 31.4905 8.50952C28.443 5.46206 24.3098 3.75001 20 3.75001V3.75001Z"
        fill={fill}
      />
      <path
        d="M25.3 27.075L19.1125 20.8875C18.9967 20.7707 18.905 20.6322 18.8428 20.4799C18.7806 20.3276 18.7491 20.1645 18.75 20V10H21.25V19.4875L27.075 25.3L25.3 27.075Z"
        fill={fill}
      />
    </svg>
  );
};
