import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {
}

export const CalendarIcon = ({size = 15, fill = "#000000"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3475 1.21869V0.00268555H9.70996V1.21869H7.90371V0.00268555H7.26621V1.21869H5.33246V0.00268555H4.69496V1.21869H0.147461V13.9974H14.8312V1.21869H10.3475ZM4.69496 1.85869V3.05335H5.33246V1.83735H7.28746V3.05335H7.92496V1.83735H9.73121V3.05335H10.3687V1.83735H14.215V11.2667H0.784961V1.85869H4.69496ZM0.784961 13.3574V11.928H14.1937V13.3574H0.784961Z"
        fill={fill}
      />
      <path
        d="M5.0135 5.40002H3.80225V6.61602H5.0135V5.40002Z"
        fill={fill}
      />
      <path
        d="M8.09504 5.40002H6.88379V6.61602H8.09504V5.40002Z"
        fill={fill}
      />
      <path
        d="M11.1976 5.40002H9.98633V6.61602H11.1976V5.40002Z"
        fill={fill}
      />
      <path
        d="M5.0135 8.51465H3.80225V9.73065H5.0135V8.51465Z"
        fill={fill}
      />
      <path
        d="M8.09504 8.51465H6.88379V9.73065H8.09504V8.51465Z"
        fill={fill}
      />
      <path
        d="M11.1976 8.51465H9.98633V9.73065H11.1976V8.51465Z"
        fill={fill}
      />
    </svg>
  );
};
