import React from "react";

import {Container} from "components/Container";
import {Cards} from "components/Cards";
import {useApi} from "api";
import {Title} from "components/HELPUA/Title";
import {ExploreIcon} from "assets/icons/Explore";


interface IProps {
  limit?: number;
}

export const HomeNFTIMExploreNFTs = ({
  limit = 20,
}: IProps) => {
  const {api} = useApi();

  return (
    <Container className={"mb-8"}>
      <div className={"grid"}>
        <Title
          title={"Explore NFT’s"}
          icon={<ExploreIcon/>}
          underTitle={"EXPLORE"}
        />
      </div>
      <Cards
        request={api.news.getNewsFromES}
        limit={limit}
        removeInfinityScroll={true}
        isShowLoadMoreButton={true}
        stateType={"object"}
        theme={"nftim"}
      />
    </Container>
  );
};
