import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {Price} from "components/Price";
import {classNames} from "utils";
import {IAsset} from "types";
import {CardMediaIcon} from "components/layout/Card/MediaIcon";
import {CardTimer} from "components/layout/Card/Timer";
import {CardImg} from "components/layout/Card/Img";
import {useAssetBlockPrice} from "hooks/useAssetBlockPrice";
import {CardLike} from "components/layout/Card/Like";

import {INFTNews} from "../types";

import S from "./style.module.scss";

interface IProps {
  onLike?: (isLike: boolean, assetId: number) => void;
  asset: INFTNews | IAsset;
  className?: string;
  staticWidth?: boolean;
}

export const CardNew = ({
  onLike,
  asset,
  className,
  staticWidth = false,
}: IProps) => {
  const price = useAssetBlockPrice(asset);

  const defaultCurrency = useMemo(() => {
    return asset.currentMarketplace?.currency || asset.currentAuction?.currency || asset.defaultCurrency;
  }, [asset.currentAuction?.currency, asset.currentMarketplace?.currency, asset.defaultCurrency]);

  return (
    <div
      className={classNames(S.Card, {[S.static]: staticWidth}, className)}
    >
      <CardMediaIcon asset={asset}/>
      <CardLike
        onLike={onLike}
        asset={asset}
      />
      <Link to={`/asset/${asset?.id}`}>
        <CardImg asset={asset}>
          {asset.hasAuction && asset.currentAuction?.auctionEnd && (
            <CardTimer asset={asset}/>
          )}
        </CardImg>
        <div className={S.CardInfoWrapper}>
          <div className={S.CardInfo}>
            <div className={S.CardOwnerAndCountInfo}>
              <div className={S.CardOwner}>
                {asset.hasMultipleOwners
                  ? "Multiple owners"
                  : asset?.author?.displayName
                }
              </div>
              <div className={S.CardCount}>
                x{asset.available}
              </div>
            </div>
            <div className={S.CardTitle}>
              <p> {asset.title} </p>
            </div>
            <Price
              cryptoImg={defaultCurrency?.image}
              symbol={defaultCurrency?.symbol}
              decimals={defaultCurrency?.decimals}
              priceCryptoSize={"text-sm"}
              priceFiatSize={"text-xs"}
              className={S.PriceWrapper}
              classNamePriceCrypto={S.PriceCrypto}
              price={price}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
