import React from "react";

import {IFiltersObject, IStructureFilter} from "api";
import {Popup, PopupSubContentHeader} from "components/Popups";
import S from "components/Filters/Popup/style.module.scss";
import {PopupButtons} from "components/Filters/Popup/PopupButtons";
import {FilterInput} from "components/Filters/Popup/FIlterInput";
import {classNames} from "utils";

interface IProps {
  filterInputData: IStructureFilter[];
  onClickClose: () => void;
}

export const FiltersPopup = ({
  filterInputData,
  onClickClose,
}: IProps) => {

  return (
    <Popup
      clickOnClose={onClickClose}
      maxWidth={"604px"}
    >
      <PopupSubContentHeader title={"Filter"}/>
      <FilterInput
        inputData={filterInputData}
      />
      <PopupButtons
        filterClearLists={
          filterInputData.reduce((previousValue: IFiltersObject[], currentValue: IStructureFilter) => {
            return [...previousValue, ...currentValue.filters];
          }, [])
        }
        onClickClose={onClickClose}
        className={classNames(S.PopupButtons, S.FilterButtons)}
      />
    </Popup>
  );
};
