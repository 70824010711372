import React from "react";

import {useAsset} from "providers";
import S from "pages/Asset/SellingAction/style.module.scss";
import {Price} from "components/Price";
import {IAssetCurrency} from "types";
import {useTheme} from "hooks/useTheme";
import {DevMode} from "components/DevMode";

interface IProps {
  isShowUserBlock?: boolean;
  price: string;
  userId?: number;
  userBid?: React.ReactNode;
  anotherPrice?: string;
  userDisplayName?: string;
  userThumbnailPhoto?: string;
  children: React.ReactNode;
  currency?: IAssetCurrency;
}

export const AssetSellingActionBlock = ({
  price,
  children,
  userBid,
  anotherPrice,
  currency
}: IProps) => {
  const theme = useTheme();

  return (
    <>
      <div className={S.BodyBlock}>
        <div className={S.PriceRow}>
          <Price
            symbol={currency?.symbol}
            decimals={currency?.decimals}
            priceFiatSize={"text-xs"}
            priceCryptoSize={"text-2xl"}
            classNamePriceCrypto={"font-bold"}
            price={price}
            isShowChain={true}
          />
          {userBid && (
            <>
              {userBid != "" && (
                <div className={S.Text}>
                  Highest bid by {userBid}
                </div>
              )}
            </>
          )}
          <DevMode>
            {theme === "nftim" && anotherPrice && (
              <div className={S.Text}>
                or make offer <a href="#">With another price</a>
              </div>
            )}
          </DevMode>
        </div>
        {children}
      </div>
    </>
  );

};
