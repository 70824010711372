import React from "react";

import {SadFaceIcon} from "assets/icons/SadFaceIcon";

interface IProps {}

export const EmptyItems = (
  {}:IProps) => {
  return (
    <div className={"relative flex flex-col items-center w-full py-24 gap-2"}>
      <SadFaceIcon size={60}/>
      <div className={"relative block w-full text-center text-base font-bold"}>
        No items to display
      </div>
      <div className={"relative block w-full text-center text-xs font-semibold text-text_description"}>
        Start building your collection by placing bids on artwork.
      </div>
    </div>
  );
};
