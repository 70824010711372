import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {IMarketplace} from "components/Home/types";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";
import {useWeb3} from "providers";
import {Button} from "components/Button";

interface IProps {
  className?: string;
  marketplace?: IMarketplace;
}

export const MarketplaceButton = ({className, marketplace}: IProps) => {
  const {t} = useTranslation();

  const {cancelMarketplace, buyItem} = useWeb3Contract();
  const {openBuyNowPopup} = useWeb3ContractPopup();
  const {web3State} = useWeb3();

  const button = useMemo(
    (): { title: string; action: Function } => {
      if (web3State?.wallet) {
        if (web3State?.wallet === marketplace?.seller) {
          return {
            title: "Cancel Marketplace",
            action: () => cancelMarketplace({
              marketplaceId: marketplace?.marketplaceContractId,
              marketplaceAddress: marketplace?.marketplaceAddress,
            })
          };
        }

        return {
          title: t("button.buyItNow"),
          action: () => {
            return buyItem({
              marketplaceContractId: marketplace?.marketplaceContractId,
              address: marketplace?.marketplaceAddress,
              price: marketplace?.price,
              currencyAddress: marketplace?.currency?.address
            });
          }
        };
      }

      return {
        title: t("button.buyItNow"),
        action: () => {
          return openBuyNowPopup({
            type: "buy",
            id: marketplace?.marketplaceContractId,
            address: marketplace?.marketplaceAddress,
            price: marketplace?.price,
            number: marketplace?.number,
            currencyAddress: marketplace?.currency?.address
          });
        }
      };
    },
    [
      buyItem,
      cancelMarketplace,
      marketplace?.currency?.address,
      marketplace?.marketplaceAddress,
      marketplace?.marketplaceContractId,
      marketplace?.number,
      marketplace?.price,
      marketplace?.seller,
      openBuyNowPopup,
      t,
      web3State?.wallet
    ]
  );

  return (
    <Button
      className={className}
      color="primary"
      onClick={() => button.action()}
    >
      {button.title}
    </Button>
  );
};
