import React, {
  useRef,
  useState,
  useEffect
} from "react";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import {Link} from "react-router-dom";

import {PopupClockIcon} from "assets/icons/PopupClockIcon";
import "react-datepicker/dist/react-datepicker.css";
import {useClickOutside} from "hooks/useClickOutside";
import {useAsset, useStore, useWeb3} from "providers";
import {classNames, errorChecker} from "utils";
import {ViewBlock} from "components/ViewBlock";
import {ButtonPopup, Popup, PopupSubContentHeader, PopupSubContentText, PopupSubContentTitle} from "components/Popups";
import {ResponsiveDesktop, ResponsiveMobile} from "components/Responsive";
import {useTheme} from "hooks/useTheme";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";
import {LightningIcon} from "assets/icons/LightningIconFilters";

import S from "./style.module.scss";
import {SalePopupAuction} from "./Auction";
import {SalePopupListForSale} from "./ListForSale";

interface IProps {
  onClose: () => void;
}

export const SalePopup = ({onClose}: IProps) => {
  const {owners, available} = useAsset();
  const {markForSale, setSaleType} = useWeb3Contract();
  const {setSalePopup} = useWeb3ContractPopup();
  const {web3State} = useWeb3();
  const theme = useTheme();

  // useWeb3
  const wallet = web3State?.wallet;
  const itemsCount = owners && owners.find((item) => item.userAddress === wallet);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [price, setPrice] = useState<string>("");
  const [immediatePrice, setImmediatePrice] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {setIsAlerts} = useStore();

  const filterAvailable = available?.marketplaces?.filter(el => el.seller === wallet);

  const availableItemsForSale = (itemsCount?.number || 0) - (filterAvailable?.length
    ? filterAvailable.length
    : 0);

  useEffect(() => {
    theme === "help_ua" && itemsCount?.number && setMaxQuantity(availableItemsForSale);
    theme === "nftim" && itemsCount?.number && setMaxQuantity(itemsCount?.number);
  }, [availableItemsForSale, itemsCount?.number, theme]);

  const closingRef = useRef(null);
  useClickOutside(closingRef, onClose);

  const onSaleSubmit = async () => {
    const isDateCorrect =
      differenceInCalendarDays(startDate, new Date()) < 365 &&
      differenceInCalendarDays(endDate, startDate) < 365 &&
      differenceInMilliseconds(endDate, startDate) > 0;
    // differenceInMilliseconds(startDate, new Date()) > 0;

    if (parseFloat(price) === 0 || price.length === 0) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
    }

    if (!isDateCorrect && currentStep === 2) {
      setIsDateError(true);
      return;
    } else {
      setIsDateError(false);
    }

    const auctionStartOffset = (startDate.getTime() - new Date().getTime()) * 0.001;

    if (owners) {
      setIsLoading(true);
      setIsAlerts(true);

      try {
        await markForSale({
          price,
          immediatePrice,
          auctionStartOffset: Math.round(auctionStartOffset > 0 ? auctionStartOffset : 0),
          auctionEndOffset: Math.round((endDate.getTime() - startDate.getTime()) * 0.001),
          amount,
        });
        setIsAlerts(false);
      } catch (err) {
        errorChecker(err);
        setIsAlerts(false);
      } finally {
        setSalePopup(false);
        setIsLoading(false);
        setIsAlerts(false);
      }
    }
  };

  return (
    <Popup
      clickOnClose={onClose}
      maxWidth={"604px"}
    >
      <fieldset className={"mt-0"}>
        {currentStep === 1 ? (
          <div className={S.SalePopup}>
            <ResponsiveDesktop>
              <PopupSubContentHeader title={"List for sale"}/>
            </ResponsiveDesktop>
            <ResponsiveMobile>
              <PopupSubContentTitle setTop={"98px"}>List for sale</PopupSubContentTitle>
            </ResponsiveMobile>

            <div className={classNames(S.ButtonBlock, S[theme])}>
              <ViewBlock
                value={["nftim", "help_ua_dev"]}
              >
                <ButtonPopup
                  onClick={() => {
                    setSaleType("Auction");
                    setCurrentStep(2);
                  }}
                  icon={<PopupClockIcon size={44} fill={"#000000"}/>}
                  title={"Auction"}
                  text={"Auction"}
                />
              </ViewBlock>
              <ButtonPopup
                onClick={() => {
                  setSaleType("FixedPrice");
                  setCurrentStep(3);
                }}
                icon={<LightningIcon size={42} fill={"#000000"}/>}
                title={"Fixed price"}
                text={"Fixed price"}
              />
            </div>
            <PopupSubContentText className={"!text-center !mt-12 !mb-0"}>
              By listing for sale, you agree our <Link to={"/terms"} onClick={onClose}>Terms of service</Link>
            </PopupSubContentText>
          </div>
        ) : currentStep === 2 ? (
          <SalePopupAuction
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateError={isDateError}
            setDateError={setIsDateError}
            setAmount={setAmount}
            price={price}
            setPrice={setPrice}
            immediatePrice={immediatePrice}
            setImmediatePrice={setImmediatePrice}
            onSaleSubmit={onSaleSubmit}
            isLoading={isLoading}
            isError={isError}
          />
        ) : (
          <SalePopupListForSale
            isError={isError}
            isLoading={isLoading}
            maxQuantity={maxQuantity}
            setAmount={setAmount}
            price={price}
            setPrice={setPrice}
            onSaleSubmit={onSaleSubmit}
          />
        )}
      </fieldset>
    </Popup>
  );
};
