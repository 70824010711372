import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const BurgerCrossIcon = (props: IProps) => {
  return (
    <svg
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21967 4.21967C4.51256 3.92678 4.98744 3.92678 5.28033 4.21967L12 10.9393L18.7197 4.21967C19.0126 3.92678 19.4874 3.92678 19.7803 4.21967C20.0732 4.51256 20.0732 4.98744 19.7803 5.28033L13.0607 12L19.7803 18.7197C20.0732 19.0126 20.0732 19.4874 19.7803 19.7803C19.4874 20.0732 19.0126 20.0732 18.7197 19.7803L12 13.0607L5.28033 19.7803C4.98744 20.0732 4.51256 20.0732 4.21967 19.7803C3.92678 19.4874 3.92678 19.0126 4.21967 18.7197L10.9393 12L4.21967 5.28033C3.92678 4.98744 3.92678 4.51256 4.21967 4.21967Z"
        fill="white"
      />
    </svg>
  );
};

export const BurgerCrossIconNoPadding = (props: IProps) => {
  return (
    <svg
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1.875 1.875L22.125 22.125" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.875 22.125L22.125 1.875" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
