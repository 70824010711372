import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import {useParams} from "react-router-dom";

import {Cards} from "components/Cards";
import {CollectionHeader} from "components/Collection/CollectionHeader";
import {
  HeaderSkeleton,
  HeaderSkeletonMobile,
  HeaderSkeletonTablet
} from "components/Collection/CollectionHeader/Skeleton";
import {useApi} from "api";
import {errorChecker} from "utils";
import {Container} from "components/Container";
import {ICollection, TIsLoading} from "types";
import {BigDesktop, Mobile, Tablet} from "components/Responsive";

import S from "./style.module.scss";

export const CollectionPage = () => {
  const {api} = useApi();
  const {id} = useParams();

  const [collection, setCollection] = useState<Partial<ICollection>>({});
  const [isLoading, setIsLoading] = useState<TIsLoading>("first");

  const collectionRequest = useCallback(async () => {
    setIsLoading("first");

    try {
      const res = await api.collections.getCollectionById(Number(id));
      setCollection(res);
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoading("loaded");
    }
  }, [api.collections, id]);

  useEffect(() => {
    (async () => collectionRequest())();
  }, [collectionRequest]);

  return (
    <Container className={S.Container}>
      {isLoading === "first" &&
        <>
          <BigDesktop>
            <HeaderSkeleton/>
          </BigDesktop>
          <Tablet>
            <HeaderSkeletonTablet/>
          </Tablet>
          <Mobile>
            <HeaderSkeletonMobile/>
          </Mobile>
        </>
      }
      {isLoading === "loaded" &&
        <CollectionHeader
          collection={collection}
        />
      }
      <Cards
        request={api.asset.getAssetsByFilters}
        query={{
          metadataCollection: String(id),
          isForSale: String(true)
        }}
        stateType={"base"}
        theme={"nftim"}
      />
    </Container>
  );
};
