import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const NotificationCanceled = ({size = 36}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2901_23927)"
      />
      <path
        d="M12.9375 12.9375L23.0625 23.0625"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9375 23.0625L23.0625 12.9375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2901_23927"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED213A"/>
          <stop
            offset="1"
            stopColor="#93291E"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
