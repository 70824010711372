import React from "react";
import {Link} from "react-router-dom";

import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

export const HelpPageHeader = (props: any) => {
  const theme = useTheme();

  return (
    <div className={S.HelpCenterPageHeaderWrapper}>
      <div className={S.HelpCenterPageHeader}>
        <ul>
          <li><Link to={"/helpFAQ"}>{theme === "nftim" ? "NFTIM" : "Help UA"}</Link></li>
          <li><span>{props.breadcrumb}</span></li>
        </ul>
      </div>
      <div className={S.HelpCenterPageHeaderTitle}>
        <h6>{props.title}</h6>
      </div>
    </div>
  );
};
