import React from "react";

import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

export interface ITab<Keys> {
  key: Keys;
  title: string;
}

interface IProps<Keys> {
  tabs: ITab<Keys>[];
  onTabClick: (key: Keys) => void;
  activeTabId?: string;
}

export const Tabs = <Keys extends any> ({
  tabs,
  onTabClick,
  activeTabId,
}: IProps<Keys>) => {

  const theme = useTheme();

  return (
    <ul className={classNames(S.TabWrapper, S[theme])}>
      {tabs.map((tab, key) => (
        <li
          className={classNames({[S.TabActive]: activeTabId === tab.key})}
          key={key}
          onClick={() => onTabClick(tab.key)}
        >
          {tab.title}
        </li>
      ))}
    </ul>
  );
};
