import React, {FC} from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const WalletIcon = ({
  className,
  onClick,
  fill = "#2F80ED",
  size = 37
}: IProps) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 37 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.45 0H3.7C1.65655 0 0 1.7349 0 3.875V27.125C0 29.2651 1.65655 31 3.7 31H31.45C33.4935 31 35.15 29.2651 35.15 27.125V24.2188C35.15 23.6837 34.7359 23.25 34.225 23.25H29.6C25.5131 23.25 22.2 19.7802 22.2 15.5C22.2 11.2198 25.5131 7.75 29.6 7.75H34.225C34.7359 7.75 35.15 7.31628 35.15 6.78125V3.875C35.15 1.7349 33.4935 0 31.45 0Z"
        fill={fill}
      />
      <path
        d="M29.6 11.625C27.5565 11.625 25.9 13.3599 25.9 15.5C25.9 17.6401 27.5565 19.375 29.6 19.375H33.3C35.3435 19.375 37 17.6401 37 15.5C37 13.3599 35.3435 11.625 33.3 11.625H29.6Z"
        fill={fill}
      />
    </svg>
  );
};
