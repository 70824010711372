import {ISocketNotification} from "components/Home/types";

interface ISocket {
  token?: string | null;
}

export const userSocketFunction = ({token}: ISocket, callback?: (data: ISocketNotification) => void) => {

  if (token || token !== null) {
    const socket = new WebSocket(
      `${process.env.REACT_APP_WSS_URL}ws/notifications?token=${token}&marketplaceName=${process.env.REACT_APP_MARKETPLACE_NAME}`
    );

    socket.addEventListener("message", function (event) {
      const debug = localStorage.getItem("debugValue") || "0";
      const data = JSON.parse(event.data) as ISocketNotification;

      if (debug === "1") {
        // eslint-disable-next-line no-console
        console.log({title: "userSocketFunction-socket", desc: data});
      }

      const customEvent = new CustomEvent("socketCustomEvent", {detail: data});
      window.dispatchEvent(customEvent);

      callback && callback(JSON.parse(event.data));
    });
  }
};
