import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const BurgerIcon = (props: IProps) => {
  return (
    <svg
      onClick={props.onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00006 6.75C3.00006 6.33579 3.33585 6 3.75006 6H20.2501C20.6643 6 21.0001 6.33579 21.0001 6.75C21.0001 7.16421 20.6643 7.5 20.2501 7.5H3.75006C3.33585 7.5 3.00006 7.16421 3.00006 6.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00006 12C3.00006 11.5858 3.33585 11.25 3.75006 11.25H20.2501C20.6643 11.25 21.0001 11.5858 21.0001 12C21.0001 12.4142 20.6643 12.75 20.2501 12.75H3.75006C3.33585 12.75 3.00006 12.4142 3.00006 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00006 17.25C3.00006 16.8358 3.33585 16.5 3.75006 16.5H20.2501C20.6643 16.5 21.0001 16.8358 21.0001 17.25C21.0001 17.6642 20.6643 18 20.2501 18H3.75006C3.33585 18 3.00006 17.6642 3.00006 17.25Z"
        fill="white"
      />
    </svg>
  );
};

export const BurgerIconDesign = (props: IProps) => {
  return (
    <svg
      onClick={props.onClick}
      width="27"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 21V24H3.5V21H20ZM27.5 10.5V13.5H0.5V10.5H27.5ZM27.5 0V3H8V0H27.5Z"
        fill="white"/>
    </svg>
  );
};
