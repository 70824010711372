import React from "react";
import {Link} from "react-router-dom";

import {Amount} from "components/layout/Amount";
import {useWeb3Contract} from "providers/Web3Contract";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  classNameInfo?: string;
}

export const ProfileMyBidsAuctionBlockAssetPreview = ({classNameInfo}: IProps) => {
  const {auction} = useWeb3Contract();
  const theme = useTheme();

  return (
    <div className={S.MyBidsAssetInfoBlock}>
      <div className={classNames(S.Thumbnail, S[theme])}>
        <img
          src={auction?.nft?.thumbnail}
          alt={auction?.nft?.title}
          className={S.MyBidsAssetImage}
        />
      </div>
      <div className={classNames(S.MyBidsAssetInfo, classNameInfo)}>
        <Link to={`/asset/${auction?.nft?.id}`}>
          <p className={"!text-xs"}>{auction?.nft?.title}</p>
        </Link>
        <Amount count={Number(auction?.number)} className={"small-timer"}/>
      </div>
    </div>
  );
};
