import React, {useRef, useState} from "react";

import {classNames} from "utils";
import {Video} from "components/Video";
import {useCreateAsset} from "providers/CreateAsset";
import {Button} from "components/Button";

import S from "./style.module.scss";

interface IProps {
  errorCoverText?: string;
  setErrorCoverText: (text: string) => void;
}

export const StepImage = ({errorCoverText = "", setErrorCoverText}: IProps) => {
  const {ipfsType, filePreview, ipfsExtends, ipfsMimeImageType, ipfsMimeImageView, setCover} = useCreateAsset();

  const fileRef = useRef<HTMLInputElement>(null);
  const [coverPrev, setCoverPrev] = useState<string>("");
  const [isCoverName, setCoverName] = useState<string>();

  const addFileImage = () => {
    if (fileRef.current?.files && fileRef.current?.files[0]) {
      let fileArray = fileRef.current?.files[0];
      if (ipfsMimeImageType.split(",").includes(fileArray.type)) {
        setErrorCoverText("");
        setCoverPrev(URL.createObjectURL(fileArray));
        setCover(fileArray);
        setCoverName(fileArray.name);
      } else {
        setErrorCoverText("incorrect mime type");
      }
    }
  };

  const fileRemove = () => {
    if (fileRef.current?.value) {
      fileRef.current.value = "";
    }
    setCoverPrev("");
    setCover(undefined);
    setCoverName("");
  };

  return (
    <div className={S.StepImage}>
      <div className={S.Frame}>
        <p>
          {coverPrev && (
            <div
              className={S.FrameBack}
              style={{backgroundImage: `url(${coverPrev})`}}
            />
          )}
          {(ipfsType === "image" && ipfsExtends !== "gif") &&
            <img
              src={filePreview}
              alt=""
            />
          }
          {ipfsExtends === "gif" &&
            <img
              src={filePreview}
              alt="gif"
            />
          }
          {ipfsType === "video" &&
            <Video
              className={S.Video}
              src={filePreview}
              controls={true}
              muted={false}
              poster={coverPrev}
            />
          }
          {ipfsType === "audio" &&
            <audio
              controls={true}
              src={filePreview}
              className={S.Audio}
            />
          }
        </p>
      </div>
      {(ipfsExtends === "gif" || ipfsType !== "image") &&
        <>
          {coverPrev
            ? <div className={S.LoadImage}>
              <div className={classNames(S.Text, S.Desc)}>{isCoverName}</div>
              <Button
                onClick={fileRemove}
                type={"button"}
              >
                Delete Cover
              </Button>
            </div>
            : <div className={S.LoadImage}>
              <div
                className={classNames(
                  S.Text,
                  {[S.Error]: !!errorCoverText}
                )}
              >Choose Cover for NFT ( {ipfsMimeImageView} )
              </div>
              <div className={S.CoverButton}>
                <Button
                  className={S.Button}
                  onClick={fileRemove}
                  type={"button"}
                >
                  Chose File
                </Button>
                <input
                  className={S.HideInput}
                  type="file"
                  onChange={addFileImage}
                  ref={fileRef}
                  accept={ipfsMimeImageType}
                />
              </div>
            </div>
          }
          {errorCoverText}
        </>
      }
    </div>
  );
};
