import React, {FC} from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const ArrowBottom: FC<IProps> = ({onClick, className}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37485 0.992497C9.37769 0.872126 9.3402 0.753921 9.26754 0.654315C9.19487 0.55471 9.09061 0.478653 8.96936 0.436719C8.8481 0.394785 8.71584 0.389058 8.59093 0.420334C8.46602 0.451609 8.35473 0.518334 8.27244 0.61123L5.01006 4.15576L1.74884 0.61123C1.69809 0.546969 1.63367 0.493152 1.55955 0.453149C1.48543 0.413146 1.4032 0.387819 1.31808 0.378754C1.23295 0.369689 1.14675 0.377082 1.06483 0.400469C0.98292 0.423855 0.90705 0.462733 0.842026 0.514666C0.777001 0.566599 0.724256 0.63047 0.686979 0.702278C0.649703 0.774086 0.628766 0.852284 0.625463 0.931976C0.622159 1.01167 0.63652 1.09113 0.667742 1.1654C0.698963 1.23967 0.746321 1.30714 0.806853 1.36358L4.53724 5.42174C4.59567 5.48547 4.66819 5.53665 4.74993 5.57174C4.83166 5.60683 4.92063 5.625 5.01064 5.625C5.10065 5.625 5.18961 5.60683 5.27134 5.57174C5.35308 5.53665 5.42567 5.48547 5.4841 5.42174L9.21808 1.36358C9.31591 1.26107 9.37153 1.12957 9.37499 0.992497L9.37485 0.992497Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};
