import React, {RefObject, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {LogoIcon} from "assets/icons/LogoIcon";

import S from "./style.module.scss";

interface IProps {
  refAttr?: RefObject<HTMLElement>;
}

export const Footer = ({refAttr}: IProps) => {
  const {i18n} = useTranslation();

  const onChangeLang = useCallback((lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  }, [i18n]);

  const lang = useMemo(() => {
    return [
      {
        name: "English",
        id: 1,
        onClick: onChangeLang,
        shortName: "en",
      },
      {
        name: "Русский",
        id: 2,
        onClick: onChangeLang,
        shortName: "ru",
      },
    ];
  }, [onChangeLang]);

  const language = localStorage.getItem("lang");
  // const currentItem = lang.find((item) => item.shortName === language);

  const listCategoriesFirst = [
    {link: "/marketplace", text: "Marketplace", target: false},
    {link: "https://helpua.io/", text: "NFT for charity", target: true},
  ];

  const listCompanyFirst = [
    {link: "/HelpFAQ", text: "Info center", target: false},
    {link: "/terms", text: "Terms of use", target: false},
  ];

  const listContactsFirst = [
    {link: "mailto:help@nftim.io", text: "help@nftim.io", desc: " - help & questions", target: true},
    {link: "mailto:tim@nftim.io", text: "tim@nftim.io", desc: " - partnerships", target: true},
    {link: "mailto:verif@nftim.io", text: "verif@nftim.io", desc: " - verification", target: true},
  ];

  return (
    <footer
      className={S.Footer}
      ref={refAttr}
    >
      <div className={S.Content}>
        <div className={S.FooterTop}>
          <div className={S.Row}>
            <div className={S.Logo}><LogoIcon/></div>
            <div className={S.Text}>World-class NFT, exclusive collaborations and grand exhibitions</div>
          </div>
          <div className={S.ListRow}>
            <div className={S.List}>
              <div className={S.Title}>Categories</div>
              <ul>
                {listCategoriesFirst.map((item, index) => {
                  return (
                    <li
                      key={index}
                      data-s={item.target}
                    >
                      {item.target ? (
                        <a
                          href={item.link}
                          target={"_blank"}
                        >{item.text}</a>
                      ) : (
                        <Link to={item.link}>{item.text}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={S.List}>
              <div className={S.Title}>Company</div>
              <ul>
                {listCompanyFirst.map((item, index) => {
                  return (
                    <li key={index}>
                      {item.target ? (
                        <a
                          href={item.link}
                          target={"_blank"}
                        >{item.text}</a>
                      ) : (
                        <Link to={item.link}>{item.text}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={S.List}>
              <div className={S.Title}>Contact us</div>
              <ul>
                {listContactsFirst.map((item, index) => {
                  return (
                    <li key={index}>
                      {item.target ? (
                        <>
                          <a
                            href={item.link}
                            target={"_blank"}
                            className={"!text-blue_static"}
                          >
                            {item.text}
                          </a>
                          {item.desc}
                        </>
                      ) : (
                        <>
                          <Link
                            to={item.link}
                            className={"!text-blue_static"}
                          >
                            {item.text}
                          </Link>
                          {item.desc}
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={S.FooterBottom}>
          <div className={S.CopyRights}>2022 @ NFTim. All rights reserved</div>
        </div>
      </div>
    </footer>
  );
};
