import React, {useCallback, useEffect, useState} from "react";

import {useApi} from "api";
import {ICollection, TIsLoading} from "types";
import {classNames, errorChecker} from "utils";
import {CardsCollections} from "components/CardsCollection";

interface IProps {
  limit?: number;
  className?: string;
}

export const NFTIMCollectionContainer = ({limit = 4, className}: IProps) => {
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [isLoading, setIsLoading] = useState<TIsLoading>("first");

  const {api} = useApi();

  const requestCollection = useCallback(async () => {
    try {
      let res = await api.collections.getCollections({
        limit: 15
      });
      setCollections(res.results);
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoading("loaded");
    }
  }, [api.collections]);

  useEffect(() => {
    requestCollection();
  }, [requestCollection]);

  return (
    <div className={classNames("relative w-full", className)}>
      <CardsCollections
        collection={collections}
        status={isLoading}
      />
    </div>
  );
};
