import React from "react";

import {AssetLeft} from "pages/Asset/AssetLeft";
import {AssetRight} from "pages/Asset/AssetRight";

import S from "./style.module.scss";

export const AssetDesc = () => {

  return (
    <div className={S.AssetContent}>
      <AssetLeft/>
      <AssetRight/>
    </div>
  );
};
