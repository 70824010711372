import React from "react";

import {useTheme} from "hooks/useTheme";
import {LinkIcon} from "assets/icons/LinkIcon";
import {useAsset} from "providers";

import S from "./style.module.scss";

export const AssetDescription = () => {
  const {asset} = useAsset();

  const theme = useTheme();

  return (
    <div className={S.WrapperDetailItem}>
      <ul>
        <li>
          <div className={S.Title}>Contact address</div>
          <div className={S.Link}>
            <LinkIcon/>
            <a
              className={"truncate"}
              target="_blank"
              href={asset?.links?.tokenAddress}
            >
              {asset?.tokenAddress}
            </a>
          </div>
        </li>
        <li>
          <div className={S.Title}>Mint hash</div>
          <div className={S.Link}>
            <LinkIcon/>
            <a
              className={"truncate"}
              target="_blank"
              href={asset?.links?.txHash}
            >
              {asset?.txHash}
            </a>

          </div>
        </li>
        <li>
          <div className={S.Title}>Token id</div>
          <div className={S.Link}>
            <span className={"truncate"}>{asset?.tokenId}</span>
          </div>
        </li>
        <li>
          <div className={S.Title}>Ipfs</div>
          <div className={S.Link}>
            <LinkIcon/>
            <a
              className={"truncate"}
              target="_blank"
              href={asset?.links?.metadataIpfs}
            >
              {asset?.ipfsJsonFull}
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};
