import React from "react";
import {Link} from "react-router-dom";

import {classNames} from "utils";
import {StepDone} from "components/CreateNft/StepContent/StepDone";
import {Button} from "components/Button";
import {useCreateAsset} from "providers/CreateAsset";
import {useMyUser} from "providers";

import S from "./style.module.scss";

export const StepFour = () => {
  const {user} = useMyUser();
  const {setStep} = useCreateAsset();

  return (
    <div className={S.StepDone}>
      <StepDone/>
      <div className={S.Title}>
        Congratulation! NFT Created!
      </div>
      <div className={S.Text}>
        Congratulations, the NFT has been created and is ready to be used on our marketplace. Go to NFT if you want to put it up for sale or auction right now
      </div>
      <ul className={S.Buttons}>
        <li>
          <Link
            to={`/profile/${user.id}#nft`}
            className={S.Link}
          >
            <span className={S.ButtonText}>Manage NFT</span>
          </Link>
        </li>
        <li>
          <Button
            className={classNames(S.Button, S.ButtonGrey)}
            color={"static"}
            classNameText={S.ButtonText}
            children={"Create OneMore"}
            onClick={() => setStep("choseChain")}
          />
        </li>
      </ul>
    </div>
  );
};
