import React from "react";

import {ImageIcon} from "assets/icons/ImageIcon";
import {VideoIcon} from "assets/icons/VideoIcon";
import {MusicIcon} from "assets/icons/MusicIcon";
import {INFTNews} from "components/Home/types";
import {IAsset} from "types";
import S from "components/layout/Card/MediaIcon/style.module.scss";

interface IProps {
  asset: INFTNews | IAsset;
}

export const CardMediaIcon = ({asset}: IProps) => {
  return (
    <div className={S.FileTypeWrapper}>
      {asset?.ipfsType === "image" ? (
        <ImageIcon/>
      ) : asset?.ipfsType === "video" ? (
        <VideoIcon/>
      ) : (
        <MusicIcon/>
      )}
    </div>
  );
};
