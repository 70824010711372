import React from "react";

export const BlockchainIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.13731 7.74254H1.78953C1.34932 7.74213 0.927251 7.56711 0.615926 7.25588C0.304601 6.94465 0.129451 6.52264 0.128906 6.08243V1.78865C0.129451 1.34844 0.304601 0.926425 0.615926 0.615196C0.927251 0.303967 1.34932 0.128948 1.78953 0.12854H6.13731C6.57743 0.129084 6.99937 0.304164 7.31058 0.615377C7.6218 0.926591 7.79688 1.34853 7.79742 1.78865V6.08243C7.79688 6.52255 7.6218 6.94449 7.31058 7.2557C6.99937 7.56692 6.57743 7.742 6.13731 7.74254ZM1.78953 1.41425C1.69023 1.41439 1.59503 1.45386 1.52476 1.52403C1.4545 1.5942 1.41489 1.68935 1.41462 1.78865V6.08243C1.41489 6.18173 1.4545 6.27688 1.52476 6.34705C1.59503 6.41722 1.69023 6.45669 1.78953 6.45683H6.13731C6.23656 6.45669 6.33171 6.4172 6.4019 6.34702C6.47208 6.27683 6.51157 6.18168 6.51171 6.08243V1.78865C6.51157 1.6894 6.47208 1.59425 6.4019 1.52406C6.33171 1.45388 6.23656 1.41439 6.13731 1.41425H1.78953Z"
      fill="#4F4F4F"
    />
    <path
      d="M6.15839 17.8714H1.81062C1.37036 17.8709 0.94829 17.6957 0.63698 17.3844C0.32567 17.0731 0.150536 16.651 0.149992 16.2108V11.9175C0.150537 11.4773 0.325686 11.0553 0.637011 10.7441C0.948336 10.4328 1.37041 10.2578 1.81062 10.2574H6.15839C6.59834 10.2582 7.02001 10.4334 7.331 10.7446C7.642 11.0558 7.81693 11.4776 7.81748 11.9175V16.2108C7.81707 16.6508 7.64219 17.0727 7.33119 17.384C7.02018 17.6953 6.59842 17.8706 6.15839 17.8714ZM1.81062 11.5431C1.71132 11.5432 1.61611 11.5827 1.54585 11.6529C1.47558 11.7231 1.43598 11.8182 1.43571 11.9175V16.2108C1.43584 16.3102 1.47539 16.4054 1.54567 16.4757C1.61595 16.546 1.71123 16.5855 1.81062 16.5857H6.15839C6.25769 16.5854 6.35284 16.5458 6.42301 16.4755C6.49318 16.4053 6.53266 16.3101 6.53279 16.2108V11.9175C6.53266 11.8183 6.49317 11.7231 6.42298 11.6529C6.3528 11.5827 6.25765 11.5432 6.15839 11.5431H1.81062Z"
      fill="#4F4F4F"
    />
    <path
      d="M16.2116 17.8714H11.8639C11.4236 17.8709 11.0015 17.6957 10.6902 17.3844C10.3789 17.0731 10.2038 16.651 10.2032 16.2108V11.9175C10.2038 11.4773 10.3789 11.0553 10.6903 10.7441C11.0016 10.4328 11.4237 10.2578 11.8639 10.2574H16.2116C16.6518 10.2579 17.0737 10.433 17.3849 10.7442C17.6961 11.0554 17.8712 11.4774 17.8718 11.9175V16.2108C17.8714 16.651 17.6963 17.0731 17.3851 17.3844C17.0739 17.6957 16.6519 17.8709 16.2116 17.8714ZM11.8639 11.5457C11.7646 11.5458 11.6694 11.5853 11.5991 11.6555C11.5288 11.7256 11.4892 11.8208 11.489 11.9201V16.2133C11.4891 16.3127 11.5286 16.408 11.5989 16.4783C11.6692 16.5486 11.7645 16.5881 11.8639 16.5883H16.2116C16.311 16.588 16.4061 16.5484 16.4763 16.4781C16.5464 16.4078 16.5859 16.3126 16.5861 16.2133V11.9175C16.5859 11.8183 16.5464 11.7231 16.4762 11.6529C16.4061 11.5827 16.3109 11.5432 16.2116 11.5431L11.8639 11.5457Z"
      fill="#4F4F4F"
    />
    <path
      d="M14.0403 7.74254C13.2889 7.73696 12.5559 7.50902 11.9338 7.08746C11.3116 6.6659 10.8283 6.06959 10.5445 5.37372C10.2608 4.67784 10.1895 3.91354 10.3395 3.17718C10.4895 2.44082 10.8542 1.76535 11.3876 1.23594C11.9209 0.706527 12.5991 0.346866 13.3365 0.2023C14.074 0.0577337 14.8377 0.134733 15.5315 0.423591C16.2252 0.712449 16.8179 1.20023 17.2349 1.82545C17.6518 2.45067 17.8743 3.18534 17.8743 3.93683C17.8693 4.94952 17.4628 5.91887 16.7441 6.63231C16.0254 7.34574 15.053 7.745 14.0403 7.74254ZM14.0403 1.41683C13.5432 1.42241 13.0588 1.57493 12.6481 1.8552C12.2374 2.13548 11.9188 2.53097 11.7324 2.9919C11.546 3.45284 11.5001 3.95861 11.6005 4.44556C11.7009 4.93252 11.9432 5.37889 12.2967 5.72849C12.6502 6.0781 13.0993 6.31531 13.5873 6.41027C14.0753 6.50524 14.5806 6.45371 15.0394 6.26217C15.4982 6.07064 15.8901 5.74766 16.1658 5.33388C16.4415 4.9201 16.5886 4.43402 16.5886 3.93683C16.5845 3.2646 16.3139 2.62145 15.8362 2.14852C15.3584 1.67559 14.7126 1.41152 14.0403 1.41425V1.41683Z"
      fill="#4F4F4F"
    />
  </svg>

);
