import React from "react";

export const NotificationMarket = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill="url(#paint0_linear_2727_26370)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 14.375C11.0403 14.375 10.8401 14.4628 10.6981 14.6172C10.5561 14.7715 10.4852 14.9783 10.5026 15.1873L11.138 22.8118C11.2351 23.978 12.21 24.875 13.3802 24.875H22.6198C23.79 24.875 24.7649 23.978 24.8621 22.8118L25.4974 15.1873C25.5149 14.9783 25.4439 14.7715 25.3019 14.6172C25.1599 14.4628 24.9598 14.375 24.75 14.375H11.25Z" fill="white"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 13.625C14.25 12.3824 15.2574 11.375 16.5 11.375H19.5C20.7427 11.375 21.75 12.3824 21.75 13.625V15.125C21.75 15.5392 21.4142 15.875 21 15.875H15C14.5858 15.875 14.25 15.5392 14.25 15.125V13.625ZM16.5 12.875C16.0858 12.875 15.75 13.2108 15.75 13.625V14.375H20.25V13.625C20.25 13.2108 19.9142 12.875 19.5 12.875H16.5Z" fill="white"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.375 11.75C15.375 10.3003 16.5502 9.125 18 9.125C19.4497 9.125 20.625 10.3003 20.625 11.75V12.125C20.625 12.5392 20.2892 12.875 19.875 12.875H16.125C15.7108 12.875 15.375 12.5392 15.375 12.125V11.75ZM16.939 11.375H19.0609C18.9065 10.9381 18.4898 10.625 18 10.625C17.5102 10.625 17.0935 10.9381 16.939 11.375Z" fill="white"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9798 10.7988L25.2298 12.6738C25.4008 12.8163 25.4997 13.0274 25.4997 13.25V15.125H23.9997V13.6013L22.0196 11.9512L22.9798 10.7988Z" fill="white"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0202 10.7988L10.7702 12.6738C10.5992 12.8163 10.5003 13.0274 10.5003 13.25V15.125H12.0003V13.6013L13.9805 11.9512L13.0202 10.7988Z" fill="white"/>
      <defs>
        <linearGradient
          id="paint0_linear_2727_26370"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#642B73"/>
          <stop
            offset="1"
            stopColor="#C6426E"
          />
        </linearGradient>
      </defs>
    </svg>

  );
};
