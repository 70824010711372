import React from "react";

import {ICollection, TIsLoading} from "types";
import {NFTIMCollectionCard} from "components/NFTIM/Collection/Card";
import {CardCollectionSkeleton} from "components/CardsCollection/skeleton";
import {EmptyItems} from "components/EmptyItems";

interface IProps {
  className?: string;
  collection?: ICollection[];
  status?: TIsLoading;
}

export const CardsProfileCollections = (
  {
    collection,
    status = "first"
  }: IProps) => {
  return (
    <>
      {status == "first" && (
        <div className={"relative grid w-full grid-cols-[repeat(auto-fill,minmax(290px,1fr))] gap-x-6 gap-y-0 max768:gap-x-3 grid-rows-[1fr_0_0_0_0] overflow-hidden"}>
          {Array.from([1, 2, 3].map((el, key) => {
            return (
              <div key={key} className={"relative block w-full h-0 pb-[calc(100%+110px)] bg-light_gray"}>
                <CardCollectionSkeleton key={key}/>
              </div>
            );
          }))}
        </div>
      )}
      {status === "loaded" && collection?.length === 0 ? (
        <EmptyItems/>
      ) : (
        <>
          <div className={"relative grid w-full grid-cols-[repeat(auto-fill,minmax(290px,1fr))] gap-x-6 gap-y-6 max768:gap-x-3"}>
            {collection && collection.map((item) =>
              <aside key={item.id} className={"relative block w-full"}>
                <NFTIMCollectionCard collection={item}/>
              </aside>
            )}
          </div>
        </>
      )}
    </>
  );
};
