import React, {useCallback, useContext, useEffect, useState} from "react";

import {useWeb3} from "providers";
import {useApi} from "api";
import {errorChecker} from "utils";
import {IUser} from "types";

interface IUseMyUser {
  user: IUser;
  requestUser: () => Promise<void>;
}

const HOCMyUserContext = React.createContext<IUseMyUser>({
  user: {
    id: 0
  },
  requestUser (): Promise<void> {
    return Promise.resolve(undefined);
  }
});
HOCMyUserContext.displayName = "HOCMyUserContext";
const HOCMyUserProvider = HOCMyUserContext.Provider;

export function useMyUser (): IUseMyUser {
  const hocMyUserData = useContext(HOCMyUserContext);
  if (hocMyUserData === null) {
    throw new Error("Hook available only in all components");
  }
  return hocMyUserData;
}

interface IProps {
  children?: React.ReactNode;
}

export const MyUserProvider = ({children}: IProps) => {
  const [user, setUser] = useState<IUser>({
    id: 0
  });

  const {web3State} = useWeb3();
  const {api} = useApi();

  const requestUser = useCallback(async () => {
    try {
      let res = await api.user.getUser();
      setUser(res);
    } catch (err) {
      errorChecker(err);
    }
  }, [api.user]);

  useEffect(() => {
    if (web3State?.wallet && sessionStorage.getItem("token")) {
      (async () => requestUser())();
    }
  }, [requestUser, web3State?.wallet]);

  return (
    <HOCMyUserProvider
      value={{
        user,
        requestUser
      }}
    >
      {children}
    </HOCMyUserProvider>
  );
};
