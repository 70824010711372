import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {INFTNews} from "components/Home/types";
import {IAsset} from "types";
import {classNames} from "utils";
import {useAssetBlockPrice} from "hooks/useAssetBlockPrice";
import {CardImg} from "components/layout/Card/Img";
import {Price} from "components/Price";
import {CardTimer} from "components/layout/Card/Timer";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  onLike?: (isLike: boolean, assetId: number) => void;
  asset: INFTNews | IAsset;
  width?: string;
  staticWidth?: boolean;
}

export const CardNFTIM = ({
  className,
  asset,
  staticWidth = false,
}: IProps) => {
  const price = useAssetBlockPrice(asset);
  const theme = useTheme();

  const defaultCurrency = useMemo(() => {
    return asset.currentMarketplace?.currency || asset.currentAuction?.currency || asset.defaultCurrency;
  }, [asset.currentAuction?.currency, asset.currentMarketplace?.currency, asset.defaultCurrency]);

  const renderPhoto = useMemo(
    () => {
      if (asset.hasMultipleOwners) {
        return (
          <>
            {asset?.lastOwners.slice(-4).map((item, key) => {
              return (
                <div
                  className={S.Avatar}
                  key={key}
                >
                  <img
                    className={S.CardUserPhoto}
                    src={item.thumbnailPhoto}
                    alt={item.displayName}
                  />
                </div>
              );
            })}
          </>
        );
      } else {
        return (
          <>
            {asset?.lastOwners.slice(-1).map((item, key) => {
              return (
                <Link
                  to={`/profile/${item?.id}`}
                  key={key}
                >
                  <div className={S.Avatar}>
                    <img
                      className={S.CardUserPhoto}
                      src={item.thumbnailPhoto}
                      alt={item.displayName}
                    />
                  </div>
                </Link>
              );
            })}
          </>
        );
      }
    },
    [asset.hasMultipleOwners, asset?.lastOwners]
  );

  return (
    <div className={classNames(S.Card, className, {[S.static]: staticWidth}, S[theme])}>
      <Link to={`/asset/${asset?.id}`}>
        <CardImg asset={asset}>
          {asset.hasAuction && asset.currentAuction?.auctionEnd && (
            <CardTimer
              asset={asset}
            />
          )}
        </CardImg>
      </Link>
      <div className={S.CardInfo}>
        <div className={S.AvatarRow}>
          {renderPhoto}
        </div>
        <div className={S.InfoRow}>
          <div className={S.Name}>
            {asset?.hasMultipleOwners
              ? "Multiple owners"
              : (
                <>
                  {asset?.lastOwners.slice(-1).map((item, key) => {
                    return (
                      <Link
                        to={`/profile/${item.id}`}
                        key={key}
                      >{item.displayName}</Link>
                    );
                  })}
                </>
              )
            }
          </div>
          <Price
            classNamePriceCrypto={S.CardPriceCrypto}
            cryptoImg={defaultCurrency?.image}
            symbol={defaultCurrency?.symbol}
            decimals={defaultCurrency?.decimals}
            price={price}
            isShowFiat={false}
            className={S.Price}
          />
        </div>
        <div className={S.InfoRow}>
          <div className={S.Title}>
            {asset.title}
          </div>
          <div className={S.CardPriceCrypto}>
            {asset.available} / {asset.initialCount}
          </div>
        </div>
      </div>
    </div>
  );
};
