import React, {useCallback, useMemo} from "react";

import {useAsset, useWeb3} from "providers";
import {AssetSellingActionBlock} from "pages/Asset/SellingAction/Block";
import {classNames, errorChecker} from "utils";
import {useTheme} from "hooks/useTheme";
import {Button} from "components/Button";
import {LightningIcon} from "assets/icons/LightningIconFilters";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";

import S from "../style.module.scss";

export const AssetSellingActionBuying = () => {
  const {isCheckUserChain, web3State} = useWeb3();
  const {cancelMarketplace} = useWeb3Contract();
  const {openBuyNowPopup} = useWeb3ContractPopup();
  const {asset, available} = useAsset();
  const theme = useTheme();

  const marketplaces = useMemo(() => {
    return available?.marketplaces?.[0];
  }, [available?.marketplaces]);

  const price = useMemo(() => {
    return String(marketplaces?.price);
  }, [marketplaces?.price]);

  const checkChainBuy = useCallback(
    async () => {
      try {
        await isCheckUserChain(asset?.chainId || 0);

        openBuyNowPopup({
          type: "buy",
          address: marketplaces?.marketplaceAddress,
          id: marketplaces?.marketplaceContractId,
          price,
          number: marketplaces?.number,
          currencyAddress: marketplaces?.currency?.address
        });
      } catch (err) {
        errorChecker(err);
      }
    },
    [
      asset?.chainId,
      isCheckUserChain,
      marketplaces?.currency?.address,
      marketplaces?.marketplaceAddress,
      marketplaces?.marketplaceContractId,
      marketplaces?.number,
      openBuyNowPopup,
      price
    ]
  );

  const BuyAuctionButtonTitle = useMemo(
    () => {
      if (web3State?.wallet && web3State?.wallet === marketplaces?.seller &&
        cancelMarketplace) {
        return {
          title: "button.cancelMarketplace",
          onClick: () => cancelMarketplace({
            marketplaceAddress: marketplaces?.marketplaceAddress,
            marketplaceId: marketplaces?.marketplaceContractId,
          })
        };

      } else {
        return {
          title: "button.buyItNow",
          onClick: checkChainBuy
        };
      }
    },
    [
      marketplaces?.marketplaceAddress,
      marketplaces?.marketplaceContractId,
      marketplaces?.seller,
      cancelMarketplace,
      checkChainBuy,
      web3State?.wallet
    ]
  );

  return (
    <div className={classNames(S.AuctionWrapper, S[theme])}>
      <div className={S.HeadBlock}>
        <div className={S.TitleRow}>
          <div className={S.Icon}><LightningIcon size={21}/></div>
          <div className={S.Title}>Buy now</div>
        </div>
        <div className={S.AmountRow}>{marketplaces?.number} in stock</div>
      </div>
      <div className={classNames(S.ActionBlock, S[theme])}>
        <AssetSellingActionBlock
          isShowUserBlock={true}
          price={price}
          userId={marketplaces?.user?.id}
          userDisplayName={marketplaces?.user?.displayName}
          userThumbnailPhoto={marketplaces?.user?.thumbnailPhoto}
          anotherPrice={"1"}
          currency={asset?.currentMarketplace?.currency}
        >
          <Button
            className={S.Button}
            onClick={BuyAuctionButtonTitle.onClick}
            color="primary"
            title={BuyAuctionButtonTitle.title}
          />
        </AssetSellingActionBlock>
      </div>
    </div>
  );
};
