import React, {useRef, useState} from "react";

import {classNames} from "utils";
import {useCreateAsset} from "providers/CreateAsset";
import {TCreateFileType} from "pages/Asset/types";

import S from "./style.module.scss";

export const StepLoad = () => {
  const {setStep, setFile, setFilePreview, setIpfsType, setIpfsExtends, ipfsMimeTypesFull} = useCreateAsset();

  const fileRef = useRef<HTMLInputElement>(null);
  const [isError, setError] = useState<boolean>(false);

  const addFile = () => {
    if (fileRef.current?.files && fileRef.current?.files[0]) {
      let file = fileRef.current?.files[0];
      if (ipfsMimeTypesFull.includes(file.type)) {
        setError(false);

        setFile(file);
        setFilePreview(URL.createObjectURL(file));

        setIpfsType(file.type.split("/")[0] as TCreateFileType || "other");
        setIpfsExtends(file.type.split("/")[1]);
        setStep("addInformation");
      } else {
        setError(true);
      }
    }
  };

  return (
    <div className={classNames(S.StepLoad, {[S.Error]: isError})}>
      <div className={S.Text}>
        {ipfsMimeTypesFull.map((el) => el.split("/")[1].toUpperCase()).join(", ")}. Max 100mb.
      </div>
      <div className={S.LoadButton}>
        <span>Choose File</span>
        <input
          type="file"
          onChange={addFile}
          ref={fileRef}
          accept={ipfsMimeTypesFull.join(",")}
        />
      </div>
    </div>
  );
};
