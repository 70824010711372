import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {classNames, errorChecker} from "utils";
import {useApi} from "api";
import {IAsset, TIsLoading} from "types";
import {Price} from "components/Price";
import {SkeletonCard} from "components/NFTIM/Home/Header/skeletCard";

import S from "./style.module.scss";

const LeftRow = () => {
  return (
    <div className={classNames(S.LeftRow)}>
      <div className={classNames(S.txt1, S.txtAll)}>
        DISCOVER
      </div>
      <div className={classNames(S.txt2, S.txtAll)}>
        EXCLUSIVE NFT
      </div>
      <div className={classNames(S.txt3, S.txtAll)}>
        BUY, SELL & COLLECT
      </div>
      <div className={classNames(S.txt4, S.txtAll)}>
        FROM CELEBRITIES & COMPANIES
      </div>
    </div>
  );
};

const RightRow = () => {
  const [card, setCard] = useState<IAsset>();
  const {api} = useApi();
  const [isLoading, setIsLoading] = useState<TIsLoading>("first");

  const cardNft = useCallback(async () => {
    try {
      let result = await api.slider.getSlides({
        limit: 1
      });
      setCard(result?.[0]?.object);
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoading("loaded");
    }
  }, [api.slider]);

  useEffect(() => {
    cardNft();
  }, [cardNft]);

  return (
    <div className={S.RightRow}>
      <div className={S.CardHeader}>
        {isLoading === "first" && (
          <SkeletonCard/>
        )}
        {isLoading === "loaded" && (
          <div className={S.Item}>
            <div className={S.Frame}>
              <div className={S.Image}>
                <div
                  className={S.Blur}
                  style={{backgroundImage: `url(${card?.image})`}}
                />
                <img
                  src={card?.image}
                  alt=""
                />
              </div>
            </div>
            <div className={S.DescBox}>
              <div className={S.Left}>
                <div className={S.Title}>{card?.title}</div>
                <div className={S.User}>
                  <div className={S.Avatar}>
                    <img
                      src={card?.author?.thumbnailPhoto}
                      alt=""
                    />
                  </div>
                  <div className={S.Box}>
                    <div className={S.Text}>Created by</div>
                    <div className={classNames(S.Name)}>
                      <span>{card?.author?.displayName}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={S.Right}>
                <Price
                  className={"flex-col !items-end"}
                  classNamePriceCrypto={"font-bold text-2xl max1152:text-lg max1152:!leading-4"}
                  classNameCryptoColor={"text-white"}
                  classNamePriceFiat={"max1152:text-xs"}
                  symbol={card?.defaultCurrency?.symbol}
                  decimals={card?.defaultCurrency?.decimals}
                  price={card?.currentMarketplace?.price}
                />
              </div>
            </div>
            <Link
              to={`/asset/${card?.id}`}
              className={S.ViewButton}
            >View NFT</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomeNFTIMHeader = () => {
  return (
    <div className={S.ContainerMainHeader}>
      <div className={S.InnerRow}>
        <div className={classNames(S.FlexRow, "max1680:max-w-[1100px] mx-auto")}>
          <LeftRow/>
          <RightRow/>
        </div>
      </div>
    </div>
  );
};
