import React, {useCallback, useContext, useEffect, useState} from "react";

import {IFiltersObject, TStructureFilterType, TQuery} from "api";

export type TSetFilterValue = Partial<IFiltersObject>;

interface IUseFilter {
  baseFilterState: TSetFilterValue[];
  setFilter: (value: TSetFilterValue, remove: boolean, type: TStructureFilterType) => void;
  clearFilter: (payloadList?: IFiltersObject[]) => void;
  applyFilter: () => void;
}

export const FilterContext = React.createContext<IUseFilter>({
  baseFilterState: [],
  setFilter: () => {
  },
  clearFilter: () => {
  },
  applyFilter: () => {
  }
});
FilterContext.displayName = "FilterContext";

export function useFilter (): IUseFilter {
  const FilterData = useContext(FilterContext);
  if (FilterData === null) {
    throw new Error("FilterData");
  }
  return FilterData;
}

interface IProps {
  children: React.ReactNode;
  onChangeFilter: (values: TQuery) => void;
}

export const FilterProvider = ({children, onChangeFilter}: IProps) => {
  const [baseFilterState, setBaseFilterState] = useState<TSetFilterValue[]>([]);
  const [initApplyChange, setInitApplyChange] = useState<number>(0);

  const setFilter = useCallback((option: TSetFilterValue, remove: boolean, type: TStructureFilterType) => {
    if (remove) {
      if (type === "checkbox") {
        const filtered = baseFilterState
          .map((el) => {
            if (!(String(el.key) === String(option.key) && String(el.value) === String(
              option.value))) {
              return el;
            }

            return undefined;
          })
          .filter(el => el !== undefined);
        // @ts-ignore
        setBaseFilterState(filtered);
      }

      if (type === "select") {
        if (option?.value !== undefined) {
          setBaseFilterState(prevState => prevState.map(el => el.key === option.key ? option : el));
        } else {
          setBaseFilterState(prevState => prevState.filter(el => el.key !== option.key));
        }
      }
    } else {
      setBaseFilterState(prevState => {
        return [
          ...prevState,
          {...option, type}
        ];
      });
    }
  }, [baseFilterState]);

  const applyFilter = useCallback(() => {
    const filters = baseFilterState.reduce<TQuery>((previousValue, currentValue) => {
      if (currentValue?.key && currentValue?.value) {
        if (currentValue?.key === "chainId") {
          if (previousValue[currentValue?.key]) {
            return {...previousValue, [currentValue?.key]: [...previousValue[currentValue?.key], currentValue?.value]};
          } else {
            return {...previousValue, [currentValue?.key]: [currentValue?.value]};
          }
        }
        return {...previousValue, [currentValue?.key]: currentValue?.value};
      }

      return previousValue;
    }, {});

    onChangeFilter(filters);
  }, [baseFilterState, onChangeFilter]);

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initApplyChange]);

  const clearFilter = useCallback((payloadList?: IFiltersObject[]) => {
    const clearLists = payloadList?.reduce((previousValue: string[], currentValue: IFiltersObject) => {
      return previousValue.find((value) => value === currentValue.key)
        ? previousValue
        : [
          ...previousValue,
          currentValue.key
        ];
    }, []);

    setBaseFilterState(prevState => prevState.filter((el) => !clearLists?.includes(el.key || "")));
    setInitApplyChange(prevState => prevState + 1);
  }, []);

  return (
    <FilterContext.Provider
      value={{
        baseFilterState,
        setFilter,
        clearFilter,
        applyFilter
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
