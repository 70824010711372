import React, {useCallback, useEffect} from "react";

import {PostInner} from "components/Profile/ProfileContent/Profile/post";
import Img from "assets/img/about-banner.png";
import {LightningIcon} from "assets/icons/LightningIconFilters";
import {useProfile} from "providers";
import {Cards} from "components/Cards";
import {useApi} from "api";
import {errorChecker} from "utils";

interface IProps {}

const posts = [
  {
    id: 1,
    avatar: Img,
    name: "NFTIM - Exclusive NFT Marketplace",
    title: "Dear friends, we announce our new NFT collection",
    text: <>
      <p>1/ In an effort to provide our community with more accurate data we’ve expanded our <a href="#">#washtrading</a> filter to include our major dashboards and <a href="#">#NFT indicators</a>.</p>
      <br/>
      <p>Let&apos;s check out our new report on NFT Wash Trading: Eliminating The &quot;Noise&quot; in the Market 👇🧵</p>
      <br/>
      <p>
        <img src={Img} alt=""/>
      </p>
    </>,
    views: "1.7M",
    likes: 0
  },
  {
    id: 2,
    avatar: Img,
    name: "NFTIM - Exclusive NFT Marketplace",
    title: "Dear friends, we announce our new NFT collection",
    text: <>
      <p>1/ In an effort to provide our community with more accurate data we’ve expanded our <a href="#">#washtrading</a> filter to include our major dashboards and <a href="#">#NFT indicators</a>.</p>
      <br/>
      <p>Let&apos;s check out our new report on NFT Wash Trading: Eliminating The &quot;Noise&quot; in the Market 👇🧵</p>
      <br/>
      <p>
        <img src={Img} alt=""/>
      </p>
    </>,
    views: "1.7M",
    likes: 10
  },
  {
    id: 3,
    avatar: Img,
    name: "NFTIM - Exclusive NFT Marketplace",
    title: "Dear friends, we announce our new NFT collection",
    text: <>
      <p>1/ In an effort to provide our community with more accurate data we’ve expanded our <a href="#">#washtrading</a> filter to include our major dashboards and <a href="#">#NFT indicators</a>.</p>
      <br/>
      <p>Let&apos;s check out our new report on NFT Wash Trading: Eliminating The &quot;Noise&quot; in the Market 👇🧵</p>
      <br/>
      <p>
        <img src={Img} alt=""/>
      </p>
    </>,
    views: "1.7M",
    likes: 20
  }
];

export const ProfilePosts = (
  {}:IProps) => {
  const {api} = useApi();

  return (
    <div className={"relative flex gap-14 max1280:flex-col"}>
      <div className={"relative flex-1 min-w-0 max1280:flex-none max1280:w-full"}>
        {posts && posts.map((item, index) => {
          return (
            <PostInner key={index} object={item}/>
          );
        })}
      </div>
      <div className={"relative block w-[327px] max1280:w-full flex-none"}>
        <div className={"relative flex items-center gap-1 mb-4"}>
          <LightningIcon size={36} fill={"#000000"}/>
          <div className={"relative block text-black text-4xl font-bold"}>
            Hot auctions
          </div>
        </div>
        <div className={"relative block w-full"}>
          <Cards
            request={api.news.getNewsFromES}
            limit={4}
            query={{
              hasAuction: String(true)
            }}
            removeInfinityScroll={true}
            stateType={"object"}
            theme={"nftim"}
          />
        </div>
      </div>
    </div>
  );
};
