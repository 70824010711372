import React from "react";
import {useNavigate} from "react-router-dom";

import {Button} from "components/Button";

import S from "./style.module.scss";

export const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className={S.Page404Wrapper}>
      <div className={S.Page404}>
        <h1>404</h1>
        <h2>
          Sorry. the content you’re looking for doesn’t exist. Either it was
          removed, or you mistyped the link.{" "}
        </h2>
        <Button
          className={S.Button}
          onClick={() => navigate("/")}
          title={"Go to homepage"}
        />
      </div>
    </div>
  );
};
