import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const CheckBox = ({
  className,
  size = 10,
  fill = "#fff",
}: IProps) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8125 4.75L3.875 6.8125L8.9375 1.75"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
