import React from "react";

import {HelpCenterPageHeader} from "pages/HelpCenter/Layout/Header";

interface IProps {

}

export const HelpCenterPageOurPartners = (props: IProps) => {
  return (
    <>
      <HelpCenterPageHeader
        title={"Our partners"}
      />
    </>
  );
};
