import React from "react";
import {useMediaQuery} from "react-responsive";

// TODO remove this all file
interface IProps {
  children: JSX.Element;
  min?: number;
  max?: number;
}

const BigDesktop = ({children}: IProps) => {
  const isSmallDesktop = useMediaQuery({minWidth: 1280});
  return isSmallDesktop ? children : <></>;
};
const BigDesktopForBids = ({children}: IProps) => {
  const isSmallDesktop = useMediaQuery({minWidth: 768});
  return isSmallDesktop ? children : <></>;
};
const SmallDesktop = ({children}: IProps) => {
  const isSmallDesktop = useMediaQuery({maxWidth: 1279});
  return isSmallDesktop ? children : <></>;
};
const Tablet = ({children}: IProps) => {
  const isTablet = useMediaQuery({minWidth: 768, maxWidth: 991});
  return isTablet ? children : <></>;
};
const Mobile = ({children}: IProps) => {
  const isMobile = useMediaQuery({maxWidth: 767});
  return isMobile ? children : null;
};
const ResponsiveDesktop = ({children}: IProps) => {
  const isSmallDesktop = useMediaQuery({minWidth: 1024});
  return isSmallDesktop ? children : <></>;
};
const ResponsiveMobile = ({children}: IProps) => {
  const isMobile = useMediaQuery({maxWidth: 1023});
  return isMobile ? children : <></>;
};
const CardsMacBook = ({children}: IProps) => {
  const isTablet = useMediaQuery({minWidth: 1024, maxWidth: 1279});
  return isTablet ? children : <></>;
};
const CardsTablet = ({children}: IProps) => {
  const isTablet = useMediaQuery({minWidth: 560, maxWidth: 1023});
  return isTablet ? children : <></>;
};
const CardMobile = ({children}: IProps) => {
  const isMobile = useMediaQuery({maxWidth: 559});
  return isMobile ? children : <></>;
};
const CardCarouselBig = ({children}: IProps) => {
  const isSmallDesktop = useMediaQuery({minWidth: 1440});
  return isSmallDesktop ? children : <></>;
};
const CardCarouselMacBook = ({children}: IProps) => {
  const isMobile = useMediaQuery({minWidth: 1024, maxWidth: 1439});
  return isMobile ? children : <></>;
};
const CardCarouselSmall = ({children}: IProps) => {
  const isMobile = useMediaQuery({maxWidth: 479});
  return isMobile ? children : <></>;
};
const ResponsiveCustom = ({children, min = 0, max = 9999}: IProps) => {
  const isResponsive = useMediaQuery({minWidth: min, maxWidth: max});
  return isResponsive ? children : <></>;
};
export {
  Tablet,
  Mobile,
  BigDesktop,
  SmallDesktop,
  BigDesktopForBids,
  ResponsiveDesktop,
  ResponsiveMobile,
  CardsMacBook,
  CardsTablet,
  CardMobile,
  CardCarouselBig,
  CardCarouselSmall,
  CardCarouselMacBook,
  ResponsiveCustom
};
