import React from "react";

import {useAsset} from "providers";
import {EyeIcon} from "assets/icons/EyeIcon";
import {HeartIconActive} from "assets/icons/HeartIconActive";
import {HeartIcon} from "assets/icons/HeartIcon";
import {ShareIcon} from "assets/icons/ShareIcon";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";

import S from "./style.module.scss";

export const AssetAction = () => {
  const {asset, assetLike} = useAsset();
  const {toggleSharePopup} = useWeb3ContractPopup();

  const blockchainNameFirstLetter: string = asset?.chain ? asset?.chain[0] : "";
  const blockchainName = (blockchainNameFirstLetter.toUpperCase() + asset?.chain?.split("").slice(1).join(""));

  return (
    <ul className={S.AssetActionWrapper}>
      <li className={S.Blockchain}>
        <img
          className={S.OwnersChainImage}
          src={asset?.currentAuction?.currency?.image}
          alt={asset?.currentAuction?.currency?.symbol}
        />
        <span>{blockchainName}</span>
      </li>
      <li>
        <EyeIcon fill={"#939CB4"}/>
        <span>Views</span>
        <p>{asset?.viewCount}</p>
      </li>
      <li
        onClick={assetLike}
        className={S.Action}
      >
        {asset?.isLike ? <HeartIconActive/> : <HeartIcon fill={"#939CB4"}/>}
        <span>{asset?.likeCount}</span>
      </li>
      <li
        onClick={toggleSharePopup}
        className={S.Action}
      >
        <ShareIcon fill={"#939CB4"}/>
      </li>
    </ul>
  );
};
