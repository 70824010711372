import React, {useEffect, useMemo, useState} from "react";

import {classNames} from "utils";
import {TCryptoCurrency, TFontSize, TStringNumber} from "types";
import {usePrice, useWeb3} from "providers";

import S from "./style.module.scss";

const optionsFiat = {
  style: "currency",
  currency: "usd",
  currencyDisplay: "symbol",
  minimumFractionDigits: 2
};

interface IUseCryptoMath {
  firstValue?: TStringNumber | number;
  secondValue?: TStringNumber | number;
  operation?: "-" | "+" | "*" | "/";
}

export const useCryptoMath = ({
  firstValue = "0",
  secondValue = "1",
  operation = "*"
}: IUseCryptoMath): [number, Function] => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    const checkFirstValue = Number(firstValue);
    const checkSecondValue = Number(secondValue);

    if (!isNaN(checkFirstValue) && !isNaN(checkSecondValue)) {
      if (operation === "*") {
        setValue(Math.round(Number(firstValue) * Number(secondValue)));
      }
      if (operation === "-") {
        setValue(Math.round(Number(firstValue) - Number(secondValue)));
      }
      if (operation === "+") {
        setValue(Math.round(Number(firstValue) + Number(secondValue)));
      }
      if (operation === "/") {
        setValue(Math.round(Number(firstValue) / Number(secondValue)));
      }
      return;
    }

    setValue(0);
  }, [firstValue, secondValue, operation]);

  return [value, setValue];
};

export const useConvertCrypto = (
  cryptoPrice: string | number = "0",
  amount: number = 1,
  toFixed: number = 5,
  decimals: number = 18
): [string, Function] => {
  const [price, setPrice] = useState<string>("0");
  const {fromWei} = useWeb3();

  useEffect(() => {
    const cryptoPriceChecked = Math.round(Number(cryptoPrice));

    if (!isNaN(cryptoPriceChecked)) {
      const p = Number(fromWei({
        price: Math.round((cryptoPriceChecked || 0) * amount),
        toFixed: 10,
        decimals: decimals
      }));
      setPrice(`${Intl.NumberFormat("en", {
        style: "decimal",
        maximumFractionDigits: toFixed
      }).format(p)}`);
      return;
    }

    setPrice("0");
  }, [amount, cryptoPrice, decimals, fromWei, toFixed]);

  return [price, setPrice];
};

export const useConvertFiat = (cryptoPrice: string = "0", cryptoType: TCryptoCurrency = "ETH"): [string, Function] => {
  const [price, setPrice] = useState<string>("~ $");
  const {cryptoList} = usePrice();

  useEffect(() => {
    if (cryptoList[cryptoType]) {
      const p = (Number(cryptoPrice.replace(",", "")) * Number(cryptoList[cryptoType]));
      setPrice(`~ ${Intl.NumberFormat("en", optionsFiat).format(p)}`);
      return;
    }

    setPrice("");
  }, [cryptoPrice, cryptoType, cryptoList]);

  return [price, setPrice];
};

export const usePriceFiat = (price?: string | number, emptyValue?: string): string => {
  return useMemo(() => {
    return price
      ? `${Intl.NumberFormat("en", optionsFiat)
        .format(Number(price) || 0)}`
      : `$${emptyValue || "0.00"}`;
  }, [emptyValue, price]);
};

interface IProps {
  symbol?: TCryptoCurrency;
  className?: string;
  classNamePriceCrypto?: string;
  classNamePriceFiat?: string;
  classNameCryptoColor?: string;
  priceCryptoSize?: TFontSize;
  priceFiatSize?: TFontSize;
  price?: string;
  amount?: number;
  isShowCrypto?: boolean;
  isShowFiat?: boolean;
  isShowChain?: boolean;
  cryptoImg?: string;
  isShowSymbol?: boolean;
  decimals?: number;
}

export const Price = ({
  symbol = "ETH",
  className,
  classNamePriceCrypto,
  classNamePriceFiat,
  priceCryptoSize = "text-base",
  priceFiatSize = "text-base",
  classNameCryptoColor = "text-black",
  price = "0",
  amount = 1,
  isShowCrypto = true,
  isShowFiat = true,
  isShowChain = false,
  cryptoImg,
  isShowSymbol = true,
  decimals = 18
}: IProps) => {
  const {fullPriceList} = usePrice();

  const [cryptoPrice] = useConvertCrypto(price, amount, 5, decimals);
  const [fiatPrice] = useConvertFiat(cryptoPrice, symbol);

  const showChinName = useMemo(() => {
    return fullPriceList.find(el => el.base === symbol)?.chain || "";
  }, [fullPriceList, symbol]);

  return (
    <div className={classNames(S.PriceWrapper, className)}>
      {cryptoImg &&
        <img
          className={S.PriceChainImage}
          src={cryptoImg}
          alt={symbol}
        />
      }
      {isShowCrypto &&
        <p
          className={classNames(S.PriceCrypto, priceCryptoSize, classNamePriceCrypto, classNameCryptoColor, "truncate")}
          title={`${cryptoPrice} ${symbol}`}
        >
          {`${cryptoPrice} ${isShowSymbol ? symbol : ""}`}
          {(isShowChain && showChinName) ? <span className={S.PriceChainName}>({showChinName})</span> : ""}
        </p>
      }
      {(isShowFiat) &&
        <p
          className={classNames(S.PriceFiat, priceFiatSize, classNamePriceFiat, "truncate")}
          title={fiatPrice}
        >
          {fiatPrice}
        </p>
      }
    </div>
  );
};
