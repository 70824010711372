import React from "react";

import S from "./style.module.scss";

interface IListView {
  title: string;
  text: string;
}

interface IProps {
  title?: string;
  body: IListView[];
}

export const ListView = ({title, body}: IProps) => {
  return (
    <div className={S.ListView}>
      <h6>{title}</h6>
      <ul>
        {body.map((el, key) => {
          return (
            <li key={key}><span>{el.title}</span> – {el.text}</li>
          );
        })}
      </ul>
    </div>
  );
};
