import React, {useMemo, useState} from "react";

import {Button} from "components/Button";
import {IWeb3CancelAuction} from "pages/Asset/types";
import {classNames, errorChecker} from "utils";
import {Price, useConvertCrypto, useConvertFiat, useCryptoMath} from "components/Price";
import {useStore, useWeb3} from "providers";
import {
  Popup,
  PopupSubContentHeader,
  PopupSubContentInput,
  PopupSubContentPriceBlock,
  PopupSubContentPriceChild
} from "components/Popups";
import {Input} from "components/Input";
import {useTheme} from "hooks/useTheme";
import {useWeb3Contract} from "providers/Web3Contract";

import S from "./style.module.scss";

interface IProps {
  onClose: () => void;
  decimals?: number;
}

const percentList = [10, 15, 20, 25, 30];

export const NewBidPopup = ({
  onClose,
  decimals = 18,
}: IProps) => {
  const {fromWei, toWei} = useWeb3();
  const {symbol, auction, placeBid} = useWeb3Contract();
  const {setIsAlerts} = useStore();

  const [activePercent, setActivePercent] = useState<number>(1.10);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const price = useMemo(() => {
    if (auction?.highestBid === "0") {
      return String(auction?.minimumBid);
    }

    return String(auction?.highestBid);
  }, [auction?.highestBid, auction?.minimumBid]);

  const [valueFirst] = useCryptoMath({
    firstValue: auction?.highestBid,
    secondValue: activePercent
  });

  const [valueSecond] = useCryptoMath({
    firstValue: valueFirst,
    secondValue: auction?.yourBid?.bid,
    operation: "-"
  });

  const bidValue = useMemo(() => {
    if (auction?.yourBid?.bid) {
      const yourBid = fromWei({price: valueSecond, toFixed: 10});

      return toWei({price: (Number(yourBid) < 0.0001 || Number(yourBid) === 0) ? 0.0001 : yourBid});
    }

    return Number(price) * activePercent;
  }, [activePercent, auction?.yourBid?.bid, fromWei, price, toWei, valueSecond]);

  const [inputValue, setInputValue] = useConvertCrypto(bidValue, 1, 6);
  const [inputValueFiat] = useConvertFiat(inputValue, symbol);

  const actionPlaceBid = async (payload: IWeb3CancelAuction) => {
    onClose();
    try {
      await placeBid(payload);
    } catch (err) {
      errorChecker(err);
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const checkPrice = async (payload: IWeb3CancelAuction) => {
    setIsAlerts(true);
    setIsError(false);
    setError("");
    let percent = 1.10;

    // YourBids exists
    if (auction?.yourBid?.bid) {
      const highestBid = parseFloat(String(Number(fromWei({price: auction?.highestBid, toFixed: 10})) * percent));
      const yourBid = parseFloat(fromWei({price: auction?.yourBid?.bid}));

      let bids = Number((highestBid - yourBid).toFixed(10));

      if (bids < 0.0001) {
        bids = 0.0001;
      }

      if (Number(inputValue) >= bids) {
        await actionPlaceBid(payload);
        return;
      } else {
        setIsAlerts(false);
        setIsError(true);
        setError(`Minimum bid ${bids}`);
        return;
      }
      // Dont exists
    } else {
      let bids;

      if (auction?.highestBid === "0") {
        bids = Number((Number(fromWei({price: auction?.minimumBid})) * percent).toFixed(10));
      } else {
        bids = Number((Number(fromWei({price: auction?.highestBid})) * percent).toFixed(10));
      }

      if (bids < 0.0001) {
        bids = 0.0001;
      }

      if (Number(inputValue) >= bids) {
        await actionPlaceBid(payload);
        return;
      } else {
        setIsAlerts(false);
        setIsError(true);
        setError(`Minimum bid ${bids}`);
        return;
      }
    }
  };

  const theme = useTheme();

  return (
    <>
      <Popup
        clickOnClose={onClose}
        maxWidth={"604px"}
      >
        <PopupSubContentHeader
          title={"Place a bid"}
        />
        <form onSubmit={onSubmit}>
          <PopupSubContentPriceBlock
            className={S.PriceBlock}
            classText={auction?.yourBid?.bid && "DoublePrice"}
          >
            <PopupSubContentPriceChild label={"Current bid"}>
              <Price
                symbol={symbol}
                decimals={decimals}
                className={S.PriceWrapper}
                price={price}
                priceFiatSize={"text-xs"}
              />
            </PopupSubContentPriceChild>
            {auction?.yourBid?.bid &&
              <PopupSubContentPriceChild label={"Your bid"}>
                <Price
                  symbol={symbol}
                  decimals={decimals}
                  className={S.PriceWrapper}
                  price={auction?.yourBid?.bid}
                  priceFiatSize={"text-xs"}
                />
              </PopupSubContentPriceChild>
            }
          </PopupSubContentPriceBlock>
          <div className={S.InputItemRow}>
            <PopupSubContentInput
              label={auction?.yourBid?.bid
                ? "How much do you want to add?"
                : "Enter your bid"}
              labelDesc={inputValueFiat && inputValueFiat}
            >
              <Input
                className={classNames(S.Input, S[theme])}
                value={inputValue}
                placeholder={inputValue}
                onChange={(value) => setInputValue(value)}
                onClick={() => setInputValue("")}
                isError={isError}
                afterLabel={error}
              />
              <label>{symbol}</label>
            </PopupSubContentInput>
          </div>
          <div className={S.PercentButtonWrapper}>
            {percentList.map((percent, key) => {
              const percentValue = 1 + (percent / 100);
              return (
                <div
                  key={key}
                  className={classNames(S.PercentButton, S[theme], {[S.Active]: activePercent === percentValue})}
                  onClick={() => {
                    setActivePercent(percentValue);
                  }}
                >
                  +{percent}%
                </div>
              );
            })}
          </div>
          <div className={S.ButtonsWrapper}>
            <Button
              onClick={() => {
                checkPrice({
                  auctionAddress: String(auction?.auctionAddress),
                  auctionId: auction?.auctionContractId || 0,
                  price: inputValue,
                  currencyAddress: auction?.currency?.address
                });
              }}
              type={"submit"}
              title="button.placeBid"
              color="primary"
            />
          </div>
        </form>
      </Popup>
    </>
  );
};
