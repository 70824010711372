import React, {useCallback, useEffect, useState} from "react";
import {isIOS, isMobile} from "react-device-detect";
import {Link} from "react-router-dom";

import {ButtonPopup, Popup, PopupSubContentHeader, PopupSubContentText, PopupSubContentTitle} from "components/Popups";
import {ViewBlock} from "components/ViewBlock";
import {TorusIcon} from "assets/icons/TorusIcon";
import {WalletsExtensionPopup} from "components/Popups/WalletsExtensionPopup";
import {ChangeIdPopup} from "components/Popups/ChangeIdPopup";
import {useWeb3} from "providers/Web3";
import {usePrice} from "providers/Price";
import {MetamaskIcon} from "assets/icons/MetamaskIcon";
import {CoinbaseIcon} from "assets/icons/Coinbase";
import {decimalToHex, errorChecker} from "utils";
import {AuthPopup} from "components/Popups/Auth";
import {ResponsiveDesktop, ResponsiveMobile} from "components/Responsive";

export type TEventList = "connect" | "install" | "changeChainId" | "setChainId" | "signature";

export type TWeb3PopupCustomEvent = Record<TEventList, number | boolean>;

export type TWeb3PopupCustomEventPartial = Partial<TWeb3PopupCustomEvent>;

export const Web3ProvidePopup = () => {
  const {login, walletTypePopup, web3} = useWeb3();
  const {listOfNetwork} = usePrice();
  const [isShowConnectModal, isSetShowConnectModal] = useState<boolean>(false);
  const [isShowInstallModal, isSetShowInstallModal] = useState<boolean>(false);
  const [isShowChangeChainIdModal, isSetShowChangeChainIdModal] = useState<boolean>(false);
  const [isShowSignedModal, setIsShowSignedModal] = useState<boolean>(false);
  const [changedChainId, setChangedChainId] = useState<number>(0);

  const web3PopupCustomEvent = useCallback((value) => {
    const detail: TWeb3PopupCustomEventPartial = value.detail;

    Object.keys(detail).map((el) => {
      if (el === "connect") {
        isSetShowConnectModal(Boolean(detail[el]));
      }
      if (el === "install") {
        isSetShowInstallModal(Boolean(detail[el]));
      }
      if (el === "changeChainId") {
        isSetShowChangeChainIdModal(Boolean(detail[el]));
      }
      if (el === "setChainId") {
        setChangedChainId(Number(detail[el]));
      }
      if (el === "signature") {
        setIsShowSignedModal(true);
        isSetShowConnectModal(false);
        isSetShowInstallModal(false);
        isSetShowChangeChainIdModal(false);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("web3PopupCustomEvent", web3PopupCustomEvent);

    return () => {
      window.removeEventListener("web3PopupCustomEvent", web3PopupCustomEvent);
    };
  }, [web3PopupCustomEvent]);

  const flowAddNewNetwork = useCallback(async () => {
    const network = listOfNetwork.find(el => el.chainId === changedChainId);

    if (network) {
      const findNetwork = {
        chainId: decimalToHex(changedChainId),
        chainName: network.name,
        rpcUrls: network.rpc,
        nativeCurrency: network.nativeCurrency,
        blockExplorerUrls: [network.explorers[0].url],
      };

      try {
        // @ts-ignore
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [findNetwork],
        });

        isSetShowChangeChainIdModal(false);
        setChangedChainId(0);
      } catch (err) {
        errorChecker(err);
      }
    }
  }, [changedChainId, listOfNetwork, web3.currentProvider]);

  const flowChangeId = useCallback(async () => {
    try {
      // @ts-ignore
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{chainId: decimalToHex(changedChainId)}]
      });

      isSetShowChangeChainIdModal(false);
      setChangedChainId(0);
    } catch (err) {
      // @ts-ignore
      if (err && (err?.code === 4902 || err?.code === -32603)) {
        await flowAddNewNetwork();
      } else {
        errorChecker(err);
      }
    }
  }, [changedChainId, flowAddNewNetwork, web3.currentProvider]);

  const renderButtonMetamask = () => {
    if (typeof window.ethereum === "undefined") {
      if (isMobile) {
        return (
          <ButtonPopup
            href={`https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}?sign=metamask`}
            title={"MetaMask"}
            icon={<MetamaskIcon size={48}/>}
            text={"Metamask"}
          />
        );
      }
    }

    return (
      <ButtonPopup
        onClick={() => login("MetaMask")}
        title={"MetaMask"}
        icon={<MetamaskIcon size={48}/>}
        text={"Metamask"}
      />
    );
  };

  const renderButtonCoinbase = () => {
    if (typeof window.ethereum === "undefined") {
      if (isMobile) {
        const link = encodeURIComponent(`${window.location.href}?sign=coinbase`);

        const openApp = () => {
          if (isIOS) {
            setTimeout(() => {
              window.location.href = `https://go.cb-w.com/dapp?cb_url=${link}`;
            }, 100);
            window.location.href = `cbwallet://dapp?url=${link}`;
          } else {
            window.open(`https://go.cb-w.com/dapp?cb_url=${link}`);
          }
        };


        return (
          <ButtonPopup
            onClick={openApp}
            title={"Coinbase"}
            icon={<CoinbaseIcon size={48}/>}
            text={"Coinbase"}
          />
        );
      }
    }

    return (
      <ButtonPopup
        onClick={() => login("Coinbase")}
        title={"Coinbase"}
        icon={<CoinbaseIcon size={48}/>}
        text={"Coinbase"}
      />
    );
  };

  return (
    <>
      {isShowConnectModal &&
        <Popup
          clickOnClose={() => isSetShowConnectModal(false)}
          maxWidth={"604px"}
        >
          <ResponsiveDesktop>
            <PopupSubContentHeader title={"Connect your wallet"}/>
          </ResponsiveDesktop>
          <ResponsiveMobile>
            <PopupSubContentTitle setTop={"98px"}>Connect your wallet</PopupSubContentTitle>
          </ResponsiveMobile>
          <div className={"relative flex flex-wrap gap-4 pt-3 max1024:pt-20"}>
            <ViewBlock value={["in_progress"]}>
              <ButtonPopup
                onClick={() => login("torus")}
                title={"Torus"}
                icon={<TorusIcon size={48}/>}
                text={"Torus"}
              />
            </ViewBlock>
            {renderButtonMetamask()}
            {renderButtonCoinbase()}
          </div>
          <PopupSubContentText className={"!text-center !mt-12 !mb-0"}>
            By connecting your wallet, you agree our <Link to={"/terms"} onClick={() => isSetShowConnectModal(false)}>Terms of service</Link>
          </PopupSubContentText>
        </Popup>
      }
      {isShowInstallModal &&
        <Popup
          clickOnClose={() => {
            isSetShowInstallModal(false);
          }}
          maxWidth={"604px"}
        >
          <WalletsExtensionPopup type={walletTypePopup}/>
        </Popup>
      }
      {isShowChangeChainIdModal &&
        <Popup
          clickOnClose={() => {
            isSetShowChangeChainIdModal(false);
          }}
          maxWidth={"604px"}
        >
          <PopupSubContentHeader title={"Switch network"}/>
          <ChangeIdPopup
            onClickClose={() => isSetShowChangeChainIdModal(false)}
            onClickSubmitButton={flowChangeId}
            networkName={listOfNetwork.find(el => el.chainId === changedChainId)?.name || "EMPTY"}
            activeProviderName={sessionStorage.getItem("walletService") || "EMPTY"}
          />
        </Popup>
      }
      {isShowSignedModal &&
        <Popup
          noModal={true}
          maxWidth={"604px"}
        >
          <AuthPopup/>
        </Popup>
      }
    </>
  );
};
