import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {UserInfo} from "pages/Asset/UserInfo";
import {CheckMarkAvatarWhite} from "assets/icons/CheckMarkAvatar";
import {useApi} from "api";
import {IUserGetById} from "types";
import {errorChecker} from "utils";

interface IProps {
  className?: string;
  id?: number;
}

export const CardTopList = (
  {
    className,
    id
  }:IProps) => {
  const {api} = useApi();
  const [isUser, setUser] = useState<IUserGetById>();
  const requestData = useCallback(async () => {
    try {
      if (id) {
        let result = await api.user.getUserById(id);
        setUser(result);
      }
    } catch (err) {
      errorChecker(err);
    }
  }, [api.user, id]);

  useEffect(() => {
    requestData();
  }, [requestData]);
  return (
    <Link to={`/profile/${isUser?.id}`} className={`relative flex flex-col w-full h-full bg-[#0E0C06] rounded-lg flex-none px-2 pt-2 pb-8 ease-all min1025:hover:scale-95 ${className}`}>
      <div
        className={"relative flex w-full h-0 pb-[100%] overflow-hidden items-center justify-center rounded-lg cursor-pointer bg-center bg-no-repeat bg-cover"}
        style={{backgroundImage: `url(${isUser?.photo || isUser?.thumbnailPhoto})`}}
      />
      <div className={"relative w-full h-11 mt-4 mb-auto px-2"}>
        <div className={"relative line-clamp-2 text-white text-lg leading-[22px] font-bold break-words"}>
          {isUser?.description}
        </div>
      </div>
      <div className={"relative w-full px-2 mt-6"}>
        <UserInfo
          avatar={isUser?.photo || isUser?.thumbnailPhoto}
          label={"User Name"}
          text={<>
            <span className={"truncate w-max max-w-full min-w-0 !no-underline"}>
              {isUser?.displayName}
            </span>
            <CheckMarkAvatarWhite size={14}/>
          </>}
          classNameLabel={"!h-max"}
          classNameAvatar={"!w-8 !h-8"}
          classNameDesc={"!gap-1"}
          classNameText={"!text-white !flex !h-max items-center gap-1"}
        />
      </div>
    </Link>
  );
};
