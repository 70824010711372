import React, {useMemo} from "react";
import {format} from "date-fns";

import {classNames} from "utils";

interface IProps {
  className?: string;
  date?: Date;
}

export const CalendarBlock = ({className, date}: IProps) => {
  const [day, month] = useMemo(() => {
    if (date) {
      const value = format(date, "dd.LLL");
      return value.split(".");
    }
    return [undefined, undefined];
  }, [date]);

  return (
    <time
      className={classNames("relative flex flex-col items-center justify-center bg-white w-[46px] h-[50px] gap-[1px]", className)}
    >
      <p className={"relative block text-center text-2xl leading-6"}>
        {day}
      </p>
      <p className={"relative block text-center text-sm leading-3 text-text_description"}>
        {month}
      </p>
    </time>
  );
};
