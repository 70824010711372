import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";

import {CopyIconLarge} from "assets/icons/CopyIcon";
import {showNotification} from "components/Notification";
import {Popup, PopupSubContentHeader} from "components/Popups";
import {
  ShareIconPopupFacebook, ShareIconPopupFacebookMessenger,
  ShareIconPopupTelegram,
  ShareIconPopupWhatsApp
} from "assets/icons/ShareIconPopup";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps {
  onClose: () => void;
}

// TODO move to global popup provider and add function to used embed shared
export const SharePopup = ({onClose}: IProps) => {
  const theme = useTheme();
  return (
    <Popup clickOnClose={onClose} maxWidth={"480px"}>
      <PopupSubContentHeader title={"Share"}/>
      <div className={S.DataWrapper}>
        <div className={S.ButtonWrapper}>
          <WhatsappShareButton url={window.location.href}>
            <div className={classNames(S.ShareButton, S[theme])}>
              <ShareIconPopupWhatsApp/>
            </div>
          </WhatsappShareButton>
          <TelegramShareButton url={window.location.href}>
            <div className={classNames(S.ShareButton, S[theme])}>
              <ShareIconPopupTelegram/>
            </div>
          </TelegramShareButton>
          <FacebookShareButton
            url={window.location.href}
            quote={"title of sharung"}
          >
            <div className={classNames(S.ShareButton, S[theme])}>
              <ShareIconPopupFacebook/>
            </div>
          </FacebookShareButton>
          <FacebookMessengerShareButton
            appId={""}
            url={window.location.href}
          >
            <div className={classNames(S.ShareButton, S[theme])}>
              <ShareIconPopupFacebookMessenger/>
            </div>
          </FacebookMessengerShareButton>
        </div>
        {/*// @ts-ignore*/}
        <CopyToClipboard
          onCopy={() =>
            showNotification({
              title: "Success",
              subtitle: "Copied successfully"
            })
          }
          text={window.location.href}
        >
          <div className={classNames(S.CopyButton, S[theme])}>
            <span>{window.location.href}</span>
            <div onClick={onClose} className={S.Copy}>
              <CopyIconLarge fill="#000"/>
            </div>
          </div>
        </CopyToClipboard>
      </div>
    </Popup>
  );
};
