import React from "react";

import {AssetTabs} from "components/Asset/AssetTabs";
import {useAsset} from "providers";
import {AssetOwners} from "pages/Asset/AssetOwners";
import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";
import {AssetAction} from "pages/Asset/Action";
import {AssetSellingAction} from "pages/Asset/SellingAction";
import {BigDesktopForBids, Mobile, ResponsiveMobile} from "components/Responsive";

import S from "../style.module.scss";
import {
  AssetLeftSideContentSkeleton,
  AssetLeftSideContentSkeletonMobile
} from "../AssetSkeleton/AssetLeftSideContentSkeleton";
import {AssetDescription} from "../Description";
import {DescriptionOffers} from "../DescriptionOffers";
import {DescriptionAbout} from "../DescriptionAbout";
import {AssetDescriptionSkeleton} from "../AssetSkeleton/AssetDescriptionSkeleton";

export const AssetRight = () => {
  const {asset} = useAsset();

  const theme = useTheme();

  return (
    <div className={classNames(S.ContentRight, S[theme])}>
      {asset ?
        (<div className={S.ContentRightWrapper}>
          <div className={S.ContentRightHeader}>
            <div className={S.Title}>{asset.title}</div>
            <AssetAction/>
          </div>
          <AssetOwners/>
          <DescriptionAbout/>
          <AssetSellingAction/>
          <AssetTabs/>
          <ResponsiveMobile>
            {asset ?
              (
                <AssetDescription/>
              ) : (
                <AssetDescriptionSkeleton/>
              )
            }
          </ResponsiveMobile>
        </div>
        ) :
        <>
          <BigDesktopForBids>
            <AssetLeftSideContentSkeleton/>
          </BigDesktopForBids>
          <Mobile>
            <AssetLeftSideContentSkeletonMobile/>
          </Mobile>
        </>
      }
    </div>
  );
};
