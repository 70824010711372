import React from "react";

import SmallBanner1 from "assets/img/about_banner_small-1.png";
import SmallBanner2 from "assets/img/about_banner_small-2.png";
import BigBanner from "assets/img/about-banner.png";

import S from "./style.module.scss";

export const AboutUs = () => {
  return (
    <div className={S.Wrapper}>
      <div
        className={S.BannerTop}
        style={{backgroundImage: BigBanner}}
      />
      <div className={S.SecondBlock}>
        <div className={S.Title}>We welcome you at NFTim</div>
        <div className={S.Subtitle}>
          a decentralized marketplace that offers buyers unique digital <br/>
          art pieces with a focus on celebrity creators.
        </div>
      </div>
      <div className={S.ThirdBlock}>
        <div className={S.ThirdBlockContent}>
          <div className={S.ThirdBlockText}>
            <div className={S.ThirdBlockTitle}>
              Our mission is simple - to rid artists and creatives of the
              middleman.
            </div>
            <div className={S.ThirdBlockSubtitle}>
              The NFT technology allows creators to directly interchange with
              their buyers. It grants a quick digital solution for art consumers
              to purchase distinct NFT items from the art creators themselves.
              The NFT technology is a game-changer and NFTim is here to
              accommodate a marketplace that allows new art forms to grow and
              for creativity to flourish.
            </div>
          </div>
          <div className={S.ThirdBlockImages}>
            <div
              className={S.SmallImg1}
              style={{backgroundImage: SmallBanner1}}
            />
            <div
              className={S.SmallImg2}
              style={{backgroundImage: SmallBanner2}}
            />
          </div>
        </div>
      </div>
      <div className={S.FourthBlock}>
        <div className={S.FourthTitle}>What is NFTim</div>
        <div className={S.FourthSubtitle}>
          NFTim is home for famous and notable creators to display their unique
          work to buyers - here you may find a collection of previously
          unreleased material, unseen photographs, digital creations, and art in
          all of its forms and all of its magnificence.
          <br/> <br/> At NFTim you will be able to safely create and sell
          digital artwork and collectibles. The marketplace is both convenient
          and safe to use as it is secured by blockchain technology.
        </div>
      </div>
    </div>
  );
};
