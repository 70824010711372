import React, {createElement, forwardRef, Ref, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";

import {priceValidator} from "common/regEx";
import {errorChecker} from "utils";
import {Input} from "components/Input";
import {Button} from "components/Button";
import {Counter} from "components/Counter";
import {useConvertFiat} from "components/Price";
import {useApi} from "api";
import {CalendarIcon} from "assets/icons/Calendar";
import {useAsset} from "providers";
import {useTheme} from "hooks/useTheme";

import {PopupSubContentHeader, PopupSubContentInput} from "../index";

import S from "./style.module.scss";

interface IProps {
  startDate: Date;
  setStartDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  dateError: boolean;
  setDateError: (value: boolean) => void;
  setAmount: (value: number) => void;
  price: string;
  setPrice: (price: string) => void;
  immediatePrice: string;
  setImmediatePrice: (price: string) => void;
  onSaleSubmit: () => void;
  isLoading?: boolean;
  isError?: boolean;
}


// TODO Problem with react-datepicker they dont have any function type component
export const SalePopupAuction = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateError,
  setDateError,
  setAmount,
  price,
  setPrice,
  immediatePrice,
  setImmediatePrice,
  isLoading,
  onSaleSubmit,
  isError
}: IProps) => {
  const {api} = useApi();
  const {asset} = useAsset();

  const {id} = useParams<{ id?: string }>();
  const [maxQuantity, setMaxQuantity] = useState<number>(0);

  const theme = useTheme();

  useEffect(() => {
    (async () => {
      try {
        let availableAsset = await api.asset.checkAvailable(Number(id));
        theme === "help_ua" && setMaxQuantity(availableAsset.obtained?.length || 0);
        theme === "nftim" && setMaxQuantity(availableAsset.obtained?.[0]?.number || 0);
      } catch (err) {
        errorChecker(err);
      }
    })();
  }, [api, id, theme]);

  const customInput = createElement(
    forwardRef(({value, onClick}: {
      value: string;
      onClick: () => void;
    }, ref: Ref<HTMLInputElement>) => (
      <Input
        ref={ref}
        value={value}
        readOnly={true}
        onClick={onClick}
        onFocus={() => dateError && setDateError(false)}
        placeholder={"Enter minimum bid"}
      />
    ))
  );

  const [priceFiatMinimalBid] = useConvertFiat(price, asset?.defaultCurrency?.symbol);

  return (
    <div className={S.SalePopup}>
      <PopupSubContentHeader
        title={"Auction"}
        titleLabel={"Publication of a token with a fixed price"}
      />
      <div className={S.InputWrapper}>
        <div className={S.InputGroup}>
          <PopupSubContentInput
            label={"Set auction start date"}
          >
            {/*// @ts-ignore*/}
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              customInput={customInput}
            />
            <label><CalendarIcon/></label>
          </PopupSubContentInput>
          <PopupSubContentInput
            label={"Set auction end date"}
          >
            {/*// @ts-ignore*/}
            <DatePicker
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              customInput={customInput}
              placeholderText={"Enter date"}
            />
            <label><CalendarIcon/></label>
          </PopupSubContentInput>
          <PopupSubContentInput
            label={"Minimal bid *"}
            labelDesc={priceFiatMinimalBid && priceFiatMinimalBid}
          >
            <Input
              type={"number"}
              value={price}
              onChange={(value) => {
                setPrice(priceValidator(value));
              }}
              placeholder={"Enter minimum bid"}
              step={0.0000000000000001}
            />
          </PopupSubContentInput>
        </div>
        <Counter
          tooltipText={"Please, set how many items you want to list for auction."}
          maxQuantity={maxQuantity}
          setAmount={setAmount}
        />
        <Button
          disabled={isLoading}
          className={S.Button}
          color={"primary"}
          title={"List for sale"}
          onClick={onSaleSubmit}
        />
      </div>
    </div>
  );
};
