import React, {memo} from "react";
import Select from "react-select";
import isEqual from "react-fast-compare";

import S from "components/Input/style.module.scss";

interface IProps {
  options?: any;
  value?: any;
  onChange?: (options: any) => void;
  isClearable?: boolean;
  className?: string;
  placeholder?: string;
  styleValue?: any;
  styleControl?: any;
  label?: string;
  name?: string;
  optionLabel?: string;
  optionValue?: string;
  required?: boolean;
  error?: string;
}

export const CustomSelect = memo(({
  options,
  value,
  onChange,
  isClearable = true,
  className,
  placeholder,
  styleValue,
  styleControl,
  label,
  name,
  optionLabel = "title",
  optionValue = "value",
  required = false,
  error
}: IProps) => {
  const customStyles = {
    // https://react-select.com/styles#select-props
    option: (styles: any, state: any) => ({
      ...styles,
      display: "flex",
      borderBottom: "1px solid #E5E5EA",
      padding: "8px 10px 8px 24px",
      margin: "0px",
      background: state.isSelected ? "#E5E5EA" : "#fff",
      cursor: "pointer",
      pointerEvents: state.isDisabled ? "none" : "visible",
      opacity: state.isDisabled ? "0.5" : "1",
      color: "#36364B",
      fontSize: "14px",

      "&:hover": {
        background: "#E5E5EA",
      },
      "&:first-of-type": {
        display: state.isDisabled ? "none" : "flex",
      },
      "&:last-child": {
        borderBottom: "0px solid rgba(0,0,0,0)"
      }
    }),
    menu: (state: any) => ({
      position: "absolute",
      display: "block",
      border: "1px solid #E5E5EA",
      background: "#fff",
      color: "#000",
      padding: "0px",
      borderRadius: "0px",
      margin: "3px 0 0",
      width: "100%",
      top: "100%",
      zIndex: "10",
      pointerEvents: state.isDisabled ? "none" : "visible",
    }),
    menuList: () => ({
      position: "relative",
      display: "block",
      width: "100%",
      padding: "0px",
      borderRadius: "0px",
      maxHeight: "146px",
      overflow: "hidden auto",
      pointerEvents: "visible",

      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRight: "5px solid var(--light-gray)",
      }
    }),
    indicatorSeparator: () => ({}),
    control: (styles: any, state: any) => ({
      ...styles,
      width: "100%",
      height: "100%",
      minHeight: "42px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #E5E5EA",
      background: "#fff",
      outline: "none",
      boxShadow: "none",
      borderRadius: "0px",
      ...styleControl,

      ".ChildPosition": {
        opacity: state.menuIsOpen ? "0.3" : "",
        transform: state.menuIsOpen ? "scale(0.8)" : "",
      },
      "&:focus": {
        borderColor: "#E5E5EA",
      },
      "&:hover": {
        borderColor: "#E5E5EA",
      }
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flex: "1",
      maxWidth: "100%",
      minWidth: "0",
      display: "flex",
      alignItems: "center",
      height: "100%",
      padding: "0 0 0 24px",
      overflow: "unset",
      ...styleValue,
    }),
    singleValue: () => ({
      background: "none",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }),
    input: () => ({
      position: "absolute",
      display: "block",
      color: "#3C3C47",
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: "14px",
      color: "#3C3C47",
      fontWeight: 400,
      margin: "0px",
    }),
    indicatorsContainer: () => ({
      flex: "none",
      width: "64px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "svg": {
        width: "16px",
        height: "16px"
      }
    })
  };

  return (
    <fieldset className={S.InputWrapper}>
      {label && <label
        className={S.InputLabel}
        htmlFor={name}
      >
        {label}
      </label>}
      <Select
        options={options}
        onChange={onChange}
        name={name}
        value={value}
        getOptionLabel={(option) => option[optionLabel]}
        getOptionValue={(option) => option[optionValue]}
        maxMenuHeight={140}
        isClearable={isClearable}
        // @ts-ignore
        styles={customStyles}
        className={className}
        placeholder={placeholder}
      />
      {error && <p className={S.Error}>{error}</p>}
    </fieldset>
  );
}, isEqual);
