import React from "react";


import {CharityMainIconAbout} from "assets/icons/CharityMainIconAbout";
import {CharityLogoMinistry} from "assets/icons/CharityLogoMinistry";
import {Button} from "components/Button";
import {classNames} from "utils";

import S from "./style.module.scss";

export const AboutHelpUa = () => {
  const goToHelpUa = () => {
    window.open("https://helpua.io");
  };
  return (
    <div className={classNames(S.HomeAboutDescriptionFooter, "before:absolute before:block before:inset-0 before:bg-black before:rounded-xl max768:before:inset-x-[-20px]")}>
      <div className={S.HomeAboutDescriptionFooterTitle}>
        <div className={S.Logo}>
          <CharityMainIconAbout/>
        </div>
        <div className={S.LogoMinistry}>
          <CharityLogoMinistry fill={"#E5EAEE"}/>
        </div>
        <Button
          className={classNames(S.Button)}
          color={"secondary_o"}
          title={"Go to Helpua.io"}
          onClick={goToHelpUa}
          doubleHover={"colorWhite"}
        />
      </div>
      <div className={S.HomeAboutDescriptionFooterDescription}>
        <div className={S.descRow}>
          <b>Ukraine is now on the verge of a humanitarian catastrophe</b>. Hundreds of houses have been destroyed, and many people keep fleeing their homes. To help Ukraine, Ukrainian artists, musicians, entrepreneurs, and IT specialists came together.
        </div>
        <div className={S.descRow}>
          <b>Everyone can donate to Ukraine and receive a blue-yellow heart as a piece of an NFT art. All raised funds will be sent for the vital needs of the refugees.</b>
        </div>
      </div>
    </div>
  );
};
