import React, {useState} from "react";
import TextareaAutosize from "react-textarea-autosize";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  name?: string;
  value?: string | number;
  placeholder?: string;
  className?: string;
  classNameText?: string;
  align?: "left" | "center" | "right";
  id?: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  resize?: boolean;
  onChange?: string | any;
  required?: boolean;
  label?: string;
}

export const Textarea = ({
  name,
  value,
  placeholder,
  className,
  classNameText,
  align = "left",
  id,
  rows,
  minRows,
  maxRows,
  resize = false,
  onChange,
  required = false,
  label
}: IProps) => {
  return (
    <fieldset className={classNames(S.Textarea, className)}>
      {label && <label
        className={S.InputLabel}
        htmlFor={name}
      >
        {label}
      </label>}
      <TextareaAutosize
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        className={classNames(classNameText, S[align], {[S.Resize]: resize})}
        id={id}
        autoFocus={false}
        minRows={minRows}
        maxRows={maxRows}
        rows={rows}
        onChange={onChange}
        required={required}
      />
    </fieldset>
  );
};
