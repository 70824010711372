import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const TermsIcon = ({className, size = 16, fill = "var(--blue_static)"}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9347 4.89347L16.2472 0.205969C16.1153 0.0741094 15.9365 0 15.75 0H5.4375C4.01592 0 2.85938 1.15655 2.85938 2.57812V21.4219C2.85938 22.8435 4.01592 24 5.4375 24H18.5625C19.9841 24 21.1406 22.8435 21.1406 21.4219V5.39062C21.1406 5.20416 21.0665 5.02533 20.9347 4.89347ZM16.4531 2.40061L18.74 4.6875H16.4531V2.40061ZM18.5625 22.5938H5.4375C4.79133 22.5938 4.26562 22.068 4.26562 21.4219V2.57812C4.26562 1.93195 4.79133 1.40625 5.4375 1.40625H15.0469V5.39062C15.0469 5.77894 15.3617 6.09375 15.75 6.09375H19.7344V21.4219C19.7344 22.068 19.2087 22.5938 18.5625 22.5938Z"
        fill={fill}
      />
      <path
        d="M16.6875 9.46875C15.799 9.46875 15.0002 8.97506 14.6029 8.18044L14.5039 7.98248C14.3848 7.74422 14.1413 7.59375 13.875 7.59375H10.125C9.85866 7.59375 9.61519 7.74422 9.49608 7.98244L9.39713 8.18034C8.99981 8.97506 8.20102 9.46875 7.3125 9.46875C6.92419 9.46875 6.60938 9.78356 6.60938 10.1719V14.1842C6.60938 15.7618 7.10714 17.2646 8.04881 18.5303C8.99048 19.7959 10.287 20.7045 11.798 21.1578C11.8639 21.1776 11.9319 21.1875 12 21.1875C12.0681 21.1875 12.1361 21.1776 12.202 21.1578C13.713 20.7045 15.0095 19.7959 15.9512 18.5303C16.8929 17.2646 17.3906 15.7618 17.3906 14.1842V10.1719C17.3906 9.78356 17.0758 9.46875 16.6875 9.46875ZM15.9844 14.1842C15.9844 16.7275 14.392 18.9367 12 19.7464C9.60802 18.9367 8.01562 16.7275 8.01562 14.1842V10.8095C9.07973 10.6086 10.0049 9.95634 10.5527 8.99995H13.4473C13.9951 9.9563 14.9203 10.6086 15.9844 10.8095V14.1842Z"
        fill={fill}
      />
      <path
        d="M13.4915 12.5692L11.3371 14.7237L10.5084 13.895C10.2339 13.6204 9.78864 13.6204 9.51405 13.895C9.23946 14.1696 9.23946 14.6148 9.51405 14.8894L10.8399 16.2152C10.9772 16.3525 11.1571 16.4212 11.337 16.4212C11.5169 16.4212 11.6969 16.3526 11.8342 16.2152L14.4858 13.5636C14.7604 13.289 14.7604 12.8438 14.4858 12.5692C14.2113 12.2946 13.7661 12.2946 13.4915 12.5692Z"
        fill={fill}
      />
    </svg>
  );
};
