import React, {useCallback, useEffect, useRef, useState} from "react";

import {classNames} from "utils";
import {PlayIcon} from "assets/icons/Play";

import S from "./style.module.scss";

interface IProps extends Partial<HTMLVideoElement> {
  src: string;
}


// TODO add controls panel
// TODO mute function
// TODO full screen function
// TODO click on video body open/close full screen
export const Video = ({
  className,
  poster,
  src,
  autoplay = false,
  preload = "auto",
  controls = false,
  loop = true,
  muted = true
}: IProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState<boolean>(!autoplay);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(muted);

  const onClickVideo = useCallback(async () => {
    const isP = !isPlay;
    const v = videoRef.current;

    setIsPlay(isP);

    if (v) {
      if (isP) {
        await v.play();
      } else {
        await v.pause();
      }
    }
  }, [isPlay]);

  useEffect(() => {
    if (autoplay) {
      onClickVideo();
    }
    // dont add any deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onClickMute = () => {
  //   setVideoMuted(!isVideoMuted);
  // };
  //
  // const onClickFullScreen = () => {
  //
  // };
  //
  // const renderControls = () => {
  //   return (
  //     <div className={S.VideoControls}>
  //
  //     </div>
  //   );
  // };

  return (
    <div className={classNames(className, S.Video)}>
      <video
        src={src}
        ref={videoRef}
        poster={poster}
        autoPlay={autoplay}
        preload={preload}
        controls={controls}
        loop={loop}
        playsInline={true}
        muted={isVideoMuted}
      />
    </div>
  );
};
