import React, {useCallback, useEffect, useState} from "react";

import {classNames, errorChecker} from "utils/";
import {useApi} from "api";
import {ICollection, TIsLoading} from "types";
import {CardsProfileCollections} from "components/Profile/ProfileContent/Projects/card";

interface IProps {
  className?: string;
  profileId?: number;
}

export const ProfileProjects = (
  {
    className,
    profileId
  }: IProps) => {
  const {api} = useApi();
  const [isCollection, setCollection] = useState<ICollection[]>();
  const [isLoading, setIsLoading] = useState<TIsLoading>("first");

  const DataCollection = useCallback(async () => {
    try {
      if (profileId) {
        let result = await api.collections.getCollectionOwnerId(profileId);
        setCollection(result.results);
      }
    } catch (err) {
      errorChecker(err);
    } finally {
      setIsLoading("loaded");
    }
  }, [api.collections, profileId]);

  useEffect(() => {
    (async () => DataCollection())();
  }, [DataCollection]);

  return (
    <div className={classNames("relative block w-full", className)}>
      <CardsProfileCollections
        collection={isCollection}
        status={isLoading}
      />
    </div>
  );
};
