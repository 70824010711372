import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";

import {useApi} from "api";
import {classNames, errorChecker} from "utils";
import {Container} from "components/Container";
import {ICollection} from "types";

import S from "./style.module.scss";

const SliderCollection = () => {
  const {api} = useApi();

  const [collectionsData, setCollectionsData] = useState<ICollection[]>([]);

  const collectionRequest = useCallback(async () => {
    try {
      let collections = await api.collections.getCollections();
      setCollectionsData(collections?.results);
    } catch (err) {
      // @ts-ignore
      errorChecker(err);
    }
  }, [api]);

  useEffect(() => {
    (async () => collectionRequest())();
  }, [collectionRequest]);

  const SampleNextArrow = (props: any) => {
    const {className, onClick} = props;
    return (
      <div
        className={classNames(S.Next, className)}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const {className, onClick} = props;
    return (
      <div
        className={classNames(S.Prev, className)}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: "ease",
    easing: "linear",
    waitForAnimate: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ]
  };


  return (
    // @ts-ignore
    <Slider
      {...settings}
      className={S.Slider}
    >
      {collectionsData && collectionsData.map((collectionItem, index) => (
        <div
          className={S.ItemChild}
          key={collectionItem?.id}
        >
          <Link
            to={`collection/${collectionItem?.id}`}
            className={S.ItemLink}
          >
            <div className={S.ItemBox}>
              <div
                className={S.Images}
                style={{backgroundImage: `url(${collectionItem?.thumbnailCover})`}}
              >
                <div className={S.Img}>
                  <img
                    src={collectionItem?.thumbnailPhoto}
                    alt={collectionItem?.name}
                  />
                </div>
              </div>
              <div className={S.Title}>
                {collectionItem?.name}
              </div>
              <div className={S.Desc}>
                {collectionItem?.description}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </Slider>
  );
};

export const CharityCollection = () => {
  return (
    <div className={S.CharityCollection}>
      <Container>
        <div className={S.TitleContainer}>All collections</div>
        <SliderCollection/>
      </Container>
    </div>
  );
};
