import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";

import {classNames} from "utils";
import {CheckMark} from "assets/icons/CheckMark";
import {HandsShakeIcon} from "assets/icons/HandsShake";
import {ContactUsIcon} from "assets/icons/ContactUsIcon";
import {TermsIcon} from "assets/icons/Terms";
import {QuestionIcon} from "assets/icons/Question";
import {useMedia} from "hooks/useMedia";

import S from "./style.module.scss";

interface INavigationList {
  icon: any;
  title: string;
  link: string;
}

// TODO get active link
export const HelpCenterNavigation = () => {
  const params = useParams();
  const {isMobile} = useMedia();

  const [activeLink, setActiveLink] = useState<string>("/help");
  const [links] = useState<INavigationList[]>([
    {icon: CheckMark, title: "About platform", link: "/help"},
    {icon: QuestionIcon, title: "F.A.Q.", link: "/help/FAQ"},
    {icon: HandsShakeIcon, title: "Our Partners", link: "/help/our-partners"},
    {icon: ContactUsIcon, title: "Contact us", link: "/help/contact-us"},
    {icon: TermsIcon, title: "Terms of use", link: "/help/terms-of-use"},
  ]);
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

  useEffect(() => {
    setIsShowMenu(!isMobile);
  }, [isMobile]);

  const toggleMenu = () => {
    if (isMobile) {
      setIsShowMenu(!isShowMenu);
    }
  };

  useEffect(() => {
    setActiveLink(params["*"] ? `/help/${params["*"]}` : "/help");
  }, [params]);

  return (
    <nav className={S.HelpCenterNavigationWrapper}>
      <div className={S.Box}>
        <h3 onClick={toggleMenu}>Articles in this section</h3>
        {isShowMenu &&
          <ul>
            {links?.map((nav, navKey) => {
              const Icon = nav.icon;

              return (
                <li
                  className={classNames(S.HelpCenterNavigation, {[S.Active]: nav.link === activeLink})}
                  key={navKey}
                >
                  <Link
                    to={nav.link}
                    onClick={toggleMenu}
                  >
                    <span className={S.Ico}>
                      {React.createElement(Icon, {
                        size: 24
                      })}
                    </span>
                    <p>{nav.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        }
      </div>
    </nav>
  );
};
