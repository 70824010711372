import React, {FC} from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {
}

export const EyeIcon: FC<IProps> = ({
  onClick,
  className,
  size = 18,
  fill = "#E5E5E5"
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_318:18340)">
        <path
          d="M9.03568 10.371C9.79289 10.371 10.4067 9.75719 10.4067 8.99997C10.4067 8.24275 9.79289 7.62891 9.03568 7.62891C8.27846 7.62891 7.66461 8.24275 7.66461 8.99997C7.66461 9.75719 8.27846 10.371 9.03568 10.371Z"
          fill={fill}
        />
        <path
          d="M9.03555 4.99219C6.82569 4.99219 5.02783 6.79005 5.02783 8.99992C5.02783 11.2098 6.82569 13.0076 9.03555 13.0076C11.2454 13.0076 13.0433 11.2098 13.0433 8.99992C13.0433 6.79005 11.2454 4.99219 9.03555 4.99219ZM9.03555 11.6014C7.60107 11.6014 6.43405 10.4344 6.43405 8.99992C6.43405 7.56543 7.60107 6.39841 9.03555 6.39841C10.47 6.39841 11.6371 7.56543 11.6371 8.99992C11.6371 10.4344 10.47 11.6014 9.03555 11.6014Z"
          fill={fill}
        />
        <path
          d="M17.5447 7.46517C17.1714 6.89191 16.118 5.42023 14.4034 4.13821C12.6653 2.83866 10.8527 2.17969 9.01579 2.17969C7.18151 2.17969 5.3673 2.83687 3.62354 4.13291C1.9013 5.41295 0.839473 6.8828 0.462878 7.45533C-0.154293 8.39361 -0.154293 9.60612 0.462878 10.5444C0.839473 11.117 1.9013 12.5868 3.62354 13.8669C5.3673 15.1629 7.18147 15.8201 9.01579 15.8201C11.8397 15.8201 14.1106 14.2455 15.5182 12.9246C15.8014 12.6589 15.8156 12.214 15.5499 11.9308C15.2841 11.6476 14.8392 11.6335 14.556 11.8992C13.3334 13.0464 11.3797 14.4139 9.01573 14.4139C5.6019 14.4139 2.77174 11.4959 1.63763 9.77167C1.32924 9.30283 1.32924 8.69701 1.63763 8.22817C2.77174 6.50396 5.6019 3.58598 9.01573 3.58598C12.4294 3.58598 15.2423 6.50677 16.3663 8.2326C16.6698 8.69874 16.6698 9.30111 16.3663 9.76724C16.1544 10.0927 16.2464 10.5282 16.5718 10.7401C16.8972 10.952 17.3328 10.86 17.5447 10.5346C18.1518 9.60229 18.1518 8.39745 17.5447 7.46517Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
