import React from "react";
import ContentLoader from "react-content-loader";

export const CardCollectionSkeleton = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"100%"}
    backgroundColor="#ffffff"
    foregroundColor="#ecebeb"
    style={{position: "absolute", display: "block", top: "0", left: "0"}}
  >
    <rect
      x="5%"
      y="20px"
      rx="0"
      ry="0"
      width="30%"
      height="20px"
    />
    <rect
      x="5%"
      y="calc(100% - 168px)"
      rx="0"
      ry="0"
      width="90%"
      height="88px"
    />
    <rect
      x="5%"
      y="calc(100% - 65px)"
      rx="0"
      ry="0"
      width="90%"
      height="50px"
    />
  </ContentLoader>
);
