import React from "react";
import ContentLoader from "react-content-loader";

export const SkeletonCard = () => (
  <div className={"relative block w-full h-0 pt-[199px] pb-[100%] bg-[rgba(17,17,17,0.3)] max1680:pt-[192px] max1152:pt-[184px]"}>
    <div className={"!absolute top-0 left-0 w-full h-0 pb-[100%]"}>
      <ContentLoader
        speed={2}
        width={`100%`}
        height={`100%`}
        backgroundColor="#222"
        foregroundColor="#333"
        className={"!absolute top-0 left-0"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    </div>
    <div className={"!absolute bottom-0 left-0 w-full h-[199px] max1680:pt-[192px] max1152:pt-[184px]"}>
      <ContentLoader
        speed={2}
        width={`100%`}
        height={`100%`}
        backgroundColor="#222"
        foregroundColor="#333"
        className={"!absolute top-0 left-0"}
      >
        <rect x="0" y="24" rx="0" ry="0" width="70%" height="28px" />
        <rect x="80%" y="24" rx="0" ry="0" width="20%" height="18px" />
        <rect x="0" y="61" rx="0" ry="0" width="70%" height="40px" />
        <rect x="80%" y="48" rx="0" ry="0" width="20%" height="28px" />
        <rect x="80%" y="82" rx="0" ry="0" width="20%" height="18px" />
        <rect x="0" y="calc(100% - 60px)" rx="0" ry="0" width="100%" height="60px" />
      </ContentLoader>
    </div>
  </div>
);
