import React from "react";

import {useAsset} from "providers";

import S from "./style.module.scss";

interface IProps {

}

export const AssetExtension = (props: IProps) => {
  const {asset} = useAsset();

  const renderSize = () => {
    if (asset?.fileExtension === "mp3") {
      return "";
    }

    if (asset?.fileExtension === "mp4") {
      return "";
    }

    return `( ${asset?.originalWidth}x${asset?.originalHeight} )`;
  };

  return (
    <div className={S.Extension}>
      {asset?.fileExtension}&nbsp;
      {renderSize()}&nbsp;
      {(asset && (asset?.originalBytesSize / 1000000).toFixed(2))} mb
    </div>
  );
};
