import React from "react";

import {StepText} from "components/CreateNft/StepContent/StepText";
import {StepBlockchain} from "components/CreateNft/StepContent/StepBlockchain";

export const StepFirst = () => {

  return (
    <>
      <StepText>
        <p>Choose the most suitable blockchain for your needs.</p>
        <p>You need to connect wallet for creation. Learn more about Blockchains</p>
      </StepText>
      <StepBlockchain/>
    </>
  );
};
