import React, {useMemo} from "react";
import {Link} from "react-router-dom";

import {Time} from "components/Time";
import {Price} from "components/Price";
import {IAuction, IMarketplace} from "components/Home/types";
import {UserInfo} from "pages/Asset/UserInfo";
import {MarketplaceButton} from "components/layout/MarketplaceButton";
import {AuctionButton} from "components/layout/AuctionButton";

import S from "../style.module.scss";

interface IProps {
  auction?: IAuction;
  marketplace?: IMarketplace;
}

export const AvailableAssetMarketplace = ({auction, marketplace}: IProps) => {
  const assetType = useMemo<Partial<IAuction | IMarketplace>>(() => {
    return (auction ? auction : marketplace) || {};
  }, [auction, marketplace]);

  const price = useMemo(() => {
    if (auction?.highestBid) {
      if (auction?.highestBid === "0") {
        return String(auction?.minimumBid);
      }

      return String(auction?.highestBid);
    }

    return String(marketplace?.price);
  }, [auction?.highestBid, auction?.minimumBid, marketplace?.price]);

  return (
    <tr>
      <td align={"left"}>
        <UserInfo
          avatar={assetType.user?.thumbnailPhoto}
          title={assetType.user?.displayName}
          text={
            <>
              {assetType && (
                <Link to={`/profile/${assetType.user?.id}`}>
                  {assetType.user?.displayName}
                </Link>
              )}
            </>
          }
          label={
            <>
              Listed for sale <Time date={assetType.createdAt}/>
            </>
          }
        />
      </td>
      <td
        width={"110px"}
        align={"right"}
      >
        <Price
          symbol={assetType?.currency?.symbol}
          decimals={assetType?.currency?.decimals}
          priceFiatSize={"text-xs"}
          price={price}
        />
      </td>
      <td
        width={"180px"}
        align={"right"}
      >
        {auction ? <AuctionButton auction={auction}/> : <MarketplaceButton marketplace={marketplace}/>}
      </td>
    </tr>
  );
};
