import React, {useState, useMemo} from "react";

import {Price} from "components/Price";
import {Counter} from "components/Counter";
import {Button} from "components/Button";
import {useAsset, useStore, useWeb3} from "providers";
import {
  Popup,
  PopupSubContentHeader,
  PopupSubContentPriceBlock,
  PopupSubContentPriceChild
} from "components/Popups";
import {errorChecker} from "utils";
import {useWyre} from "providers/Wyre";
import {VisaIcon} from "assets/icons/Visa";
import {MasterCardIcon} from "assets/icons/MasterCard";
import {ViewBlock} from "components/ViewBlock";
import {WyreSupportConstant} from "common/wyreSupportConstant";
import {useWeb3Contract} from "providers/Web3Contract";
import {useWeb3ContractPopup} from "providers/Web3Contract/popup";
import {IWeb3ButItem} from "pages/Asset/types";

import S from "./style.module.scss";

interface IBuyPopup {
  address?: string;
  // marketplaceId || auctionId
  id?: number;
  number?: number;
  price?: string;
  type: "buy" | "buy_now_auction";
}

export type TBuyPopup = IBuyPopup & {
  currencyAddress?: IWeb3ButItem["currencyAddress"]
};

type TProps = TBuyPopup & {
  onClose: () => void;
}

export const BuyNowPopup = ({
  type,
  id,
  price,
  number = 1,
  address,
  onClose,
  currencyAddress
}: TProps) => {
  const {fromWei, web3State} = useWeb3();
  const {asset} = useAsset();
  const {buyItem, buyNowAuctionItem} = useWeb3Contract();
  const {setBuyNowPopup} = useWeb3ContractPopup();
  const {buyFiatViaWyre} = useWyre();

  const [amount, setAmount] = useState<number>(1);

  const isInvalidPrice = useMemo(() => {
    if (web3State?.wallet) {
      return Number(fromWei({price})) * amount > Number(web3State?.balance);
    }

    return false;
  }, [amount, fromWei, price, web3State?.balance, web3State?.wallet]);

  const {setIsAlerts} = useStore();

  const onClickBuy = async () => {
    setBuyNowPopup(false);
    setIsAlerts(true);
    if (type === "buy") {
      try {
        await buyItem({
          price,
          amount,
          marketplaceContractId: id,
          address,
          currencyAddress
        });
        setIsAlerts(false);
      } catch (err) {
        errorChecker(err);
        setIsAlerts(false);
      }
    }

    if (type === "buy_now_auction") {
      try {
        await buyNowAuctionItem({
          price,
          auctionId: id,
          address,
          currencyAddress
        });
      } catch (err) {
        errorChecker(err);
      }
    }
  };

  return (
    <>
      <Popup clickOnClose={onClose}>
        <PopupSubContentHeader
          title={"Buy it now"}
        />
        <PopupSubContentPriceBlock>
          <PopupSubContentPriceChild label={"Buy it now price"}>
            <Price
              symbol={asset?.currentMarketplace?.currency?.symbol}
              decimals={asset?.currentMarketplace?.currency?.decimals}
              className={"ml-4 justify-center"}
              priceCryptoSize={"text-2xl"}
              price={price}
              amount={amount}
            />
          </PopupSubContentPriceChild>
        </PopupSubContentPriceBlock>
        {type === "buy" &&
          <Counter
            tooltipText={"Please, set how many items you want to buy now."}
            maxQuantity={number}
            setAmount={setAmount}
          />
        }
        <ViewBlock value={["nftim", "help_ua", "help_ua_dev"]}>
          <Button
            onClick={onClickBuy}
            disabled={isInvalidPrice}
            title="Buy it now"
            color="primary"
          />
          {web3State?.wallet &&
            <>
              {WyreSupportConstant.includes(web3State?.symbol) &&
                <>
                  <Button
                    onClick={() => buyFiatViaWyre()}
                    color={"primary_o"}
                    className={"mt-4"}
                    classNameText={"font-normal"}
                  >
                    <div className={S.TextButton}>
                      <span>Add funds with</span>
                      <VisaIcon/>
                      <MasterCardIcon/>
                    </div>
                  </Button>
                  <p className={"mt-4 text-center text-sm"}>Insufficient funds in {web3State?.currency}</p>
                </>
              }
            </>
          }
        </ViewBlock>
      </Popup>
    </>
  );
};
