import React, {FormEvent, useState} from "react";

import {classNames, errorChecker} from "utils";
import {Popup, PopupSubContentHeader} from "components/Popups/index";
import {Input} from "components/Input";
import {Textarea} from "components/Textarea";
import S from "components/CreateNft/StepContent/StepForm/style.module.scss";
import {Button} from "components/Button";
import {StepLoadCollection} from "components/CreateNft/StepContent/StepLoadCollection";
import {useApi} from "api";
import {useCreateAsset} from "providers/CreateAsset";
import {showNotification} from "components/Notification";

interface IProps {
  onClose?: () => void;
  updateCollectionList: () => void;
}

export const PopupCreateCollection = ({onClose, updateCollectionList}: IProps) => {
  const {api} = useApi();
  const {ipfsMimeImageView} = useCreateAsset();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    try {
      await api.collections.createCollection(formData);
      showNotification({
        title: "Create Success",
        type: "success",
      });
      onClose && onClose();

      setTimeout(() => {
        updateCollectionList();
      }, 5000);
    } catch (err) {
      errorChecker(err);
      setIsLoading(false);
    }
  };

  return (
    <Popup
      clickOnClose={onClose}
      maxWidth={"504px"}
    >
      <PopupSubContentHeader
        title={"Create collection"}
      />
      <form
        className={S.StepForm}
        onSubmit={onSubmit}
      >
        <fieldset disabled={isLoading} className={"relative block w-full max-w-full min-w-0"}>
          <div className={"relative block w-full"}>
            <Input
              name={"name"}
              label={"Name"}
              type={"text"}
              placeholder={"e.g. Priority pass, ticket, NFT, etc."}
              className={S.Input}
              required={true}
            />
          </div>
          <div className={"relative block w-full"}>
            <Textarea
              name={"description"}
              label={"Description"}
              placeholder={"e.g. After purchase you’ll be able to get Priority Pass or etc."}
              className={S.Textarea}
              minRows={6}
              maxRows={12}
              required={true}
            />
          </div>
          <div className={"relative block w-full"}>
            <label>Avatar</label>
            <StepLoadCollection
              name={"photo"}
              description={`We recommend an image (${ipfsMimeImageView}) of at least 300x300. Max 5mb.`}
            />
          </div>
          <div className={"relative block w-full"}>
            <label>Cover</label>
            <StepLoadCollection
              name={"cover"}
              description={`We recommend an image (${ipfsMimeImageView}) of at least 1528x260. Max 5mb.`}
            />
          </div>
          <div className={S.ButtonRow}>
            <Button
              type={"submit"}
              className={classNames(S.CreateButton, S.CreateButtonCollection)}
              classNameText={S.CreateButtonText}
              children={"Create Collection"}
            />
          </div>
        </fieldset>
      </form>
    </Popup>
  );
};
