import React from "react";

import {WalletIcon} from "assets/icons/WalletIcon";

import {PopupSubContentText, PopupSubContentTitle} from "../index";

import S from "./style.module.scss";

export const AuthPopup = () => {
  return (
    <div className={S.AuthPopup}>
      <PopupSubContentTitle
        className={S.Title}
        position={"center"}
      >
        Signature Request
      </PopupSubContentTitle>
      <div className={S.WalletBlockWrapper}>
        <div className={S.WalletBlock}>
          <WalletIcon/>
        </div>
      </div>
      <PopupSubContentText
        className={S.Text}
        position={"center"}
      >
        You must signature with your wallet in order to do action on site
      </PopupSubContentText>
    </div>
  );
};
