import React, {useState} from "react";

import {
  DebugProvider,
  MyUserProvider,
  PopupProvide,
  PriceProvide,
  StoreProvider,
  Web3Provider,
  WyreProvider
} from "providers";
import {App} from "App";
import {Web3ProvidePopup} from "providers/Web3/web3Popup";
import {FirstLoad} from "components/Preloader/FirstLoad";

export const AppWrapper = () => {
  const [isPreloader, setIsPreloader] = useState<boolean>(true);

  return (
    <StoreProvider>
      <DebugProvider>
        <PriceProvide setPreloader={setIsPreloader}>
          {!isPreloader
            ? (
              <>
                <Web3Provider>
                  <WyreProvider>
                    <PopupProvide>
                      <MyUserProvider>

                        <App/>
                        <Web3ProvidePopup/>
                      </MyUserProvider>
                    </PopupProvide>
                  </WyreProvider>
                </Web3Provider>
              </>
            )
            : <FirstLoad/>
          }
        </PriceProvide>
      </DebugProvider>
    </StoreProvider>
  );
};
