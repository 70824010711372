import React from "react";

import {classNames} from "utils";
import {useTheme} from "hooks/useTheme";

import S from "./style.module.scss";

interface IProps extends Partial<Omit<HTMLDivElement, "children">> {
  className?: string;
  children?: React.ReactNode;
  fluid?: boolean;
}

export const Container = ({id, className, children, fluid = false}: IProps) => {
  const theme = useTheme();
  return (
    <div
      id={id}
      className={classNames(S.Container, "ml-auto mr-auto mt-0 mb-0", className, {[S.Fluid]: fluid}, S[theme])}
    >
      {children}
    </div>
  );
};
