import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {classNames} from "utils";
import {MarketplaceButtonIcon} from "assets/icons/MarketplaceButtonIcon";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  image?: string;
  title?: string;
  text?: string;
  goal?: number;
  progress?: number;
  id?: number;
}

export const HelpPeopleItemCard = (
  {
    className,
    image,
    title,
    text,
    goal = 0,
    progress = 0,
    id
  }: IProps) => {
  const [isPercent, sePercent] = useState<number>(0);
  useEffect(() => {
    let percent = Math.round(progress / goal * 100);
    sePercent(percent > 100 ? 100 : percent);
  }, [goal, progress]);

  return (
    <div className={classNames(S.HelpPeopleItemCard, className)}>
      <div
        className={S.Image}
        style={{backgroundImage: `url(${image})`}}
      />
      <div className={S.Title}>{title}</div>
      <div className={S.Text}>{text}</div>
      <div className={S.Status}>
        <div className={S.Count}>{isPercent}% ( ${progress}/${goal})</div>
        <div className={S.Load}><span style={{width: `${isPercent}%`}}/></div>
      </div>
      <Link
        to={`/support-people/${id}`}
        className={S.ButtonText}
      >
        <span>BUY NFT</span>
        <MarketplaceButtonIcon size={24}/>
      </Link>

    </div>
  );
};
