import React from "react";

import ImgKostinkiy from "assets/img/Command/5.png";
import ImgMishchenko from "assets/img/Command/6.png";
import ImgBondar from "assets/img/Command/7.png";
import ImgTarieieva from "assets/img/Command/9.png";
import ImgBaiton from "assets/img/Command/4.png";
import ImgPotapenko from "assets/img/Command/1.png";
import ImgSuleymanov from "assets/img/Command/0.png";
import {classNames} from "utils";

import S from "./style.module.scss";

const commandList = [
  {
    img: ImgSuleymanov,
    firstname: "Tim",
    secondname: "Suleymanov",
    link: "#",
  },
  {
    img: ImgPotapenko,
    firstname: "Oleksii",
    secondname: "Potapenko",
    link: "#",
  },
  {
    img: ImgBaiton,
    firstname: "Gary",
    secondname: "Baiton",
    link: "#",
  },
  {
    img: ImgKostinkiy,
    firstname: "Mike",
    secondname: "Kostinkiy",
    link: "#",
  },
  {
    img: ImgMishchenko,
    firstname: "Ihor",
    secondname: "Mishchenko",
    link: "#",
  },
];


export const CharityCommand = () => {
  return (
    <div className={classNames(S.CharityCommand, "CharityWrapper")}>
      <div className={"CharityInnerWrapper"}>
        <div className={S.FlexBox}>
          <div className={S.LeftRow}>
            <div className={S.Title}>
              <div className={S.TextFirst}>WE ARE</div>
              <div className={S.TextSecond}>HERE TO HELP</div>
            </div>
            <div className={S.Description}>
              <p>Ukraine is now on the verge of humanitarian catastrophe. Despite that over 4 million Ukrainians are reported to have left the country, hundreds of thousands are still trapped in the occupied areas without any food, water and electricity. They require urgent humanitarian and medical assistance.</p>
              <p>The Project was created under the auspices of the Ministry of Digital Transformation of Ukraine and a deep engagement of Ukrainian show business, Celebrity alumni, as well as entrepreneurs, digital artists and the global fintech community.</p>
            </div>
          </div>
          <div className={S.RightRow}>
            {/*<div className={S.FlexLink}>*/}
            {/*  <Link to='/'>View all list Humanitarian Angels {'>'}</Link>*/}
            {/*</div>*/}
            <ul className={S.ListCommand}>
              {commandList.map((item, index) => (
                <li key={index}>
                  <span className={S.Item}>
                    <span className={S.Avatar}><img
                      src={item.img}
                      alt=""
                    /></span>
                    <span className={S.Name}>
                      <p className={S.First}>{item.firstname}</p>
                      <p className={S.Second}>{item.secondname}</p>
                    </span>
                    {/*<span className={S.Ico}>*/}
                    {/*  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*    <path d="M10.9375 5.06885C7.97456 5.06885 5.51953 7.48155 5.51953 10.4868C5.51953 13.4921 7.93223 15.9048 10.9375 15.9048C13.9428 15.9048 16.3555 13.4498 16.3555 10.4868C16.3555 7.52387 13.9005 5.06885 10.9375 5.06885ZM10.9375 13.9577C9.03276 13.9577 7.46662 12.3916 7.46662 10.4868C7.46662 8.58207 9.03276 7.01594 10.9375 7.01594C12.8423 7.01594 14.4084 8.58207 14.4084 10.4868C14.4084 12.3916 12.8423 13.9577 10.9375 13.9577Z" fill="black"/>*/}
                    {/*    <path d="M16.5674 6.16938C17.2453 6.16938 17.7949 5.61981 17.7949 4.94187C17.7949 4.26393 17.2453 3.71436 16.5674 3.71436C15.8894 3.71436 15.3398 4.26393 15.3398 4.94187C15.3398 5.61981 15.8894 6.16938 16.5674 6.16938Z" fill="black"/>*/}
                    {/*    <path d="M19.741 1.76719C18.6405 0.624331 17.0744 0.0317383 15.2966 0.0317383H6.57702C2.89448 0.0317383 0.439453 2.48676 0.439453 6.1693V14.8466C0.439453 16.6667 1.03205 18.2328 2.21723 19.3757C3.36009 20.4762 4.8839 21.0264 6.61935 21.0264H15.2543C17.0744 21.0264 18.5982 20.4339 19.6987 19.3757C20.8416 18.2751 21.4342 16.709 21.4342 14.8889V6.1693C21.4342 4.39153 20.8416 2.86772 19.741 1.76719ZM19.5717 14.8889C19.5717 16.2011 19.1061 17.2593 18.3442 17.9788C17.5823 18.6984 16.5241 19.0794 15.2543 19.0794H6.61935C5.34951 19.0794 4.2913 18.6984 3.5294 17.9788C2.76749 17.2169 2.38654 16.1587 2.38654 14.8466V6.1693C2.38654 4.89946 2.76749 3.84126 3.5294 3.07936C4.24898 2.35978 5.34951 1.97883 6.61935 1.97883H15.3389C16.6088 1.97883 17.667 2.35978 18.4289 3.12169C19.1484 3.88359 19.5717 4.94179 19.5717 6.1693V14.8889Z" fill="black"/>*/}
                    {/*  </svg>*/}
                    {/*</span>*/}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
