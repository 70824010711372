export const classNames = (...className: (number | string | Record<string, boolean> | undefined)[]): string => {
  return className.map((el) => {
    let classes = [];

    if (el) {
      if (typeof el === "string" || typeof el === "number") {
        classes.push(el);
      } else if (typeof el === "object") {
        Object.keys(el).forEach((objectEl) => {
          if (el[objectEl]) {
            classes.push(objectEl);
          }
        });
      }
    }

    return classes;
  }).join(" ");
};
