import React, {useContext, useState} from "react";

export interface IUseStore {
  isLoader: boolean;
  isPreloader: boolean;
  isFooterVisible: boolean;
  setIsLoader: (isLoader: boolean) => void;
  setIsPreloader: (isLoader: boolean) => void;
  setIsFooterVisible: (isFooterState: boolean) => void;
  token: string;
  isAlerts: boolean;
  setIsAlerts: (isLoading: boolean) => void;
}

export const StoreContext = React.createContext<IUseStore>({
  isLoader: false,
  isPreloader: true,
  isFooterVisible: false,
  setIsLoader (): void {
  },
  setIsPreloader (): void {
  },
  setIsFooterVisible (): void {
  },
  token: "",
  isAlerts: false,
  setIsAlerts (): void {
  },
});
StoreContext.displayName = "StoreContext";

export function useStore (): IUseStore {
  const StoreData = useContext(StoreContext);
  if (StoreData === null) {
    throw new Error("StoreData");
  }
  return StoreData;
}

interface IProps {
  children: React.ReactNode;
}

export const StoreProvider = ({children}: IProps) => {
  const [isLoader, setIsLoaderStore] = useState<boolean>((window.sessionStorage.getItem("loader") || "false") === "true" || false);
  const [isPreloader, setIsPreloader] = useState<boolean>(true);
  const [token] = useState<string>(sessionStorage.getItem("token") || "");
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);
  const [isAlerts, setIsAlerts] = useState<boolean>(false);

  const setLoader = (value: boolean) => {
    setIsLoaderStore(value);
    window.sessionStorage.setItem("loader", `${value}`);
  };

  return (
    <StoreContext.Provider
      value={{
        isLoader,
        isPreloader,
        setIsLoader: setLoader,
        setIsPreloader,
        token,
        isFooterVisible,
        setIsFooterVisible,
        isAlerts,
        setIsAlerts
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
