import React from "react";

import {ClientOnlyPortal} from "components/Popups";
import gifAnimation from "assets/img/animation.gif";
import {useStore} from "providers";
import {NotificationContainer} from "components/Notification";

interface IProps {
  name?: string;
}

export const Alerts = (
  {
    name = "#alerts",
  }:IProps) => {
  const {isAlerts} = useStore();

  return (
    <ClientOnlyPortal selector={name}>
      {isAlerts && (
        <div className={"fixed flex w-full h-full z-[100] bg-[rgba(17,17,17,0.9)] top-0 left-0"}>
          <img
            src={gifAnimation}
            alt=""
            className={"relative block max-w-[200px] h-auto m-auto"}
          />
        </div>
      )}
      <NotificationContainer/>
    </ClientOnlyPortal>
  );
};
