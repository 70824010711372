import React from "react";

interface IProps {
  fill?: string;
  size?: number;
}

export const CopyIcon = ({fill = "#fff", size = 16}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7344 15.4922H5.76562C4.79626 15.4922 4.00781 14.7037 4.00781 13.7344V5.76562C4.00781 4.79626 4.79626 4.00781 5.76562 4.00781H13.7344C14.7037 4.00781 15.4922 4.79626 15.4922 5.76562V13.7344C15.4922 14.7037 14.7037 15.4922 13.7344 15.4922ZM5.76562 5.17969C5.44254 5.17969 5.17969 5.44254 5.17969 5.76562V13.7344C5.17969 14.0575 5.44254 14.3203 5.76562 14.3203H13.7344C14.0575 14.3203 14.3203 14.0575 14.3203 13.7344V5.76562C14.3203 5.44254 14.0575 5.17969 13.7344 5.17969H5.76562ZM2.83594 10.8047H2.25C1.92691 10.8047 1.66406 10.5418 1.66406 10.2188V2.25C1.66406 1.92691 1.92691 1.66406 2.25 1.66406H10.2188C10.5418 1.66406 10.8047 1.92691 10.8047 2.25V2.80664H11.9766V2.25C11.9766 1.28064 11.1881 0.492188 10.2188 0.492188H2.25C1.28064 0.492188 0.492188 1.28064 0.492188 2.25V10.2188C0.492188 11.1881 1.28064 11.9766 2.25 11.9766H2.83594V10.8047Z"
        fill={fill}
      />
    </svg>
  );
};

export const CopyIconLarge = ({fill = "#000", size = 18}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0.75H3C2.17125 0.75 1.5 1.42125 1.5 2.25V12.75H3V2.25H12V0.75ZM14.25 3.75H6C5.17125 3.75 4.5 4.42125 4.5 5.25V15.75C4.5 16.5788 5.17125 17.25 6 17.25H14.25C15.0788 17.25 15.75 16.5788 15.75 15.75V5.25C15.75 4.42125 15.0788 3.75 14.25 3.75ZM14.25 15.75H6V5.25H14.25V15.75Z"
        fill={fill}
      />
    </svg>

  );
};
