import React, {useState} from "react";

import BannerNftim from "assets/img/profile-banner-nftim.jpg";
import {SharePopup} from "components/Popups/SharePopup";
import {ProfileSideBar} from "components/Profile/ProfileSideBar";
import {
  ProfileProvider,
} from "providers";
import {Container} from "components/Container";
import {ProfileContent} from "components/Profile/ProfileContent";

import S from "./style.module.scss";

const ProfileWrapper = () => {
  const [isSharePopup, toggleIsSharePopup] = useState(false);

  return (
    <div className={S.Wrapper}>
      <div
        className={S.BackgroundImg}
        style={{backgroundImage: `url(${BannerNftim})`}}
      />

      <Container>
        <div className={S.FlexBox}>
          <ProfileSideBar/>
          <ProfileContent/>
        </div>
        {isSharePopup && <SharePopup onClose={() => toggleIsSharePopup(false)}/>}
      </Container>
    </div>
  );
};

export const Profile = () => {
  return (
    <ProfileProvider>
      <ProfileWrapper/>
    </ProfileProvider>
  );
};
