import React from "react";
import {Route, Routes} from "react-router-dom";

import S from "./style.module.scss";
import {PageFaq} from "./Pages/PageFaq";
import {PageFaqAnswer} from "./Pages/PageFaqAnswer";
import {WalletCoinbase} from "./Pages/WalletCoinbase";
import {WalletMetamask} from "./Pages/WalletMetamask";
import {BlockchainFAQ} from "./Pages/Blockchain";
import {WhatNftFAQ} from "./Pages/WhatNFT";
import {CryptoFAQ} from "./Pages/Crypto";

export const HelpFAQ = () => {
  return (
    <div className={S.HelpCenterContent}>
      <div className={S.HelpCenterContainer}>
        <Routes>
          <Route
            path={"/*"}
            element={<PageFaq/>}
          />
          <Route
            path={"/faq1"}
            element={<PageFaqAnswer/>}
          />
          <Route
            path={"/wallet-coinbase"}
            element={<WalletCoinbase/>}
          />
          <Route
            path={"/wallet-metamask"}
            element={<WalletMetamask/>}
          />
          <Route
            path={"/blockchain"}
            element={<BlockchainFAQ/>}
          />
          <Route
            path={"/what-nft"}
            element={<WhatNftFAQ/>}
          />
          <Route
            path={"/crypto"}
            element={<CryptoFAQ/>}
          />
        </Routes>
      </div>
    </div>
  );
};
