import React, {ReactNode} from "react";

import {classNames} from "utils";
import {NotificationIcon} from "components/NotificationCenter/NotificationIcon";

import {NotificationProps, TNotificationPropsTypes} from "./types";
import S from "./style.module.scss";

const getNotificationIconByType = (
  iconType: TNotificationPropsTypes,
  icon: ReactNode
) => {
  switch (iconType) {
    case "custom":
      return <>{icon}</>;
    default:
      return <NotificationIcon
        notificationType={iconType}
        size={36}
      />;
  }
};

export const Message = ({
  title,
  subtitle,
  type = "success",
  icon
}: NotificationProps) => {
  return (
    <div
      className={S.Notification}
      data-test-id="notification"
    >
      <div className={classNames(S.NotificationIcon, S[type])}>
        {getNotificationIconByType(type, icon)}
      </div>
      <div>
        {title && <div className={S.NotificationTitle}>{title}</div>}
        {subtitle && <div className={S.NotificationSubtitle}>{subtitle}</div>}
      </div>
    </div>
  );
};
