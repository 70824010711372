import React from "react";

import {IAsset} from "types";
import {AssetMainItemSkeleton} from "pages/Asset/AssetSkeleton/AssetMainItemSkeleton";
import {Video} from "components/Video";
import {useTheme} from "hooks/useTheme";
import {classNames} from "utils";
import {AssetZoom} from "pages/Asset/Zoom";

import S from "./style.module.scss";

interface IProps {
  asset?: IAsset;
}

export const MediaBlock = ({asset}: IProps) => {

  const renderVideo = () => {
    return (
      <Video
        className={S.Video}
        src={asset?.ipfsFull || ""}
        poster={asset?.thumbnail || ""}
        autoplay={true}
        controls={true}
      />
    );
  };

  const renderImg = () => {
    if (asset?.fileExtension === "gif") {
      return <img
        src={asset?.ipfsFull || ""}
        alt={asset?.title || process.env.REACT_APP_TITLE}
      />;
    }

    return <img
      src={asset?.thumbnail || asset?.ipfsFull || ""}
      alt={asset?.title || process.env.REACT_APP_TITLE}
    />;
  };

  const theme = useTheme();

  if (asset) {
    return (
      <div className={classNames(S.PhotoWrapper, S[theme])}>
        <div className={S.MediaBlock}>
          <div
            className={S.Before}
            style={{backgroundImage: `url(${asset?.thumbnail})`}}
          />
          {asset.ipfsType === "video" ? renderVideo() : renderImg()}
        </div>
        <div className={S.ZoomBox}>
          <AssetZoom/>
        </div>
      </div>
    );
  }

  return (
    <AssetMainItemSkeleton/>
  );
};
