import React from "react";

export type TReactAppType = "nftim" | "help_ua" | "help_ua_dev" | "in_progress";

interface IProps {
  value: TReactAppType[];
  children?: React.ReactNode;
}

export const useViewBlock = ({value}: Omit<IProps, "children">) => {
  const type = (process.env.REACT_APP_TYPE || "nftim") as TReactAppType;

  return value.indexOf(type) >= 0;
};

export const ViewBlock = ({value, children}: IProps) => {
  const hasView = useViewBlock({value});

  if (hasView) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <></>
  );
};
