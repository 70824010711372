import React from "react";

interface IProps {
  color?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  className?: string;
  borderColor?: string;
}

export const VideoIcon = ({
  onClick,
  className,
  width = "50",
  height = "50",
}: IProps) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M44.0888 19.9631L10.3652 0.769678C6.78797 -1.27256 3.14087 0.96093 3.14087 4.97723V45.0454C3.14087 48.448 5.35607 49.9997 7.41494 49.9997C8.38451 49.9997 9.37237 49.6937 10.3536 49.0966L44.2285 28.3616C45.9315 27.3155 46.8877 25.7672 46.8578 24.1124C46.8295 22.456 45.8217 20.9426 44.0888 19.9631ZM41.9584 24.6579L8.08516 45.388C7.85732 45.5277 7.67771 45.5975 7.55464 45.6325C7.52138 45.5094 7.48812 45.3198 7.48812 45.0454V4.97889C7.48812 4.56645 7.56295 4.35857 7.56295 4.30701C7.696 4.31533 7.92883 4.38351 8.22153 4.54982L41.9401 23.7432C42.3559 23.9811 42.479 24.1873 42.5189 24.1424C42.4973 24.1989 42.3559 24.4101 41.9584 24.6579Z"
      fill="white"
    />
  </svg>
);
