import React from "react";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {Message} from "./Message";
import {NotificationProps, NotificationContainerProps} from "./types";
import S from "./style.module.scss";

export const NotificationContainer = (props: NotificationContainerProps) => (
  <ToastContainer
    {...props}
    className={S.NotificationWrapper}
    position="top-right"
    draggablePercent={40}
    hideProgressBar
    closeButton={false}
  />
);

export const showNotification = ({
  title,
  subtitle,
  type,
  icon
}: NotificationProps) => {
  toast(<Message
    title={title}
    subtitle={subtitle}
    type={type}
    icon={icon}
  />);
};
