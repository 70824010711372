import React, { FC } from "react";

interface IProps {
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  className?: string;
  color?: string;
}

export const Cross: FC<IProps> = ({
  onClick,
  className,
  color = "#D13F67",
  ...rest
}) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21967 4.398C4.51256 4.10366 4.98744 4.10366 5.28033 4.398L12 11.1508L18.7197 4.398C19.0126 4.10366 19.4874 4.10366 19.7803 4.398C20.0732 4.69233 20.0732 5.16955 19.7803 5.46388L13.0607 12.2167L19.7803 18.9694C20.0732 19.2638 20.0732 19.741 19.7803 20.0353C19.4874 20.3296 19.0126 20.3296 18.7197 20.0353L12 13.2825L5.28033 20.0353C4.98744 20.3296 4.51256 20.3296 4.21967 20.0353C3.92678 19.741 3.92678 19.2638 4.21967 18.9694L10.9393 12.2167L4.21967 5.46388C3.92678 5.16955 3.92678 4.69233 4.21967 4.398Z"
        fill="#565661"
      />
    </svg>
  );
};
