import React, {useRef, useState} from "react";

import {classNames} from "utils";

import S from "./style.module.scss";

interface IProps {
  className?: string;
  description?: string;
  format?: object | any;
  image?: string | any;
  imageSet?: string | any;
  name?: string;
}

export const AddImages = (
  {
    className,
    description,
    format,
    image,
    imageSet,
    name
  }: IProps) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [isError, setError] = useState<boolean>(false);

  const addFile = () => {
    if (fileRef.current?.files && fileRef.current?.files[0]) {
      let fileArray = fileRef.current?.files[0];
      let item = format && format.filter((el: any) => el === fileArray.type);
      if (item.length !== 0) {
        setError(false);
        image(URL.createObjectURL(fileArray));
        imageSet(fileArray);
      } else {
        setError(true);
        image("");
        imageSet("");
      }
    }
  };

  const accept = format && format.map((item: any) => item);

  return (
    <div className={classNames(S.StepLoad, className, {[S.Error]: isError})}>
      <div className={S.Text}>{description}</div>
      <div className={S.LoadButton}>
        <span>Choose File</span>
        <input
          type="file"
          name={name}
          onChange={addFile}
          ref={fileRef}
          accept={accept}
        />
      </div>
    </div>
  );
};
