import React from "react";

import {IIcon} from "assets/icons/type";

interface IProps extends IIcon {

}

export const MetamaskIcon = ({size = 36}: IProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1442_25729)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3276 40.8601L20.8284 42.5849V40.3295L21.359 39.7988H25.0736V42.4521V44.3094H21.0937L16.1849 42.1868L14.3276 40.8601Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5542 40.8601L29.1863 42.5849V40.3295L28.6556 39.7988H24.941V42.4521V44.3094H28.9209L33.8294 42.1868L35.5542 40.8601Z"
        fill="#CDBDB2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3593 35.9515L20.8286 40.3294L21.4918 39.7987H28.3904L29.1864 40.3294L28.6557 35.9515L27.5944 35.2881L22.2878 35.4209L21.3593 35.9515Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7773 6.36719L20.9613 13.7964L22.4207 35.421H27.5946L29.1865 13.7964L32.1052 6.36719H17.7773Z"
        fill="#F89C35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84743 22.685L0.132812 33.4308L9.41937 32.9001H15.3892V28.257L15.1238 18.7051L13.7972 19.7664L3.84743 22.685Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8784 23.8789L21.757 24.1442L20.5631 29.7162L15.389 28.3895L10.8784 23.8789Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8784 24.0117L15.389 28.257V32.5023L10.8784 24.0117Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3892 28.3896L20.6958 29.7163L22.4205 35.421L21.2264 36.0842L15.3892 32.6349V28.3896Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.389 32.6348L14.3276 40.86L21.359 35.9515L15.389 32.6348Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7573 24.1445L22.4208 35.4212L20.4307 29.6502L21.7573 24.1445Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28662 32.7675L15.3892 32.6348L14.3279 40.86L9.28662 32.7675Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.05144 44.4421L14.3279 40.86L9.28659 32.7676L0.132812 33.4308L3.05144 44.4421Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.961 13.7969L15.2565 18.5728L10.8784 23.8794L21.757 24.2775L20.961 13.7969Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3276 40.8597L21.359 35.9512L20.8283 40.1965V42.5844L16.0524 41.6557L14.3276 40.8597Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5542 40.8597L28.6556 35.9512L29.1863 40.1965V42.5844L33.9622 41.6557L35.5542 40.8597Z"
        fill="#DFCEC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8384 26.5322L20.2979 29.5836L15.1238 28.257L18.8384 26.5322Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91846 0.131836L20.9609 13.7962L17.9097 6.36698L2.91846 0.131836Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91873 0.131836L0.530762 7.42842L1.85741 15.3883L0.928757 15.919L2.25541 17.113L1.19409 18.0416L2.6534 19.3683L1.72475 20.1643L3.84739 22.8176L13.7973 19.7663C18.6617 15.8748 21.0496 13.8848 20.9612 13.7963C20.8727 13.7079 14.8586 9.15306 2.91873 0.131836Z"
        fill="#8E5A30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0349 22.685L49.7495 33.4308L40.463 32.9001H34.4931V28.257L34.7585 18.7051L36.0851 19.7664L46.0349 22.685Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0039 23.8789L28.1254 24.1442L29.3192 29.7162L34.4933 28.3895L39.0039 23.8789Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0039 24.0117L34.4933 28.257V32.5023L39.0039 24.0117Z"
        fill="#EA8D3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4932 28.3896L29.1866 29.7163L27.4618 35.421L28.6559 36.0842L34.4932 32.6349V28.3896Z"
        fill="#F89D35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4934 32.6348L35.5547 40.86L28.6561 36.0841L34.4934 32.6348Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.125 24.1445L27.4616 35.4212L29.4517 29.6502L28.125 24.1445Z"
        fill="#EA8E3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5957 32.7675L34.4931 32.6348L35.5544 40.86L40.5957 32.7675Z"
        fill="#D87C30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8309 44.4421L35.5545 40.86L40.5957 32.7676L49.7495 33.4308L46.8309 44.4421Z"
        fill="#EB8F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9214 13.7969L34.6258 18.5728L39.0039 23.8794L28.1254 24.2775L28.9214 13.7969Z"
        fill="#E8821E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0439 26.5322L29.5845 29.5836L34.7585 28.257L31.0439 26.5322Z"
        fill="#393939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9639 0.131836L28.9214 13.7962L31.9726 6.36698L46.9639 0.131836Z"
        fill="#E88F35"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9636 0.131836L49.3516 7.42842L48.0249 15.3883L48.9536 15.919L47.6269 17.113L48.6882 18.0416L47.2289 19.3683L48.1576 20.1643L46.0349 22.8176L36.0851 19.7663C31.2207 15.8748 28.8327 13.8848 28.9211 13.7963C29.0096 13.7079 35.0237 9.15306 46.9636 0.131836Z"
        fill="#8E5A30"
      />
    </g>
    <defs>
      <clipPath id="clip0_1442_25729">
        <rect
          width="50"
          height="44.5755"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
