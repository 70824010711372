import React from "react";

import {IIcon} from "../type";

interface IProps extends IIcon {
}

export const SadFaceIcon = (
  {
    size = 52,
    fill = "#000"
  }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size}
      height={size}
    >
      <g id="box">
        <path
          d="M50.208 33.92a19 19 0 1 0-32.414.003"
          fill={"none"}
          stroke={fill}
          strokeLinejoin={"round"}
          strokeWidth={"2px"}
          strokeLinecap={"round"}
        />
        <path
          d="M42.361 33.955a13 13 0 1 0-16.687.03"
          fill={"none"}
          stroke={fill}
          strokeLinejoin={"round"}
          strokeWidth={"2px"}
          strokeLinecap={"round"}
        />
        <path
          d="M36 21h-3v6M36 24h-3M30 25l-4-4v6M30 21v6M39 21h4M41 27v-6M10 42.338V60h46V42.338M5 42h32l5-8H10l-5 8zM61 42H47l-5-8h14l5 8zM29 55h9"
          fill={"none"}
          stroke={fill}
          strokeLinejoin={"round"}
          strokeWidth={"2px"}
          strokeLinecap={"round"}
        />
        <circle
          cx="7.5"
          cy="23.5"
          r="1.132"
        />
        <path
          d="m7.586 10.586 2.828 2.828M10.414 10.586l-2.828 2.828"
          fill={"none"}
          stroke={fill}
          strokeLinejoin={"round"}
          strokeWidth={"2px"}
          strokeLinecap={"round"}
        />
        <circle
          cx="34"
          cy="8"
          r="1"
        />
        <circle
          cx="50"
          cy="24"
          r="1"
        />
        <circle
          cx="18"
          cy="24"
          r="1"
        />
        <circle
          cx="22.686"
          cy="12.686"
          r="1"
        />
        <circle
          cx="45.314"
          cy="12.686"
          r="1"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M42 60V35"
        />
      </g>
    </svg>
  );
};
